import { PostboxDocument } from '@cp-de/common';
import { ActiveLeasingContract } from '../contracts/examples/ExampleData';

export const PostboxDocumentNotContractRelated: PostboxDocument = {
    description: 'Bankverbindungsänderung',
    creationDate: '2020-07-01',
    _downloadLink: '/postbox-document-123-download',
};

export const PostboxWithAllValues: PostboxDocument[] = [
    PostboxDocumentNotContractRelated,
    {
        description: 'Leasing statement',
        creationDate: '2019-12-01',
        _downloadLink: '/postbox-document-bank-account-change-1',
        contractNumber: ActiveLeasingContract.contractNumber,
    },
    {
        description: 'Leasing statement',
        creationDate: '2019-11-01',
        _downloadLink: '/postbox-document-bank-account-change-2',
        contractNumber: ActiveLeasingContract.contractNumber,
    },
    {
        description: 'Leasing statement',
        creationDate: '2019-10-01',
        _downloadLink: '/postbox-document-bank-account-change-2',
        contractNumber: ActiveLeasingContract.contractNumber,
    },
    {
        description: 'Leasing statement',
        creationDate: '2019-09-01',
        _downloadLink: '/postbox-document-bank-account-change-3',
        contractNumber: ActiveLeasingContract.contractNumber,
    },
    {
        description: 'Leasing statement',
        creationDate: '2018-07-01',
        _downloadLink: '/postbox-document-bank-account-change-4',
        contractNumber: ActiveLeasingContract.contractNumber,
    },
    {
        description: 'Leasing statement',
        creationDate: '2018-06-01',
        _downloadLink: '/postbox-document-bank-account-change-5',
        contractNumber: ActiveLeasingContract.contractNumber,
    },
    {
        description: 'Leasing statement',
        creationDate: '2018-05-01',
        _downloadLink: '/postbox-document-bank-account-change-6',
        contractNumber: ActiveLeasingContract.contractNumber,
    },
    {
        description: 'Leasing statement',
        creationDate: '2018-04-01',
        _downloadLink: '/postbox-document-bank-account-change-7',
        contractNumber: ActiveLeasingContract.contractNumber,
    },
];

export const EmptyPostboxList: PostboxDocument[] = [];
