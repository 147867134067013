import { CleaveInput, UiBlockingSpinner } from '@cp-shared-8/frontend-ui';
import { FormikProps, useFormikContext } from 'formik';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';
import React from 'react';
import { IbanStateHandler } from '../../../types';
import { InitialValues } from '../../initialValues';

export type IbanValidationViewProps = {
    isValidating: boolean;
    ibanStateHandler: IbanStateHandler;
};

const translationPrefix = 'iban-section';
const translationIbanPrefix = `${translationPrefix}.bank-account-validation.iban-change-input`;
const cleaveOptionsIban = {
    delimiter: ' ',
    blocks: [4, 4, 4, 4, 4, 4, 4, 4],
    numericOnly: false,
};

export const IbanValidationView: React.FC<IbanValidationViewProps> = ({ isValidating, ibanStateHandler }) => {
    const { t } = useTranslationWithFormatting('total-early-settlement');
    const formik: FormikProps<InitialValues> = useFormikContext();
    const { signedBankData } = ibanStateHandler;

    setTimeout(() => formik.setFieldTouched('iban'), 0);

    return (
        <UiBlockingSpinner isBlocking={isValidating}>
            <CleaveInput
                cleaveOptions={cleaveOptionsIban}
                label={t(`${translationIbanPrefix}.text`)}
                name="iban"
                testId="iban"
                placeholder={t(`${translationIbanPrefix}.placeholder`)}
                stateIcon
            />
            {signedBankData?.data.isValid && (
                <p className="u-text-success">{signedBankData.data.bankDetails?.bankName}</p>
            )}
        </UiBlockingSpinner>
    );
};
