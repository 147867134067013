import { Contract } from '@cp-de/common';
import React from 'react';
import { Card, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ContractHeader } from '../../../contract-header';
import { MissingInformationView } from './missing-information-view';
import { CPDate } from '@cp-shared-8/common-utilities';
import { DealerInformationView } from './dealer-information-view';
import { SilentExtensionView } from './silent-extension-view';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { dashboardPagePath } from '../../../navigation/paths';
import { useFinancialDetails } from '../../financial-details';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

type ContractSilentExtensionProps = {
    contract: Contract;
    email?: string;
};

export const ContractSilentExtension: React.FC<ContractSilentExtensionProps> = ({ contract, email }) => {
    const { t } = useTranslation('contract-silent-extension');
    const history = useHistory();

    const backToDashboard = () => {
        history.push(dashboardPagePath());
    };

    useAnalyticsPageViewTracker('silentContractExtension');

    const { dealer, contractId, endDate } = contract;

    const { data: financialDetails } = useFinancialDetails(contractId || '');

    const locationState = useLocation().state as { dateValues: CPDate[] };

    const dateValues = locationState?.dateValues;

    const getCurrentView = () => {
        if (!locationState) {
            backToDashboard();
        }
        if (!!dateValues) {
            return (
                <SilentExtensionView
                    contractId={contractId}
                    backToDashboard={backToDashboard}
                    contractEndDate={endDate}
                    contractExtensionEnd={financialDetails?.contractExtensionEnd}
                    dateValues={dateValues}
                    email={email}
                />
            );
        } else if (dealer && !isEmpty(dealer)) {
            return <DealerInformationView dealer={dealer} backToDashboard={backToDashboard} />;
        } else {
            return <MissingInformationView backToDashboard={backToDashboard} />;
        }
    };

    return (
        <>
            <Heading level={1}>{t('heading')}</Heading>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <Card element="article">
                        <ContractHeader isDeferralLinkShown={false} contract={contract} />
                    </Card>
                </Layout.Item>
                {getCurrentView()}
            </Layout>
        </>
    );
};
