import { InsuranceValidationError, ReportDamageValidationError } from './types';

export const defaultInsuranceError: InsuranceValidationError = {
    name: { required: 'name is required' },
    address: {
        streetName: { required: 'streetName is required' },
        houseNumber: { required: 'houseNumber is required' },
        postOfficeBox: { required: 'postOfficeBox is required' },
        zipCode: { required: 'zipCode is required' },
        locality: { required: 'locality is required' },
    },
    contact: {
        email: { required: 'email is required', email: 'email format invalid' },
    },
    claimId: { required: 'claimId is required' },
};

export const defaultReportDamageValidationError: ReportDamageValidationError = {
    claimType: { required: 'claimType is required', oneOf: '' },
    responsibleInsurance: { required: 'responsibleInsurance is required', oneOf: '' },
    insurance: { ...defaultInsuranceError, invalid: 'invalid insurance object' },
};
