import React from 'react';
import { ArrearPaymentUi } from './ui/ArrearPaymentUi';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { useContract } from '../contracts/useContract';
import { missingFields } from '../contracts/missingFields';
import { useFinancialDetails } from '../contracts/financial-details';
import { ArrearPaymentLoadingPlaceholder } from './skeleton/ArrearPaymentLoadingPlaceholder';

const ArrearPaymentWithHandlers = withLoadingAndNoConnectionHandler(ArrearPaymentUi);

type ArrearPaymentProps = {
    contractId: string;
};

export const ArrearPayment: React.FC<ArrearPaymentProps> = ({ contractId }) => {
    const { data: contractState, isLoading: isContractLoading, loadingError: contractLoadingError } = useContract(
        contractId,
    );

    const {
        data: financialDetails,
        isLoading: isFinancialDetailsLoading,
        loadingError: financialDetailsLoadingError,
    } = useFinancialDetails(contractState?.contractId || '');

    const missingFieldsError = missingFields(contractState);
    const loadingError = contractLoadingError || financialDetailsLoadingError || missingFieldsError;
    const isLoading = isContractLoading || isFinancialDetailsLoading;

    return (
        <ArrearPaymentWithHandlers
            isLoading={isLoading}
            contract={contractState}
            financialDetails={financialDetails}
            hasError={!!loadingError}
            loadingPlaceholder={<ArrearPaymentLoadingPlaceholder />}
        />
    );
};
