import React from 'react';
import { DashboardMarketingCard, Dealer } from '@cp-de/common';
import { Carousel } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { FlickityOptions } from 'react-flickity-component';
import { MarketingCardComponent } from '../marketing-card-component';

type DashboardMarketingCardsUiProps = {
    dashboardMarketingCard?: DashboardMarketingCard;
    productRoutingUrl?: string;
    userWithFinancialContractsOnly: boolean;
    userWithFinancialContracts: boolean;
    userWithYoungLeasingContract: boolean;
    userWithYoungFinancialContractPriceProtection: boolean;
    userWithAutoAbo: boolean;
    dealer?: Dealer;
};

export const DashboardMarketingCardsUi: React.FC<DashboardMarketingCardsUiProps> = ({
    dashboardMarketingCard,
    userWithFinancialContracts,
    userWithYoungLeasingContract,
    userWithYoungFinancialContractPriceProtection,
    userWithAutoAbo,
    dealer,
}) => {
    const { t } = useTranslation('marketing-card');

    if (!dashboardMarketingCard) {
        return null;
    }

    const { title = '', imageUrl = '', buttonLabel = '', buttonUrl = '', text = '' } = dashboardMarketingCard;

    const options: FlickityOptions = {
        adaptiveHeight: false,
        autoPlay: 10000,
    };

    return (
        <>
            {dealer && (
                <p>
                    <Carousel options={options}>
                        <MarketingCardComponent
                            title={t('financial-contract-dealer.title')}
                            imageSrc={t('financial-contract-dealer.imageUrl')}
                        >
                            <>
                                <div>{dealer?.name}</div>
                                <div className="u-mt">
                                    {dealer?.address?.street &&
                                        dealer?.address?.street + ' ' + dealer?.address?.houseNumber}
                                </div>
                                <div>
                                    {dealer?.address?.zipCode && dealer?.address?.zipCode + ' ' + dealer?.address?.city}
                                </div>
                                <div className="u-mt">{dealer?.contact?.phone && 'Tel: ' + dealer?.contact?.phone}</div>
                            </>
                        </MarketingCardComponent>
                    </Carousel>
                </p>
            )}
            <Carousel options={options}>
                <MarketingCardComponent
                    title={title}
                    imageSrc={imageUrl}
                    buttonLabel={buttonLabel}
                    buttonUrl={buttonUrl}
                >
                    {text}
                </MarketingCardComponent>
                {userWithFinancialContracts && (
                    <MarketingCardComponent
                        title={t('young-finacial-contract-guarantee.title')}
                        imageSrc={t('young-finacial-contract-guarantee.imageUrl')}
                        buttonLabel={t('young-finacial-contract-guarantee.button.title')}
                        buttonUrl={t('young-finacial-contract-guarantee.button.link')}
                    >
                        {t('young-finacial-contract-guarantee.text')}
                    </MarketingCardComponent>
                )}
                {userWithYoungFinancialContractPriceProtection && (
                    <MarketingCardComponent
                        title={t('young-finacial-contract-price-protection.title')}
                        imageSrc={t('young-finacial-contract-price-protection.imageUrl')}
                        buttonLabel={t('young-finacial-contract-price-protection.button.title')}
                        buttonUrl={t('young-finacial-contract-price-protection.button.link')}
                    >
                        {t('young-finacial-contract-price-protection.text')}
                    </MarketingCardComponent>
                )}
                {userWithYoungLeasingContract && (
                    <MarketingCardComponent
                        title={t('young-leasing-contract.title')}
                        imageSrc={t('young-leasing-contract.imageUrl')}
                        buttonLabel={t('young-leasing-contract.button.title')}
                        buttonUrl={t('young-leasing-contract.button.link')}
                    >
                        {t('young-leasing-contract.text')}
                    </MarketingCardComponent>
                )}
                {!userWithAutoAbo && (
                    <MarketingCardComponent
                        title={t('user-with-auto-abo.title')}
                        imageSrc={t('user-with-auto-abo.imageUrl')}
                        buttonLabel={t('user-with-auto-abo.button.title')}
                        buttonUrl={t('user-with-auto-abo.button.link')}
                    >
                        {t('user-with-auto-abo.text')}
                    </MarketingCardComponent>
                )}
                <MarketingCardComponent
                    title={t('thg-quote.title')}
                    imageSrc={t('thg-quote.imageUrl')}
                    buttonLabel={t('thg-quote.button.title')}
                    buttonUrl={t('thg-quote.button.link')}
                >
                    {t('thg-quote.text')}
                </MarketingCardComponent>
                <MarketingCardComponent
                    title={t('mobility-platform.title')}
                    imageSrc={t('mobility-platform.imageUrl')}
                    buttonLabel={t('mobility-platform.button.title')}
                    buttonUrl={t('mobility-platform.button.link')}
                >
                    {t('mobility-platform.text')}
                </MarketingCardComponent>
            </Carousel>
        </>
    );
};
