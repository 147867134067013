import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CancelAoDReservation, CancelRaCReservation } from '../../components/cancel-reservation';
import { UserType } from '@cp-de/common';

interface RouteParams {
    contractId: string;
    userType: string;
}

export const CancelReservationPage: React.FC = () => {
    const { contractId, userType } = useParams<RouteParams>();
    const { t } = useTranslation('cancel-reservation');
    const isAoD = userType === UserType.AOD.toLocaleLowerCase();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{isAoD ? t('headline.aod') : t('headline.rac')}</Heading>
            {isAoD ? (
                <CancelAoDReservation contractId={contractId} />
            ) : (
                <CancelRaCReservation contractId={contractId} />
            )}
        </ContentSection>
    );
};
