import { Dealer } from '@cp-de/common';
import React from 'react';

export const DealerDetails: React.FC<Pick<Dealer, Exclude<keyof Dealer, 'type'>>> = ({ name, address, contact }) => {
    return (
        <span>
            <br />
            <strong>{name}</strong>
            <br />
            <strong>{`${address?.street} ${address?.houseNumber}`}</strong>
            {(address?.street || address?.houseNumber) && <br />}
            <strong>{`${address?.zipCode} ${address?.city}`}</strong>
            {(address?.zipCode || address?.city) && <br />}
            <strong>
                {contact?.email !== undefined && contact?.email !== '' ? (
                    <>
                        {`E-Mail: ${contact?.email}`}
                        <br />
                    </>
                ) : (
                    ''
                )}
            </strong>
            <strong>
                {contact?.phone !== undefined && contact?.phone !== '' ? (
                    <>
                        {`Tel.: ${contact?.phone}`}
                        <br />
                    </>
                ) : (
                    ''
                )}
            </strong>
        </span>
    );
};
