import { Contract, EligibleForMileageChangeType, FinancialDetails } from '@cp-de/common';
import { changeMileagePath } from '../../../navigation/paths';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { ConsultView } from './consult-view';
import { EditStatus } from '../base-section/EditStatus';
import { MileageEditView } from './edit-view/mileage-edit-view';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { MileageInfoView } from './edit-view/mileage-info-view';

export type MileageSectionProps = {
    contract: Contract;
    financialDetails: FinancialDetails;
    isInactive?: boolean;
};

export const MileageSection: React.FC<MileageSectionProps> = ({ contract, financialDetails, isInactive }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const { onAction } = useAnalyticsActionTracker('financialDetailsEditMileageSection');
    const { onAction: onSuccess } = useAnalyticsActionTracker('financialDetailsEditMileageSectionSuccess');

    const startEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        onAction();
        history.push(changeMileagePath(contract.contractId));
    };

    const cancelEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (lastEditStatus: EditStatus): void => {
        if (lastEditStatus === EditStatus.SUCCESS) {
            onSuccess();
        }
        setLastEditStatus(lastEditStatus);
        history.push(path);
    };

    return (
        <Switch>
            {financialDetails.eligibleForMileageChange === EligibleForMileageChangeType.YES && (
                <Route exact path={changeMileagePath(contract.contractId)}>
                    <MileageEditView
                        financialDetails={financialDetails}
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                    />
                </Route>
            )}
            {financialDetails.eligibleForMileageChange !== EligibleForMileageChangeType.YES && (
                <Route exact path={changeMileagePath(contract.contractId)}>
                    <MileageInfoView
                        contract={contract}
                        financialDetails={financialDetails}
                        cancelEditing={cancelEditing}
                    />
                </Route>
            )}
            <Route path={path}>
                <ConsultView
                    financialDetails={financialDetails}
                    startEditing={startEditing}
                    lastEditStatus={lastEditStatus}
                    isInactive={isInactive}
                />
            </Route>
        </Switch>
    );
};
