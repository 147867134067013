import React from 'react';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { TheftFormValues, TheftStep1FormValues, TheftStep2FormValues } from '../types';
import { Step1FormView } from './step1-form-view';
import { Step2FormView } from './step2-form-view';
import { ExpertReportStep1FormValues } from '../../expert-report/types';

type TheftFormViewProps = {
    innerStep: number;
    handleNext: () => void;
    handlePrev: () => void;
    handleInnerNext: () => void;
    handleInnerPrev: () => void;
    theftValues: TheftFormValues;
    saveFormValues: (values: TheftStep1FormValues | TheftStep2FormValues) => void;
    contractId: string;
    isAccipiens: boolean;
};

export const TheftFormView: React.FC<TheftFormViewProps> = ({
    innerStep,
    handleNext,
    handlePrev,
    handleInnerNext,
    handleInnerPrev,
    theftValues,
    saveFormValues,
    contractId,
    isAccipiens,
}) => {
    useAnalyticsPageViewTracker('reportDamageTheft');

    const onStep1Next = (values: TheftStep1FormValues): void => {
        saveFormValues(values);
        handleInnerNext();
    };

    const onStep1Prev = (values: ExpertReportStep1FormValues): void => {
        saveFormValues(values);
        handlePrev();
    };

    const onStep2Next = (values: TheftStep2FormValues): void => {
        saveFormValues(values);
        handleNext();
    };

    const onStep2Prev = (values: TheftStep2FormValues): void => {
        saveFormValues(values);
        handleInnerPrev();
    };

    return innerStep === 1 ? (
        <Step1FormView
            handleNext={onStep1Next}
            handlePrev={onStep1Prev}
            theftValues={theftValues}
            contractId={contractId}
            isAccipiens={isAccipiens}
        />
    ) : (
        <Step2FormView
            isAccipiens={isAccipiens}
            handleNext={onStep2Next}
            handlePrev={onStep2Prev}
            theftValues={theftValues}
        />
    );
};
