import { Card, ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { ContractHeaderLoadingPlaceholder, Skeleton } from '@cp-shared-8/frontend-ui';
import React from 'react';

export const CancelReservationLoadingPlaceholder: React.FC = () => (
    <>
        <Card element="article">
            <ContractHeaderLoadingPlaceholder summaryElements={1} />
        </Card>
        <ContentSection className={'u-text-center'} pageWrapSize="medium">
            <Layout>
                <Layout.Item>
                    <Skeleton count={1} height={40} />
                </Layout.Item>
            </Layout>
            <Skeleton height={40} width={200} className="u-mt u-m-xsmall" count={2} />
        </ContentSection>
    </>
);
