import React from 'react';
import { TileSelect, TileSelectGroup } from '@vwfs-bronson/bronson-react';
import { AuthorityAddress } from '@cp-de/common';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

export const testIds = {
    selectItem: 'selectItem',
};

type LicensingAuthoritiesProps = {
    licensingAuthorities?: AuthorityAddress[];
    showRoadTrafficOfficesInfo?: boolean;
    onSelect: (index: number) => void;
};

export const LicensingAuthorities: React.FC<LicensingAuthoritiesProps> = ({
    licensingAuthorities,
    showRoadTrafficOfficesInfo,
    onSelect,
}) => {
    const { t } = useTranslation('my-profile');

    if (!licensingAuthorities) {
        return null;
    }

    return (
        <>
            {showRoadTrafficOfficesInfo && !isEmpty(licensingAuthorities) && (
                <div className="u-mv">{t('request-registration-certificate.info-text-for-licensing-authorities')}</div>
            )}
            <TileSelectGroup layoutItemClassName={'u-1/3  u-1/3@l  u-1/1@s'}>
                {licensingAuthorities.map((authority, index) => (
                    <TileSelect
                        key={`authority_${index}`}
                        inputType="radio"
                        radioGroupName="tile-select-group"
                        defaultChecked={index === 0}
                        testId={testIds.selectItem + index}
                        onClick={(): void => {
                            onSelect(index);
                        }}
                    >
                        <div className={'u-text-left'}>
                            <div>
                                <b>{authority.name}</b>
                            </div>
                            <div className={'u-mt'}>
                                {authority.street} {authority.houseNumber}
                            </div>
                            <div>
                                {authority.zipCode} {authority.locality}
                            </div>
                        </div>
                    </TileSelect>
                ))}
            </TileSelectGroup>
        </>
    );
};
