import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { RevenuesOpenPositions } from '../../components/revenues-open-positions/RevenuesOpenPositions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export const RevenuesOpenPositionsPage: React.FC = () => {
    const { t } = useTranslation('revenues-open-positions');
    const { customerNumber } = useParams<{ customerNumber: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <Heading level={3}>{`${t('subtitle')}${customerNumber}`}</Heading>
            <RevenuesOpenPositions customerNumber={customerNumber} />
        </ContentSection>
    );
};
