import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { PostboxUi } from './ui';
import { usePostbox } from './usePostbox';
import { usePostboxFourSales } from './usePostboxFourSales';
import { useTranslation } from 'react-i18next';
import {
    useAnalyticsPageViewTracker,
    MyDocumentsLoadingPlaceholder,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { useMyProfileData } from '../my-profile/useMyProfileData';
import { useUserContractsDataAndFlags } from '../../Providers';
import { CombinedContractType, isStandardContract } from '../../utils';

const PostboxWithHandlers = withLoadingAndNoConnectionHandler(PostboxUi);

type PostboxProps = {
    contractId?: string;
};

export const Postbox: React.FC<PostboxProps> = ({ contractId }) => {
    const { t } = useTranslation('postbox');

    const {
        contracts: { data: contracts, isLoading: isLoadingContracts, loadingError: contractsLoadingError },
        aboContracts: { data: autoAboContracts },
        fourSalesContracts: {
            data: fourSalesContracts,
            isLoading: isFourSalesContractsLoading,
            loadingError: fourSalesContractsLoadingError,
        },
        isFetchingStandardAllowed,
        isFetchingFourSalesAllowed,
    } = useUserContractsDataAndFlags();
    const {
        data: postboxDocuments,
        isLoading: isDocumentsLoading,
        loadingError: loadingErrorPostboxDocuments,
    } = usePostbox(isFetchingStandardAllowed);
    const {
        data: postboxDocuments4S,
        isLoading: isDocumentsLoading4S,
        loadingError: loadingErrorPostboxDocuments4S,
    } = usePostboxFourSales(isFetchingFourSalesAllowed);
    const { isAod, isRac } = useMyProfileData();

    const allContracts = [...(contracts || []), ...(fourSalesContracts || [])] as CombinedContractType[];
    const documents = [...(postboxDocuments || []), ...(postboxDocuments4S || [])];

    useAnalyticsPageViewTracker('postbox', !!postboxDocuments);

    const isLoading = isDocumentsLoading || isLoadingContracts || isFourSalesContractsLoading || isDocumentsLoading4S;
    const postboxLoadingError =
        (isFetchingStandardAllowed &&
            isFetchingFourSalesAllowed &&
            !!loadingErrorPostboxDocuments &&
            !!loadingErrorPostboxDocuments4S) ||
        (!isFetchingFourSalesAllowed && !!loadingErrorPostboxDocuments) ||
        (!isFetchingStandardAllowed && !!loadingErrorPostboxDocuments4S);
    const loadingError = !!contractsLoadingError || !!fourSalesContractsLoadingError || postboxLoadingError;
    const isMixedCustomer =
        (isFetchingStandardAllowed && isFetchingFourSalesAllowed) ||
        ((isFetchingStandardAllowed || isFetchingFourSalesAllowed) &&
            (isAod || isRac || (Array.isArray(autoAboContracts) && !!autoAboContracts.length)));

    let contractNumber;

    if (contractId) {
        const contract = allContracts.find(contract => contract.contractId === contractId);
        contractNumber = contract
            ? isStandardContract(contract)
                ? contract.contractNumber
                : contract.contractId
            : undefined;
    }

    let customerErrorMessageForNoConnection = undefined;

    if (!isFetchingStandardAllowed && !!loadingErrorPostboxDocuments4S) {
        // custom error message when fetching only 4S documents and fetch fails
        customerErrorMessageForNoConnection = t('error-messages.only-four-sales-error');
    } else if (!!loadingErrorPostboxDocuments && !!loadingErrorPostboxDocuments4S) {
        // custom error message when standard and 4S document fetch fails
        customerErrorMessageForNoConnection = t('error-messages.mixed-cutomer-error');
    }

    return (
        <>
            {isMixedCustomer && (
                <Notification
                    className="u-mb"
                    status={NotificationStatus.info}
                    text={t('mixed-customer-notification')}
                />
            )}
            {!loadingError && (!!loadingErrorPostboxDocuments || !!loadingErrorPostboxDocuments4S) && (
                <Notification
                    className="u-mb"
                    status={NotificationStatus.warning}
                    text={t('error-messages.dss-or-four-sales-error')}
                />
            )}

            <PostboxWithHandlers
                isLoading={isLoading}
                contracts={allContracts}
                contractNumber={contractNumber}
                postboxDocuments={documents}
                hasError={!!loadingError}
                errorText={customerErrorMessageForNoConnection}
                loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
            />
        </>
    );
};
