import { TFunction } from 'i18next';

export const getVehicleClassByAcrissCode = (t: TFunction, acrissCode?: string): string | undefined => {
    if (!acrissCode) {
        return;
    }

    const code = acrissCode.toLowerCase();

    return [
        t(`acriss-code.category.${code[0]}`, { defaultValue: '' }),
        t(`acriss-code.type.${code[1]}`, { defaultValue: '' }),
    ]
        .filter(Boolean)
        .join(' / ');
};
