import React, { useState } from 'react';
import { Card, Layout, Form, FormField, Fieldset, ButtonContainer, Button } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Contract, formatAsCurrency, formatAsDate, getChangePaymentDateEndpoint } from '@cp-de/common';
import {
    InfoIcon,
    preventSubmit,
    ValidatedCheckbox,
    // useAnalyticsActionTracker,
    Spinner,
    Notification,
    ValidatedInput,
    ValidatedRadioButtonGroup,
    useAnalyticsFormTracker,
} from '@cp-shared-8/frontend-ui';
import { ContractHeader } from '../../contract-header';
import { useTranslation } from 'react-i18next';
import { dashboardPagePath } from '../../navigation/paths';
import { validationSchema } from './validation';
import { ChangePaymentDateModal } from '../modal/ChangePaymentDateModal';
import { CpDataApi } from 'cp-xhr';
import { isAccipiensContract, isDirectCredit } from '../../../utils';

export type ChangePaymentDateFormType = {
    dateOfNextInstallment: string;
    customerMailAddress: string;
    customerConsent: boolean;
};

export type ChangePaymentDateUiProps = {
    customerMailAddress?: string;
    contract?: Contract;
};

export const ChangePaymentDateUi: React.FC<ChangePaymentDateUiProps> = ({ customerMailAddress, contract }) => {
    const { t } = useTranslation('change-payment-date');
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorStatus, setErrorStatus] = useState<string>('');
    const { onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        confirm: 'onChangePaymentDateSubmit',
    });

    // const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');
    // const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');

    if (!contract) {
        return null;
    }

    const { contractId, changePaymentDate } = contract;

    const goBackToDashboard = (): void => history.push(dashboardPagePath());

    const initialValues: ChangePaymentDateFormType = {
        dateOfNextInstallment: '',
        customerMailAddress: customerMailAddress || '',
        customerConsent: false,
    };

    const radioButtons =
        changePaymentDate?.dateOfNextInstallment?.map((date: string) => ({ label: date.slice(-2), value: date })) || [];
    const isAccipiens = isAccipiensContract(contract);
    const isDirectCreditGroup = isDirectCredit(contract);
    const hideLicencePlate = isAccipiens && isDirectCreditGroup;

    const handleSubmit = (formValues: ChangePaymentDateFormType): void => {
        trackOnSubmit();
        setLoading(true);
        setIsError(false);

        const { dateOfNextInstallment, customerMailAddress, customerConsent } = formValues;

        const body = {
            dateOfNextInstallment,
            customerConsent,
            feeAmount: changePaymentDate?.feeAmount,
            feeCurrency: changePaymentDate?.feeCurrency,
            customerMailAddress,
        };

        if (!contractId) {
            setIsError(true);
            return;
        }

        const changePaymentDateEndpoint = getChangePaymentDateEndpoint(contractId);

        CpDataApi.post(changePaymentDateEndpoint, body)
            // .then(() => {
            //     onSuccess(trackingCategoryAndSubcategory);
            // })
            .catch(error => {
                setIsError(!!error);
                setErrorStatus(error?.response?.data?.status);
                // onError(trackingCategoryAndSubcategory);
            })
            .finally(() => {
                setLoading(false);
                setShowModal(true);
            });
    };

    return (
        <>
            {loading && <Spinner fullPage={true} />}
            <Layout center>
                <Layout.Item>
                    <Card element="article">
                        <ContractHeader
                            isDeferralLinkShown={false}
                            contract={contract}
                            hideLicencePlate={hideLicencePlate}
                        />
                    </Card>
                </Layout.Item>
                <Layout.Item default="1/2">
                    <p>{t('overview.rate-amount')}</p>
                </Layout.Item>
                <Layout.Item default="1/2" className={'u-text-right'}>
                    <strong>{formatAsCurrency(contract.nextDueAmount)}</strong>
                </Layout.Item>
                <Layout.Item default="1/2" className={'u-mt-none'}>
                    <p>{t('overview.next-date')}</p>
                </Layout.Item>
                <Layout.Item default="1/2" className={'u-text-right u-mt-none'}>
                    <strong>{formatAsDate(changePaymentDate?.currentDate)}</strong>
                </Layout.Item>

                <Layout.Item className="u-mt-small">
                    <h3>
                        {t('date-of-next-installment.title')}
                        <InfoIcon text={t('date-of-next-installment.tooltip')} />
                    </h3>
                </Layout.Item>

                <Layout.Item className="u-mt-none">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema(t)}
                    >
                        {({ values, submitForm }): React.ReactNode => (
                            <Form onSubmit={e => preventSubmit(e)} data-testid="fillForm">
                                <Fieldset className="u-mb-small">
                                    <Fieldset.Row>
                                        <ValidatedRadioButtonGroup
                                            name="dateOfNextInstallment"
                                            radioButtons={radioButtons}
                                            testId="dateOfNextInstallment"
                                        />
                                    </Fieldset.Row>

                                    {values.dateOfNextInstallment && (
                                        <Notification
                                            className="u-mb"
                                            headline={t('date-of-next-installment.notification.title', {
                                                day: values.dateOfNextInstallment.slice(-2),
                                                month: values.dateOfNextInstallment.slice(5, 7),
                                            })}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t('date-of-next-installment.notification.text', {
                                                        day: values.dateOfNextInstallment.slice(-2),
                                                    }),
                                                }}
                                            />
                                        </Notification>
                                    )}

                                    <Fieldset.Row className="u-mb-large">
                                        <h3 className="u-mb-small">{t('customer-mail-address.title')}</h3>
                                        <p>{t('customer-mail-address.text')}</p>
                                        <FormField type="input">
                                            <ValidatedInput
                                                name="customerMailAddress"
                                                label=""
                                                inputMode={'email'}
                                                testId={'customerMailAddress'}
                                            />
                                        </FormField>
                                    </Fieldset.Row>

                                    <Fieldset.Row>
                                        <ValidatedCheckbox
                                            name={'customerConsent'}
                                            label={
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: t(
                                                            !isAccipiens
                                                                ? 'customer-consent'
                                                                : 'customer-consent-for-accipiens',
                                                            {
                                                                amount: changePaymentDate?.feeAmount,
                                                                currency: changePaymentDate?.feeCurrency,
                                                            },
                                                        ),
                                                    }}
                                                />
                                            }
                                            testId={'customerConsent'}
                                        />
                                    </Fieldset.Row>
                                </Fieldset>

                                {(changePaymentDate?.lastInstalmentDate ||
                                    changePaymentDate?.balloonInstalmentDate) && (
                                    <p className="u-mb-none">
                                        <b>{t('info-text.title')}</b>
                                    </p>
                                )}
                                {changePaymentDate?.lastInstalmentDate && (
                                    <p>
                                        {t('info-text.text-1', {
                                            date: formatAsDate(changePaymentDate?.lastInstalmentDate),
                                        })}
                                    </p>
                                )}
                                {changePaymentDate?.balloonInstalmentDate && (
                                    <p>
                                        {t('info-text.text-2', {
                                            date: formatAsDate(changePaymentDate?.balloonInstalmentDate),
                                        })}
                                    </p>
                                )}
                                {isAccipiens && contract.changePaymentDate?.hasOutstandingInstalment && (
                                    <p>{t('info-text.text-3-for-accipiens')}</p>
                                )}

                                <ButtonContainer center className="u-mt-large">
                                    <Button type="button" secondary onClick={goBackToDashboard} testId="cancelButton">
                                        {t('buttons.go-back')}
                                    </Button>
                                    <Button onClick={submitForm} testId="submitButton" type="submit">
                                        {t('buttons.submit')}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </Layout.Item>
            </Layout>
            <ChangePaymentDateModal
                shown={showModal}
                errorStatus={errorStatus}
                isError={isError}
                onClose={(): void => setShowModal(false)}
                onConfirm={!isError ? goBackToDashboard : (): void => setShowModal(false)}
            />
        </>
    );
};
