import React, { useState } from 'react';
import { AoDContract, AutoAboContract, Contract, ContractType, FourSalesContract, RaCContract } from '@cp-de/common';
import { useTranslation } from 'react-i18next';
import { LicensePlate } from '../../../license-plate';
import { ProductInfoSummary, Modal } from '@vwfs-bronson/bronson-react';
import { createVehicleData, GeneratedVehicleData } from '../../vehicle-details-and-image/createVehicleData';

type InactiveVehicleDetailsAndImageProps = {
    contract?: Contract;
    reservationContract?: AutoAboContract | FourSalesContract | AoDContract | RaCContract;
};

export const InactiveVehicleDetailsAndImage: React.FC<InactiveVehicleDetailsAndImageProps> = ({
    contract,
    reservationContract,
}) => {
    const { t, ready } = useTranslation('vehicle-details-and-image', { useSuspense: false });
    const [openModal, setOpenModal] = useState<boolean>(false);

    if (!contract && !reservationContract) {
        return null;
    }

    const vehicleData: GeneratedVehicleData = createVehicleData({ contract, reservationContract });

    const subTitle = `${vehicleData.fuelType} | ${vehicleData.enginePower} | ${vehicleData.transmission} | ${vehicleData.bodyColor}`;

    const isRacContrat = reservationContract?.contractType === ContractType.RENT_A_CAR;
    const isAodOrRacContract = reservationContract?.contractType === ContractType.AUDI_ON_DEMAND || isRacContrat;

    const getImageSrc = () => {
        const image = vehicleData?.imgSrc;
        const brand = vehicleData?.brand;
        const showDefault = !image;
        if (image) return image;
        if (showDefault) {
            switch (brand) {
                case 'Audi':
                    return '/contents/vwfs/audi_default.svg';
                case 'Seat':
                    return '/contents/vwfs/seat_default.svg';
                case 'SKODA':
                    return '/contents/vwfs/skoda_default.svg';
                case 'VW':
                    return '/contents/vwfs/vw_default.svg';
                default:
                    return '/contents/vwfs/vw_default.svg';
            }
        }
        return '';
    };

    return (
        <>
            {ready && (
                <ProductInfoSummary
                    className={'u-1/1'}
                    title={`${vehicleData?.brand} ${vehicleData?.modelName} ${vehicleData?.trim || ''}`}
                    infoLabel={!isAodOrRacContract && <LicensePlate licensePlate={vehicleData?.licensePlate} />}
                    subtitle={subTitle}
                    imgSrc={getImageSrc()}
                />
            )}
            <Modal shown={openModal} onClose={() => setOpenModal(false)} testId={'current-mileage-modal'}>
                {t('vehicle-details.current-mileage.text')}
            </Modal>
        </>
    );
};
