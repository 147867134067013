import React from 'react';
import { ContentSection, Heading, Tabs } from '@vwfs-bronson/bronson-react';

import { Postbox } from '../../components/postbox';
import { Inbox } from '../../components/inbox';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

enum Tab {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export const PostboxPage: React.FC = () => {
    const { t } = useTranslation('postbox');
    const { contractId } = useParams<{ contractId: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('title')}</Heading>
            <h3>{t('sub-title')}</h3>
            <p>{t('description')}</p>
            <Tabs defaultSelected={Tab.ACTIVE}>
                <Tabs.Header>
                    <Tabs.HeaderItem tabId={Tab.ACTIVE}>{t('postboxTabLabel')}</Tabs.HeaderItem>
                    <Tabs.HeaderItem tabId={Tab.INACTIVE}>{t('inboxTabLabel')}</Tabs.HeaderItem>
                </Tabs.Header>
                <Tabs.Content>
                    <Tabs.ContentItem tabId={Tab.ACTIVE} testId="tab-active">
                        <Postbox contractId={contractId} />
                    </Tabs.ContentItem>
                    <Tabs.ContentItem tabId={Tab.INACTIVE} testId="tab-inactive">
                        <Inbox contractId={contractId} />
                    </Tabs.ContentItem>
                </Tabs.Content>
            </Tabs>
        </ContentSection>
    );
};
