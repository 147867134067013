import { Contract, ContractStatus, ContractType, formatAsDate } from '@cp-de/common';
import { ContractActionItemType, ContractActions, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { Accordion } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    amortizationTablePagePath,
    contractEndOptionsPagePath,
    earlyTerminationPagePath,
    postboxPagePathWithContractId,
    reportDamagePagePath,
    requestDocumentPagePath,
    totalEarlySettlementPagePath,
    changePaymentDatePagePath,
    revenuesOpenPositionsPagePath,
} from '../../navigation/paths';
import { BundledProducts } from '../bundled-products';
import { FinancialDetails, useFinancialDetails } from '../financial-details';

export type ContractCardBodyProps = {
    contract: Contract;
    inactiveContract?: boolean;
    isLeasingContractWithCustomerNumber?: boolean;
};

export const ContractCardBody: React.FC<ContractCardBodyProps> = ({
    contract,
    inactiveContract = false,
    isLeasingContractWithCustomerNumber = false,
}) => {
    const { t } = useTranslation('contracts');
    const { onAction: onReportDamageClick } = useAnalyticsActionTracker('onReportDamage');
    const { onAction: onChangePaymentDateClick } = useAnalyticsActionTracker('onDashboardChangePaymentDate');
    const { onAction: onOpenContractAccordion } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { contractStatus, contractType } = contract;
    const {
        data: financialDetails,
        isLoading: isFinancialDetailsLoading,
        loadingError: financialDetailsLoadingError,
    } = useFinancialDetails(contract?.contractId || '');
    const [accordionItemClosed, setAccordionItemClosed] = useState([true, true]);

    if (!contract) {
        return null;
    }

    const getActionItems = (): ContractActionItemType[] => {
        const actionItems: ContractActionItemType[] = [];
        actionItems.push({
            iconName: 'semantic-download',
            pageUrl: requestDocumentPagePath(contract.contractId),
            label: t('contract-actions.request-document'),
        });

        if (contract._links?.repairApproval) {
            actionItems.push({
                iconName: 'semantic-service',
                pageUrl: reportDamagePagePath(contract.contractId),
                label: t('contract-actions.repair-approval'),
                onClick: (): void => onReportDamageClick(),
            });
        }

        if (contract._links?.earlySettlementRequest && contractType === ContractType.FINANCING) {
            actionItems.push({
                iconName: 'semantic-calculator',
                pageUrl: totalEarlySettlementPagePath(contract.contractId),
                label: t('contract-actions.total-early-settlement'),
            });
        }

        if (
            contractType === ContractType.FINANCING &&
            (contractStatus === ContractStatus.ACTIVE || contractStatus === ContractStatus.EXPIRED)
        ) {
            actionItems.push({
                iconName: 'semantic-calendar',
                pageUrl: amortizationTablePagePath(contract.contractId),
                label: t('contract-actions.amortization-table'),
            });
        }

        if (
            contract.earlyTermination?.status &&
            (contract.earlyTermination?.type === 'Email' || contract.earlyTermination?.type === 'Dealer')
        ) {
            actionItems.push({
                iconName: 'semantic-crossed-out',
                pageUrl: earlyTerminationPagePath(contract.contractId),
                label: t('contract-actions.early-termination'),
            });
        }

        actionItems.push({
            iconName: 'semantic-mail',
            pageUrl: postboxPagePathWithContractId(contract.contractId),
            label: t('contract-actions.postbox'),
        });

        if (contractStatus === ContractStatus.ACTIVE && contractType === ContractType.LEASING) {
            actionItems.push({
                iconName: 'car-calendar',
                pageUrl: contractEndOptionsPagePath(contract.contractId),
                label: t('contract-actions.contract-end-options'),
            });
        }

        if (
            contractStatus === ContractStatus.ACTIVE &&
            contractType === ContractType.FINANCING &&
            contract?.changePaymentDate
        ) {
            actionItems.push({
                iconName: 'calendar-finger',
                pageUrl: changePaymentDatePagePath(contract.contractId),
                label: t('contract-actions.change-payment-date'),
                onClick: () => {
                    onChangePaymentDateClick();
                },
            });
        }

        if (isLeasingContractWithCustomerNumber) {
            actionItems.push({
                iconName: 'semantic-brochure',
                pageUrl: revenuesOpenPositionsPagePath(contract.customerNumber),
                label: t('leasing-group.show-revenues'),
            });
        }
        return actionItems;
    };

    return inactiveContract ? (
        <Accordion headerSplit>
            <div
                onClick={() => {
                    accordionItemClosed[0] && onOpenContractAccordion('Financial Details');
                    const newState = [...accordionItemClosed];
                    newState[0] = !accordionItemClosed[0];
                    setAccordionItemClosed(newState);
                }}
            >
                <Accordion.Item
                    title={t('financial-details.headline')}
                    subtitle={t('financial-details.sub-title', { date: formatAsDate(contract.endDate) })}
                    titleSplit={contract.contractNumber}
                >
                    <FinancialDetails
                        contract={contract}
                        financialDetails={financialDetails}
                        isFinancialDetailsLoading={isFinancialDetailsLoading}
                        isFinancialDetailsLoadingError={!!financialDetailsLoadingError}
                    />
                </Accordion.Item>
            </div>
            <div
                onClick={() => {
                    accordionItemClosed[1] && onOpenContractAccordion('Products / Services included');
                    const newState = [...accordionItemClosed];
                    newState[1] = !accordionItemClosed[1];
                    setAccordionItemClosed(newState);
                }}
            >
                <Accordion.Item
                    title={t('bundled-products.headline')}
                    subtitle={t('bundled-products.sub-title', { date: formatAsDate(contract.endDate) })}
                    titleSplit={contract.contractNumber}
                >
                    <BundledProducts
                        financialDetails={financialDetails}
                        isFinancialDetailsLoading={isFinancialDetailsLoading}
                        isFinancialDetailsLoadingError={!!financialDetailsLoadingError}
                    />
                </Accordion.Item>
            </div>
        </Accordion>
    ) : (
        <Accordion>
            <ContractActions contractActionItems={getActionItems()} className="u-mt-none u-mb" />
            <div
                onClick={() => {
                    accordionItemClosed[0] && onOpenContractAccordion('Financial Details');
                    const newState = [...accordionItemClosed];
                    newState[0] = !accordionItemClosed[0];
                    setAccordionItemClosed(newState);
                }}
            >
                <Accordion.Item title={t('financial-details.headline')}>
                    <FinancialDetails
                        contract={contract}
                        financialDetails={financialDetails}
                        isFinancialDetailsLoading={isFinancialDetailsLoading}
                        isFinancialDetailsLoadingError={!!financialDetailsLoadingError}
                    />
                </Accordion.Item>
            </div>
            <div
                onClick={() => {
                    accordionItemClosed[1] && onOpenContractAccordion('Products / Services included');
                    const newState = [...accordionItemClosed];
                    newState[1] = !accordionItemClosed[1];
                    setAccordionItemClosed(newState);
                }}
            >
                <Accordion.Item title={t('bundled-products.headline')}>
                    <BundledProducts
                        financialDetails={financialDetails}
                        isFinancialDetailsLoading={isFinancialDetailsLoading}
                        isFinancialDetailsLoadingError={!!financialDetailsLoadingError}
                    />
                </Accordion.Item>
            </div>
        </Accordion>
    );
};
