import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from 'cp-xhr';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';

import { Contract, FinancialDetails } from '@cp-de/common';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { Layout, Button, Modal } from '@vwfs-bronson/bronson-react';
import { AmortizationTableHeader } from '../header';
import { AmortizationTablePaymentOverview } from '../payment-overview';
import { formatCpDate } from '@cp-shared-8/common-utilities';

export const testIds = {
    downloadFailureModal: 'downloadFailureModal',
    downloadLink: 'downloadLink',
};

export const AmortizationTableUi: React.FC<{ contract?: Contract; financialDetails?: FinancialDetails }> = ({
    contract,
    financialDetails,
}) => {
    const { t } = useTranslation('amortization-table');
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [downloadError, setDownloadError] = useState<boolean>(false);
    if (!contract || !financialDetails) {
        return null;
    }

    const download = (): void => {
        const link = contract._links?.amortizationTableDownload;
        if (!link) {
            return;
        }
        setIsDownloading(true);
        CpDataApi.get(link)
            .then(response => {
                const fileContent = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                const filename = `${t('download.filename')}_${contract.contractNumber}_${formatCpDate().format(
                    'YYYYMMDD',
                )}.pdf`;
                downloadFileAs(pdfBlob, filename);
                setIsDownloading(false);
            })
            .catch(() => {
                setDownloadError(true);
                setIsDownloading(false);
            });
    };

    return (
        <>
            <Layout>
                <Layout.Item default="1/1">
                    <AmortizationTableHeader contract={contract} financialDetails={financialDetails} />
                </Layout.Item>
                {contract._links?.amortizationTableDownload && (
                    <Layout.Item default="1/1" className="u-mt-large u-text-right">
                        {isDownloading ? (
                            <div className={'u-float-right'}>
                                <Spinner small center={false} />
                            </div>
                        ) : (
                            <Button onClick={download} testId={testIds.downloadLink} icon="download">
                                {t('download.button')}
                            </Button>
                        )}
                    </Layout.Item>
                )}
                <Layout.Item default="1/1">
                    <AmortizationTablePaymentOverview paymentOverview={contract.paymentPlan} />
                </Layout.Item>
            </Layout>
            <Modal
                shown={!!downloadError}
                buttonConfirmText={t('download.error-modal.close')}
                onConfirm={(): void => setDownloadError(false)}
                onClose={(): void => setDownloadError(false)}
                title={t('download.error-modal.title')}
                status="error"
                testId={testIds.downloadFailureModal}
            >
                {t('download.error-modal.text')}
            </Modal>
        </>
    );
};
