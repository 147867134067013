export function targetNewTab(html?: string): string {
    if (!html) return '';

    const matcher = /<a(.*?)>/gi;
    let match;
    let res = html;

    while ((match = matcher.exec(html)) !== null) {
        const found = match[1];
        res = res.replace(found, `${found} target="_blank"`);
    }

    return res;
}
