import React from 'react';

import { HeycarMarketingCard } from '@cp-de/common';
import { Card, Button } from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';

export const HeycarMarketingCardUi: React.FC<{ heyCarMarketingCard?: HeycarMarketingCard }> = ({
    heyCarMarketingCard,
}) => {
    const { onAction } = useAnalyticsActionTracker('onHeyCarLinkClick');
    if (!heyCarMarketingCard) {
        return null;
    }

    const handleClick = (): void | Promise<void> | null => {
        onAction();
        window.open(heyCarMarketingCard?.buttonUrl, '_blank');
    };

    return (
        <Card
            element="article"
            title={heyCarMarketingCard?.title || ''}
            imageSrc={heyCarMarketingCard?.imageUrl || ''}
            footer={true}
            buttons={[
                <Button
                    key={heyCarMarketingCard?.buttonUrl || 'isLoading'}
                    element="a"
                    href={heyCarMarketingCard?.buttonUrl}
                    icon="semantic-forward"
                    iconReversed
                    small
                    onClick={handleClick}
                >
                    {heyCarMarketingCard?.buttonLabel}
                </Button>,
            ]}
        >
            <div dangerouslySetInnerHTML={{ __html: (heyCarMarketingCard?.text as string) || '' }} />
        </Card>
    );
};
