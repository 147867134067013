import { Contract, ContractStatus, ContractType, formatAsCurrency, formatAsDate } from '@cp-de/common';
import {
    ContractSummary,
    ContractSummaryItemProps,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { Card, Button } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from '../../license-plate';
import { UnpaidAlertNotification } from '../../notifications/unpaid-alert/UnpaidAlertNotification';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCarInformation, getContractCategoryTranslationKey } from '../../contract-header';
import { ContractCardBody } from './ContractCardBody';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image/VehicleDetailsAndImage';
import { CombinedContractType, isAccipiensContract, isDirectCredit } from '../../../utils';

export const DeferralLink: React.FC<{
    title: string;
    href: string;
    showDeferralLink: boolean;
    children: React.ReactNode;
}> = ({ title, href, showDeferralLink, children }) => {
    const { onAction } = useAnalyticsActionTracker('onOpenSuspendInstallment');

    const handleClick = (): void => {
        if (showDeferralLink) {
            onAction();
            window.open(href, '_blank');
        }
    };
    return (
        <Button
            link
            small
            className="u-pb-small"
            title={title}
            testId={'deferral-button'}
            element="a"
            href={href}
            icon="semantic-forward"
            iconReversed
            onClick={handleClick}
        >
            {children}
        </Button>
    );
};

export const ContractCard: React.FC<{
    contract: Contract;
    defaultExpanded?: boolean;
    inactiveContract?: boolean;
    isVehicleDetailsAndImageHidden?: boolean;
}> = ({ contract, defaultExpanded, inactiveContract, isVehicleDetailsAndImageHidden }) => {
    const { t } = useTranslation('contracts');
    const { i18n } = useTranslation();
    const language = i18n.languages[0];

    if (!contract) {
        return null;
    }

    const isContractWithVin = !!contract?.vehicleData?.vin;
    const isLeasingContract =
        contract.contractType === ContractType.LEASING || contract.contractType === ContractType.SERVICE_MANAGEMENT;
    const isLeasingContractWithCustomerNumber = isLeasingContract && !!contract.customerNumber;
    const isAccipiens = isAccipiensContract(contract);
    const isDirectCreditGroup = isDirectCredit(contract);

    const contractSummaryItems: Array<ContractSummaryItemProps> = [];

    if (contract.contractStatus === ContractStatus.TERMINATED) {
        contractSummaryItems.push({
            label: t('status.label'),
            testClass: 'status',
            value: t('status.terminated'),
        });
    }

    if (contract.contractStatus === ContractStatus.EXPIRED) {
        contractSummaryItems.push({
            label: t('status.label'),
            testClass: 'status',
            value: t('status.expired'),
        });
    }

    if (contract.nextDueDate) {
        contractSummaryItems.push({
            label: t('next-due-date'),
            testClass: 'next-due-date',
            value: formatAsDate(contract.nextDueDate),
        });
    }

    if (typeof contract.nextDueAmount === 'number' && contract.contractType === ContractType.FINANCING) {
        contractSummaryItems.push({
            label: t('next-due-amount'),
            testClass: 'next-due-amount',
            value: contract.nextDueAmount ? formatAsCurrency(contract.nextDueAmount) : '0,00 €',
        });
    }

    if (contract.nextDueAmount && isLeasingContract) {
        contractSummaryItems.push({
            label: t('leasing-rate.label'),
            tooltip: t('leasing-rate.tooltip'),
            testClass: 'leasing-rate',
            value: formatAsCurrency(contract.nextDueAmount),
        });
    }

    if (contract.endDate) {
        contractSummaryItems.push({
            label: t('contract-end-date'),
            testClass: 'contract-end-date',
            value: formatAsDate(contract.endDate),
        });
    }

    // in order of precedence
    const displayUnpaidAlert = contract.outstandingPayment;
    const displayOverpaymentAlert = contract.contractType === ContractType.FINANCING && contract.overpayment;

    let contractAlert = undefined;
    if (displayUnpaidAlert) {
        contractAlert = <UnpaidAlertNotification contract={contract as CombinedContractType} />;
    } else if (displayOverpaymentAlert) {
        contractAlert = (
            <Notification
                className={'c-notification--context'}
                status={NotificationStatus.warning}
                headline={t('overpaid-alert.headline')}
                testId={'overpaid-contract-alert'}
            >
                {t('overpaid-alert.text')}
            </Notification>
        );
    }

    const licensePlate = <LicensePlate licensePlate={contract.vehicle?.licensePlate} />;
    const carInformation = getCarInformation(contract, language);
    const cardTitle = isAccipiens && isDirectCreditGroup ? t('direct-credit-title') : carInformation;

    const contractSummary = <ContractSummary items={contractSummaryItems} />;

    const translationKey = getContractCategoryTranslationKey(contract);
    const contractCategory = translationKey ? t(translationKey) : '';

    const showDeferralLink =
        contract.contractType === ContractType.FINANCING &&
        contract.contractStatus === ContractStatus.ACTIVE &&
        !inactiveContract;

    const renderContractCardBody = (
        <ContractCardBody
            contract={contract}
            inactiveContract={inactiveContract}
            isLeasingContractWithCustomerNumber={isLeasingContractWithCustomerNumber}
        />
    );
    const renderSubTitle = (
        <>
            {isContractWithVin ? (
                <>
                    {t('contract-number')} {contract.contractNumber}
                </>
            ) : (
                <>
                    {contractCategory} {contract.contractNumber}
                </>
            )}
            <br />
            {isLeasingContractWithCustomerNumber && isContractWithVin && (
                <>
                    {t('leasing-group.leasing-customer-number')} {contract.customerNumber}
                </>
            )}
        </>
    );

    return (
        <>
            {isContractWithVin && isVehicleDetailsAndImageHidden && !inactiveContract && (
                <VehicleDetailsAndImage contract={contract} />
            )}
            <Card
                element="article"
                expandable={!isContractWithVin}
                className="u-mb u-text-left"
                title={inactiveContract ? null : isContractWithVin ? contractCategory : cardTitle}
                subtitle={renderSubTitle}
                contentShort={
                    isContractWithVin || inactiveContract || (isAccipiens && isDirectCreditGroup) ? null : licensePlate
                }
                defaultExpanded={defaultExpanded}
                contentLarge={isContractWithVin ? null : renderContractCardBody}
            >
                {!inactiveContract && contractSummary}
                {contractAlert}
                {showDeferralLink && (
                    <>
                        <div className="u-mt" />
                        <DeferralLink
                            title={t('deferral-button.label')}
                            href={t('deferral-button.link-url')}
                            showDeferralLink={showDeferralLink}
                        >
                            {t('deferral-button.label')}
                        </DeferralLink>
                    </>
                )}
                {isContractWithVin && renderContractCardBody}
            </Card>
        </>
    );
};
