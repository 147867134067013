import React from 'react';
import { FourSalesContractDetails } from './contract-details';
import { FourSalesCustomerData } from './customer-details';
import { Accordion } from '@vwfs-bronson/bronson-react';
import { FourSalesContract } from '@cp-de/common';
import { useTranslation } from 'react-i18next';
import { ContractActionItemType, ContractActions } from '@cp-shared-8/frontend-ui';
import { compact } from 'lodash';
import { postboxPagePathWithContractId } from '../../../navigation/paths';

type ContractDetailsUiProps = {
    contract: FourSalesContract;
};

export const FourSalesDetails: React.FC<ContractDetailsUiProps> = ({ contract }) => {
    const { t, ready } = useTranslation(['four-sales-contract-details', 'four-sales-customer-details', 'contracts'], {
        useSuspense: false,
    });

    const {
        details: { customerDetails },
    } = contract;

    const actionItems: ContractActionItemType[] = compact([
        {
            iconName: 'semantic-mail',
            pageUrl: postboxPagePathWithContractId(contract.contractId),
            label: t('contracts:contract-actions.postbox'),
        },
    ]);

    return (
        <>
            {ready && (
                <Accordion>
                    <ContractActions contractActionItems={actionItems} className="u-mt-none u-mb" />
                    <Accordion.Item title={t('four-sales-contract-details:headline')} className={'u-mt'}>
                        <FourSalesContractDetails contract={contract} />
                    </Accordion.Item>
                    <Accordion.Item title={t('four-sales-customer-details:headline')}>
                        <FourSalesCustomerData customerDetails={customerDetails} />
                    </Accordion.Item>
                </Accordion>
            )}
        </>
    );
};
