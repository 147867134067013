import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type ArrearPaymentModalProps = {
    shown: boolean;
    error: boolean;
    onConfirm: () => void;
    onClose: () => void;
};

export const testIds = {
    confirmModal: 'confirmModal',
};

export const ArrearPaymentModal: React.FC<ArrearPaymentModalProps> = ({ shown, error, onConfirm, onClose }) => {
    const { t } = useTranslation(['arrear-payment', 'translation']);

    return (
        <Modal
            shown={shown}
            buttonConfirmText={t('arrear-payment:modal.button')}
            onConfirm={onConfirm}
            onClose={onClose}
            status={error ? 'error' : 'success'}
            testId={testIds.confirmModal}
            title={error ? t('translation:error.noConnection.title') : t('arrear-payment:modal.success.title')}
        >
            {error ? t('translation:error.noConnection.text') : t('arrear-payment:modal.success.text')}
        </Modal>
    );
};
