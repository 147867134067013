import { AutoAboContract, Contract, ContractType, FourSalesContract } from '@cp-de/common';
import {
    ContractsLoadingPlaceholder,
    MarketingCardLoadingPlaceholder,
    useAnalyticsDashboardPageTracker,
} from '@cp-shared-8/frontend-ui';
import { Layout, ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import { Contracts, usePendingContracts } from '../contracts';
import { DashboardMarketingCards } from '../dashboard-marketing-cards';
import { Greeting } from '../greeting/Greeting';
import {
    getMostRecentFinancingContract,
    getMostRecentLeasingContract,
    isDateInPeriod,
} from '../dashboard-marketing-cards/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';

import { useUserContractsDataAndFlags } from '../../Providers';
import { AddContractLoadingPlaceholder } from '../contracts/add-contract/skeleton';
import { withLoadingAndNoConnectionHandler, withLoadingHandler } from '../integration-wrapper';
import {
    addContractPath,
    loginInProgressPagePath,
    registrationPagePath,
    registrationAnalyticsPath,
    digitalOfferPagePath,
} from '../navigation/paths';
import { sortAutoAboContracts } from '../../utils';
import { DashboardHeroTeaser } from '../dashboard-hero-teasers/DashboardHeroTeaser';
import { useLastLocation } from 'react-router-last-location';

const ContractsWithHandlers = withLoadingAndNoConnectionHandler(Contracts);
const DashboardMarketingCardWithHandlers = withLoadingHandler(DashboardMarketingCards);
const DashboardHeroTeaserWithHandlers = withLoadingHandler(DashboardHeroTeaser);

export const Dashboard: React.FC = () => {
    const { t: tR } = useTranslation('reservation-contract');
    const { t: tAbo } = useTranslation('auto-abo-contracts');
    const { t: tFS } = useTranslation('four-sales-contracts');
    const { t } = useTranslation('contracts');
    const history = useHistory();

    const {
        contracts: { data: contracts, isLoading: isContractsLoading, loadingError: contractsLoadingError },
        aboContracts: {
            data: autoAboContracts,
            isLoading: isAboContractsLoading,
            loadingError: aboContractsLoadingError,
        },
        aodContracts: { data: aodContracts, isLoading: isAodContractsLoading, loadingError: aodContractsLoadingError },
        racContracts: { data: racContracts, isLoading: isRacContractsLoading, loadingError: racContractsLoadingError },
        fourSalesContracts: {
            data: fourSalesContracts,
            isLoading: isFourSalesContractsLoading,
            loadingError: fourSalesContractsLoadingError,
        },
        digialOfferTransactions: { data: digialOfferTransactions },
        isFetchingFourSalesAllowed,
        isFetchingAboAllowed,
        isFetchingAodAllowed,
        isFetchingStandardAllowed,
        isFetchingRacAllowed,
    } = useUserContractsDataAndFlags();

    const unpaid = contracts && contracts.some(contract => contract.outstandingPayment);
    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? loginInProgressPagePath() : lastLocation?.pathname;

    const { search } = useLocation();
    let submitLastPath = true;

    const queryParams = new URLSearchParams(search);
    const freshRegistration = queryParams.get('freshRegistration');
    if (lastPathname === registrationPagePath() && freshRegistration !== 'true') {
        submitLastPath = false;
    }

    useAnalyticsDashboardPageTracker(true, !!unpaid, submitLastPath ? lastPathname : registrationAnalyticsPath());

    const {
        data: pendingContracts,
        isLoading: hasPendingContractsLoading,
        loadingError: pendingContractsLoadingError,
    } = usePendingContracts(isFetchingStandardAllowed);

    const { data: consent, isLoading: isLoadingConsent, loadingError: loadingErrorConsent } = useSelector(
        (state: RootState) => state.consent,
    );

    const pendingContractsError = contracts?.length === 0 ? pendingContractsLoadingError : false;
    const isLoading = isContractsLoading || hasPendingContractsLoading;
    const loadingError = contractsLoadingError || pendingContractsError;

    const loadingPlaceholder =
        history.location.pathname === addContractPath() ? (
            <AddContractLoadingPlaceholder />
        ) : (
            <ContractsLoadingPlaceholder numberOfContracts={1} />
        );
    const sortedAutoAboContracts = sortAutoAboContracts(autoAboContracts);

    const hasStandardAndAboOrReservationError =
        !!loadingError && (!!aboContractsLoadingError || !!aodContractsLoadingError || !!racContractsLoadingError);
    const hasOnlyStandardError =
        isFetchingStandardAllowed &&
        !isFetchingAodAllowed &&
        !isFetchingRacAllowed &&
        !isFetchingAboAllowed &&
        !isFetchingFourSalesAllowed &&
        !!loadingError;
    const hasOnlyReservationError =
        !isFetchingStandardAllowed &&
        (isFetchingAodAllowed || isFetchingRacAllowed) &&
        !isFetchingAboAllowed &&
        !isFetchingFourSalesAllowed &&
        (!!aodContractsLoadingError || !!racContractsLoadingError);
    const hasOnlyAboError =
        !isFetchingStandardAllowed &&
        (!isFetchingAodAllowed || !isFetchingRacAllowed) &&
        isFetchingAboAllowed &&
        !isFetchingFourSalesAllowed &&
        !!aboContractsLoadingError;
    const hasOnlyFourSalesError =
        !isFetchingStandardAllowed &&
        (!isFetchingAodAllowed || !isFetchingRacAllowed) &&
        !isFetchingAboAllowed &&
        !!fourSalesContractsLoadingError;
    const hasError =
        hasStandardAndAboOrReservationError ||
        hasOnlyStandardError ||
        hasOnlyReservationError ||
        hasOnlyAboError ||
        hasOnlyFourSalesError ||
        !!loadingErrorConsent;
    const hasAnyContract =
        !!contracts?.length || !!aodContracts?.length || !!racContracts?.length || !!fourSalesContracts?.length;

    const getErrorText = () => {
        if (hasStandardAndAboOrReservationError || hasOnlyStandardError) return t('notification.no-connection');
        else if (hasOnlyReservationError) return tR('notification.no-reservation-connection');
        else if (hasOnlyAboError) return tAbo('notification.no-abo-connection');
        else if (hasOnlyFourSalesError) return tFS('notification.no-four-sales-connection');
    };

    const financingContract = getMostRecentFinancingContract(contracts || []);
    const leasingContract = getMostRecentLeasingContract(contracts || []);

    const userWithFinancialContractsOnly =
        !!contracts?.length &&
        contracts?.every((contract: Contract) => contract.contractType === ContractType.FINANCING);

    const userWithFinancialContracts =
        !!contracts?.length &&
        contracts?.some((contract: Contract) => contract.contractType === ContractType.FINANCING);

    const userWithMotorInsurance =
        !!fourSalesContracts?.length &&
        fourSalesContracts?.some((contract: FourSalesContract) => contract.contractType === ContractType.FOUR_SALES);

    const userWithAutoAbo =
        !!autoAboContracts?.length &&
        autoAboContracts?.some((contract: AutoAboContract) => contract.contractType === ContractType.AUTO_ABO);

    const userWithOnlyDigitalOffer = !hasAnyContract && !!digialOfferTransactions?.length;

    const isUserWithYoungFinancialContractPriceProtection = isDateInPeriod(75, 'days', financingContract?.startDate);

    const isUserWithYoungLeasingContract = isDateInPeriod(8, 'weeks', leasingContract?.startDate);

    if (userWithOnlyDigitalOffer) {
        history.push(digitalOfferPagePath());
    }

    return (
        <>
            <DashboardHeroTeaserWithHandlers
                isLoading={isLoading}
                contracts={contracts}
                userWithMotorInsurance={isFourSalesContractsLoading ? true : userWithMotorInsurance}
                userWithAutoAbo={isAboContractsLoading ? true : userWithAutoAbo}
                loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
            />
            <div className={'u-mb-xlarge'}>
                <ContentSection pageWrapSize="medium" className={'u-pb-none'}>
                    <Greeting />
                </ContentSection>
                <PageWrap>
                    <Layout>
                        <ContractsWithHandlers
                            contracts={contracts}
                            arePendingContractsAvailable={pendingContracts?.arePendingContractsAvailable}
                            isLoading={
                                isLoading ||
                                isAboContractsLoading ||
                                isFourSalesContractsLoading ||
                                isAodContractsLoading ||
                                isRacContractsLoading ||
                                isLoadingConsent
                            }
                            contractsLoadingError={!!contractsLoadingError}
                            aboContractsLoadingError={!!aboContractsLoadingError}
                            fourSalesContractsLoadingError={!!fourSalesContractsLoadingError}
                            aodContractsLoadingError={!!aodContractsLoadingError}
                            racContractsLoadingError={!!racContractsLoadingError}
                            autoAboContracts={sortedAutoAboContracts}
                            fourSalesContracts={fourSalesContracts}
                            aodContracts={aodContracts}
                            racContracts={racContracts}
                            hasError={hasError}
                            loadingPlaceholder={loadingPlaceholder}
                            isFetchingStandardAllowed={isFetchingStandardAllowed}
                            isFetchingAboAllowed={isFetchingAboAllowed}
                            isFetchingFourSalesAllowed={isFetchingFourSalesAllowed}
                            isFetchingAodAllowed={isFetchingAodAllowed}
                            isFetchingRacAllowed={isFetchingRacAllowed}
                            errorText={getErrorText()}
                            consent={consent}
                        />
                        <DashboardMarketingCardWithHandlers
                            contracts={contracts}
                            userWithFinancialContractsOnly={userWithFinancialContractsOnly}
                            userWithFinancialContracts={userWithFinancialContracts}
                            userWithYoungLeasingContract={isUserWithYoungLeasingContract}
                            userWithYoungFinancialContractPriceProtection={
                                isUserWithYoungFinancialContractPriceProtection
                            }
                            userWithAutoAbo={isAboContractsLoading ? true : userWithAutoAbo}
                            renderDealersCard={!racContracts?.length && !autoAboContracts?.length}
                            isLoading={isLoading}
                            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
                        />
                    </Layout>
                </PageWrap>
            </div>
        </>
    );
};
