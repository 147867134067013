import { AutoAboContract, formatAsCurrency, formatAsDistance } from '@cp-de/common';
import { Card, Tag, ContextNotification, Notification } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractSummary, ContractSummaryItemProps, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { AutoAboDetails } from './details';
//import { AutoAboContractSubtitle } from './AutoAboContractSubtitle';
import { compact, isEmpty } from 'lodash';
import { confirmVehicleDetailsComposition } from '../utils';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image/VehicleDetailsAndImage';
import { checkDateRange } from '../../../utils';

export const AutoAboContractCard: React.FC<{ contract: AutoAboContract; isVehicleDetailsAndImageHidden?: boolean }> = ({
    contract,
    isVehicleDetailsAndImageHidden,
}) => {
    const { t } = useTranslation('auto-abo-contracts');
    const { onAction: onMonthlyPaymentTooltip } = useAnalyticsActionTracker('onMonthlyPaymentInfoTip');

    const {
        details: { contractDetails },
        contractNumber,
        monthlyMileage,
        monthlyPayment,
        minimumContractPeriod,
    } = contract;

    const contractSummaryItems: Array<ContractSummaryItemProps> = compact([
        {
            onTooltip: onMonthlyPaymentTooltip,
            label: t('monthly-payment.label'),
            tooltip: t('monthly-payment.tooltip'),
            testClass: 'monthly-payment',
            value: monthlyPayment ? formatAsCurrency(Number(monthlyPayment)) : '',
        },
        {
            label: t('monthly-mileage'),
            value: monthlyMileage ? formatAsDistance(monthlyMileage) : '',
            testClass: 'monthly-mileage',
        },
        minimumContractPeriod && {
            label: t('minimum-contract-period'),
            value: minimumContractPeriod + ' Monate',
            testClass: 'minimum-contract-period',
        },
    ]).filter(item => !!item.value);

    const getProductName = (productCode?: string): string => {
        let text = '';
        if (!isEmpty(productCode)) {
            text = t(`product-names.${productCode?.toLowerCase()}`);
        }
        return text;
    };

    const getFAQs = (productCode?: string): string => {
        let FAQ = '';
        if (!isEmpty(productCode)) {
            FAQ = t(`tire-change-notification.FAQs.${productCode?.toLowerCase()}`);
        }
        return FAQ;
    };

    const getSubscriptionStatus = (status?: string): string => {
        let text = '';
        if (!isEmpty(status)) {
            text = t(`subscription-status.${status?.toLowerCase()}`);
        }
        return text;
    };

    // Currently proposed dates for notification to be displayed CO-22095 CP-DE-28
    const springDateFrom = new Date(0, 2, 16);
    const springDateTo = new Date(0, 3, 30);
    const autumnDateFrom = new Date(0, 9, 1);
    const autumnDateTo = new Date(0, 9, 31);

    const contractSummary = <ContractSummary items={contractSummaryItems} />;
    /*TO BE ADDED ON BRONSON VERSION 6.3+
    const contractSubtitle = (
        <AutoAboContractSubtitle
            cancellationDate={cancellationDate}
            contractNumber={contractNumber}
            cancellationNoticeDate={cancellationNoticeDate}
        />
    );
    */
    return (
        <>
            {!isVehicleDetailsAndImageHidden && confirmVehicleDetailsComposition(contract.details.vehicleDetails) && (
                <VehicleDetailsAndImage reservationContract={contract} />
            )}
            <Card
                element="article"
                expandable
                className="u-mb"
                title={getProductName(contractDetails?.product)}
                subtitle={contractNumber}
                contentShort={<Tag>{getSubscriptionStatus(contractDetails?.status)}</Tag>}
                contentLarge={<AutoAboDetails contract={contract} contractNumber={contractNumber} />}
            >
                {contractSummary}
                {(checkDateRange(
                    springDateFrom.getMonth(),
                    springDateTo.getMonth(),
                    springDateFrom.getDate(),
                    springDateTo.getDate(),
                ) ||
                    checkDateRange(
                        autumnDateFrom.getMonth(),
                        autumnDateTo.getMonth(),
                        autumnDateFrom.getDate(),
                        autumnDateTo.getDate(),
                    )) && (
                    <ContextNotification>
                        <Notification
                            showCloseButton={false}
                            status="info"
                            visible
                            title={t('tire-change-notification.heading')}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('tire-change-notification.text') + getFAQs(contractDetails?.product),
                                }}
                            />
                        </Notification>
                    </ContextNotification>
                )}
            </Card>
        </>
    );
};
