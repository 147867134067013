import { getPendingContractsEndpoint, PendingContracts } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<PendingContracts, DefaultBusinessMarketApiErrorCode>({
    dataName: 'pendingContracts',
    fetchCallback() {
        return CpDataApi.get(getPendingContractsEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchPendingContracts = fetchData;
