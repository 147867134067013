import { PostboxDocument, UserType, getPostboxDocumentsEndpoint } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<PostboxDocument[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'postboxDocuments',
    fetchCallback() {
        return CpDataApi.get(getPostboxDocumentsEndpoint(UserType.STANDARD)).then(response => response.data);
    },
});

export default reducer;
export const fetchPostbox = fetchData;
