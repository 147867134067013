export type DealerDetails = {
    companyName?: string;
    contactData?: DealerContactDetails;
    address?: DealerAddressDetails;
    openingHours?: DealerOpeningHours[];
};

export type DealerContactDetails = {
    mobilePhoneCountryCode?: string;
    mobilePhoneNumber?: string;
    email?: string;
    telephoneCountryCode?: string;
    telephoneNumber?: string;
    website?: string;
};

export type DealerAddressDetails = {
    street?: string;
    houseNumber?: string;
    zipCode?: string;
    city?: string;
};

export type DealerOpeningHours = {
    opens?: string;
    closes?: string;
    dayOfWeek?: DayOfWeek;
};

export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}
