import React from 'react';
import { AoDContract, AutoAboContract, FourSalesContract, ConsentStatus, Contract, RaCContract } from '@cp-de/common';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Route } from 'react-router-dom';
import { addContractPath } from '../navigation/paths';
import { AddContract, AddContractSuccess } from './add-contract';
import { CustomerHappinessIndex } from '../customer-happiness-index/CustomerHappinessIndex';
import { ContractsNotifications, ContractsOverview } from './ui';
import { DriverLicenseCheckSuccessModal } from './auto-abo/details/customer-details/driver-license-section';

type ContractsProps = {
    isFetchingStandardAllowed: boolean;
    isFetchingAboAllowed: boolean;
    isFetchingFourSalesAllowed: boolean;
    isFetchingAodAllowed: boolean;
    isFetchingRacAllowed: boolean;
    contracts?: Contract[];
    arePendingContractsAvailable?: boolean;
    contractsLoadingError: boolean;
    autoAboContracts?: AutoAboContract[];
    aboContractsLoadingError: boolean;
    fourSalesContracts?: FourSalesContract[];
    fourSalesContractsLoadingError: boolean;
    aodContracts?: AoDContract[];
    aodContractsLoadingError: boolean;
    racContracts?: RaCContract[];
    racContractsLoadingError: boolean;
    consent?: ConsentStatus;
};

export const Contracts: React.FC<ContractsProps> = ({
    isFetchingStandardAllowed,
    isFetchingAboAllowed,
    isFetchingFourSalesAllowed,
    isFetchingAodAllowed,
    isFetchingRacAllowed,
    contracts,
    arePendingContractsAvailable = false,
    contractsLoadingError,
    autoAboContracts,
    aboContractsLoadingError,
    fourSalesContracts,
    fourSalesContractsLoadingError,
    aodContracts,
    aodContractsLoadingError,
    racContracts,
    racContractsLoadingError,
    consent,
}) => {
    return (
        <>
            <Layout.Item default={'1/1'} m={'1/1'}>
                <ContractsNotifications
                    contracts={contracts}
                    autoAboContracts={autoAboContracts}
                    fourSalesContracts={fourSalesContracts}
                    aodContracts={aodContracts}
                    racContracts={racContracts}
                    arePendingContractsAvailable={arePendingContractsAvailable}
                    isFetchingStandardAllowed={isFetchingStandardAllowed}
                    isFetchingAboAllowed={isFetchingAboAllowed}
                    isFetchingFourSalesAllowed={isFetchingFourSalesAllowed}
                    isFetchingAodAllowed={isFetchingAodAllowed}
                    isFetchingRacAllowed={isFetchingRacAllowed}
                    contractsLoadingError={contractsLoadingError}
                    aboContractsLoadingError={aboContractsLoadingError}
                    fourSalesContractsLoadingError={fourSalesContractsLoadingError}
                    aodContractsLoadingError={aodContractsLoadingError}
                    racContractsLoadingError={racContractsLoadingError}
                    consent={consent}
                />
            </Layout.Item>
            <Layout.Item default={'2/3'} m={'1/1'}>
                <AddContractSuccess />
                <Route exact path={addContractPath()}>
                    <AddContract contracts={contracts} />
                </Route>
                <DriverLicenseCheckSuccessModal />
                <ContractsOverview
                    contracts={contracts}
                    autoAboContracts={autoAboContracts}
                    fourSalesContracts={fourSalesContracts}
                    aodContracts={aodContracts}
                    racContracts={racContracts}
                    isFetchingStandardAllowed={isFetchingStandardAllowed}
                    isFetchingAboAllowed={isFetchingAboAllowed}
                    isFetchingFourSalesAllowed={isFetchingFourSalesAllowed}
                    isFetchingAodAllowed={isFetchingAodAllowed}
                    isFetchingRacAllowed={isFetchingRacAllowed}
                />
                <CustomerHappinessIndex />
            </Layout.Item>
        </>
    );
};
