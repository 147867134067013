import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { LoginChoiceUi } from './ui';
import { useLoginChoice } from './useLoginChoice';
import { useAnalyticsPageViewTracker, LoginChoiceLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { skipPostboxConsentsCheckStorageKey } from 'config';

const LoginChoiceWithHandlers = withLoadingAndNoConnectionHandler(LoginChoiceUi);

export const LoginChoice: React.FC = () => {
    const { cmsContent: loginChoice, isLoading, loadingError } = useLoginChoice();

    React.useEffect(() => {
        window?.localStorage?.removeItem(skipPostboxConsentsCheckStorageKey);
    }, []);

    useAnalyticsPageViewTracker('landingPage', !!loginChoice);

    return (
        <LoginChoiceWithHandlers
            isLoading={isLoading}
            loginChoice={loginChoice}
            hasError={!!loadingError}
            loadingPlaceholder={<LoginChoiceLoadingPlaceholder />}
        />
    );
};
