import { ErrorPage, getConnectionProblemErrorEndpoint } from '@cp-de/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ErrorPage>({
    contentName: 'connectionProblemError',
    contentEndpoint: getConnectionProblemErrorEndpoint,
});

export default reducer;
export const fetchConnectionProblemError = fetchContent;
