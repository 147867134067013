import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerReservationContactDetails } from '@cp-de/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export const ContactSection: React.FC<{ contact?: CustomerReservationContactDetails }> = ({ contact }) => {
    const { t, ready } = useTranslation('aod-contract-parties', { useSuspense: false });
    const { email = '', mobilePhoneNumber = '', mobilePhoneCountryCode = '' } = contact || {};

    const itemList: DefinitionListItem[] = [
        { label: t('contact.email'), value: email ? email : '-', testId: 'mail' },
        {
            label: t('contact.phone'),
            value: mobilePhoneNumber ? `${mobilePhoneCountryCode} ${mobilePhoneNumber}` : '-',
            testId: 'phone',
        },
    ];

    return (
        <>
            {ready && (
                <>
                    <b className="u-text-brand">{t('contact.header')}</b>
                    <DefinitionListHorizontal className="u-mt-small" list={itemList} />
                </>
            )}
        </>
    );
};
