import { ContactDetailsChangeValidationMessages, contactDetailsValidationSchema } from '@cp-de/common';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction) => {
    const prefix = 'contact.change-contact.validation.';

    const contactDetailsChangeValidationMessages: ContactDetailsChangeValidationMessages = {
        requiredEmailError: t(`${prefix}required.email`),
        invalidEmailError: t(`${prefix}invalid.email`),
        requiredMobilePhoneError: t(`${prefix}required.phone`),
        invalidMobilePhoneError: t(`${prefix}invalid.phone`),
    };

    return contactDetailsValidationSchema(contactDetailsChangeValidationMessages);
};
