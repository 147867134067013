import { WhatsNewNotification, getWhatsNewNotificationEndpoint } from '@cp-de/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<WhatsNewNotification>({
    contentName: 'whatsNewNotification',
    contentEndpoint: getWhatsNewNotificationEndpoint,
});

export default reducer;
export const fetchWhatsNewNotification = fetchContent;
