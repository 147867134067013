import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ReportDamageResponsibleInsurance, longDateFormat } from '@cp-de/common';
import { YesNoButtons, insuranceFormValidationSchema, getYesNoValidation } from '../../common';
import { AnyObject } from 'yup/lib/types';
import { formatCpDate } from '@cp-shared-8/common-utilities';

export const getStep1ValidationSchema = (t: TFunction, isAccipiens: boolean): Yup.ObjectSchema<AnyObject> => {
    const shape: AnyObject = {
        responsibleInsurance: Yup.string()
            .required(t('form.error.empty-radio-buttons'))
            .oneOf(Object.values(ReportDamageResponsibleInsurance)),
        policyHolder: Yup.mixed<YesNoButtons>().when('responsibleInsurance', {
            is: (responsibleInsurance: string) => responsibleInsurance === ReportDamageResponsibleInsurance.OWN,
            then: getYesNoValidation(t),
        }),
        transferInsurance: Yup.mixed<YesNoButtons>().when('policyHolder', {
            is: (policyHolder: string) => policyHolder === YesNoButtons.no,
            then: getYesNoValidation(t),
        }),
        insuranceClaim: Yup.array().when('transferInsurance', {
            is: (transferInsurance: string) => transferInsurance === YesNoButtons.yes,
            then: Yup.array()
                .required(t('insurance-claim-upload.required-validation-error'))
                .max(1, t('file-upload.errors.multiple-files-error')),
        }),
        redeemLoan: Yup.mixed<YesNoButtons>().when(['responsibleInsurance', 'policyHolder', 'transferInsurance'], {
            is: (responsibleInsurance: string, policyHolder: string, transferInsurance: string) =>
                responsibleInsurance === ReportDamageResponsibleInsurance.NONE ||
                responsibleInsurance === ReportDamageResponsibleInsurance.OTHER_PARTY ||
                policyHolder === YesNoButtons.yes ||
                transferInsurance === YesNoButtons.yes,
            then: getYesNoValidation(t),
        }),
    };
    if (isAccipiens) {
        shape['damageDate'] = Yup.string()
            .required(t('form.error.date.not-empty'))
            .test('format', t('form.error.date.incorrect-format'), date => formatCpDate(date, longDateFormat).isValid())
            .test('no future date', t('form.error.date.not-in-future'), date =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrBefore(formatCpDate().toMoment()),
            );
    }
    return Yup.object().shape(shape);
};

export const getStep2ValidationSchema = (
    t: TFunction,
    showInsuranceForm: boolean,
    isAccipiens: boolean,
): Yup.ObjectSchema<AnyObject> => {
    const conditionalValidations: AnyObject = {
        insurance: insuranceFormValidationSchema(t, isAccipiens),
    };
    if (!isAccipiens) {
        conditionalValidations['replacementValue'] = Yup.string().when('vehicleSold', {
            is: (vehicleSold: string) => vehicleSold === YesNoButtons.no,
            then: Yup.string(),
        });
    }
    const shape: AnyObject = {
        ...(showInsuranceForm ? conditionalValidations : {}),
    };
    if (!isAccipiens || !showInsuranceForm) {
        shape['attachments'] = Yup.array().when('vehicleSold', {
            is: (vehicleSold: string) => vehicleSold === YesNoButtons.yes,
            then: Yup.array()
                .required(t('file-upload.errors.required-error'))
                .max(3, t('file-upload.errors.max-three-files-error')),
        });
        shape['vehicleSold'] = getYesNoValidation(t);
    }
    return Yup.object().shape(shape);
};
