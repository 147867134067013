import { Contract, ContractType } from '@cp-de/common';
import { DataOverview, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import { withLoadingAndNoConnectionHandler } from '../../../../../integration-wrapper';
import { useTranslationWithFormatting } from '../../../../../../localization/useTranslationWithFormatting';
import React from 'react';

import { useContracts } from '../../../../useContracts';
import { LeasingContractHeader } from './leasing-contract-header/LeasingContractHeader';
import { isAccipiensContract } from '../../../../../../utils';

export type LeasingContractsViewUiProps = {
    contracts?: Contract[];
    isAccipiens?: boolean;
};

export const LeasingContractsViewUi: React.FC<LeasingContractsViewUiProps> = ({ contracts, isAccipiens }) => {
    if (!contracts || !Array.isArray(contracts)) {
        return null;
    }

    function isLeasingContract(contract: Contract): boolean {
        return (
            contract.contractType === ContractType.LEASING || contract.contractType === ContractType.SERVICE_MANAGEMENT
        );
    }

    return (
        <Layout equalHeight>
            {isAccipiens
                ? contracts.filter(isAccipiensContract).map((contract, index) => (
                      <Layout.Item key={index} default="1/2" s="1/1" m="1/2" className="u-mt-none@m">
                          <LeasingContractHeader contract={contract} />
                      </Layout.Item>
                  ))
                : contracts.filter(isLeasingContract).map((contract, index) => (
                      <Layout.Item key={index} default="1/2" s="1/1" m="1/2" className="u-mt-none@m">
                          <LeasingContractHeader contract={contract} />
                      </Layout.Item>
                  ))}
        </Layout>
    );
};

export type LeasingContractsViewProps = {
    toPreviousView: () => void;
    toNextView: () => void;
    isAccipiens: boolean;
};

export const LeasingContractsView: React.FC<LeasingContractsViewProps> = ({
    toPreviousView,
    toNextView,
    isAccipiens,
}) => {
    const { t } = useTranslationWithFormatting('contracts');

    const { data: contracts, isLoading, loadingError } = useContracts();
    const ibanSectionTranslationPrefix = 'financial-details.iban-section';
    const leasingContractsViewTranslationPrefix = `${ibanSectionTranslationPrefix}.leasing-contract-display`;
    const accipiensContractsViewTranslationPrefix = `${ibanSectionTranslationPrefix}.accipiens-contract-display`;

    const LeasingContractsViewWithHandlers = withLoadingAndNoConnectionHandler(LeasingContractsViewUi);

    const notificationText = isAccipiens
        ? t(`${accipiensContractsViewTranslationPrefix}.information-text`)
        : t(`${leasingContractsViewTranslationPrefix}.information-text`);

    return (
        <DataOverview title={t(`${ibanSectionTranslationPrefix}.headline.edit-mode`)} withoutCardEffect={true}>
            <Notification className={'u-mb'} status={NotificationStatus.info}>
                {notificationText}
            </Notification>

            <LeasingContractsViewWithHandlers
                contracts={contracts}
                isAccipiens={isAccipiens}
                isLoading={isLoading}
                hasError={!!loadingError}
            />

            <ButtonContainer className={'u-mt'} center>
                <Button secondary onClick={toPreviousView} testId="cancelButton">
                    {t('translation:editableSectionNav.cancel')}
                </Button>
                {!isLoading && !loadingError && (
                    <Button onClick={toNextView} testId="submitButton">
                        {t('translation:editableSectionNav.continue')}
                    </Button>
                )}
            </ButtonContainer>
        </DataOverview>
    );
};
