import React from 'react';
import { Hr } from '@vwfs-bronson/bronson-react';
import { CustomerReservationDetails } from '@cp-de/common';
import { IdentitySection } from './identity-section';
import { AddressSection } from './address-section';
import { ContactDetailsSection } from './contact-details-section';
import { DriverLicenseSection } from './driver-license-section/DriverLicenseSection';

export const AutoAboCustomerDetails: React.FC<{
    customerDetails?: CustomerReservationDetails;
    contractNumber?: string;
}> = ({ customerDetails, contractNumber }) => {
    if (!customerDetails) {
        return null;
    }

    return (
        <>
            <IdentitySection details={customerDetails} />
            <Hr className="u-mb-small" />
            <AddressSection details={customerDetails} />
            <Hr className="u-mb-small" />
            <ContactDetailsSection details={customerDetails} />
            <Hr className="u-mb-small" />
            <DriverLicenseSection customerDetails={customerDetails} contractNumber={contractNumber} />
        </>
    );
};
