export type DigitalOfferFinancialProduct =
    | {
          sfListingLineItemId: string;
          product: DigitalOfferProductType.CASHPURCHASE;
          subsequentInstallmentTotal: null;
          duration: null;
          yearlyMileage: null;
          downPayment: null;
          balloonPaymentAmount: null;
      }
    | {
          sfListingLineItemId: string;
          product: DigitalOfferProductType.PRIVATELEASE;
          subsequentInstallmentTotal: number;
          duration: number;
          yearlyMileage: number;
          downPayment: number;
          balloonPaymentAmount: null;
      }
    | {
          sfListingLineItemId: string;
          product: DigitalOfferProductType.AUTOCREDIT;
          subsequentInstallmentTotal: number;
          duration: number;
          yearlyMileage: number;
          downPayment: number;
          balloonPaymentAmount: number;
      };

export enum DigitalOfferProductType {
    PRIVATELEASE = 'PRIVATELEASE',
    AUTOCREDIT = 'AUTOCREDIT',
    CASHPURCHASE = 'CASHPURCHASE',
}

export interface DigitalOfferDataFrontend {
    transactionId: string;
    vehicleId: string;
    statusType: DigitalOfferStatus;
    vehicleData: DigitalOfferVehicleData;
    financialProducts: DigitalOfferFinancialProduct[];
    dealerData: DigitalOfferDealerData;
    retailPriceAmount: number;
    redirectLink: string | null;
    deleteLink: string;
}

export interface DigitalOfferVehicleData {
    manufacturer: string;
    modelName: string;
    initialRegistrationDate: string;
    fuelType: string;
    odometer: number;
    fuelconsumptionWLTP: number;
    emissionWLTP: number;
    efficiencyClass: string;
    emissionClass: string;
    images: ImageObject[];
}

export interface ImageObject {
    s: string;
    m: string;
    l: string;
    xl: string;
}

export interface DigitalOfferDealerData {
    companyName?: string;
    city?: string;
    groupId?: string;
}

export type DigitalOfferStatus = null | 'reserved';

export enum DigitalOfferIntegrationErrors {
    CustomerIndexNotFound = 'CUSTOMER_INDEX_NOT_FOUND',
    DataStorageNotFound = 'DATA_STORAGE_NOT_FOUND',
    DataStorageUnknownError = 'DATA_STORAGE_UNKNOWN_ERROR',
    CustomerIndexNoDataStorageID = 'CUSTOMER_INDEX_NO_DATA_STORAGE_ID',
    CustomerIndexDuplicateTransactionID = 'CUSTOMER_INDEX_DUPLICATE_TRANSACTION_ID',
    CustomerIndexUnknownError = 'CUSTOMER_INDEX_UNKNOWN_ERROR',
    AcsGraphQlUnknownError = 'ACS_GRAPHQL_UNKNOWN_ERROR',
    DealerInformationsUnknownError = 'DEALER_INFORMATIONS_UNKNOWN_ERROR',
    IDIsNotInCustomerIndex = 'ID_IS_NOT_IN_Customer_INDEX',
    UnknownError = 'UNKNOWN_ERROR',
}
