import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { AmortizationTable } from '../../components/amortization-table';

export const AmortizationTablePage: React.FC = () => {
    const { t } = useTranslation('amortization-table');
    const { contractId } = useParams<{ contractId: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <AmortizationTable contractId={contractId} />
        </ContentSection>
    );
};
