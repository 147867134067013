import { Button, ButtonContainer, Callout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CustomerHappinessIndexTypes = {
    handleButtonClick: () => void;
};

export const CustomerHappinessIndexUi: React.FC<CustomerHappinessIndexTypes> = ({ handleButtonClick }) => {
    const { t } = useTranslation('customer-happiness-index');

    return (
        <>
            <Callout title={t('title')} className={'u-mb'}>
                {t('text')}
                <ButtonContainer center className={'u-mt'}>
                    <Button onClick={handleButtonClick}>{t('button')}</Button>
                </ButtonContainer>
            </Callout>
            <span
                className={'u-font-size-fs-2'}
                dangerouslySetInnerHTML={{
                    __html: t('legal-notice'),
                }}
            />
        </>
    );
};
