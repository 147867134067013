import { Profile } from '@cp-de/common';

export const MyProfileWithAllValues: Profile = {
    identity: {
        fullName: 'Max Mustermann',
    },
    address: {
        street: 'Johannesstr. 3',
        locality: 'München, Deutschland',
        zipCode: '81677',
    },
    contactDetails: {
        email: 'test@test.de',
        mobile: {
            countryCode: '',
            number: '0178-1234567',
            formattedNumber: '0178-1234567',
        },
    },
    addressChangePermission: 'NOT_ALLOWED',
    nameChangePermission: 'NOT_ALLOWED',
};

export const AutoAboMyProfileWithAllValues: Profile = {
    identity: {
        fullName: 'Herr Konstantin Hermann Kampka',
    },
    address: {
        street: 'Kollberg 9',
        locality: 'München, Deutschland',
        zipCode: '30916',
    },
    contactDetails: {
        email: 'jadwiga.hymel@test.de',
        mobile: {
            countryCode: '49',
            number: '5566778899',
            formattedNumber: '+495566778899',
        },
    },
};

export const AutoAboMyProfileWithoutPhoneNumber: Profile = {
    identity: {
        fullName: 'Herr Konstantin Hermann Kampka',
    },
    address: {
        street: 'Kollberg 9',
        locality: 'München, Deutschland',
        zipCode: '30916',
    },
    contactDetails: {
        mobile: {
            countryCode: '',
            number: '',
        },
    },
};

export const MyProfileWithDirectBankCustomer: Profile = {
    identity: {
        fullName: 'Max Mustermann',
    },
    address: {
        street: 'Johannesstr. 3',
        locality: 'München, Deutschland',
        zipCode: '81677',
    },
    contactDetails: {
        email: 'test@test.de',
        mobile: {
            countryCode: '',
            number: '0178-1234567',
        },
    },
    addressChangePermission: 'DIRECT_BANK_CUSTOMER',
    nameChangePermission: 'DIRECT_BANK_CUSTOMER',
};

export const MyProfileWithChangePermission: Profile = {
    identity: {
        fullName: 'Max Mustermann',
    },
    address: {
        street: 'Johannesstr. 3',
        locality: 'München, Deutschland',
        zipCode: '81677',
    },
    contactDetails: {
        email: 'test@test.de',
        mobile: {
            countryCode: '',
            number: '0178-1234567',
        },
    },
    addressChangePermission: 'ALLOWED',
    nameChangePermission: 'ALLOWED',
};

export const MyProfileWithMandatoryValuesOnly: Profile = {
    addressChangePermission: 'NOT_ALLOWED',
    nameChangePermission: 'NOT_ALLOWED',
};

export const AutoAboMyProfileWithoutNumber: Profile = {
    identity: {
        fullName: 'Herr Konstantin Hermann Kampka',
    },
    address: {
        street: 'Kollberg 9',
        locality: 'München, Deutschland',
        zipCode: '30916',
    },
    contactDetails: {
        email: 'test@test.de',
        mobile: {
            countryCode: '+49',
        },
    },
};

export const AutoAboMyProfileWithoutCountryCode: Profile = {
    identity: {
        fullName: 'Herr Konstantin Hermann Kampka',
    },
    address: {
        street: 'Kollberg 9',
        locality: 'München, Deutschland',
        zipCode: '30916',
    },
    contactDetails: {
        email: 'test@test.de',
        mobile: {
            countryCode: '',
            number: '5566778899',
            formattedNumber: '5566778899',
        },
    },
};
