import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { ArrearPayment } from '../../components/arrear-payment/ArrearPayment';
import { useParams } from 'react-router-dom';

export const ArrearPaymentPage: React.FC = () => {
    const { contractId } = useParams<{ contractId: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <ArrearPayment contractId={contractId} />
        </ContentSection>
    );
};
