import {
    AoDContract,
    Contract,
    ContractStatus,
    ContractType,
    RaCContract,
    AutoAboContract,
    ReservationContractStatus,
    FourSalesContract,
    FourSalesContractSatus,
} from '@cp-de/common';

export const getActiveContractTypes = (
    contracts: (Contract | AoDContract | RaCContract | AutoAboContract | FourSalesContract)[],
): ContractType[] =>
    contracts.reduce<ContractType[]>((contractTypes, currContract) => {
        if (
            (currContract?.contractType === ContractType.AUDI_ON_DEMAND ||
                currContract?.contractType === ContractType.RENT_A_CAR) &&
            ([ReservationContractStatus.RENT_STARTED, ReservationContractStatus.SUBSCRIPTION_STARTED] as (
                | ReservationContractStatus
                | undefined
            )[]).includes((currContract as AoDContract | RaCContract)?.contractStatus)
        ) {
            contractTypes.push(currContract.contractType);
        } else if (
            currContract?.contractType === ContractType.AUTO_ABO &&
            ([ReservationContractStatus.SUBSCRIPTION_RESERVATION, ReservationContractStatus.SUBSCRIPTION_STARTED] as (
                | ReservationContractStatus
                | undefined
            )[]).includes((currContract as AutoAboContract)?.details?.contractDetails?.status)
        ) {
            contractTypes.push(currContract.contractType);
        } else if (
            currContract?.contractType === ContractType.FOUR_SALES &&
            ([FourSalesContractSatus.OFFER, FourSalesContractSatus.PROPOSAL, FourSalesContractSatus.CONTRACT] as (
                | FourSalesContractSatus
                | undefined
            )[]).includes((currContract as FourSalesContract)?.details?.contractDetails?.status)
        ) {
            contractTypes.push(currContract.contractType);
        } else if (
            currContract?.contractType !== ContractType.AUTO_ABO &&
            currContract?.contractType !== ContractType.FOUR_SALES &&
            (currContract as Contract | AoDContract | RaCContract)?.contractStatus === ContractStatus.ACTIVE &&
            currContract?.contractType &&
            !contractTypes.includes(currContract.contractType)
        ) {
            contractTypes.push(currContract.contractType);
        }
        return contractTypes;
    }, []);

export const getChatbotContractType = (contractTypes: ContractType[]): string => {
    const allEqual = (arr: string[]): boolean => arr.every((val: string) => val === arr[0]);
    if (!contractTypes?.length) {
        return 'None';
    }
    if (contractTypes[0] === ContractType.FINANCING && allEqual(contractTypes)) {
        return 'financing';
    }
    if (contractTypes[0] === ContractType.SERVICE_MANAGEMENT && allEqual(contractTypes)) {
        return 'smv';
    }
    if (contractTypes[0] === ContractType.LEASING && allEqual(contractTypes)) {
        return 'leasing';
    }
    if (contractTypes[0] === ContractType.AUDI_ON_DEMAND && allEqual(contractTypes)) {
        return 'Rent';
    }
    if (contractTypes[0] === ContractType.RENT_A_CAR && allEqual(contractTypes)) {
        return 'Rent';
    }
    if (contractTypes[0] === ContractType.AUTO_ABO && allEqual(contractTypes)) {
        return 'Autoabo';
    }
    if (contractTypes[0] === ContractType.FOUR_SALES && allEqual(contractTypes)) {
        return 'Motorinsurance';
    }
    if (
        contractTypes?.length === 2 &&
        contractTypes?.includes(ContractType.SERVICE_MANAGEMENT) &&
        contractTypes?.includes(ContractType.LEASING)
    ) {
        return 'leasing';
    }
    // different contract types
    return 'None';
};

export const getLeasingCustomerNumber = (contracts: Contract[]): string | undefined => {
    const leasingContract = contracts.find(
        contract =>
            contract.contractStatus === ContractStatus.ACTIVE && contract.contractType !== ContractType.FINANCING,
    );

    return leasingContract?.customerNumber;
};
