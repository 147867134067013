import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Moment } from 'moment-timezone';

import { longDateFormat } from '@cp-de/common';

import { RequestDocumentValidationFields } from './RequestDocumentFormFields';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { AnyObject } from 'yup/lib/types';

export const errorMessageIds = {
    category: 'form-fields.category.error-message',
    shippingReason: 'form-fields.shipping-reason.error-message',
    year: {
        isRequired: 'form-fields.year.error-message.required',
        invalidFormat: 'form-fields.year.error-message.invalid-format',
        invalidDate: 'form-fields.year.error-message.invalid-date',
    },
    from: {
        isRequired: 'form-fields.from.error-message.required',
        invalidFormat: 'form-fields.from-to.error-message.invalid',
        futureDate: 'form-fields.from-to.error-message.future-date',
        dateAfterToDate: 'form-fields.from.error-message.date-after-to-date',
        dateBeforeContractStart: 'form-fields.from.error-message.date-before-contract-start',
    },
    to: {
        isRequired: 'form-fields.to.error-message.required',
        invalidFormat: 'form-fields.from-to.error-message.invalid',
        futureDate: 'form-fields.from-to.error-message.future-date',
        dateBeforeFromDate: 'form-fields.to.error-message.date-before-from-date',
    },
};

const requestForZBT2 = ['RequestForZBT2Leasing', 'RequestForZBT2Finance'];

export const validationSchema = (t: TFunction, contractStartDate?: Moment): Yup.ObjectSchema<AnyObject> => {
    const validations: Record<keyof RequestDocumentValidationFields, Yup.StringSchema> = {
        category: Yup.string().required(t(errorMessageIds.category)),
        shippingReason: Yup.string().when('category', {
            is: (val: string) => requestForZBT2.includes(val),
            then: Yup.string().required(t(errorMessageIds.shippingReason)),
        }),
        year: Yup.string().when('category', {
            is: 'InterestCertificate',
            then: Yup.string()
                .required(t(errorMessageIds.year.isRequired))
                .matches(RegExp('^[0-9]{4}$'), t(errorMessageIds.year.invalidFormat))
                .test(
                    'invalid format',
                    t(errorMessageIds.year.invalidDate),
                    (date: string | undefined) =>
                        !!date &&
                        parseInt(formatCpDate().format('YYYY')) > parseInt(date) &&
                        !!contractStartDate &&
                        contractStartDate.year() <= parseInt(date),
                ),
        }),
        from: Yup.string().when('category', {
            is: 'StatementOfAccount',
            then: Yup.string()
                .required(t(errorMessageIds.from.isRequired))
                .test(
                    'invalid format',
                    t(errorMessageIds.from.invalidFormat),
                    (date: string | Moment | null | undefined) => formatCpDate(date, longDateFormat).isValid(),
                )
                .test('future date', t(errorMessageIds.from.futureDate), (date: string | Moment | null | undefined) =>
                    formatCpDate(date, longDateFormat)
                        .toMoment()
                        .isSameOrBefore(formatCpDate().toMoment()),
                )
                .when('to', (to: string, schema: Yup.StringSchema) => {
                    return schema.test(
                        'date after to date',
                        t(errorMessageIds.from.dateAfterToDate),
                        (date: string | Moment | null | undefined) => {
                            if (formatCpDate(to, longDateFormat).isValid()) {
                                return formatCpDate(date, longDateFormat)
                                    .toMoment()
                                    .isSameOrBefore(formatCpDate(to, longDateFormat).toMoment());
                            }
                            return true;
                        },
                    );
                })
                .test(
                    'date before contract start',
                    t(errorMessageIds.from.dateBeforeContractStart),
                    (date: string | Moment | null | undefined) =>
                        !!contractStartDate &&
                        formatCpDate(contractStartDate, longDateFormat)
                            .toMoment()
                            .isSameOrBefore(formatCpDate(date, longDateFormat).toMoment()),
                ),
        }),
        to: Yup.string().when('category', {
            is: 'StatementOfAccount',
            then: Yup.string()
                .required(t(errorMessageIds.to.isRequired))
                .test(
                    'invalid format',
                    t(errorMessageIds.to.invalidFormat),
                    (date: string | Moment | null | undefined) => formatCpDate(date, longDateFormat).isValid(),
                )
                .test('future date', t(errorMessageIds.to.futureDate), (date: string | Moment | null | undefined) =>
                    formatCpDate(date, longDateFormat)
                        .toMoment()
                        .isSameOrBefore(formatCpDate().toMoment()),
                )
                .when('from', (from: string, schema: Yup.StringSchema) => {
                    return schema.test(
                        'date before from date',
                        t(errorMessageIds.to.dateBeforeFromDate),
                        (date: string | Moment | null | undefined) => {
                            if (formatCpDate(from, longDateFormat).isValid()) {
                                return formatCpDate(date, longDateFormat)
                                    .toMoment()
                                    .isSameOrAfter(formatCpDate(from, longDateFormat).toMoment());
                            }
                            return true;
                        },
                    );
                }),
        }),
    };
    return Yup.object().shape(validations);
};
