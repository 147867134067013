import React, { useState } from 'react';
import { AddressChange } from '@cp-de/common';
import { DataOverview, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { ButtonContainer, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

import { AddressSelectComponent } from './address-select-component';

export type AddressSelectionProps = {
    addresses: AddressChange[];
    onCancel: () => void;
    onSubmit: (address: AddressChange) => void;
    isContractView?: boolean;
};

export const testIds = {
    cancel: 'cancel',
    submit: 'submit',
};

export const AddressSelection: React.FC<AddressSelectionProps> = ({
    addresses,
    onCancel,
    onSubmit,
    isContractView,
}) => {
    const [selectedAddress, setSelectedAddress] = useState<AddressChange>(addresses[0]);
    const { t } = useTranslation('my-profile');

    return (
        <DataOverview title={t('address-selection.title')} withoutCardEffect={isContractView}>
            <Notification testId="notification" status={NotificationStatus.info}>
                {t('address-selection.info')}
            </Notification>
            <div className="u-mt">
                <AddressSelectComponent onSelect={setSelectedAddress} addresses={addresses} />
            </div>
            <ButtonContainer nav className="u-mt">
                <Button secondary onClick={onCancel} testId={testIds.cancel}>
                    {t('translation:editableSectionNav.cancel')}
                </Button>
                <Button onClick={() => onSubmit(selectedAddress)} testId={testIds.submit}>
                    {t('address-selection.submit')}
                </Button>
            </ButtonContainer>
        </DataOverview>
    );
};
