import { ContractType, ServiceRequestCategory } from '@cp-de/common';

export type CategoryField = {
    translationId: string;
    category: string;
    forActiveContracts?: boolean;
};

export type ShippingField = {
    reason: string;
    translationId: string;
};

export type ShippingReason =
    | 'ChangeOfAddress'
    | 'ChangeOfName'
    | 'ChangeOfRegistrationNumber'
    | 'RegisterVehicle'
    | 'DeregisterVehicle'
    | 'RecreateZBT1'
    | 'TechnicalChange';

const categoryFields: CategoryField[] = [
    {
        translationId: 'category-labels.account-statement-copy',
        category: 'AccountStatementCopy',
    },
    {
        translationId: 'category-labels.copy-of-zbt2',
        category: 'CopyOfZBT2Finance',
    },
    {
        translationId: 'category-labels.interest-and-amortization-schedule',
        category: 'InterestAndAmortizationSchedule',
    },
    {
        translationId: 'category-labels.interest-certificate',
        category: 'InterestCertificate',
    },
    {
        translationId: 'category-labels.loan-contract',
        category: 'LoanContract',
    },
    {
        translationId: 'category-labels.secured-sale-of-return',
        category: 'SecuredSaleOfReturn',
    },
    {
        translationId: 'category-labels.request-for-zbt2',
        category: 'RequestForZBT2Finance',
    },
    {
        translationId: 'category-labels.copy-of-zbt2',
        category: 'CopyOfZBT2Leasing',
        forActiveContracts: true,
    },
    {
        translationId: 'category-labels.leasing-order',
        category: 'LeasingOrder',
    },
    {
        translationId: 'category-labels.leasing-confirmation',
        category: 'LeasingConfirmation',
    },
    {
        translationId: 'category-labels.contract-prolongation',
        category: 'ContractProlongation',
    },
    {
        translationId: 'category-labels.diminished-value-appraisal',
        category: 'DiminishedValueAppraisal',
    },
    {
        translationId: 'category-labels.return-protocol',
        category: 'ReturnProtocol',
    },
    {
        translationId: 'category-labels.request-for-zbt2',
        category: 'RequestForZBT2Leasing',
    },
    {
        translationId: 'category-labels.service-management-order',
        category: 'ServiceManagementOrder',
    },
    {
        translationId: 'category-labels.service-management-confirmation',
        category: 'ServiceManagementConfirmation',
    },
    {
        translationId: 'category-labels.statement-of-account',
        category: 'StatementOfAccount',
    },
];

const shippingReasonFieldsLeasing: ShippingField[] = [
    {
        reason: 'ChangeOfAddress',
        translationId: 'shipping-reason-labels.change-of-address',
    },
    {
        reason: 'ChangeOfRegistrationNumber',
        translationId: 'shipping-reason-labels.change-of-registration-number',
    },
    {
        reason: 'DeregisterVehicle',
        translationId: 'shipping-reason-labels.deregister-vehicle',
    },
    {
        reason: 'RegisterVehicle',
        translationId: 'shipping-reason-labels.register-vehicle',
    },
    {
        reason: 'ChangeOfName',
        translationId: 'shipping-reason-labels.change-of-name',
    },
    {
        reason: 'RecreateZBT1',
        translationId: 'shipping-reason-labels.recreate-z-b-t1',
    },
];

const shippingReasonFieldsFinancing: ShippingField[] = [
    ...shippingReasonFieldsLeasing,
    {
        reason: 'TechnicalChange',
        translationId: 'shipping-reason-labels.technical-change',
    },
];

const filterFieldsByCategories = (categories: ServiceRequestCategory[]): CategoryField[] => {
    return categories
        .map(field => categoryFields.find(category => category.category === field.category))
        .filter(categoryField => categoryField !== undefined) as CategoryField[];
};

export const getCategoryFields = (categories: ServiceRequestCategory[]): CategoryField[] => {
    if (!categories) {
        return [];
    }
    return filterFieldsByCategories(categories);
};

export const getShippingReasonFields = (contractType?: ContractType): ShippingField[] =>
    contractType === 'LEASING'
        ? shippingReasonFieldsLeasing
        : contractType === 'FINANCING'
        ? shippingReasonFieldsFinancing
        : [];
