import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

import { ContractType } from '@cp-de/common';

import { getCategoryLabel, getProductLabel } from './labelMapper';
import { RequestDocumentFormCategory } from '../request-document-form/utils/RequestDocumentFormFields';

export type RequestDocumentModalProps = {
    shown: boolean;
    error: boolean;
    category?: RequestDocumentFormCategory;
    product?: ContractType;
    onConfirm: () => void;
    onClose: () => void;
};

export const testIds = {
    confirmModal: 'confirmModal',
};

export const RequestDocumentModal: React.FC<RequestDocumentModalProps> = ({
    shown,
    error,
    category,
    product,
    onConfirm,
    onClose,
}) => {
    const { t } = useTranslation('request-document');

    return (
        <Modal
            shown={shown}
            buttonConfirmText={t('modal.confirm')}
            onConfirm={onConfirm}
            onClose={onClose}
            status={error ? 'error' : 'success'}
            testId={testIds.confirmModal}
            title={error ? t('modal.error-title') : t('modal.title')}
        >
            {error ? t('modal.error-text') : t(`modal.text.${getCategoryLabel(category)}.${getProductLabel(product)}`)}
        </Modal>
    );
};
