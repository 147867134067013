import * as Yup from 'yup';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { longDateFormat } from '../../formatters';

export type AddContractValidationMessages = {
    contractNumber: ContractNumberValidationMessages;
    zipCode: {
        empty: string;
        format: string;
    };
    birthDate: {
        empty: string;
        format: string;
        futureDate: string;
        underage: string;
    };
    lastName: string;
    product: string;
};

export type ContractNumberValidationMessages = {
    empty: string;
    min: string;
    max: string;
    format: string;
    format4Sales: string;
};

const defaultValidationErrorMessages: AddContractValidationMessages = {
    contractNumber: {
        empty: 'Contract number is required',
        min: 'Contract number should be at least 7 digits length',
        max: 'Contract number should be not grater than 12 digits',
        format: 'Contract number should be at least 7 digits length',
        format4Sales: 'The possible contract consists of 2 letters (VM) and 10 digits',
    },
    zipCode: {
        empty: 'Zip code is required',
        format: 'Zip code format is not correct',
    },
    birthDate: {
        empty: 'Birth date is required',
        format: 'Birth date format is not correct',
        futureDate: 'Birth date is in the future',
        underage: 'Birth date of an underaged person',
    },
    lastName: 'Last name is required',
    product: 'Product is required',
};

/**
 * Creates a Yup validation schema for contract number based on the sales condition.
 *
 * @param {boolean} is4Sales - Indicates if the validation is for sales.
 * @param {ContractNumberValidationMessages} errorMessages - Object containing error messages.
 * @returns {Yup.StringSchema} - The Yup validation schema for contract number.
 */
export function createContractNumberValidation(
    is4Sales: boolean,
    errorMessages: ContractNumberValidationMessages,
): Yup.StringSchema {
    return Yup.string()
        .required(errorMessages.empty)
        .min(is4Sales ? 12 : 7, is4Sales ? errorMessages.format4Sales : errorMessages.format)
        .max(12, is4Sales ? errorMessages.format4Sales : errorMessages.max)
        .test(
            'format',
            is4Sales ? errorMessages.format4Sales : errorMessages.format,
            (contractNumber: string | undefined): boolean => {
                if (!contractNumber || contractNumber.length === 9) {
                    return false;
                }
                if (contractNumber.length === 7) {
                    return /^[a-zA-Z0-9]{7}$/.test(contractNumber);
                }
                if (contractNumber.length === 10) {
                    return contractNumber[0] === 'A';
                }
                if (contractNumber.length === 12) {
                    return /^VM\d{10}$/.test(contractNumber);
                }
                return !!contractNumber.match(/^[0-9]+$/);
            },
        );
}

export const addContractValidationSchema = (
    is4Sales: boolean,
    errorMessages: AddContractValidationMessages | undefined = defaultValidationErrorMessages,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
): Yup.ObjectSchema => {
    const validations = {
        contractNumber: createContractNumberValidation(is4Sales, errorMessages.contractNumber),
        zipCode: Yup.string()
            .trim()
            .required(errorMessages.zipCode.empty)
            .min(5, errorMessages.zipCode.format),
        lastName: Yup.string()
            .trim()
            .required(errorMessages.lastName),
        birthDate: Yup.string()
            .required(errorMessages.birthDate.empty)
            .test('format', errorMessages.birthDate.format, date => formatCpDate(date, longDateFormat).isValid())
            .test('no future date', errorMessages.birthDate.futureDate, date =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrBefore(formatCpDate().toMoment()),
            )
            .test('too old', errorMessages.birthDate.format, date =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrAfter(
                        formatCpDate()
                            .subtract(100, 'years')
                            .toMoment(),
                    ),
            )
            .test('underage', errorMessages.birthDate.underage, date =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrBefore(
                        formatCpDate()
                            .subtract(18, 'years')
                            .toMoment(),
                    ),
            ),
        product: Yup.string().required(errorMessages.product),
    };
    return Yup.object().shape(validations);
};
