import { Contract } from '@cp-de/common';

export function missingFields(data: Contract | undefined): boolean {
    let missingFields = false;
    if (data && data.paymentPlan && data.paymentPlan.length > 0) {
        data.paymentPlan.forEach(paymentPlan => {
            if (
                !paymentPlan.paymentRateNumber ||
                !paymentPlan.paymentDate ||
                typeof paymentPlan.paymentAmount === 'undefined'
            ) {
                missingFields = true;
            }
        });
        return missingFields;
    } else return true;
}
