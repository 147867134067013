import { PrivacyPolicy, getPrivacyPolicyEndpoint } from '@cp-de/common';
import { createCmsContentSlice } from 'cms-integration';

// TODO: Add privacy-ploicy content to json (CO-8274)
const { reducer, fetchContent } = createCmsContentSlice<PrivacyPolicy>({
    contentName: 'privacyPolicy',
    contentEndpoint: getPrivacyPolicyEndpoint,
});

export default reducer;
export const fetchPrivacyPolicy = fetchContent;
