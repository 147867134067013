import React from 'react';
import { RadioButton, RadioButtonGroup } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormikContext } from 'formik';
import { InitialValues } from '../../initialValues';

export const TerminatePpiForm: React.FC = () => {
    const { t } = useTranslation('total-early-settlement');
    const { setFieldValue }: FormikProps<InitialValues> = useFormikContext();

    const options = [false, true];

    return (
        <RadioButtonGroup>
            {options.map((isConfirmation, index) => {
                const translationSuffix = isConfirmation ? 'yes' : 'no';
                return (
                    <RadioButton
                        key={index}
                        testId={`requester-${isConfirmation}`}
                        value={`${isConfirmation}`}
                        name="terminatePpi"
                        defaultChecked={!isConfirmation}
                        onChange={(): void => setFieldValue('terminatePpi', `${isConfirmation}`)}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t(`terminate-ppi.radio-button-${translationSuffix}`),
                            }}
                        />
                    </RadioButton>
                );
            })}
        </RadioButtonGroup>
    );
};
