import { InsuranceOrganizationFormValues } from './types';

export const getInitialValues = (
    isAccipiens: boolean,
    defaultValues?: InsuranceOrganizationFormValues,
): InsuranceOrganizationFormValues => {
    const defaults: InsuranceOrganizationFormValues = {
        name: '',
        address: {
            streetName: '',
            houseNumber: '',
            postOfficeBox: '',
            zipCode: '',
            locality: '',
        },
        contact: {},
        claimId: '',
    };
    if (!isAccipiens) {
        defaults['contact']['faxNumber'] = '';
    }
    if (isAccipiens) {
        defaults['contact']['email'] = '';
        defaults['damageAmount'] = '';
    }
    return defaultValues || defaults;
};
