import React, { useState } from 'react';
import {
    AutoAboContract,
    CancelContractRequest,
    cancelContractValidationSchema,
    getCancelContractEndpoint,
    longDateFormat,
} from '@cp-de/common';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Card, Fieldset, Form, Layout, Modal } from '@vwfs-bronson/bronson-react';
import {
    ContractHeader as ContractHeaderShared,
    preventSubmit,
    Spinner,
    ValidatedCheckbox,
    ValidatedDate,
} from '@cp-shared-8/frontend-ui';
import { LicensePlate } from '../../license-plate';
import { Formik } from 'formik';
import { CpDataApi } from 'cp-xhr';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { CancelContractInitialValues, getCancelContractInitialValues } from './initialValues';
import { getDate2MonthsAfter, getDate30DaysBefore } from '../utils';
import { dashboardPagePath } from '../../navigation/paths';
import { getCarBrandAndModel } from '../../contracts/utils';

type CancelContractProps = { autoAboContract?: AutoAboContract };

export const CancelContractUi: React.FC<CancelContractProps> = ({ autoAboContract }) => {
    const { t, i18n } = useTranslation('auto-abo-cancel-contract');
    const [modalStatus, setModalStatus] = useState<'success' | 'error' | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const history = useHistory();

    const language = i18n.languages[0];
    if (!autoAboContract || autoAboContract.cancellationNoticeDate || autoAboContract.cancellationDate) {
        return null;
    }
    const { contractNumber, licensePlate, details, isInReservation } = autoAboContract;
    const { carBrand, carModel } = details?.vehicleDetails || {};
    const { endDate } = details.contractDetails || { endDate: '' };

    const licensePlateComponent = <LicensePlate licensePlate={licensePlate} />;

    const content = <div dangerouslySetInnerHTML={{ __html: t('description') }} />;

    const handleSubmit = ({ terminationDate }: CancelContractInitialValues): void => {
        setIsSubmitting(true);
        const body: CancelContractRequest = {
            terminationDate: formatCpDate(terminationDate, longDateFormat).toCpDate(),
        };
        CpDataApi.post(getCancelContractEndpoint(autoAboContract.contractId), body)
            .then(() => {
                setModalStatus('success');
            })
            .catch(() => {
                setModalStatus('error');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const closeModal = (): void => {
        if (modalStatus === 'success') history.push(dashboardPagePath());
        else setModalStatus(undefined);
    };

    return (
        <>
            {isSubmitting && <Spinner center fullPage />}
            <Card element="article">
                <ContractHeaderShared
                    carInformation={getCarBrandAndModel(carBrand, carModel, language)}
                    contractCategory={isInReservation ? t('contract-type-in-reservation') : t('contract-type')}
                    contractNumber={contractNumber}
                    contractIdentifier={licensePlate && licensePlateComponent}
                />
            </Card>
            <div className={'u-mt'}>
                {content}
                <Formik
                    initialValues={getCancelContractInitialValues()}
                    onSubmit={handleSubmit}
                    validationSchema={cancelContractValidationSchema({ terminationDate: t('form.date.error.empty') })}
                >
                    {(formik): React.ReactNode => (
                        <Form onSubmit={e => preventSubmit(e)}>
                            <Fieldset className={'u-mt-large'}>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/1" s="1/1">
                                            <ValidatedDate
                                                label={t('form.date.label')}
                                                language={language}
                                                outputDateFormat="d.m.Y"
                                                maxDate={getDate2MonthsAfter(endDate)}
                                                minDate={getDate30DaysBefore(endDate)}
                                                name={'terminationDate'}
                                                testId={'terminationDate'}
                                                type="text"
                                            />
                                            <p className={'u-mt'}>{t('form.date.text')}</p>
                                        </Layout.Item>
                                    </Layout>
                                    <Layout className={'u-mt'}>
                                        <Layout.Item default={'1/1'}>
                                            <ValidatedCheckbox
                                                label={t('form.checkbox.label')}
                                                name={'terminate'}
                                                testId={'terminate'}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ButtonContainer center>
                                            <Button
                                                type="button"
                                                secondary
                                                onClick={(): void => history.push(dashboardPagePath())}
                                                testId="cancelButton"
                                            >
                                                {t('form.go-back')}
                                            </Button>
                                            <Button
                                                onClick={formik.submitForm}
                                                testId="submitButton"
                                                type="submit"
                                                disabled={!formik.values.terminate}
                                            >
                                                {t('form.confirm')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
                <Modal
                    shown={!!modalStatus}
                    buttonConfirmText={
                        modalStatus === 'success'
                            ? t(`form.modal.success.button`)
                            : t('translation:editableSectionNav.close')
                    }
                    onConfirm={closeModal}
                    onClose={closeModal}
                    title={t(`form.modal.${modalStatus}.title`)}
                    status={modalStatus}
                    testId={`${modalStatus}-modal`}
                >
                    {t(`form.modal.${modalStatus}.text`)}
                </Modal>
            </div>
        </>
    );
};
