import { AddressChange, Profile } from '@cp-de/common';
import { DefinitionListItem, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { changeAddressOnlineBankingPath, changeAddressPath, myProfilePagePath } from '../../navigation/paths';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { OnlineBankingRedirection } from '../online-banking-redirection';
import { ProfileSection, ProfileSectionEditButtonProps } from '../profile-section';
import { ChangeAddress } from './change-address';

export const testIds = {
    editAddressButton: 'editAddress',
};

const initialValues: AddressChange = {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
};

type AddressSectionProps = { profile: Profile; isAbo?: boolean };

export const AddressSection: React.FC<AddressSectionProps> = ({ profile, isAbo }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { t } = useTranslation('my-profile');
    const [formValues, setFormValues] = useState<AddressChange>(initialValues);
    const [showEditSuccessMessage, setShowEditSuccessMessage] = useState<boolean>(false);

    const isEditAllowed = profile.addressChangePermission === 'ALLOWED' || isAbo;

    const addressItems: DefinitionListItem[] = [
        {
            label: t('address.street'),
            value: profile.address?.street,
        },
        {
            label: t('address.locality'),
            value: profile.address?.zipCode + ' ' + profile.address?.locality,
        },
    ];

    const backToNonEditMode = (): void => {
        setShowEditSuccessMessage(() => false);
        history.push(myProfilePagePath());
    };

    const { onAction } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onEditProfileAddressSuccess');

    const startEdit = (editLink: string): void => {
        onAction();
        setShowEditSuccessMessage(false);
        history.push(editLink);
    };

    const buttonProps = (): ProfileSectionEditButtonProps | undefined => {
        if (profile.addressChangePermission === 'NOT_ALLOWED') return undefined;

        const editLink = isEditAllowed ? changeAddressPath() : changeAddressOnlineBankingPath();
        return {
            label: t('translation:editableSectionNav.start'),
            onClick: (): void => startEdit(editLink),
            testId: testIds.editAddressButton,
        };
    };

    return (
        <Switch>
            {isEditAllowed && (
                <Route exact path={changeAddressPath()}>
                    <ChangeAddress
                        initialValues={formValues}
                        onSubmit={setFormValues}
                        onSubmitSuccess={(): void => {
                            setShowEditSuccessMessage(true);
                            onSuccess();
                        }}
                        onCancel={(currentFormValues: AddressChange): void => {
                            setFormValues(currentFormValues);
                            backToNonEditMode();
                        }}
                        isAbo={isAbo}
                    />
                </Route>
            )}
            {profile.addressChangePermission === 'DIRECT_BANK_CUSTOMER' && (
                <Route exact path={changeAddressOnlineBankingPath()}>
                    <OnlineBankingRedirection />
                </Route>
            )}
            <Route path={path}>
                <ProfileSection
                    successMessage={showEditSuccessMessage ? t('change-address.success') : undefined}
                    headline={t('address.headline')}
                    items={addressItems}
                    editButtonProps={buttonProps()}
                />
            </Route>
        </Switch>
    );
};
