import React, { useEffect } from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { CancelContractUi } from './ui';
import { useAutoAboContract } from '../contracts';
import { CancelContractLoadingPlaceholder } from './CancelContractLoadingPlaceholder';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../navigation/paths';

const CancelContractWithHandlers = withLoadingAndNoConnectionHandler(CancelContractUi);

export const CancelContract: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { t } = useTranslation('auto-abo-cancel-contract');
    const history = useHistory();
    const { data: autoAboContract, isLoading, loadingError, hasReceivedResponse } = useAutoAboContract(contractId);

    const { cancellationDate, cancellationNoticeDate } = autoAboContract || {};

    useEffect(() => {
        if (cancellationDate || cancellationNoticeDate) {
            history.push(dashboardPagePath());
        }
    }, [cancellationDate, cancellationNoticeDate, history]);

    return (
        <CancelContractWithHandlers
            isLoading={isLoading}
            autoAboContract={autoAboContract}
            hasError={!!loadingError || (hasReceivedResponse && !autoAboContract)}
            errorText={t('error.no-connection')}
            loadingPlaceholder={<CancelContractLoadingPlaceholder />}
        />
    );
};
