import React from 'react';
import { IconList } from '@vwfs-bronson/bronson-react';
import { BundledProductsDetails } from '@cp-de/common';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';

export const BundledProductsUi: React.FC<{ bundledProducts?: BundledProductsDetails[] }> = ({ bundledProducts }) => {
    const { t } = useTranslation('contracts');

    const list = bundledProducts?.filter(item => item.productName);

    if (!list || list.length === 0) {
        return (
            <Notification status={NotificationStatus.info}>{t('bundled-products.no-bundled-products')}</Notification>
        );
    }

    return (
        <IconList>
            {list.map(product => (
                <IconList.Item icon="checkmark" key={product.productId}>
                    {product.productName}
                </IconList.Item>
            ))}
        </IconList>
    );
};
