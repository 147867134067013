import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { formatAsCurrency, PaymentInfo } from '@cp-de/common';

type ArrearPaymentStepProps = {
    handleNext: () => void;
    handlePrev: () => void;
    paymentInfo: PaymentInfo;
};

export const ArrearPaymentStep2: React.FC<ArrearPaymentStepProps> = ({ handleNext, handlePrev, paymentInfo }) => {
    const { t } = useTranslation('arrear-payment');

    return (
        <>
            <div className={'u-mh-xlarge u-mb u-ph-xlarge u-ph-small@m u-mh-none@m'}>
                <p>{t('step-2.description-1')}</p>
                <p>
                    <strong>{formatAsCurrency(paymentInfo.outstandingPayment)}</strong>
                </p>
                <p>{t('step-2.description-2')}</p>
                <p>{t('step-2.description-3')}</p>
                <strong>{t('step-2.iban')} </strong>
                {paymentInfo.iban}
                <br />
                <strong>{t('step-2.reference')} </strong>
                {paymentInfo.contractNumber}
            </div>
            <ButtonContainer center className={'u-ph-large@s'}>
                <Button testId="arrear-back-button-2" onClick={handlePrev}>
                    {t('step-2.back-button')}
                </Button>
                <Button testId="arrear-confirm-button-2" onClick={handleNext}>
                    {t('step-2.confirm-button')}
                </Button>
            </ButtonContainer>
        </>
    );
};
