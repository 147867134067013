import { useMemo } from 'react';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-8/frontend-integration';

import { AutoAboContract } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { fetchAutoAboContracts } from './AutoAboContractsSlice';
import { AutoAboContractsDataSelector } from './AutoAboContractsDataSelector';

type ContractTransformer = (contracts?: AutoAboContract[]) => AutoAboContract | undefined;

function getFilterForContractId(contractId: string): ContractTransformer {
    return autoAboContracts =>
        autoAboContracts ? autoAboContracts.filter(contract => contract.contractId === contractId)[0] : undefined;
}
/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId Contract ID to get the contract for.
 */
export function useAutoAboContract(
    contractId: string,
): AbstractDataState<AutoAboContract | undefined, DefaultBusinessMarketApiErrorCode> {
    const contractFilter = useMemo(() => getFilterForContractId(contractId), [contractId]);
    return useGetSimpleApiDataWithTransformer(fetchAutoAboContracts, AutoAboContractsDataSelector, contractFilter);
}
