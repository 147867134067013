import {
    Contract,
    ContractEndOptionsParameters,
    fastTrackDateFormat,
    getPurchasePriceInquiryEndpoint,
    marketApiDateFormat,
    PurchasePriceInquiryRequest,
    purchasePriceInquiryValidationSchema,
} from '@cp-de/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    preventSubmit,
    Spinner,
    ValidatedInput,
    ValidatedRadioButtonGroup,
    useAnalyticsFormTracker,
} from '@cp-shared-8/frontend-ui';
import { ContractHeader } from '../../../../contract-header';
import { dashboardPagePath } from '../../../../navigation/paths';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, ButtonContainer, Card, Fieldset, Form, Layout, Heading, Modal } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { isEmpty } from 'lodash';

type PurchasePriceInquiryProps = {
    contract: Contract;
    email?: string;
    contractEndOptionsParameter: ContractEndOptionsParameters[];
};

type FormType = {
    date: string;
    mileage: string;
    email: string;
};

export const PurchasePriceInquiryView: React.FC<PurchasePriceInquiryProps> = ({
    contract,
    email,
    contractEndOptionsParameter,
}) => {
    const { t } = useTranslation('purchase-price-inquiry');
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [modalStatus, setModalStatus] = useState<'error' | 'success'>();
    const { contractId } = contract;
    const { onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        confirm: 'onRequestPriceSubmit',
    });

    useEffect(() => {
        if (!contractEndOptionsParameter || isEmpty(contractEndOptionsParameter)) {
            history.push(dashboardPagePath());
        }
    }, [contractEndOptionsParameter, history]);

    const findParameter = (
        list: ContractEndOptionsParameters[],
        key: string,
    ): ContractEndOptionsParameters | undefined => {
        return list?.find((item: ContractEndOptionsParameters) => item.Key === key);
    };

    const dateOptions = (
        findParameter(contractEndOptionsParameter, 'PurchasePriceInquiryOptions')?.DateValues || []
    ).map((item: string) => {
        return {
            label: formatCpDate(item).format(fastTrackDateFormat),
            value: item,
        };
    });
    const mileage = findParameter(contractEndOptionsParameter, 'PurchasePriceInquiryMaxMileage')?.IntegerValue;

    const initialValues: FormType = {
        date: '',
        mileage: '',
        email: email || '',
    };

    const handleSubmit = (values: FormType): void => {
        trackOnSubmit();
        setIsLoading(true);
        const request: PurchasePriceInquiryRequest = {
            PurchaserEmailAddress: values.email,
            ExpectedMileage: +(values.mileage || 0),
            RequestedPurchaseDate: formatCpDate(values.date).format(marketApiDateFormat),
        };
        CpDataApi.post(getPurchasePriceInquiryEndpoint(contractId), request)
            .then(() => {
                setModalStatus('success');
                setIsLoading(false);
            })
            .catch(() => {
                setModalStatus('error');
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading && <Spinner center fullPage />}
            <Heading level={1}>{t('heading')}</Heading>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <Card element="article">
                        <ContractHeader isDeferralLinkShown={false} contract={contract} />
                    </Card>
                </Layout.Item>
            </Layout>
            <Formik
                initialValues={initialValues}
                validationSchema={purchasePriceInquiryValidationSchema(
                    {
                        dateRequired: t('validation.dateRequired'),
                        mileageRequired: t('validation.mileageRequired'),
                        invalidMileage: t('validation.invalidMileage'),
                        maxMileageExceeded: t('validation.maxMileageExceeded', { maxMileage: mileage }),
                        emailRequired: t('validation.emailRequired'),
                        invalidEmail: t('validation.invalidEmail'),
                    },
                    mileage || 0,
                )}
                onSubmit={handleSubmit}
            >
                {(formik): React.ReactNode => (
                    <Form onSubmit={e => preventSubmit(e)}>
                        <Fieldset className={'u-mt'}>
                            <Heading level={3}>{t('date-label')}</Heading>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default={'1/1'}>
                                        <ValidatedRadioButtonGroup
                                            label=""
                                            radioButtons={dateOptions}
                                            name="date"
                                            testId="date"
                                            isMandatory={true}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Heading level={3}>{t('mileage-label', { maxMileage: mileage })}</Heading>
                            <Fieldset.Row>
                                <ValidatedInput
                                    name="mileage"
                                    className="u-text-left"
                                    label=""
                                    inputMode={'text'}
                                    testId={'mileage'}
                                    placeholder="km"
                                />
                            </Fieldset.Row>
                            <Heading level={3}>{t('email-label')}</Heading>
                            <Fieldset.Row>
                                <ValidatedInput
                                    name="email"
                                    className="u-text-left"
                                    label={t('email-additional-message')}
                                    inputMode={'email'}
                                    testId={'email'}
                                />
                            </Fieldset.Row>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            type="button"
                                            secondary
                                            onClick={(): void => history.push(dashboardPagePath())}
                                            testId="cancelButton"
                                        >
                                            {t('back-button')}
                                        </Button>
                                        <Button onClick={formik.submitForm} testId="submitButton" type="submit">
                                            {t('submit-button')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Layout.Item>
                <Modal
                    shown={!!modalStatus}
                    buttonCancelText={t('modal.ok-button')}
                    onCancel={(): void => {
                        history.push(dashboardPagePath());
                    }}
                    hideCloseButton
                    status={modalStatus}
                    testId={`modal-${modalStatus}`}
                    title={t(`modal.${modalStatus}.title`)}
                >
                    {t(`modal.${modalStatus}.text`)}
                </Modal>
            </Layout.Item>
        </>
    );
};
