import React, { useState } from 'react';
import { Card, Layout, Wizard } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { Contract, ReportDamageClaimType } from '@cp-de/common';
import { ContractHeader } from '../../contract-header';
import { useTranslation } from 'react-i18next';
import { dashboardPagePath } from '../../navigation/paths';
import { FurtherApproval } from '../further-approvals';
import { ApprovalSelection, ApprovalSelectOption } from './approval-selection';
import { RepairApproval } from '../repair-approval';
import { FormNavigationButtons } from '../form-navigation-buttons';

export type ReportDamageUiProps = {
    contract?: Contract;
    isAccipiens: boolean;
};

export const ReportDamageUi: React.FC<ReportDamageUiProps> = ({ contract, isAccipiens }) => {
    const { t } = useTranslation('repair-approval');
    const history = useHistory();
    const [step, setActiveStep] = useState<number>(1);
    const [tileSelected, setTileSelected] = useState<ApprovalSelectOption>();
    const [isError, setIsError] = useState<boolean>(false);
    const [reportDamageClaimType, setReportDamageClaimType] = useState<ReportDamageClaimType>();

    if (!contract) {
        return null;
    }

    const steps = [
        {
            step: 1,
            name: t('step1.category-name'),
        },
        {
            step: 2,
            name: t('step2.category-name'),
        },
        {
            step: 3,
            name: t('step3.category-name'),
        },
    ];

    const goBackToDashboard = (): void => history.push(dashboardPagePath());

    const handleNext = () => {
        if (step === 1 && !tileSelected) {
            setIsError(true);
            return;
        }
        setActiveStep(prevState => prevState + 1);
    };

    const handlePrev = (): void => {
        step === 1 || !step ? goBackToDashboard() : setActiveStep(prevState => prevState - 1);
    };

    const updateTileSelected = (selection: ApprovalSelectOption) => {
        setIsError(false);
        setTileSelected(() => selection);
    };

    const saveReportDamageClaimType = (newReportDamageClaimType: ReportDamageClaimType): void => {
        if (newReportDamageClaimType !== reportDamageClaimType) {
            setReportDamageClaimType(newReportDamageClaimType);
        }
    };

    return (
        <>
            <Layout center>
                <Layout.Item>
                    <Wizard collapsedText={steps[step - 1].name} collapsedCounter={step + '/' + steps.length}>
                        {steps.map((item: { step: number; name: string }, index: number) => (
                            <Wizard.Item key={index} done={index + 1 < step} active={index + 1 === step}>
                                {item.name}
                            </Wizard.Item>
                        ))}
                    </Wizard>
                </Layout.Item>
                <Layout.Item>
                    <Card element="article">
                        <ContractHeader isDeferralLinkShown={false} contract={contract} />
                    </Card>
                </Layout.Item>
            </Layout>
            {step === 1 && (
                <ApprovalSelection updateTile={updateTileSelected} selectedTile={tileSelected} isError={isError} />
            )}
            {tileSelected === 'repairApproval' && (
                <RepairApproval
                    contractId={contract.contractId}
                    step={step}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                />
            )}
            {tileSelected === 'furtherApprovals' && (
                <FurtherApproval
                    step={step}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    reportDamageClaimType={reportDamageClaimType}
                    saveReportDamageClaimType={saveReportDamageClaimType}
                    contractId={contract.contractId}
                    isAccipiens={isAccipiens}
                />
            )}
            {step === 1 && tileSelected !== 'furtherApprovals' && (
                <FormNavigationButtons onPrimaryButtonClick={handleNext} onSecondaryButtonClick={handlePrev} />
            )}
        </>
    );
};
