export function getFinancialDetailsEndpoint(contractId: string): string {
    return `/contracts/${contractId}/financial-details`;
}

export function getChangeIbanEndpoint(contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractId)}/iban`;
}

export function getChangeMileageEndpoint(contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractId)}/mileage`;
}
