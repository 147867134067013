import { getGenericErrorEndpoint } from '@cp-de/common';
import { GenericErrorPage } from '@cp-de/common/src/apis/cms/types';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<GenericErrorPage>({
    contentName: 'genericError',
    contentEndpoint: getGenericErrorEndpoint,
});

export default reducer;
export const fetchGenericError = fetchContent;
