import { CpDataApi } from 'cp-xhr';

import { ServiceRequestCategory } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    ServiceRequestCategory[],
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'RequestCategories',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default reducer;
export const fetchRequestCategories = fetchData;
