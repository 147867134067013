import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { PostboxConsentUi } from './ui';
import { usePostboxConsents } from './usePostboxConsents';

const ConsentWithHandlers = withLoadingAndNoConnectionHandler(PostboxConsentUi);

export const PostboxConsents: React.FC = () => {
    const { data: postboxConsents, isLoading, loadingError } = usePostboxConsents();

    return <ConsentWithHandlers isLoading={isLoading} postboxConsents={postboxConsents} hasError={!!loadingError} />;
};
