import {
    formatAsDate,
    ContractType,
    ContractStatus,
    Contract,
    AoDContract,
    RaCContract,
    AutoAboContract,
    FourSalesContract,
    AutoAboDetails,
} from '@cp-de/common';
import {
    CombinedContractType,
    isAccipiensContract,
    isStandardContract,
    isAodContract,
    isRACContract,
    isAutoAboContract,
    isFourSalesContract,
    isDirectCredit,
} from 'utils';
import { Accordion } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Notification,
    NotificationStatus,
    ContractActions,
    useAnalyticsActionTracker,
    ContractActionItemType,
} from '@cp-shared-8/frontend-ui';
import { UnpaidAlertNotification } from '../../../notifications/unpaid-alert/UnpaidAlertNotification';
import {
    amortizationTablePagePath,
    contractEndOptionsPagePath,
    earlyTerminationPagePath,
    postboxPagePathWithContractId,
    reportDamagePagePath,
    requestDocumentPagePath,
    totalEarlySettlementPagePath,
    changePaymentDatePagePath,
    revenuesOpenPositionsPagePath,
} from '../../../navigation/paths';
import { isEmpty } from 'lodash';
import { getContractCategoryTranslationKey } from 'components/contract-header';
import { FinancialDetails, useFinancialDetails } from 'components/contracts/financial-details';
import { BundledProducts } from 'components/contracts/bundled-products';
import { ReservationContractDetails } from 'components/contracts/reservations';
import { AutoAboContractDetails } from 'components/contracts/auto-abo';
import { FourSalesContractDetails } from 'components/contracts/four-sales/details/contract-details';

type InactiveContractCardDetailProps = {
    contract: CombinedContractType;
};

export const InactiveContractDetails: React.FC<InactiveContractCardDetailProps> = ({ contract }) => {
    const { t } = useTranslation('contracts');
    const translationRef =
        contract.contractType === ContractType.FOUR_SALES
            ? 'four-sales-contracts'
            : contract.contractType === ContractType.AUDI_ON_DEMAND
            ? 'reservation-contract'
            : contract.contractType === ContractType.RENT_A_CAR
            ? 'reservation-contract'
            : contract.contractType === ContractType.AUTO_ABO
            ? 'auto-abo-contracts'
            : '';
    const { t: titleT } = useTranslation(translationRef);
    const { onAction: onReportDamageClick } = useAnalyticsActionTracker('onReportDamage');
    const { contractStatus, contractType } = contract;
    const isLeasingContract =
        contract.contractType === ContractType.LEASING || contract.contractType === ContractType.SERVICE_MANAGEMENT;
    const isLeasingContractWithCustomerNumber = isLeasingContract && !!contract.customerNumber;
    const {
        data: financialDetails,
        isLoading: isFinancialDetailsLoading,
        loadingError: financialDetailsLoadingError,
    } = useFinancialDetails(contract?.contractId || '');

    const translationKey = isStandardContract(contract) && getContractCategoryTranslationKey(contract as Contract);
    const contractCategory = translationKey ? t(translationKey) : '';

    const isAccipiens = isStandardContract(contract) && isAccipiensContract(contract as Contract);
    const isDirectCreditGroup = isDirectCredit(contract as Contract);
    const getProductName = (productCode?: string): string => {
        let text = '';
        if (!isEmpty(productCode)) {
            text = titleT(`product-names.${productCode?.toLowerCase()}`);
        }
        return text;
    };

    let title = '';
    if (contract.contractType === ContractType.FOUR_SALES) {
        title = titleT('title');
    } else if (contract.contractType === ContractType.AUTO_ABO) {
        title = getProductName((contract?.details as AutoAboDetails).contractDetails?.product);
    } else if (
        contract.contractType === ContractType.RENT_A_CAR ||
        contract.contractType === ContractType.AUDI_ON_DEMAND
    ) {
        title = contract.contractType === ContractType.AUDI_ON_DEMAND ? titleT('title.aod') : titleT('title.rac');
    } else {
        title = isAccipiens && isDirectCreditGroup ? t('direct-credit-title') : contractCategory;
    }

    const displayUnpaidAlert = contract.outstandingPayment;
    const displayOverpaymentAlert = contract.contractType === ContractType.FINANCING && contract.overpayment;

    let contractAlert = undefined;
    if (displayUnpaidAlert) {
        contractAlert = <UnpaidAlertNotification contract={contract} />;
    } else if (displayOverpaymentAlert) {
        contractAlert = (
            <Notification
                className={'c-notification--context'}
                status={NotificationStatus.warning}
                headline={t('overpaid-alert.headline')}
                testId={'overpaid-contract-alert'}
            >
                {t('overpaid-alert.text')}
            </Notification>
        );
    }

    const getActionItems = (): ContractActionItemType[] => {
        const actionItems: ContractActionItemType[] = [];
        actionItems.push({
            iconName: 'semantic-download',
            pageUrl: requestDocumentPagePath(contract.contractId),
            label: t('contract-actions.request-document'),
        });

        if (contract._links?.repairApproval) {
            actionItems.push({
                iconName: 'semantic-service',
                pageUrl: reportDamagePagePath(contract.contractId),
                label: t('contract-actions.repair-approval'),
                onClick: (): void => onReportDamageClick(),
            });
        }

        if (contract._links?.earlySettlementRequest && contractType === ContractType.FINANCING) {
            actionItems.push({
                iconName: 'semantic-calculator',
                pageUrl: totalEarlySettlementPagePath(contract.contractId),
                label: t('contract-actions.total-early-settlement'),
            });
        }

        if (
            contractType === ContractType.FINANCING &&
            (contractStatus === ContractStatus.ACTIVE || contractStatus === ContractStatus.EXPIRED)
        ) {
            actionItems.push({
                iconName: 'semantic-calendar',
                pageUrl: amortizationTablePagePath(contract.contractId),
                label: t('contract-actions.amortization-table'),
            });
        }

        if (
            contract.earlyTermination?.status &&
            (contract.earlyTermination?.type === 'Email' || contract.earlyTermination?.type === 'Dealer')
        ) {
            actionItems.push({
                iconName: 'semantic-crossed-out',
                pageUrl: earlyTerminationPagePath(contract.contractId),
                label: t('contract-actions.early-termination'),
            });
        }

        actionItems.push({
            iconName: 'semantic-mail',
            pageUrl: postboxPagePathWithContractId(contract.contractId),
            label: t('contract-actions.postbox'),
        });

        if (contractStatus === ContractStatus.ACTIVE && contractType === ContractType.LEASING) {
            actionItems.push({
                iconName: 'car-calendar',
                pageUrl: contractEndOptionsPagePath(contract.contractId),
                label: t('contract-actions.contract-end-options'),
            });
        }

        if (
            contractStatus === ContractStatus.ACTIVE &&
            contractType === ContractType.FINANCING &&
            contract?.changePaymentDate
        ) {
            actionItems.push({
                iconName: 'calendar-finger',
                pageUrl: changePaymentDatePagePath(contract.contractId),
                label: t('contract-actions.change-payment-date'),
            });
        }

        if (isLeasingContractWithCustomerNumber) {
            actionItems.push({
                iconName: 'semantic-brochure',
                pageUrl: revenuesOpenPositionsPagePath(contract.customerNumber),
                label: t('leasing-group.show-revenues'),
            });
        }
        return actionItems;
    };

    const endDate = isStandardContract(contract) ? financialDetails?.endDate : contract.endDate;

    return (
        <Accordion.Item
            title={
                <>
                    {title}
                    <span style={{ display: 'none' }}>{contract.contractNumber}</span>
                </>
            }
            subtitle={
                !isAutoAboContract(contract) &&
                endDate &&
                t('financial-details.sub-title', { date: formatAsDate(endDate) })
            }
            titleSplit={contract.contractNumber}
        >
            <>
                {(isStandardContract(contract) || isFourSalesContract(contract)) && (
                    <ContractActions contractActionItems={getActionItems()} className="u-mt-none u-mb" />
                )}
                {isStandardContract(contract) ? (
                    <React.Fragment>
                        <FinancialDetails
                            contract={contract as Contract}
                            financialDetails={financialDetails}
                            isFinancialDetailsLoading={isFinancialDetailsLoading}
                            isFinancialDetailsLoadingError={!!financialDetailsLoadingError}
                            isInactive
                        />
                        <BundledProducts
                            financialDetails={financialDetails}
                            isFinancialDetailsLoading={isFinancialDetailsLoading}
                            isFinancialDetailsLoadingError={!!financialDetailsLoadingError}
                        />
                    </React.Fragment>
                ) : isAodContract(contract) || isRACContract(contract) ? (
                    <ReservationContractDetails contract={contract as AoDContract | RaCContract} isInactive={true} />
                ) : isAutoAboContract(contract) ? (
                    <AutoAboContractDetails contract={contract as AutoAboContract} isInactive={true} />
                ) : (
                    <FourSalesContractDetails contract={contract as FourSalesContract} />
                )}

                {contractAlert}
            </>
        </Accordion.Item>
    );
};
