import React from 'react';
import { Layout, Button } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { WhatsNewNotification } from '@cp-de/common';
import { whatsNewPageContentLocalStorageKey } from 'config';
import CryptoJS from 'crypto-js';

export const WhatsNewNotificationUi: React.FC<{ whatsNewContent?: WhatsNewNotification }> = ({ whatsNewContent }) => {
    const history = useHistory();

    if (!whatsNewContent) return null;

    const latestWhatsNewPageContent = whatsNewContent.textField;

    const handleConfirmButtonClick = () => {
        const hashedLatestWhatsNewPageContent = CryptoJS.MD5(latestWhatsNewPageContent).toString();
        window.localStorage.setItem(whatsNewPageContentLocalStorageKey, hashedLatestWhatsNewPageContent);
        history.push('/');
    };

    return (
        <>
            <Layout>
                <Layout.Item className={'u-text-center'}>
                    <h2>{whatsNewContent.title}</h2>
                </Layout.Item>
                {whatsNewContent.textField && (
                    <Layout.Item className={'u-text-center'}>
                        <div className={'u-text-center'}>{whatsNewContent.textField}</div>
                    </Layout.Item>
                )}
                <Layout.Item className={'u-text-center u-mt-large'}>
                    <Button
                        className={'u-text-center'}
                        onClick={handleConfirmButtonClick}
                        testId={'whats-new-page-button'}
                    >
                        {whatsNewContent.buttonLabel}
                    </Button>
                </Layout.Item>
            </Layout>
        </>
    );
};
