import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChangePaymentDate } from '../../components/change-payment-date';

export const ChangePaymentDatePage: React.FC = () => {
    const { t } = useTranslation('change-payment-date');
    const { contractId } = useParams<{ contractId: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('title')}</Heading>
            <ChangePaymentDate contractId={contractId} />
        </ContentSection>
    );
};
