import React, { FC } from 'react';
import { CenteredSpinner } from '../../components/centered-spinner';

import { getUrlSearchParams } from 'utils/urlSearchParams';
import { CpDataApi } from 'cp-xhr';
import {
    DigitalOfferIntegrationErrors,
    UserRegistrationsResult,
    getDigialOfferRegistrationEndpoint,
    getUserRegistrationStatusEndpoint,
    getDigitalOfferIDsEndpoint,
    getDigitalOfferTransactionIdRegisterdEndpoint,
} from '@cp-de/common';
import { useHistory } from 'react-router-dom';
import { connectionProblemPagePath, digitalOfferPagePath, loginChoiceOfferPagePath } from 'components/navigation/paths';
import axios from 'axios';

export const LoginInProgressDigitalOfferPage: FC = () => {
    const history = useHistory();
    const transactionId = getUrlSearchParams().get('ncbp_transaction-id') as string;
    // const { isLoading } = useRegistrationFlowDigitalOffer(transactionId);

    function handleRedirect(path: string, state: object = {}) {
        history.push({ pathname: path, state });
    }

    // Centralized error handling function
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    function handleError(err: any) {
        // Specific error handling
        if (err.response && err.response.data) {
            if (err.response.data.code === DigitalOfferIntegrationErrors.DataStorageNotFound) {
                handleRedirect(loginChoiceOfferPagePath(), {
                    error: DigitalOfferIntegrationErrors.DataStorageNotFound,
                });
                return;
            }
        }
        if (err.response && err.response.data) {
            if (err.response.data.code === DigitalOfferIntegrationErrors.CustomerIndexDuplicateTransactionID) {
                handleRedirect(loginChoiceOfferPagePath(), {
                    error: DigitalOfferIntegrationErrors.CustomerIndexDuplicateTransactionID,
                });
                return;
            }
        }

        // General error handling
        handleRedirect(connectionProblemPagePath());
    }

    async function registrationFlow() {
        try {
            console.log('Starting registration flow');

            const registrationStatus = await CpDataApi.get<UserRegistrationsResult>(
                getUserRegistrationStatusEndpoint(),
            );

            console.log('Registration status:', registrationStatus);

            if (registrationStatus?.data?.digitalOffer?.isRegistered === false) {
                console.log('User is not registered, checking transaction ID');

                const { data: transactionIdAlreadyRegistered } = await CpDataApi.get<{ data: boolean }>(
                    getDigitalOfferTransactionIdRegisterdEndpoint(transactionId),
                );

                console.log('Transaction ID already registered:', transactionIdAlreadyRegistered);

                if (transactionIdAlreadyRegistered) {
                    console.log('Handling redirect due to duplicate transaction ID');
                    handleRedirect(loginChoiceOfferPagePath(), {
                        error: DigitalOfferIntegrationErrors.CustomerIndexDuplicateTransactionID,
                    });
                } else {
                    console.log('Posting digital offer registration');
                    await CpDataApi.post(getDigialOfferRegistrationEndpoint(transactionId));
                    window.location.replace(digitalOfferPagePath());
                }
            } else {
                console.log('User is registered, checking if ID is attached to user');

                const { data: idAlreadyAttachedToUser } = await CpDataApi.post<string | 'NOT_FOUND'>(
                    getDigitalOfferIDsEndpoint(transactionId),
                );

                console.log('ID already attached to user:', idAlreadyAttachedToUser);

                if (idAlreadyAttachedToUser !== 'NOT_FOUND') {
                    console.log('Handling redirect to digital offer page');
                    handleRedirect(digitalOfferPagePath());
                } else {
                    console.log('Checking if transaction ID is already registered');

                    const { data: transactionIdAlreadyRegistered } = await CpDataApi.get<{ data: boolean }>(
                        getDigitalOfferTransactionIdRegisterdEndpoint(transactionId),
                    );

                    console.log('Transaction ID already registered:', transactionIdAlreadyRegistered);

                    if (transactionIdAlreadyRegistered.data) {
                        console.log('Handling redirect due to duplicate transaction ID');
                        handleRedirect(loginChoiceOfferPagePath(), {
                            error: DigitalOfferIntegrationErrors.CustomerIndexDuplicateTransactionID,
                        });
                    } else {
                        console.log('Posting digital offer registration');
                        try {
                            await CpDataApi.post(getDigialOfferRegistrationEndpoint(transactionId));
                            window.location.replace(digitalOfferPagePath());
                        } catch (error) {
                            // Still redirect since there is a DataStorage Item attached to the user
                            if (
                                axios.isAxiosError(error) &&
                                error?.response?.data?.code === DigitalOfferIntegrationErrors.DataStorageNotFound
                            ) {
                                handleRedirect(digitalOfferPagePath());
                            } else {
                                throw error;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log('Error occurred:', error);
            handleError(error);
        }
    }

    registrationFlow();

    return <CenteredSpinner />;
};
