import { useMemo } from 'react';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-8/frontend-integration';
import { RaCContract } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { RaCContractsDataSelector } from './RaCContractsDataSelector';
import { fetchRaCContracts } from './RaCContractsSlice';

type ContractTransformer = (contracts?: RaCContract[]) => RaCContract | undefined;

function getFilterForContractId(contractId: string): ContractTransformer {
    return racContracts =>
        racContracts ? racContracts.filter(contract => contract.contractId === contractId)[0] : undefined;
}
/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId contract (reservation) ID to get the contract for.
 */
export function useRaCContract(
    contractId: string,
): AbstractDataState<RaCContract | undefined, DefaultBusinessMarketApiErrorCode> {
    const contractFilter = useMemo(() => getFilterForContractId(contractId), [contractId]);
    return useGetSimpleApiDataWithTransformer(fetchRaCContracts, RaCContractsDataSelector, contractFilter);
}
