import React from 'react';
import { ContentSection, Heading, Layout, PageWrap } from '@vwfs-bronson/bronson-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TotalEarlySettlement } from '../../components/total-early-settlement';
import { HeycarMarketingCard } from '../../components/total-early-settlement/heycar-marketing-card';

export const TotalEarlySettlementPage: React.FC = () => {
    const { t } = useTranslation('total-early-settlement');
    const { contractId } = useParams<{ contractId: string }>();
    return (
        <div className={'u-mb-xlarge'}>
            <ContentSection pageWrapSize="medium">
                <Heading level={1}>{t('title')}</Heading>
            </ContentSection>
            <PageWrap>
                <Layout>
                    <Layout.Item default={'2/3'} m={'1/1'}>
                        <TotalEarlySettlement contractId={contractId} />
                    </Layout.Item>
                    <Layout.Item default={'1/3'} m={'1/1'}>
                        <HeycarMarketingCard />
                    </Layout.Item>
                </Layout>
            </PageWrap>
        </div>
    );
};
