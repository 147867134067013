import { IdpHint, LoginOptions } from '@cp-de/common';

export const idpHints: { [key in LoginOptions]: IdpHint } = {
    [LoginOptions.VW]: IdpHint.VW,
    [LoginOptions.VERIMI]: IdpHint.VERIMI,
    [LoginOptions.AUDI]: IdpHint.AUDI,
    [LoginOptions.SEAT]: IdpHint.SEAT,
    [LoginOptions.SKODA]: IdpHint.SKODA,
    [LoginOptions.CUPRA]: IdpHint.CUPRA,
};
