import { ContentSection, Logo } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { Consent } from '../../components/consent';

export const ConsentPage: React.FC = () => {
    return (
        <ContentSection pageWrapSize="xxsmall">
            <Logo className={'u-block-center'} />
            <ContentSection>
                <Consent />
            </ContentSection>
        </ContentSection>
    );
};
