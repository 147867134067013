import { formatAsIban, IbanChange, SepaMandateDocument } from '@cp-de/common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
    Spinner,
} from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { useTranslationWithFormatting } from '../../../../../../localization/useTranslationWithFormatting';
import React, { useState } from 'react';

export type IbanConfirmationViewProps = {
    validatedBankData?: IbanChange;
    toPreviousView: () => void;
    toNextView: () => void;
    ibanChangeEndpoint: string;
    forLeasingContract: boolean;
    isAccipiens: boolean;
    setSepaDocument: (sepaDocument: SepaMandateDocument) => void;
};

export const IbanConfirmationView: React.FC<IbanConfirmationViewProps> = ({
    validatedBankData,
    toPreviousView,
    toNextView,
    ibanChangeEndpoint,
    forLeasingContract,
    isAccipiens,
    setSepaDocument,
}) => {
    const { t } = useTranslationWithFormatting('contracts');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const ibanSectionTranslationPrefix = 'financial-details.iban-section';
    const confirmationViewTranslationPrefix = `${ibanSectionTranslationPrefix}.bank-account-control-display`;
    const notificationTranslationSuffix = forLeasingContract
        ? 'information-box.leasing-and-smv'
        : isAccipiens
        ? 'information-box.accipiens'
        : 'information-box.financing';

    if (!validatedBankData || !validatedBankData.signedBankData.data.bankDetails) {
        return null;
    }

    const { bankName, bic, iban } = validatedBankData.signedBankData.data.bankDetails;

    const newValidatedBankAccountItems: DefinitionListItem[] = [
        {
            label: t(`${ibanSectionTranslationPrefix}.display-fields.iban`),
            value: formatAsIban(iban),
        },
        {
            label: t(`${ibanSectionTranslationPrefix}.display-fields.bic`),
            value: bic,
        },
        {
            label: t(`${ibanSectionTranslationPrefix}.display-fields.bank-name`),
            value: bankName,
        },
    ].filter(item => !!item.value);

    function onConfirmIbanChange() {
        setIsSubmitting(true);
        CpDataApi.put(ibanChangeEndpoint, validatedBankData)
            .then(
                isAccipiens
                    ? () => {
                          setHasSubmitError(false);
                          setIsSubmitting(false);
                          toNextView();
                      }
                    : ({ data }: { data: SepaMandateDocument }) => {
                          setHasSubmitError(false);
                          setSepaDocument(data);
                          setIsSubmitting(false);
                          toNextView();
                      },
            )
            .catch(() => {
                setIsSubmitting(false);
                setHasSubmitError(true);
            });
    }

    return (
        <DataOverview title={t(`${ibanSectionTranslationPrefix}.headline.edit-mode`)} withoutCardEffect={true}>
            <p className={`u-mb`}>{t(`${confirmationViewTranslationPrefix}.description`)}</p>
            <DefinitionListHorizontal list={newValidatedBankAccountItems} />
            {!hasSubmitError ? (
                <Notification
                    testId={forLeasingContract ? 'leasing-notification' : 'financing-notification'}
                    status={NotificationStatus.info}
                    className={`u-mb`}
                >
                    {t(`${confirmationViewTranslationPrefix}.${notificationTranslationSuffix}`)}
                </Notification>
            ) : (
                <Notification testId={'error-notification'} status={NotificationStatus.error} className={`u-mb`}>
                    {t(`${confirmationViewTranslationPrefix}.change-error`)}
                </Notification>
            )}
            {isSubmitting ? (
                <Spinner center fullPage />
            ) : (
                <ButtonContainer center>
                    <Button type="reset" secondary onClick={toPreviousView} testId="backButton">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                    <Button type="submit" onClick={onConfirmIbanChange} testId="confirmButton">
                        {t('translation:editableSectionNav.confirm')}
                    </Button>
                </ButtonContainer>
            )}
        </DataOverview>
    );
};
