import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { AnyObject } from 'yup/lib/types';

export const errorMessageIds = {
    mileage: {
        empty: 'form.mileage.error.empty',
        numbers: 'form.mileage.error.numbers',
    },
    file: {
        empty: 'file-upload-validation.empty',
        multiple: 'file-upload-validation.multiple',
    },
};

const onlyNumbersPattern = /^[0-9]+$/;

export const validationSchema = (t: TFunction): Yup.ObjectSchema<AnyObject> => {
    const validations = {
        mileage: Yup.string()
            .trim()
            .matches(onlyNumbersPattern, t(errorMessageIds.mileage.numbers))
            .required(t(errorMessageIds.mileage.empty)),
        file: Yup.array()
            .required(t(errorMessageIds.file.empty))
            .max(1, t(errorMessageIds.file.multiple)),
    };
    return Yup.object().shape(validations);
};
