import * as Yup from 'yup';

export type CancelContractValidationMessages = {
    terminationDate: string;
};

const defaultValidationErrorMessages: CancelContractValidationMessages = {
    terminationDate: 'Termination date is required',
};

export const cancelContractValidationSchema = (
    errorMessages: CancelContractValidationMessages | undefined = defaultValidationErrorMessages,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
): Yup.ObjectSchema =>
    Yup.object().shape({
        terminationDate: Yup.string().required(errorMessages.terminationDate),
    });
