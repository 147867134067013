import { CPDate } from '@cp-shared-8/common-utilities';
import { UserType } from './user-type';

export type PostboxDocument = {
    _downloadLink: string;
    description: string;
    creationDate: CPDate;
    // Related contract number if document is related to contract
    contractNumber?: string;
    contractType?: UserType;
    product?: string;
};

export type PostboxDocumentFile = {
    content: string;
    mediaType: string;
};

export type InboxDocumentFile = PostboxDocumentFile;

export type InboxDocument = PostboxDocument & {
    documentKind: DocumentKind;
    isRead: boolean;
    documentId: string;
};

export enum PostBoxConsentType {
    InboxConsentNecessaryFinancing = 'InboxConsentNecessaryFinancing',
    InboxConsentNecessaryLeasing = 'InboxConsentNecessaryLeasing',
}

export type PostboxConsents = {
    [PostBoxConsentType.InboxConsentNecessaryFinancing]: boolean;
    [PostBoxConsentType.InboxConsentNecessaryLeasing]: boolean;
};

export type PostboxGivenConsents = {
    inboxConsentGivenFinancing?: boolean;
    inboxConsentGivenLeasing?: boolean;
};

export enum DocumentKind {
    ApplicationDocument = 'ApplicationDocument',
    ContractDocument = 'ContractDocument',
}
