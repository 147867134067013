import { AoDContract, CommonReservationDetails, ReservationContractStatus, ContractType } from '@cp-de/common';

import {
    exampleDealerDetailsWithAllValues,
    exampleDealerDetailsWithMissingValues,
    exampleReservationCustomerDetailsWithAllValues,
    exampleReservationCustomerDetailsWithMissingValues,
    exampleVehicleDetailsWithAllValues,
    exampleVehicleDetailsWithMissingValues,
} from '../common';

export const aodContractNumber = 'OoF3456';
export const aodContractId = `encrypted-${aodContractNumber}`;
export const canceledAodContractNumber = '3456oof';
export const canceledAodContractId = `canceled-${canceledAodContractNumber}`;

export const AodDetailsWithAllValues: CommonReservationDetails = {
    vehicleDetails: exampleVehicleDetailsWithAllValues,
    customerDetails: exampleReservationCustomerDetailsWithAllValues,
    dealerDetails: exampleDealerDetailsWithAllValues,
};

export const AodDetailsWithMissingValues: CommonReservationDetails = {
    vehicleDetails: exampleVehicleDetailsWithMissingValues,
    customerDetails: exampleReservationCustomerDetailsWithMissingValues,
    dealerDetails: exampleDealerDetailsWithMissingValues,
};

export const AodContractWithAllValues: AoDContract = {
    contractNumber: aodContractNumber,
    contractId: aodContractId,
    contractMileage: 123,
    price: 2500,
    startDate: '2019-05-01T22:00:00.000Z',
    startTime: '11:00:00',
    endTime: '19:00:00',
    endDate: '2039-05-01T22:00:00.000Z',
    contractStatus: ReservationContractStatus.RENT_STARTED,
    contractType: ContractType.AUDI_ON_DEMAND,
    additionalProducts: [
        {
            code: 'CDW_LOW',
            qualifiers: [
                {
                    id: 'insurance_text',
                    stringValue: '150',
                },
            ],
        },
        {
            code: '26',
        },
        {
            code: 'PAI',
        },
    ],
    details: AodDetailsWithAllValues,
};

export const AodContractCancellAllowed: AoDContract = {
    ...AodContractWithAllValues,
    startDate: '2222-05-01T22:00:00.000Z',
    startTime: '11:00:00',
};

export const AodContractCancelled: AoDContract = {
    ...AodContractWithAllValues,
    contractStatus: ReservationContractStatus.RENT_CANCELLED,
    contractId: canceledAodContractId,
    contractNumber: canceledAodContractNumber,
};

export const AodContractWithMissingValues: AoDContract = {
    contractNumber: aodContractNumber + '-1',
    contractId: aodContractId + '-1',
    contractMileage: 123,
    unlimitedMileage: true,
    price: 1700,
    startDate: '2019-05-01T22:00:00.000Z',
    endDate: '2039-05-01T22:00:00.000Z',
    contractStatus: ReservationContractStatus.RENT_STARTED,
    contractType: ContractType.AUDI_ON_DEMAND,
    additionalProducts: [
        {
            name: 'Unfallversicherung',
        },
    ],
    details: AodDetailsWithMissingValues,
};

export const AoDContractsWithDifferentValues = [AodContractWithAllValues, AodContractWithMissingValues];
