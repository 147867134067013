import { Contract, FinancialDetails } from '@cp-de/common';
import { Hr } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { BaseSection } from '../base-section/BaseSection';
import { IbanSection } from '../iban-section/IbanSection';
import { MileageSection } from '../mileage-section';

interface FinancialDetailsUiProps {
    financialDetails?: FinancialDetails;
    contract?: Contract;
    isInactive?: boolean;
}

export const FinancialDetailsUi: React.FC<FinancialDetailsUiProps> = ({ financialDetails, contract, isInactive }) => {
    if (!financialDetails || !contract) {
        return null;
    }

    return (
        <div>
            <BaseSection financialDetails={financialDetails} contract={contract} isInactive={isInactive} />
            {financialDetails.yearlyMileage && (
                <>
                    <Hr className={'u-mb-none'} />
                    <MileageSection contract={contract} financialDetails={financialDetails} isInactive={isInactive} />
                </>
            )}
            {financialDetails.iban && (
                <>
                    <Hr className={'u-mb-none'} />
                    <IbanSection financialDetails={financialDetails} contract={contract} isInactive={isInactive} />
                </>
            )}
        </div>
    );
};
