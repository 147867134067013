import { RaCContract, getContractsEndpoint, UserType } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from '../../../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<RaCContract[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'racContracts',
    fetchCallback() {
        return CpDataApi.get(getContractsEndpoint(UserType.RAC)).then(response => response.data);
    },
});

export default reducer;
export const fetchRaCContracts = fetchData;
