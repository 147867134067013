import { ExpertReportFormValues, ExpertReportStep1FormValues, ExpertReportStep2FormValues } from '../types';
import { getInitialValues as getCommonInsuranceFormInitialValues } from '../../common';

export const getStep1InitialValues = (
    isAccipiens: boolean,
    defaultValues?: ExpertReportFormValues,
): ExpertReportStep1FormValues => {
    const shape: ExpertReportStep1FormValues = {
        responsibleInsurance: defaultValues?.responsibleInsurance || '',
        policyHolder: defaultValues?.policyHolder || '',
        transferInsurance: defaultValues?.transferInsurance || '',
        insuranceClaim: defaultValues?.insuranceClaim || [],
    };
    if (isAccipiens) {
        shape['damageDate'] = defaultValues?.damageDate || '';
    }
    return shape;
};

export const getStep2InitialValues = (
    isAccipiens: boolean,
    defaultValues?: ExpertReportFormValues,
): ExpertReportStep2FormValues => {
    const shape: ExpertReportStep2FormValues = {
        insurance: getCommonInsuranceFormInitialValues(isAccipiens, defaultValues?.insurance),
    };
    if (!isAccipiens) {
        shape['paymentToLoanContract'] = defaultValues?.paymentToLoanContract || '';
        shape['repairByPrepayment'] = defaultValues?.repairByPrepayment || '';
        shape['attachments'] = defaultValues?.attachments || [];
    }
    return shape;
};

export const getInitialValues = (isAccipiens: boolean): ExpertReportFormValues => {
    return {
        ...getStep1InitialValues(isAccipiens),
        ...getStep2InitialValues(isAccipiens),
    };
};
