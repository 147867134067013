import { FurtherApprovalSelectionFormValues } from './types';
import { ReportDamageClaimType } from '@cp-de/common';

export const getInitialValues = (
    defaultReportDamageClaimType?: ReportDamageClaimType,
): FurtherApprovalSelectionFormValues => {
    return {
        reportDamageClaimType: defaultReportDamageClaimType || '',
    };
};
