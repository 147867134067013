import { Contract } from '@cp-de/common';
import { getCarBrandAndModel } from '../contracts/utils';
import { CombinedContractType } from '../../utils';

export const getCarInformation = (contract: Contract, language: string) => {
    const carBrand = contract.vehicle?.carBrand;
    const carModel = contract.vehicle?.carModel;

    return getCarBrandAndModel(carBrand, carModel, language);
};

export const getCarInformationFromCombinedContract = (contract: CombinedContractType, language: string) => {
    if (contract.vehicle) {
        return getCarInformation(contract as Contract, language);
    }

    const carBrand = contract.details?.vehicleDetails?.carBrand;
    const carModel = contract.details?.vehicleDetails?.carModel;

    return getCarBrandAndModel(carBrand, carModel, language);
};
