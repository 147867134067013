import { getContractsEndpoint, UserType } from '@cp-de/common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import React from 'react';
import { CpDataApi } from '../../../../cp-xhr';
import { RaCContractWithAllValues } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: [RaCContractWithAllValues],
        delay: 1000,
    },
    'With empty contracts list': {
        status: 200,
        responseBody: [],
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const RaCContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Rac Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(UserType.RAC),
};

export const RacContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, RaCContractsMock);

    return <div>{storyFn()}</div>;
};
