import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { FaqUi } from './ui';
import { useFaq } from './useFaq';
import { FaqLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

export const Faq: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useFaq();
    const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqUi);

    useAnalyticsPageViewTracker('faq', !!faq);
    return (
        <FaqWithHandlers
            isLoading={isLoading}
            faq={faq}
            hasError={!!loadingError}
            loadingPlaceholder={<FaqLoadingPlaceholder withDescription />}
        />
    );
};
