type ProviderType = 'VERIMI_ONLINE';

type Provider = {
    type: ProviderType;
};

type Legitimation = {
    provider: Provider;
};

type Sign = {
    provider: Provider;
};

type Identity = {
    legitimation: Legitimation;
    sign: Sign;
};

type Config = {
    identity: Identity;
};

type Address = {
    city?: string;
    countryCode?: string;
    houseNumber?: string;
    street?: string;
    zipCode?: string;
};

type CustomerData = {
    addresses: Address[];
    dateOfBirth?: string;
    firstName?: string;
    lastName?: string;
    nationality?: string;
    placeOfBirth?: string;
    salutation?: string;
};

export type CustomerRequest = {
    data: CustomerData;
};

export type ExternalIdentifier = {
    type: string;
    id: string;
};

type Signature = {
    height: number;
    mandatory: boolean;
    page: number;
    posX: number;
    posY: number;
    width: number;
};

type File = {
    base64: string;
    documentRef: string;
    filename: string;
    label: string;
    legalEntity: string;
    mandatoryView: boolean;
    mandatorySign: boolean;
    sequence: number;
    signatures: Signature[];
    signatureType: string;
};

type SignDocument = {
    files: File[];
    label: string;
    sequence: number;
};

type Transaction = {
    externalIdentifiers: ExternalIdentifier[];
};

type CallbackUrl = {
    url: string;
    headers?: [
        {
            key: string;
            value: string;
        },
    ];
};

type Urls = {
    redirect?: string;
    success?: string;
    failure?: string;
    callback?: CallbackUrl;
};

export type DriverLicenseCheckRequest = {
    config?: Config;
    customers: CustomerRequest[];
    signDocuments?: SignDocument[];
    transaction?: Transaction;
    urls?: Urls;
};

export type DriverLicenseRequestFE = {
    data: CustomerData;
    contractNumber: string;
};

export type DriverLicenseResponse = {
    redirectUrl: string;
};

export enum DriverLicenseCheckResponseStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}
export type DriverLicenseMailData = {
    contractNumber: string;
    finishDate: string;
    valid: string;
};

export type Customer = {
    externalIdentifiers: ExternalIdentifier[];
};

export type DriverLicenseCheckServiceCallback = {
    transaction?: {
        id: string;
        externalIdentifiers: ExternalIdentifier[];
    };
    customers?: [
        {
            identity: {
                legitimation: {
                    identificationMethod: [string];
                    customerId: string;
                };
            };
        },
    ];
    signDocuments?: SignDocument;
    status: {
        legitimation: {
            consumerCallbackDate: string;
            customerInteractionDate: string;
            updateDate: string;
            initDate: string;
            finishDate: string;
            legitimationDocumentReceivedDate: string;
            status: DriverLicenseCheckResponseStatus;
        };
        matching: {
            status: string;
            finishDate: string;
        };
        sign?: {};
    };
};
