export function getDigitalOffersEndpoint(): string {
    return `/digital-offer`;
}

export function getDigitalOfferIDsEndpoint(transactionId: string): string {
    return `/digital-offer/${transactionId}`;
}

export function getDigitalOfferTransactionIdRegisterdEndpoint(transactionId: string): string {
    return `/digital-offer/storage/${transactionId}`;
}

export function getDeleteDigitalOfferVehicleEndpoint(transactionId: string, vehicleId: string): string {
    return `/digital-offer/${transactionId}/${vehicleId}`;
}
