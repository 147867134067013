import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from '../../navigation/paths';

type FormNavigationButtonsProps = {
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
    primaryButtonType?: 'continue' | 'confirm';
    showDashboardButton?: boolean;
};

export const FormNavigationButtons: React.FC<FormNavigationButtonsProps> = ({
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    primaryButtonType = 'continue',
    showDashboardButton = false,
}) => {
    const { t } = useTranslation('translation');
    const history = useHistory();

    return showDashboardButton ? (
        <ButtonContainer className="u-mt" center>
            <Button secondary onClick={() => history.push(dashboardPagePath())} testId="button-dashboard" type="button">
                {t('translation:editableSectionNav.dashboard')}
            </Button>
        </ButtonContainer>
    ) : (
        <ButtonContainer className="u-mt" center>
            <Button type="button" secondary onClick={onSecondaryButtonClick} testId="cancelButton">
                {t('editableSectionNav.back')}
            </Button>
            <Button onClick={onPrimaryButtonClick} testId="submitButton" type="submit">
                {t(`editableSectionNav.${primaryButtonType}`)}
            </Button>
        </ButtonContainer>
    );
};
