import { PostboxDocument } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { fetchPostbox } from './PostboxSlice';
import { PostboxDataSelector } from './PostboxDataSelector';

export function usePostbox(
    isFetchingAllowed: boolean,
): AbstractDataState<PostboxDocument[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchPostbox, PostboxDataSelector, false, undefined, undefined, isFetchingAllowed);
}
