import { LicensePlate as LicensePlateShared } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type LicensePlateProps = {
    licensePlate?: string;
    size?: 'large' | 'medium' | 'small' | 'xsmall';
    className?: string;
};

export const LicensePlate: React.FC<LicensePlateProps> = ({ licensePlate, size = 'medium', className }) => {
    const { t } = useTranslation();
    return (
        <LicensePlateShared
            className={className}
            size={size}
            country={t('license-plate.country-code')}
            countryCode={t('license-plate.country-code')}
            registrationNumber={licensePlate || '-'}
            horizontalStripEu={true}
            euStars={true}
        />
    );
};
