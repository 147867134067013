import { Greeting } from '@cp-de/common';
import { Greeting as GreetingShared } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const GreetingUi: React.FC<{ greeting?: Greeting }> = ({ greeting }) => {
    const { t } = useTranslation('greeting');

    const greetingText = greeting ? ' ' + greeting : '';
    const fullGreetingText = t('message-text', { greetingName: greetingText });

    return <GreetingShared fullGreetingText={fullGreetingText} />;
};
