export type ServiceRequestCategory = {
    category: string;
};

export type ServiceRequestCategories = ServiceRequestCategory[];

export type Category =
    | 'AccountStatementCopy'
    | 'CopyOfZBT2Leasing'
    | 'CopyOfZBT2Finance'
    | 'InterestAndAmortizationSchedule'
    | 'InterestCertificate'
    | 'LoanContract'
    | 'SecuredSaleOfReturn'
    | 'LeasingOrder'
    | 'LeasingConfirmation'
    | 'ContractProlongation'
    | 'DiminishedValueAppraisal'
    | 'ReturnProtocol'
    | 'RequestForZBT2Finance'
    | 'RequestForZBT2Leasing'
    | 'StatementOfAccount'
    | 'RequestForZBT2FinanceAllContracts'
    | 'RequestForZBT2LeasingAllContracts'
    | 'RequestThirdPartyRedemption'
    | 'TransferFeeRequest'
    | 'ServiceManagementConfirmation'
    | 'ServiceManagementOrder';

export type ReasonForShipment =
    | 'ChangeOfAddress'
    | 'ChangeOfName'
    | 'ChangeOfRegistrationNumber'
    | 'RegisterVehicle'
    | 'DeregisterVehicle'
    | 'RecreateZBT1'
    | 'TechnicalChange';

export type ServiceRequest = {
    category: Category;
    reasonForShipment?: ReasonForShipment;
    additionalProperties?: { [key: string]: string | number };
};

export enum Requester {
    CUSTOMER = 'Customer',
    THIRD_PARTY = 'ThirdParty',
}

export enum VehicleDocumentReceiver {
    CUSTOMER = 'Customer',
    DEALER = 'Dealer',
    THIRD_PARTY = 'ThirdParty',
}
export enum CustomerVehicleDocumentReceiver {
    CUSTOMER = 'Customer',
    DEALER = 'Dealer',
}

export type EarlySettlementRequest = {
    requester: Requester;
    contractRedemptionDate: string;
    vehicleDocumentReceiver?: VehicleDocumentReceiver | CustomerVehicleDocumentReceiver;
    additionalProperties?: { [key: string]: string | number | boolean };
};
