import React from 'react';
import { Button, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export const MissingInformationView: React.FC<{ backToDashboard: () => void }> = ({ backToDashboard }) => {
    const { t } = useTranslation('contract-silent-extension');

    return (
        <>
            <Layout.Item>
                <Paragraph>{t('missing-information-view.description')}</Paragraph>
            </Layout.Item>
            <Layout.Item className={'u-text-center'}>
                <Button secondary onClick={backToDashboard} testId="backButton">
                    {t('back-button')}
                </Button>
            </Layout.Item>
        </>
    );
};
