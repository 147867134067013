import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ConsentDescriptionProps = {
    usageAgreement: string;
    isFirstLogin?: boolean;
};

export const ConsentDescription: React.FC<ConsentDescriptionProps> = ({ usageAgreement, isFirstLogin }) => {
    const { t } = useTranslation('consent');
    return (
        <>
            <Layout.Item className={'u-text-center'}>
                <h2>{t('headline')}</h2>
                {usageAgreement && <div dangerouslySetInnerHTML={{ __html: t('description') }} />}
            </Layout.Item>
            <Layout.Item className={'u-text-left'}>
                {!isFirstLogin && <div dangerouslySetInnerHTML={{ __html: t('description-new-consent') }} />}
            </Layout.Item>
            <Layout.Item className={'u-text-center'}>
                <div dangerouslySetInnerHTML={{ __html: t('description-end') }} />
            </Layout.Item>
        </>
    );
};
