import { Contract } from '@cp-de/common';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import React from 'react';
import { DashboardMarketingCardsUi } from './ui';
import { useDashboardMarketingCard } from './useDashboardMarketingCard';
import { getMostRecentFinancingContractWithAllDealerDetails } from './utils';
import { MarketingCardLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';

const DashboardMarketingCardWithHandlers = withLoadingAndNoConnectionHandler(DashboardMarketingCardsUi);

type DashboardMarketingCardsProps = {
    userWithFinancialContractsOnly: boolean;
    userWithFinancialContracts: boolean;
    userWithYoungLeasingContract: boolean;
    userWithYoungFinancialContractPriceProtection: boolean;
    userWithAutoAbo: boolean;
    contracts?: Contract[];
    renderDealersCard?: boolean;
};

export const DashboardMarketingCards: React.FC<DashboardMarketingCardsProps> = ({
    userWithFinancialContractsOnly,
    userWithFinancialContracts,
    userWithYoungLeasingContract,
    userWithYoungFinancialContractPriceProtection,
    userWithAutoAbo,
    contracts,
    // Dealer card removed until market has provided additional information
    // renderDealersCard,
}) => {
    const { cmsContent: dashboardMarketingCard, isLoading, loadingError } = useDashboardMarketingCard();

    const financingContractWithAllDealerDetails = getMostRecentFinancingContractWithAllDealerDetails(contracts || []);

    return (
        <Layout.Item default={'1/4'} m={'1/1'}>
            <DashboardMarketingCardWithHandlers
                isLoading={isLoading}
                dashboardMarketingCard={dashboardMarketingCard}
                dealer={financingContractWithAllDealerDetails?.dealer}
                hasError={!!loadingError}
                userWithFinancialContractsOnly={userWithFinancialContractsOnly}
                userWithFinancialContracts={userWithFinancialContracts}
                userWithYoungLeasingContract={userWithYoungLeasingContract}
                userWithYoungFinancialContractPriceProtection={userWithYoungFinancialContractPriceProtection}
                userWithAutoAbo={userWithAutoAbo}
                loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
            />
        </Layout.Item>
    );
};
