import { Contract } from '@cp-de/common';
import { Card } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from '../../../../../../license-plate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isDirectCredit } from '../../../../../../../utils';

export type LeasingContractHeaderProps = {
    contract: Contract;
};

export const LeasingContractHeader: React.FC<LeasingContractHeaderProps> = ({ contract }) => {
    const { t } = useTranslation('contracts');
    const { i18n } = useTranslation();
    const language = i18n.languages[0];

    const isDirectCreditGroup = isDirectCredit(contract);

    const carInformation = contract.vehicle?.carBrand
        ? contract.vehicle.carBrand.toLocaleUpperCase(language) +
          ' ' +
          contract.vehicle.carModel?.toLocaleUpperCase(language)
        : undefined;

    const productTypeTranslationKey = contract.productType
        ?.toString()
        .toLowerCase()
        .replace(/_/g, '-');

    const contractCategory = productTypeTranslationKey ? t(`product-types.${productTypeTranslationKey}`) : '';
    return (
        <Card element="article">
            <div className="u-text-left@m-up  u-mr-none cp-contract-identifier-slot">
                {!isDirectCreditGroup && (
                    <LicensePlate size={'small'} licensePlate={contract.vehicle?.licensePlate} className="u-mb" />
                )}

                {carInformation && <p className="u-zeta">{carInformation}</p>}

                <p className="u-zeta">{contractCategory}</p>

                <p>{`${t('contract')} ${contract.contractNumber}`}</p>
            </div>
        </Card>
    );
};
