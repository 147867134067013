import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Button,
    ButtonContainer,
    Card,
    Heading,
    Layout,
    TileSelect,
    TileSelectGroup,
} from '@vwfs-bronson/bronson-react';
import {
    contractEndOptionsPurchasePriceInquiryPagePath,
    contractEndOptionsSilentExtensionPagePath,
    contractEndOptionsVehicleReturnPagePath,
    dashboardPagePath,
} from '../../../navigation/paths';
import { ContractHeader } from '../../../contract-header';
import { Contract, getContractEndOptionsEndpoint, getPurchasePriceInquiryEndpoint } from '@cp-de/common';
import { Notification, NotificationStatus, Spinner } from '@cp-shared-8/frontend-ui';
import { CpDataApi } from '../../../../cp-xhr';

export enum Options {
    VEHICLE_RETURN = 'VEHICLE_RETURN',
    CONTRACT_SILENT_EXTENSION = 'CONTRACT_SILENT_EXTENSION',
    PURCHASE_PRICE_REQUEST = 'PURCHASE_PRICE_REQUEST',
}

export const ContractEndOptionsEntryPoint: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { t } = useTranslation('contract-end-options');
    const history = useHistory();

    const [notificationContent, setNotificationContent] = useState<{ content: string; status: NotificationStatus }>();
    const [isLoading, setIsLoading] = useState(false);

    const { contractId, vehicleReturnInfo } = contract;
    const { vehicleReturnAllowed, referralToDealerAllowed } = vehicleReturnInfo || {};

    const onSubmit = (chosenOption: Options) => {
        if (chosenOption === Options.CONTRACT_SILENT_EXTENSION) {
            setIsLoading(true);
            CpDataApi.get(getContractEndOptionsEndpoint(contractId))
                .then(response => {
                    const { dateValues } = response.data;
                    history.push(contractEndOptionsSilentExtensionPagePath(contractId), {
                        dateValues,
                    });
                })
                .catch(error => {
                    const notificationContentAndStatus =
                        error.response.data.code === 'NOT_AVAILABLE'
                            ? {
                                  content: t('contract-silent-extension.silent-extension-not-available'),
                                  status: NotificationStatus.warning,
                              }
                            : {
                                  content: t('contract-silent-extension.silent-extension-error'),
                                  status: NotificationStatus.error,
                              };
                    setNotificationContent(notificationContentAndStatus);
                    setIsLoading(false);
                });
        } else if (chosenOption === Options.VEHICLE_RETURN) {
            if (!vehicleReturnInfo)
                setNotificationContent({
                    content: t('vehicle-return.no-vehicle-return-info'),
                    status: NotificationStatus.error,
                });
            else if (!vehicleReturnAllowed)
                setNotificationContent({
                    content: t('vehicle-return.vehicle-return-not-allowed'),
                    status: NotificationStatus.warning,
                });
            else if (!referralToDealerAllowed)
                setNotificationContent({
                    content: t('vehicle-return.referral-to-dealer-not-allowed'),
                    status: NotificationStatus.warning,
                });
            else history.push(contractEndOptionsVehicleReturnPagePath(contractId));
        } else if (chosenOption === Options.PURCHASE_PRICE_REQUEST) {
            setIsLoading(true);
            CpDataApi.get(getPurchasePriceInquiryEndpoint(contractId))
                .then(response => {
                    const data = response.data;
                    if (data) {
                        history.push(contractEndOptionsPurchasePriceInquiryPagePath(contractId), { data });
                    } else {
                        const notificationContentAndStatus = {
                            content: t('purchase-price-request.not-available'),
                            status: NotificationStatus.warning,
                        };
                        setNotificationContent(notificationContentAndStatus);
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    const notificationContentAndStatus = {
                        content: t('purchase-price-request.error'),
                        status: NotificationStatus.error,
                    };
                    setNotificationContent(notificationContentAndStatus);
                    setIsLoading(false);
                });
        }
    };

    return (
        <>
            {isLoading && <Spinner center fullPage />}
            <Heading level={1}>{t('heading')}</Heading>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <Card element="article">
                        <ContractHeader isDeferralLinkShown={false} contract={contract} />
                    </Card>
                </Layout.Item>
                <Layout.Item className="u-text-center" default="1/1" s="1/1">
                    <TileSelectGroup layoutItemClassName={'u-1/3 u-1/3@l  u-1/1@s'}>
                        <TileSelect
                            testId="optionVehicleReturn"
                            title={t('vehicle-return.title')}
                            inputType="radio"
                            icon="semantic-car-handover"
                            value={'vehicleReturn'}
                            radioGroupName="contractEndOptions"
                            onClick={(): void => {
                                onSubmit(Options.VEHICLE_RETURN);
                            }}
                        >
                            {t('vehicle-return.text')}
                        </TileSelect>
                        <TileSelect
                            testId="optionContractSilentExtension"
                            title={t('contract-silent-extension.title')}
                            inputType="radio"
                            icon="calendar-circle-checkmark"
                            value={'contractSilentExtension'}
                            radioGroupName="contractEndOptions"
                            onClick={(): void => {
                                onSubmit(Options.CONTRACT_SILENT_EXTENSION);
                            }}
                        >
                            {t('contract-silent-extension.text')}
                        </TileSelect>
                        <TileSelect
                            testId="optionPurchasePriceRequest"
                            title={t('purchase-price-request.title')}
                            inputType="radio"
                            icon="car-circle-eurosign"
                            value={'purchasePriceRequest'}
                            radioGroupName="contractEndOptions"
                            onClick={(): void => {
                                onSubmit(Options.PURCHASE_PRICE_REQUEST);
                            }}
                        >
                            {t('purchase-price-request.text')}
                        </TileSelect>
                    </TileSelectGroup>
                </Layout.Item>

                {notificationContent && (
                    <Layout.Item className="u-mt" default="1/1" s="1/1">
                        <Notification className="u-mb c-notification--context" status={notificationContent.status}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: notificationContent.content,
                                }}
                            />
                        </Notification>
                    </Layout.Item>
                )}

                <Layout.Item className="u-mt" default="1/1" s="1/1">
                    <ButtonContainer center>
                        <Button secondary onClick={(): void => history.push(dashboardPagePath())} testId="backButton">
                            {t('back-button')}
                        </Button>
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
        </>
    );
};
