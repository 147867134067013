import { CustomerVehicleDocumentReceiver, VehicleDocumentReceiver } from '@cp-de/common';
import { FormOfAddress, Tab } from './enums';

export type InitialValues = {
    contractRedemptionDate: string;
    isThirdPartyRequester?: boolean;
    selectedTab?: Tab;
    person?: Person;
    business?: Business;
    accountHolder?: string;
    iban?: string;
    vehicleDocumentReceiver?: VehicleDocumentReceiver;
    customerVehicleDocumentReceiver?: CustomerVehicleDocumentReceiver;
    terminatePpi?: string;
    consent?: boolean;
};

export type Person = {
    formOfAddress: FormOfAddress | '';
    firstName: string;
    lastName: string;
    streetName: string;
    houseNumber: string;
    zipCode: string;
    locality: string;
};

export type Business = {
    companyName: string;
    streetName: string;
    houseNumber: string;
    zipCode: string;
    locality: string;
};

export const initialValues = (accountHolder = '', iban = ''): InitialValues => {
    return {
        contractRedemptionDate: '',
        isThirdPartyRequester: false,
        selectedTab: Tab.PERSON,
        person: {
            formOfAddress: '',
            firstName: '',
            lastName: '',
            streetName: '',
            houseNumber: '',
            zipCode: '',
            locality: '',
        },
        business: {
            companyName: '',
            streetName: '',
            houseNumber: '',
            zipCode: '',
            locality: '',
        },
        accountHolder,
        iban,
        vehicleDocumentReceiver: VehicleDocumentReceiver.CUSTOMER,
        customerVehicleDocumentReceiver: CustomerVehicleDocumentReceiver.CUSTOMER,
        terminatePpi: 'false',
        consent: false,
    };
};
