import React, { useEffect } from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { CancelReservationUi } from './ui';
import { CancelReservationLoadingPlaceholder } from './CancelReservationLoadingPlaceholder';
import { useTranslation } from 'react-i18next';

import { useRaCContract } from '../contracts';
import { dashboardPagePath } from '../navigation/paths';
import { useHistory } from 'react-router-dom';
import { ReservationContractStatus } from '@cp-de/common';

const CancelReservationWithHandlers = withLoadingAndNoConnectionHandler(CancelReservationUi);

export const CancelRaCReservation: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { t } = useTranslation('cancel-reservation');
    const history = useHistory();

    const { data: racContract, isLoading, loadingError, hasReceivedResponse } = useRaCContract(contractId);

    const { contractStatus } = racContract || {};

    useEffect(() => {
        if (contractStatus === ReservationContractStatus.RENT_CANCELLED) {
            history.push(dashboardPagePath());
        }
    }, [history, contractStatus]);

    return (
        <CancelReservationWithHandlers
            isLoading={isLoading}
            reservationContract={racContract}
            hasError={!!loadingError || (hasReceivedResponse && !racContract)}
            errorText={t('error.no-connection')}
            loadingPlaceholder={<CancelReservationLoadingPlaceholder />}
        />
    );
};
