import { FinancialDetails, IbanChange, SepaMandateDocument } from '@cp-de/common';
import React, { useState } from 'react';
import { IbanConfirmationView } from './iban-confirmation-view/IbanConfirmationView';
import { IbanValidationView } from './iban-validation-view/IbanValidationView';
import { LeasingContractsView } from './leasing-contracts-view/LeasingContractsView';
import { SepaDownloadView } from './sepa-download-view/SepaDownloadView';
import { EditStatus } from '../../base-section/EditStatus';

export type EditViewNames =
    | 'LEASING_CONTRACTS_VIEW'
    | 'ACCIPIENS_CONTRACTS_VIEW'
    | 'IBAN_VALIDATION_VIEW'
    | 'IBAN_DETAILS_VIEW'
    | 'SEPA_MANDATE_DOWNLOAD_VIEW';

export type EditViewProps = {
    financialDetails: FinancialDetails;
    contractNumber: string;
    forLeasingContract: boolean;
    isAccipiens: boolean;
    finishEditing: (lastEditStatus: EditStatus) => void;
};

export const EditView: React.FC<EditViewProps> = ({
    financialDetails,
    contractNumber,
    forLeasingContract,
    isAccipiens,
    finishEditing,
}) => {
    const firstView = forLeasingContract
        ? 'LEASING_CONTRACTS_VIEW'
        : isAccipiens
        ? 'ACCIPIENS_CONTRACTS_VIEW'
        : 'IBAN_VALIDATION_VIEW';
    const [sepaDocument, setSepaDocument] = useState<SepaMandateDocument | undefined>(undefined);
    const [currentView, setCurrentView] = useState<EditViewNames>(firstView);
    const [validatedBankData, setValidatedBankData] = useState<IbanChange | undefined>(undefined);

    if (!financialDetails._links?.changeIban) {
        return null;
    }

    const toNextView = (): void => {
        switch (currentView) {
            case 'LEASING_CONTRACTS_VIEW': {
                setCurrentView('IBAN_VALIDATION_VIEW');
                break;
            }
            case 'ACCIPIENS_CONTRACTS_VIEW': {
                setCurrentView('IBAN_VALIDATION_VIEW');
                break;
            }
            case 'IBAN_VALIDATION_VIEW': {
                setCurrentView('IBAN_DETAILS_VIEW');
                break;
            }
            case 'IBAN_DETAILS_VIEW': {
                if (isAccipiens) {
                    finishEditing(EditStatus.SUCCESS);
                } else {
                    setCurrentView('SEPA_MANDATE_DOWNLOAD_VIEW');
                }
                break;
            }
            case 'SEPA_MANDATE_DOWNLOAD_VIEW': {
                finishEditing(EditStatus.SUCCESS);
                break;
            }
        }
    };

    const toPreviousView = (): void => {
        switch (currentView) {
            case 'LEASING_CONTRACTS_VIEW': {
                finishEditing(EditStatus.NOT_PERFORMED);
                break;
            }
            case 'ACCIPIENS_CONTRACTS_VIEW': {
                finishEditing(EditStatus.NOT_PERFORMED);
                break;
            }
            case 'IBAN_VALIDATION_VIEW': {
                if (forLeasingContract) {
                    setCurrentView('LEASING_CONTRACTS_VIEW');
                } else if (isAccipiens) {
                    setCurrentView('ACCIPIENS_CONTRACTS_VIEW');
                } else {
                    finishEditing(EditStatus.NOT_PERFORMED);
                }
                break;
            }
            case 'IBAN_DETAILS_VIEW': {
                setCurrentView('IBAN_VALIDATION_VIEW');
                break;
            }
            case 'SEPA_MANDATE_DOWNLOAD_VIEW': {
                setCurrentView('IBAN_DETAILS_VIEW');
                break;
            }
        }
    };

    const onValidIban = (validatedBankData: IbanChange): void => {
        setValidatedBankData(validatedBankData);
        toNextView();
    };

    return (
        <>
            {(currentView === 'LEASING_CONTRACTS_VIEW' || currentView === 'ACCIPIENS_CONTRACTS_VIEW') && (
                <LeasingContractsView
                    toPreviousView={toPreviousView}
                    toNextView={toNextView}
                    isAccipiens={isAccipiens}
                />
            )}
            {currentView === 'IBAN_VALIDATION_VIEW' && (
                <IbanValidationView
                    previousIban={financialDetails.iban}
                    onValidIban={onValidIban}
                    toPreviousView={toPreviousView}
                    isFirstView={!forLeasingContract}
                />
            )}
            {currentView === 'IBAN_DETAILS_VIEW' && (
                <IbanConfirmationView
                    validatedBankData={validatedBankData}
                    toPreviousView={toPreviousView}
                    toNextView={toNextView}
                    forLeasingContract={forLeasingContract}
                    isAccipiens={isAccipiens}
                    ibanChangeEndpoint={financialDetails._links.changeIban}
                    setSepaDocument={setSepaDocument}
                />
            )}
            {currentView === 'SEPA_MANDATE_DOWNLOAD_VIEW' && sepaDocument && (
                <SepaDownloadView contractNumber={contractNumber} sepaDocument={sepaDocument} toNextView={toNextView} />
            )}
        </>
    );
};
