import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { Card, DataOverview, Layout } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import React from 'react';
import { Contract, FinancialDetails, PaymentStatus } from '@cp-de/common';
import { ContractHeader } from '../../contract-header';

export type AmortizationTableHeaderProps = {
    contract: Contract;
    financialDetails: FinancialDetails;
};

export const AmortizationTableHeader: React.FC<AmortizationTableHeaderProps> = ({ contract, financialDetails }) => {
    const { t, f } = useTranslationWithFormatting('amortization-table');

    const paymentOverview =
        contract.paymentPlan?.map(payment => {
            return payment.paymentRateNumber;
        }) || [];

    const nominalAmount = financialDetails.nominalAmount;
    const pendingCapital = financialDetails.pendingCapital;
    const startDate = financialDetails.startDate;
    const endDate = financialDetails.endDate;

    const totalInstallments = Math.max.apply(null, paymentOverview);
    const pendingInstallments = contract.paymentPlan?.filter(
        payment => payment.paymentStatus !== PaymentStatus.VOLLBEZAHLT,
    ).length;

    const tiles = [
        {
            title: t('tiles.installment.title'),
            icon: 'list-view',
            data: [
                {
                    label: t('tiles.installment.total-installments'),
                    value: f(totalInstallments, TranslationFormat.NUMBER),
                },
                {
                    label: t('tiles.installment.pending-installments'),
                    value: f(pendingInstallments, TranslationFormat.NUMBER),
                },
            ].filter(item => !!item.value),
        },
        {
            title: t('tiles.finance.title'),
            icon: 'hand-circle-eurosign',
            data: [
                {
                    label: t('tiles.finance.nominal-amount'),
                    value: f(nominalAmount, TranslationFormat.CURRENCY),
                },
                {
                    label: t('tiles.finance.pending-capital'),
                    value: f(pendingCapital, TranslationFormat.CURRENCY),
                },
            ].filter(item => !!item.value),
        },
        {
            title: t('tiles.timeline.title'),
            icon: 'calendar',
            data: [
                {
                    label: t('tiles.timeline.begin-date'),
                    value: f(startDate, TranslationFormat.DATE),
                },
                {
                    label: t('tiles.timeline.end-date'),
                    value: f(endDate, TranslationFormat.DATE),
                },
            ].filter(item => !!item.value),
        },
    ];

    return (
        <Layout>
            <Layout.Item default="1/1">
                <Card element="article">
                    <ContractHeader contract={contract} isDeferralLinkShown={false} />
                </Card>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Layout className={'o-layout--equal-height'}>
                    {tiles.map((tile, index) => (
                        <Layout.Item key={index} default="1/3" m="1/1">
                            <DataOverview title={tile.title} icon={tile.icon}>
                                <DefinitionListHorizontal list={tile.data as DefinitionListItem[]} />
                            </DataOverview>
                        </Layout.Item>
                    ))}
                </Layout>
            </Layout.Item>
        </Layout>
    );
};
