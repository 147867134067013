import { AoDContract, AutoAboContract, Contract, FourSalesContract, RaCContract } from '@cp-de/common';
import { capitalize } from 'lodash';

type CreateVehicleData = {
    contract?: Contract;
    reservationContract?: AutoAboContract | FourSalesContract | AoDContract | RaCContract;
};

export type GeneratedVehicleData = {
    title: string;
    brand: string;
    imgSrc: string;
    licensePlate: string;
    vin: string;
    modelName: string;
    modelYear: number;
    bodyColor: string;
    fuelType: string;
    enginePower: string;
    transmission: string;
    trim: string;
};

export const createVehicleData = ({ contract, reservationContract }: CreateVehicleData): GeneratedVehicleData => {
    let title = '';
    let brand = '';
    let imgSrc = '';
    let licensePlate = '';
    let vin = '';
    let modelName = '';
    let modelYear = 0;
    let bodyColor = '';
    let fuelType = '';
    let enginePower = '';
    let transmission = '';
    let trim = '';
    if (contract) {
        const { vehicleData, vehicleImagesData, vehicle } = contract;
        title = `${vehicleData?.brand} ${vehicleData?.modelName}`;
        brand = vehicleData?.brand as string;
        imgSrc = vehicleImagesData?.images[0]?.url as string;
        licensePlate = vehicle?.licensePlate as string;
        vin = vehicleData?.vin as string;
        modelName = vehicleData?.modelName as string;
        modelYear = vehicleData?.modelYear as number;
        bodyColor = `${capitalize(vehicleData?.bodyColorName)} ${capitalize(vehicleData?.bodyColorFinish)}`;
        fuelType = vehicleData?.fuelType as string;
        enginePower = `${vehicleData?.enginePower?.value} ${vehicleData?.enginePower?.unit}`;
        transmission = vehicleData?.transmission as string;
        trim = vehicleData?.trim as string;
    } else if (reservationContract?.details?.vehicleDetails) {
        const vehicleDetails = reservationContract.details.vehicleDetails;
        title = `${vehicleDetails?.carBrand} ${vehicleDetails?.carModel}`;
        brand = vehicleDetails?.carBrand as string;
        imgSrc = vehicleDetails?.carImage as string;
        licensePlate = vehicleDetails?.registrationNumber as string;
        vin = vehicleDetails?.vin as string;
        modelName = vehicleDetails?.carModel as string;
        modelYear = vehicleDetails?.modelYear as number;
        bodyColor = `${capitalize(vehicleDetails?.bodyColorName)} ${capitalize(vehicleDetails?.bodyColorFinish)}`;
        fuelType = vehicleDetails?.fuelType as string;
        enginePower = `${vehicleDetails?.enginePowerValue} ${vehicleDetails?.enginePowerUnit}`;
        transmission = vehicleDetails?.transmission as string;
        trim = vehicleDetails?.trimValue as string;
    }
    return {
        title,
        brand,
        imgSrc,
        licensePlate,
        vin,
        modelName,
        modelYear,
        bodyColor,
        fuelType,
        enginePower,
        transmission,
        trim,
    };
};
