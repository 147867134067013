import React from 'react';
import {
    Notification,
    NotificationStatus,
    DefinitionListHorizontal,
    DefinitionListItem,
} from '@cp-shared-8/frontend-ui';
import { AdditionalProduct, AutoAboContract } from '@cp-de/common';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';
import { isEmptyOrUndefined } from '../utils';
import { AboBaseSection } from './abo-base-section/AboBaseSection';
import { Heading } from '@vwfs-bronson/bronson-react';

export const AutoAboContractDetails: React.FC<{ contract: AutoAboContract; isInactive?: boolean }> = ({
    contract,
    isInactive,
}) => {
    const { t, ready } = useTranslationWithFormatting('auto-abo-contract-details', { useSuspense: false });

    const {
        details: { contractDetails },
        additionalProducts,
    } = contract;

    if (!contractDetails || isEmptyOrUndefined(contractDetails)) {
        return (
            <Notification status={NotificationStatus.error} className="c-notification--context">
                {t('error-notification')}
            </Notification>
        );
    }

    const allowedProductCodes = [
        '37',
        '36',
        '39',
        '38',
        '41',
        '40',
        '33',
        '34',
        '53',
        '62',
        '63',
        'PAI',
        'CDW_LOW',
        'CDW_NORMAL',
        'CDW_1',
        'CDW_2',
        'CDW_3',
        'CDW_4',
    ];

    const getProductNameTranslation = (product: AdditionalProduct): { label: string; value: string } => {
        let result = { label: '', value: '' };
        if (product.code) {
            switch (product.code) {
                case 'CDW_LOW':
                case 'CDW_NORMAL':
                case 'CDW_1':
                case 'CDW_2':
                case 'CDW_3':
                case 'CDW_4':
                    const cdwValue = product.qualifiers?.find(el => el.id === 'insurance_text');
                    result = {
                        label: t('product-codes.cdw.name'),
                        value: t('product-codes.cdw.value', cdwValue && { amount: cdwValue.stringValue }),
                    };
                    break;
                case 'PAI':
                    return {
                        label: t('product-codes.pai.name'),
                        value: t('product-codes.pai.value'),
                    };
                default:
                    result = {
                        label: t(`product-codes.${product.code.toLowerCase()}`),
                        value: '',
                    };
            }
        }

        return result;
    };

    const additionalProductsNames: DefinitionListItem[] =
        additionalProducts
            ?.filter((product: AdditionalProduct) => {
                return product?.code && allowedProductCodes.includes(product.code);
            })
            .map((product: AdditionalProduct) => getProductNameTranslation(product)) || [];

    return (
        <>
            {ready && <AboBaseSection contract={contract} isInactive={isInactive} />}
            {!!additionalProductsNames.length && (
                <>
                    <Heading level="6" className="u-pt">
                        {t('additional-products')}
                    </Heading>
                    <DefinitionListHorizontal list={additionalProductsNames} />
                </>
            )}
        </>
    );
};
