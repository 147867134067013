import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { SubmittingStatus } from '../types';
import { ReportDamageClaimType } from '@cp-de/common';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../../../navigation/paths';

type ConfirmationModalProps = {
    claimType?: ReportDamageClaimType;
    status: SubmittingStatus;
    onClose: () => void;
    onConfirm?: () => void;
    redirectPath?: string;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    claimType,
    status,
    onClose,
    onConfirm,
    redirectPath,
}) => {
    const { t } = useTranslation('repair-approval');
    const history = useHistory();

    if (!(status === SubmittingStatus.SUCCESS || status === SubmittingStatus.ERROR)) {
        return null;
    }

    const translationPrefix = {
        SUCCESS: 'confirmation-success-modal',
        ERROR: 'translation:error.noConnection',
    };

    const getTitle = (): string => {
        return t(`${translationPrefix[status]}.title`);
    };

    const getText = (): string => {
        return status === SubmittingStatus.ERROR
            ? t(`${translationPrefix[status]}.text`)
            : t(`${translationPrefix[status]}.${claimType}.text`);
    };

    const onModalConfirm = (): void => {
        if (onConfirm) onConfirm();
        history.push(redirectPath || dashboardPagePath());
    };

    return (
        <Modal
            shown={status === SubmittingStatus.ERROR || status === SubmittingStatus.SUCCESS}
            status={status === SubmittingStatus.SUCCESS ? 'success' : 'error'}
            hideCloseButton={status === SubmittingStatus.SUCCESS}
            onClose={onClose}
            onConfirm={onModalConfirm}
            title={getTitle()}
            buttonConfirmText={status === SubmittingStatus.SUCCESS ? t('translation:editableSectionNav.ok') : undefined}
            testId={'status-modal'}
        >
            <p>{getText()}</p>
        </Modal>
    );
};
