import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RequestDocument } from '../../components/request-document';
import { RequestDocumentFormCategory } from '../../components/request-document/request-document-component/request-document-form/utils/RequestDocumentFormFields';
import { ReasonForShipment } from '@cp-de/common';

export const RequestDocumentPage: React.FC = () => {
    const { t } = useTranslation('request-document');
    const { contractId, documentType, shippingReason } = useParams<{
        contractId: string;
        documentType?: RequestDocumentFormCategory;
        shippingReason?: ReasonForShipment;
    }>();
    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('title')}</Heading>
            <RequestDocument contractId={contractId} documentType={documentType} shippingReason={shippingReason} />
        </ContentSection>
    );
};
