import React from 'react';
import { Button, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Dealer } from '@cp-de/common';

type DealerInformationViewProps = { dealer: Dealer; backToDashboard: () => void };

export const DealerInformationView: React.FC<DealerInformationViewProps> = ({ dealer, backToDashboard }) => {
    const { t } = useTranslation('contract-silent-extension');
    const { name = '', contact, address } = dealer;
    const { street = '', houseNumber = '', city = '' } = address || {};
    const { email = '', phone = '' } = contact || {};

    return (
        <>
            <Layout.Item>
                <Paragraph>{t('dealer-information-view.description')}</Paragraph>
                <Paragraph className={'u-text-center'}>
                    <b>
                        {t('dealer-information-view.name', { name })}
                        <br />
                        {t('dealer-information-view.street', { street, houseNumber })}
                        <br />
                        {t('dealer-information-view.city', { city })}
                        <br />
                        {t('dealer-information-view.phone', { phone })}
                        <br />
                        {t('dealer-information-view.email', { email })}
                    </b>
                </Paragraph>
            </Layout.Item>
            <Layout.Item className={'u-text-center'}>
                <Button secondary onClick={backToDashboard} testId="backButton">
                    {t('back-button')}
                </Button>
            </Layout.Item>
        </>
    );
};
