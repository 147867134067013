import React, { useState } from 'react';
import { AddressChange } from '@cp-de/common';
import { ChangeAddress } from '../../../../../../my-profile/address-section/change-address';

export type EditViewProps = {
    setShowEditSuccessMessage: (arg0: boolean) => void;
    onSuccess: () => void;
    backToNonEditMode: () => void;
};

const initialValues: AddressChange = {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
};

export const EditView: React.FC<EditViewProps> = ({ setShowEditSuccessMessage, onSuccess, backToNonEditMode }) => {
    const [formValues, setFormValues] = useState<AddressChange>(initialValues);

    return (
        <ChangeAddress
            isContractView={true}
            isAbo={true}
            initialValues={formValues}
            onSubmit={setFormValues}
            onSubmitSuccess={(): void => {
                setShowEditSuccessMessage(true);
                onSuccess();
            }}
            onCancel={(currentFormValues: AddressChange): void => {
                setFormValues(currentFormValues);
                backToNonEditMode();
            }}
        />
    );
};
