import { AutoAboContract } from '@cp-de/common';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';

import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { fetchAutoAboContracts } from './AutoAboContractsSlice';
import { AutoAboContractsDataSelector } from './AutoAboContractsDataSelector';

export function useAutoAboContracts(
    isFetchingAllowed = true,
): AbstractDataState<AutoAboContract[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchAutoAboContracts,
        AutoAboContractsDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
