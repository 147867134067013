import { AuthorityAddress } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { fetchLicensingAuthorities } from './CustomLicensingAuthoritiesSlice';
import { CustomLicensingAuthoritiesDataSelector } from './CustomLicensingAuthoritiesDataSelector';

export function useCustomLicensingAuthorities(
    zipCode: string,
    alwaysRefetch: boolean,
): AbstractDataState<AuthorityAddress[], DefaultBusinessMarketApiErrorCode> {
    const data = { zipCode };
    return useGetSimpleApiData(fetchLicensingAuthorities, CustomLicensingAuthoritiesDataSelector, alwaysRefetch, {
        data,
    });
}
