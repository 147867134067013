import { InboxDocument, DocumentKind } from '@cp-de/common';

export const InboxDocumentContractRelated: InboxDocument = {
    description: 'Bankverbindungsänderung',
    creationDate: '2020-07-01',
    _downloadLink: '/Inbox-document-123-download',
    isRead: false,
    documentKind: DocumentKind.ApplicationDocument,
    contractNumber: '987654321',
    documentId: '123456789',
};

export const InboxWithAllValues: InboxDocument[] = [InboxDocumentContractRelated];

export const EmptyInboxList: InboxDocument[] = [];
