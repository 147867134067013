import React from 'react';

import { NotificationStatus, NotificationWithNavigationButton } from '@cp-shared-8/frontend-ui';
import { postboxPagePath } from '../../navigation/paths';
import { useTranslation } from 'react-i18next';

export type PendingContractNotificationProps = {
    testId?: string;
    className?: string;
};

export const PendingContractNotification: React.FC<PendingContractNotificationProps> = ({ testId, className }) => {
    const { t } = useTranslation('contracts');

    return (
        <NotificationWithNavigationButton
            status={NotificationStatus.warning}
            headline={t('pending-contracts.headline')}
            text={t('pending-contracts.text')}
            notificationTestId={testId}
            submitButtonLabel={t('pending-contracts.button-text')}
            pathToNavigate={postboxPagePath()}
            className={className}
        />
    );
};
