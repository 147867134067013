import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { AnyObject } from 'yup/lib/types';

export type IdentityChangeFormValues = {
    lastName: string;
    birthName: string;
    frontFile: File[];
    backFile: File[];
};

export const initialValues: IdentityChangeFormValues = {
    lastName: '',
    birthName: '',
    frontFile: [],
    backFile: [],
};

export const errorMessageIds = {
    lastName: {
        empty: 'change-name.last-name.error.empty',
        numbers: 'change-name.last-name.error.numbers',
    },
    birthName: {
        empty: 'change-name.birth-name.error.empty',
        numbers: 'change-name.birth-name.error.numbers',
    },
    frontFile: {
        empty: 'change-name.front-file.error.empty',
        multiple: 'change-name.front-file.error.multiple',
    },
    backFile: {
        empty: 'change-name.back-file.error.empty',
        multiple: 'change-name.back-file.error.multiple',
    },
};

const noNumbersPattern = /^[^0-9]+$/;

export const validationSchema = (t: TFunction): Yup.ObjectSchema<AnyObject> => {
    const validations = {
        lastName: Yup.string()
            .trim()
            .matches(noNumbersPattern, t(errorMessageIds.lastName.numbers))
            .required(t(errorMessageIds.lastName.empty)),
        birthName: Yup.string()
            .trim()
            .matches(noNumbersPattern, t(errorMessageIds.birthName.numbers))
            .required(t(errorMessageIds.birthName.empty)),
        frontFile: Yup.array()
            .required(t(errorMessageIds.frontFile.empty))
            .max(1, t(errorMessageIds.frontFile.multiple)),
        backFile: Yup.array()
            .required(t(errorMessageIds.backFile.empty))
            .max(1, t(errorMessageIds.frontFile.multiple)),
    };
    return Yup.object().shape(validations);
};
