export function getContractEndOptionsEndpoint(contractId: string): string {
    return `/contracts/${contractId}/contract-end-options`;
}

export function getSilentExtensionEndpoint(contractId: string): string {
    return `/contracts/${contractId}/silent-extension`;
}

export function getPurchasePriceInquiryEndpoint(contractId: string): string {
    return `/contracts/${contractId}/purchase-price-inquiry`;
}
