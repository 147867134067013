import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EarlyTermination } from '../../components/early-termination';

export const EarlyTerminationPage: React.FC = () => {
    const { t } = useTranslation('early-termination');
    const { contractId } = useParams<{ contractId: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <EarlyTermination contractId={contractId} />
        </ContentSection>
    );
};
