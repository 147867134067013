import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type EarlyTerminationModalProps = {
    shown: boolean;
    error: boolean;
    onConfirm: () => void;
    onClose: () => void;
};

export const testIds = {
    confirmModal: 'confirmModal',
};

export const EarlyTerminationModal: React.FC<EarlyTerminationModalProps> = ({ shown, error, onConfirm, onClose }) => {
    const { t } = useTranslation('early-termination');

    return (
        <Modal
            shown={shown}
            buttonConfirmText={t('modal.button')}
            onConfirm={onConfirm}
            onClose={onClose}
            status={error ? 'error' : 'success'}
            testId={testIds.confirmModal}
            title={error ? t('modal.error.title') : t('modal.success.title')}
        >
            {error ? t('modal.error.text') : t('modal.success.text')}
        </Modal>
    );
};
