import { Dealer } from '@cp-de/common';

export const testIds = {
    mileage: 'mileage',
    email: 'email',
    cancel: 'cancel',
    submit: 'submit',
};

const getDataOrEmpty = (data?: string): string => (data ? data : '');

const getDealerDataWithHtmlMarkup = (data1?: string, data2?: string): string => {
    if (data1 || data2) {
        return `<strong>${getDataOrEmpty(data1)} ${getDataOrEmpty(data2)}</strong></br>`;
    } else {
        return '';
    }
};

export function buildDealerAddress(dealerData: Dealer) {
    return {
        dealerName: getDealerDataWithHtmlMarkup(dealerData.name),
        dealerStreet: getDealerDataWithHtmlMarkup(dealerData.address?.street, dealerData.address?.houseNumber),
        dealerCity: getDealerDataWithHtmlMarkup(dealerData.address?.zipCode, dealerData.address?.city),
        dealerEmail: dealerData.contact?.email ? getDealerDataWithHtmlMarkup('E-Mail:', dealerData.contact?.email) : '',
        dealerPhone: dealerData.contact?.phone ? getDealerDataWithHtmlMarkup('Tel.:', dealerData.contact?.phone) : '',
    };
}
