import { useMemo } from 'react';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-8/frontend-integration';
import { AoDContract } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AoDContractsDataSelector } from './AoDContractsDataSelector';
import { fetchAoDContracts } from './AoDContractsSlice';

type ContractTransformer = (contracts?: AoDContract[]) => AoDContract | undefined;

function getFilterForContractId(contractId: string): ContractTransformer {
    return aodContracts =>
        aodContracts ? aodContracts.filter(contract => contract.contractId === contractId)[0] : undefined;
}
/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId contract (reservation) ID to get the contract for.
 */
export function useAoDContract(
    contractId: string,
): AbstractDataState<AoDContract | undefined, DefaultBusinessMarketApiErrorCode> {
    const contractFilter = useMemo(() => getFilterForContractId(contractId), [contractId]);
    return useGetSimpleApiDataWithTransformer(fetchAoDContracts, AoDContractsDataSelector, contractFilter);
}
