import React from 'react';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { ExpertReportFormValues, ExpertReportStep1FormValues, ExpertReportStep2FormValues } from '../types';
import { Step1FormView } from './step1-form-view';
import { Step2FormView } from './step2-form-view';

type ExpertReportFormViewProps = {
    innerStep: number;
    handleNext: () => void;
    handlePrev: () => void;
    handleInnerNext: () => void;
    handleInnerPrev: () => void;
    expertReportValues: ExpertReportFormValues;
    saveFormValues: (values: ExpertReportStep1FormValues | ExpertReportStep2FormValues) => void;
    contractId: string;
    isAccipiens: boolean;
};

export const ExpertReportFormView: React.FC<ExpertReportFormViewProps> = ({
    innerStep,
    handleNext,
    handlePrev,
    handleInnerNext,
    handleInnerPrev,
    expertReportValues,
    saveFormValues,
    contractId,
    isAccipiens,
}) => {
    useAnalyticsPageViewTracker('reportDamageExpertReport');

    const onStep1Next = (values: ExpertReportStep1FormValues): void => {
        saveFormValues(values);
        handleInnerNext();
    };

    const onStep1Prev = (values: ExpertReportStep1FormValues): void => {
        saveFormValues(values);
        handlePrev();
    };

    const onStep2Next = (values: ExpertReportStep2FormValues): void => {
        saveFormValues(values);
        handleNext();
    };

    const onStep2Prev = (values: ExpertReportStep2FormValues): void => {
        saveFormValues(values);
        handleInnerPrev();
    };

    return innerStep === 1 ? (
        <Step1FormView
            handleNext={onStep1Next}
            handlePrev={onStep1Prev}
            expertReportValues={expertReportValues}
            contractId={contractId}
            isAccipiens={isAccipiens}
        />
    ) : (
        <Step2FormView
            isAccipiens={isAccipiens}
            handleNext={onStep2Next}
            handlePrev={onStep2Prev}
            expertReportValues={expertReportValues}
        />
    );
};
