import { PostboxDocument } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { fetchFourSalesPostbox } from './PostboxFourSalesSlice';
import { PostboxFourSalesDataSelector } from './PostboxFourSalesDataSelector';

export function usePostboxFourSales(
    isFetchingAllowed: boolean,
): AbstractDataState<PostboxDocument[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchFourSalesPostbox,
        PostboxFourSalesDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
