export function getUserRegistrationStatusEndpoint(): string {
    return '/user-registration-status';
}

export function getRegistrationMappingEndpoint(): string {
    return '/registration-mapping';
}

export function getRegistrationOnlineFlagEndpoint(): string {
    return '/registration-online-flag';
}

export function getRegistrationEndpoint(contractType: string): string {
    return `/registration/${contractType}`;
}

export function getDigialOfferRegistrationEndpoint(transactionId: string): string {
    return `/registration-offer/${transactionId}`;
}
