import React from 'react';
import { AoDContract, AutoAboContract, FourSalesContract, Contract, RaCContract } from '@cp-de/common';
import { Tabs } from '@vwfs-bronson/bronson-react';
import { Tab } from '../enums';
import { useTranslation } from 'react-i18next';
import { ContractCard } from './ContractCard';
import {
    CombinedContractType,
    isAodContract,
    isAutoAboContract,
    isFourSalesContract,
    isRACContract,
    isStandardContract,
} from '../../../utils';
import { groupContractsByVin } from '../utils';
import { AutoAboContractCard } from '../auto-abo/AutoAboContractCard';
import { ReservationContractCard } from '../reservations';
import { FourSalesContractCard } from '../four-sales';
import { InactiveContractsCard } from './InactiveContractsCard';

type AllContractsProps = {
    fActiveContracts: Contract[];
    fExpiredOrTerminatedContracts: Contract[];
    fActiveAutoAboContracts: AutoAboContract[];
    fClosedOrCancelledAutoAboContracts: AutoAboContract[];
    fActiveFourSalesContracts: FourSalesContract[];
    fClosedOrCancelledFourSalesContracts: FourSalesContract[];
    fActiveAoDContracts: AoDContract[];
    fClosedOrCancelledAoDContracts: AoDContract[];
    fActiveRaCContracts: RaCContract[];
    fClosedOrCancelledRaCContracts: RaCContract[];
    isFetchingStandardAllowed: boolean;
    isFetchingAboAllowed: boolean;
    isFetchingFourSalesAllowed: boolean;
    isFetchingAodAllowed: boolean;
    isFetchingRacAllowed: boolean;
};

export const AllContracts: React.FC<AllContractsProps> = ({
    fActiveContracts,
    fExpiredOrTerminatedContracts,
    fActiveAutoAboContracts,
    fClosedOrCancelledAutoAboContracts,
    fActiveFourSalesContracts,
    fClosedOrCancelledFourSalesContracts,
    fActiveAoDContracts,
    fClosedOrCancelledAoDContracts,
    fActiveRaCContracts,
    fClosedOrCancelledRaCContracts,
    isFetchingStandardAllowed,
    isFetchingAboAllowed,
    isFetchingFourSalesAllowed,
    isFetchingAodAllowed,
    isFetchingRacAllowed,
}) => {
    const { t } = useTranslation();
    const allActiveContracts = [
        ...fActiveContracts,
        ...fActiveAutoAboContracts,
        ...fActiveAoDContracts,
        ...fActiveRaCContracts,
        ...fActiveFourSalesContracts,
    ] as CombinedContractType[];
    const allInactiveContracts = [
        ...fExpiredOrTerminatedContracts,
        ...fClosedOrCancelledAutoAboContracts,
        ...fClosedOrCancelledAoDContracts,
        ...fClosedOrCancelledRaCContracts,
        ...fClosedOrCancelledFourSalesContracts,
    ] as CombinedContractType[];

    const renderContracts = (
        contracts: CombinedContractType[],
        inactive?: boolean,
    ): (JSX.Element | JSX.Element[])[] => {
        return groupContractsByVin(contracts).map((contractGroup, groupIndex, array) => {
            const isLastGroup = groupIndex === array.length - 1;
            const isFirstGroup = groupIndex === 0;
            if (inactive) {
                return (
                    <React.Fragment key={groupIndex}>
                        <InactiveContractsCard contracts={contractGroup as CombinedContractType[]} />
                    </React.Fragment>
                );
            } else {
                return contractGroup.map((contract, index) => {
                    // show details with image only for first contract in vin group
                    const isFirstContractInGroup = index === 0;
                    const isLastContractInGroup = index === contractGroup.length - 1;
                    return (
                        <React.Fragment key={contract.contractNumber}>
                            {isFetchingStandardAllowed && isStandardContract(contract) && (
                                <ContractCard
                                    contract={contract as Contract}
                                    defaultExpanded={isFirstGroup && isFirstContractInGroup}
                                    inactiveContract={inactive}
                                    isVehicleDetailsAndImageHidden={isFirstContractInGroup}
                                />
                            )}
                            {isFetchingAboAllowed && isAutoAboContract(contract) && (
                                <AutoAboContractCard
                                    contract={contract as AutoAboContract}
                                    isVehicleDetailsAndImageHidden={!isFirstContractInGroup || inactive}
                                />
                            )}
                            {isFetchingAodAllowed && isAodContract(contract) && (
                                <ReservationContractCard
                                    contract={contract as AoDContract}
                                    isVehicleDetailsAndImageHidden={!isFirstContractInGroup || inactive}
                                />
                            )}
                            {isFetchingRacAllowed && isRACContract(contract) && (
                                <ReservationContractCard
                                    contract={contract as RaCContract}
                                    isVehicleDetailsAndImageHidden={!isFirstContractInGroup || inactive}
                                />
                            )}
                            {isFetchingFourSalesAllowed && isFourSalesContract(contract) && (
                                <FourSalesContractCard
                                    contract={contract as FourSalesContract}
                                    isVehicleDetailsAndImageHidden={!isFirstContractInGroup || inactive}
                                />
                            )}
                            {!inactive && isLastContractInGroup && !isLastGroup && <hr />}
                        </React.Fragment>
                    );
                });
            }
        });
    };

    const renderActiveContracts = (): (JSX.Element | JSX.Element[])[] => {
        return renderContracts(allActiveContracts);
    };

    const renderInactiveContracts = (): (JSX.Element | JSX.Element[])[] => {
        return renderContracts(allInactiveContracts, true);
    };

    return (
        <>
            <Tabs defaultSelected={Tab.ACTIVE}>
                <Tabs.Header>
                    <Tabs.HeaderItem tabId={Tab.ACTIVE}>
                        {t('contracts:overview.tabs.active', {
                            amount: allActiveContracts.length || 0,
                        })}
                    </Tabs.HeaderItem>
                    <Tabs.HeaderItem tabId={Tab.INACTIVE}>
                        {t('contracts:overview.tabs.inactive', {
                            amount: allInactiveContracts.length || 0,
                        })}
                    </Tabs.HeaderItem>
                </Tabs.Header>
                <Tabs.Content>
                    <Tabs.ContentItem tabId={Tab.ACTIVE} testId="tab-active">
                        {renderActiveContracts()}
                    </Tabs.ContentItem>
                    <Tabs.ContentItem tabId={Tab.INACTIVE} testId="tab-inactive">
                        {renderInactiveContracts()}
                    </Tabs.ContentItem>
                </Tabs.Content>
            </Tabs>
        </>
    );
};
