import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { LoginChoiceOffer } from '../../components/login-choice-offer';

export const LoginChoiceOfferPage: React.FC = () => {
    return (
        <ContentSection pageWrapSize="xxsmall">
            <LoginChoiceOffer />
        </ContentSection>
    );
};
