export type FileInfo = string;

export type ReportDamageRequest = {
    claimType: ReportDamageClaimType;
    claimDate?: string;
    responsibleInsurance: ReportDamageResponsibleInsurance;
    insurance?: InsuranceOrganization;
    paymentToLoanContract?: boolean;
    repairByPrepayment?: boolean;
    vehicleSold?: boolean;
    vehicleDeregistered?: boolean;
    replacementValue?: number;
    claimsTransfer?: FileInfo;
    attachments?: FileInfo[];
};

export enum ReportDamageClaimType {
    APPRAISAL = 'Appraisal',
    TOTAL_LOSS = 'TotalLoss',
    THEFT = 'Theft',
    TOTAL_LOSS_REDEMPTION = 'TotalLossWithRedemption',
    TOTAL_LOSS_NO_REDEMPTION = 'TotalLossWithoutRedemption',
}

export enum ReportDamageResponsibleInsurance {
    NONE = 'None',
    OWN = 'Own',
    OTHER_PARTY = 'OtherParty',
}

export type InsuranceOrganization = {
    name?: string;
    address?: InsuranceOrganizationAddress;
    contact?: InsuranceOrganizationContact;
    claimId?: string;
    claimAmount?: number | undefined;
};

export type InsuranceOrganizationAddress = {
    streetName?: string;
    houseNumber?: string;
    postOfficeBox?: string;
    zipCode?: string;
    locality?: string;
};

export type InsuranceOrganizationContact = {
    faxNumber?: string;
};
