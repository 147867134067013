import React from 'react';
import { CpContentApi } from 'cp-xhr';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';

import { getConsentEndpoint, getPrivacyPolicyEndpoint, getUsageAgreementEndpoint } from '@cp-de/common';

const mockOptionsPrivacyPolicy: MockOptions<'DEFAULT_ERROR'> = {
    Success: {
        status: 200,
        responseBody: { content: 'Privacy Policy' },
    },
    'Default error': {
        status: 500,
        responseBody: 'DEFAULT_ERROR',
    },
};

const mockOptionsUsageAgreement: MockOptions<'DEFAULT_ERROR'> = {
    Success: {
        status: 200,
        responseBody: { content: 'Usage Agreement' },
    },
    'Default error': {
        status: 500,
        responseBody: 'DEFAULT_ERROR',
    },
};

const mockOptionsMarketingConsent: MockOptions<'DEFAULT_ERROR'> = {
    Success: {
        status: 200,
        responseBody: { content: 'Usage Agreement' },
    },
    'Default error': {
        status: 500,
        responseBody: 'DEFAULT_ERROR',
    },
};

export const privacyPolicyMock: EndpointMockDescription<'DEFAULT ERROR'> = {
    name: 'Privacy Policy Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsPrivacyPolicy,
    url: getPrivacyPolicyEndpoint('de', 'vwfs'),
};

export const usageAgreementMock: EndpointMockDescription<'DEFAULT ERROR'> = {
    name: 'Usage Agreement Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsUsageAgreement,
    url: getUsageAgreementEndpoint('de', 'vwfs'),
};

export const marketingConsentMock: EndpointMockDescription<'DEFAULT ERROR'> = {
    name: 'Marketing Consent Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsMarketingConsent,
    url: getConsentEndpoint(),
};

export const RegistrationFormResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpContentApi, [privacyPolicyMock, usageAgreementMock, marketingConsentMock]);

    return <div>{storyFn()}</div>;
};
