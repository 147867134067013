import { RaCContract, CommonReservationDetails, ReservationContractStatus, ContractType } from '@cp-de/common';
import {
    exampleDealerDetailsWithAllValues,
    exampleDealerDetailsWithMissingValues,
    exampleReservationCustomerDetailsWithAllValues,
    exampleReservationCustomerDetailsWithMissingValues,
    exampleVehicleDetailsWithAllValues,
    exampleVehicleDetailsWithMissingValues,
} from '../common';

export const racContractNumber = 'RaC3456';
export const racContractId = `encrypted-${racContractNumber}`;
export const cancelledRaCContractNumber = '3456oofRac';
export const cancelledRaCContractId = `cancelled-${cancelledRaCContractNumber}`;

export const RaCDetailsWithAllValues: CommonReservationDetails = {
    dealerDetails: exampleDealerDetailsWithAllValues,
    vehicleDetails: exampleVehicleDetailsWithAllValues,
    customerDetails: exampleReservationCustomerDetailsWithAllValues,
};

export const RaCDetailsWithMissingValues: CommonReservationDetails = {
    dealerDetails: exampleDealerDetailsWithMissingValues,
    vehicleDetails: exampleVehicleDetailsWithMissingValues,
    customerDetails: exampleReservationCustomerDetailsWithMissingValues,
};

export const RaCContractWithAllValues: RaCContract = {
    contractNumber: racContractNumber,
    contractId: racContractId,
    contractMileage: 123,
    price: 2500,
    startDate: '2019-05-01T22:00:00.000Z',
    startTime: '11:00:00',
    endTime: '19:00:00',
    endDate: '2039-05-01T22:00:00.000Z',
    contractStatus: ReservationContractStatus.RENT_STARTED,
    contractType: ContractType.RENT_A_CAR,
    additionalProducts: [
        {
            code: 'CDW_LOW',
            qualifiers: [
                {
                    id: 'insurance_text',
                    stringValue: '199,99',
                },
            ],
        },
        {
            code: '26',
        },
        {
            code: 'PAI',
        },
    ],
    details: RaCDetailsWithAllValues,
};

export const RaCContractWithAllValuesCancellAllowed: RaCContract = {
    ...RaCContractWithAllValues,
    startDate: '2222-05-01T22:00:00.000Z',
    startTime: '11:00:00',
};

export const RaCContractWithMissingValues: RaCContract = {
    contractNumber: racContractNumber + '-1',
    contractId: racContractId + '-1',
    contractMileage: 123,
    price: 1700,
    startDate: '2019-05-01T22:00:00.000Z',
    endDate: '2039-05-01T22:00:00.000Z',
    contractStatus: ReservationContractStatus.RENT_STARTED,
    contractType: ContractType.RENT_A_CAR,
    additionalProducts: [
        {
            name: 'Unfallversicherung',
        },
    ],
    details: RaCDetailsWithMissingValues,
};

export const RaCContractCancelled: RaCContract = {
    ...RaCContractWithAllValues,
    contractNumber: cancelledRaCContractNumber,
    contractId: cancelledRaCContractId,
    contractStatus: ReservationContractStatus.RENT_CANCELLED,
};

export const RaCContractsWithDifferentValues = [RaCContractWithAllValues, RaCContractWithMissingValues];
