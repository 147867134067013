import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Fieldset, Form, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { CleaveInput, Notification, preventSubmit } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { TotalLossFormValues, TotalLossStep2FormValues } from '../../types';
import { getStep2InitialValues } from '../initialValues';
import {
    areFilesValid,
    AttachmentsUpload,
    CommonInsuranceForm,
    resetField,
    resetFileUpload,
    YesNoButtons,
    YesNoRadioGroup,
} from '../../../common';
import { getStep2ValidationSchema } from '../validationSchema';
import { ReportDamageResponsibleInsurance } from '@cp-de/common';
import { FormNavigationButtons } from '../../../../form-navigation-buttons';

type TotalLossFormViewProps = {
    handleNext: (values: TotalLossStep2FormValues) => void;
    handlePrev: (values: TotalLossStep2FormValues) => void;
    totalLossValues?: TotalLossFormValues;
    isAccipiens: boolean;
};

export const Step2FormView: React.FC<TotalLossFormViewProps> = ({
    handleNext,
    handlePrev,
    totalLossValues,
    isAccipiens,
}) => {
    const { t } = useTranslation('repair-approval');

    const showInsuranceForm =
        totalLossValues?.responsibleInsurance === ReportDamageResponsibleInsurance.OWN ||
        totalLossValues?.responsibleInsurance === ReportDamageResponsibleInsurance.OTHER_PARTY;

    const onVehicleSoldChange = (formik: FormikProps<TotalLossStep2FormValues>): void => {
        resetField(formik, 'replacementValue');
        resetFileUpload(formik, 'attachments');
    };

    const handleSubmit = (values: TotalLossStep2FormValues): void => {
        const { attachments } = values;
        if (!isAccipiens && (!attachments || !areFilesValid(attachments))) return;
        handleNext(values);
    };

    const onBackClick = (values: TotalLossStep2FormValues): void => {
        handlePrev(values);
    };

    const cleaveOptions = {
        numeral: true,
        delimiter: '.',
        numeralDecimalMark: ',',
    };

    return (
        <Formik
            initialValues={getStep2InitialValues(isAccipiens, totalLossValues, showInsuranceForm)}
            validationSchema={getStep2ValidationSchema(t, showInsuranceForm, isAccipiens)}
            onSubmit={handleSubmit}
        >
            {(formik: FormikProps<TotalLossStep2FormValues>): React.ReactNode => (
                <Form onSubmit={e => preventSubmit(e)}>
                    <Layout center>
                        <Layout.Item default="2/3" m={'1/1'} className={'u-text-center'}>
                            <Heading className="u-mt-large" level={4}>
                                {t('total-loss.step2.title')}
                            </Heading>
                        </Layout.Item>
                        {showInsuranceForm && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <CommonInsuranceForm name={'insurance'} isAccipiens={isAccipiens} />
                            </Layout.Item>
                        )}
                        {(!isAccipiens || !showInsuranceForm) && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <YesNoRadioGroup
                                    name={'vehicleSold'}
                                    label={t('total-loss.vehicle-sold.label')}
                                    onChange={(): void => onVehicleSoldChange(formik)}
                                />
                            </Layout.Item>
                        )}
                        {(!isAccipiens || !showInsuranceForm) && formik.values.vehicleSold === YesNoButtons.no && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                {showInsuranceForm && !isAccipiens && (
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <CleaveInput
                                                    cleaveOptions={cleaveOptions}
                                                    name="replacementValue"
                                                    label={t('total-loss.replacement-value.label')}
                                                    addonText={t('translation:currency.euro')}
                                                    testId={`replacementValue`}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                )}
                                <Notification
                                    headline={t('total-loss.notification.headline')}
                                    text={t('total-loss.notification.text')}
                                />
                            </Layout.Item>
                        )}
                        {(!isAccipiens || !showInsuranceForm) && formik.values.vehicleSold === YesNoButtons.yes && (
                            <Layout.Item default="2/3" s="1/1">
                                <AttachmentsUpload name={'attachments'} label={t('total-loss.attachments.label')} />
                            </Layout.Item>
                        )}
                        <Layout.Item>
                            <FormNavigationButtons
                                onPrimaryButtonClick={formik.submitForm}
                                onSecondaryButtonClick={(): void => onBackClick(formik.values)}
                                primaryButtonType={'continue'}
                            />
                        </Layout.Item>
                    </Layout>
                </Form>
            )}
        </Formik>
    );
};
