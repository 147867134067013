import { TotalLossStep1FormValues } from '../types';
import { ReportDamageResponsibleInsurance } from '@cp-de/common';
import { YesNoButtons } from '../../common';

export const showPolicyHolder = (responsibleInsurance: string): boolean =>
    responsibleInsurance === ReportDamageResponsibleInsurance.OWN;

export const showTransferInsurance = (policyHolder: string): boolean => policyHolder === YesNoButtons.no;

export const isTransferInsuranceWithNo = (transferInsurance: string): boolean => transferInsurance === YesNoButtons.no;

export const isTransferInsuranceWithYes = (transferInsurance: string): boolean =>
    transferInsurance === YesNoButtons.yes;

export const isRedeemLoanWithYes = (redeemLoan: string): boolean => redeemLoan === YesNoButtons.yes;

export const showRedeemLoan = (values: TotalLossStep1FormValues): boolean =>
    values.responsibleInsurance === ReportDamageResponsibleInsurance.NONE ||
    values.responsibleInsurance === ReportDamageResponsibleInsurance.OTHER_PARTY ||
    (showPolicyHolder(values.responsibleInsurance) && values.policyHolder === YesNoButtons.yes) ||
    isTransferInsuranceWithYes(values.transferInsurance);
