import React from 'react';
import { formatAsFileSize } from '@cp-de/common';
import { FileUpload as SharedFileUpload } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';

export type FileUploadProps = {
    name: string;
    multiple?: boolean;
    maxFileSize?: number;
    maxFileSizePerFile?: number;
    validFileFormats?: string[];
    sizeError?: string | React.ReactNode;
    typeError?: string | React.ReactNode;
    sizeAndTypeError?: string | React.ReactNode;
};

export const FileUpload: React.FC<FileUploadProps> = ({
    name,
    multiple,
    maxFileSize,
    maxFileSizePerFile,
    validFileFormats,
    sizeError,
    typeError,
    sizeAndTypeError,
}) => {
    const { t } = useTranslation('file-upload');

    const descriptionText = (
        <div>
            <p className="u-hide@s">{t('desktop.headline')}</p>
            <p className="u-hide@s-up">{t('mobile.headline')}</p>
        </div>
    );

    const descriptionSupplementaryText = (
        <div>
            <p className="u-hide@s">{t('desktop.text')}</p>
            <p className="u-hide@s-up">{t('mobile.text')}</p>
        </div>
    );

    return (
        <SharedFileUpload
            name={name}
            descriptionText={descriptionText}
            descriptionSupplementaryText={descriptionSupplementaryText}
            buttonText={t('button-text')}
            cancelLabel={t('cancel')}
            fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
            multiple={multiple}
            maxFileSize={maxFileSize}
            maxFileSizePerFile={maxFileSizePerFile}
            validFileFormats={validFileFormats}
            sizeError={sizeError}
            typeError={typeError}
            sizeAndTypeError={sizeAndTypeError}
        />
    );
};
