import {
    AoDContract,
    AutoAboContract,
    Contract,
    RaCContract,
    ReservationContractStatus,
    FourSalesContractSatus,
    CombinedContactStatus,
    FourSalesContract,
    ProductType,
    ContractType,
} from '@cp-de/common';

type MergeTypes<A, B> = {
    [K in keyof A | keyof B]: K extends keyof A & keyof B
        ? A[K] | B[K]
        : K extends keyof B
        ? B[K]
        : K extends keyof A
        ? A[K]
        : never;
};

type CombinedAoDRac = MergeTypes<AoDContract, RaCContract>;
type CombinedAboAoDRac = MergeTypes<AutoAboContract, CombinedAoDRac>;
type CombinedFourSalesAboAoDRac = MergeTypes<FourSalesContract, CombinedAboAoDRac>;
type CombinedStandardFourSalesAboAoDRac = MergeTypes<Contract, CombinedFourSalesAboAoDRac>;

export type CombinedContractType = CombinedStandardFourSalesAboAoDRac & CombinedContactStatus;

export const findLatestContract = (
    contracts?: (Contract | AutoAboContract | AoDContract | RaCContract | FourSalesContract)[],
): Contract | AutoAboContract | AoDContract | RaCContract | FourSalesContract | undefined => {
    if (!contracts?.length) {
        return;
    }
    return contracts.reduce((a, b) => ((a.startDate || '') > (b.startDate || '') ? a : b));
};

export const sortAutoAboContracts = (items: AutoAboContract[] | undefined) => {
    const newArrForSorting = items && [...items];
    return newArrForSorting?.sort((a, b) => (a.isInReservation < b.isInReservation ? -1 : 1));
};

export const getActiveReservationContracts = (reservations: (AoDContract | RaCContract)[]) => {
    return reservations.filter(
        ({ contractStatus }) =>
            contractStatus === ReservationContractStatus.RENT_STARTED ||
            contractStatus === ReservationContractStatus.RENT_RESERVATION,
    );
};

export const getClosedOrCancelledReservationContracts = (reservations: (AoDContract | RaCContract)[]) =>
    reservations.filter(
        ({ contractStatus }) =>
            contractStatus === ReservationContractStatus.RENT_CANCELLED ||
            contractStatus === ReservationContractStatus.RENT_CLOSED,
    );

export const getActiveAutoAboContracts = (contracts: AutoAboContract[]) => {
    return contracts.filter(
        contract =>
            contract?.details?.contractDetails?.status === ReservationContractStatus.SUBSCRIPTION_RESERVATION ||
            contract?.details?.contractDetails?.status === ReservationContractStatus.SUBSCRIPTION_STARTED,
    );
};

export const getClosedOrCancelledAutoAboContracts = (contracts: AutoAboContract[]) =>
    contracts.filter(
        contract =>
            contract?.details?.contractDetails?.status === ReservationContractStatus.SUBSCRIPTION_CLOSED ||
            contract?.details?.contractDetails?.status === ReservationContractStatus.SUBSCRIPTION_CANCELLED,
    );

export const getActiveContracts = (contracts: Contract[]) =>
    contracts.filter(({ contractStatus }) => contractStatus === 'ACTIVE');

export const getActiveFourSalesContracts = (contracts: FourSalesContract[]) => {
    return contracts.filter(
        contract =>
            contract?.details?.contractDetails?.status === FourSalesContractSatus.OFFER ||
            contract?.details?.contractDetails?.status === FourSalesContractSatus.PROPOSAL ||
            contract?.details?.contractDetails?.status === FourSalesContractSatus.CONTRACT,
    );
};

export const getClosedOrCancelledFourSalesContracts = (contracts: FourSalesContract[]) =>
    contracts.filter(
        contract =>
            contract?.details?.contractDetails?.status === FourSalesContractSatus.CANCELLED ||
            contract?.details?.contractDetails?.status === FourSalesContractSatus.EXPIRED,
    );

export const isStandardContract = (contract: CombinedContractType) =>
    contract.contractType &&
    [ContractType.FINANCING, ContractType.LEASING, ContractType.SERVICE_MANAGEMENT].includes(contract.contractType);
export const isAutoAboContract = (contract: CombinedContractType) => ContractType.AUTO_ABO === contract.contractType;
export const isAodContract = (contract: CombinedContractType) => ContractType.AUDI_ON_DEMAND === contract.contractType;
export const isRACContract = (contract: CombinedContractType) => ContractType.RENT_A_CAR === contract.contractType;
export const isFourSalesContract = (contract: CombinedContractType) =>
    ContractType.FOUR_SALES === contract.contractType;
export const isAccipiensContract = (contract?: Contract) =>
    (contract?.productType === ProductType.CLASSIC_CREDIT || contract?.productType === ProductType.INDIVIDUAL_CREDIT) &&
    ['DirectCredit', 'DirectCreditCar'].includes(contract.productGroup || '');

export const isDirectCredit = (contract?: Contract) => 'DirectCredit' === contract?.productGroup;
