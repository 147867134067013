import React from 'react';
import { Modal, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { postboxPagePathWithContractId, requestDocumentWithDocumentTypePagePath } from '../../navigation/paths';
import { Contract, ProductType } from '@cp-de/common';
import { isDirectCredit } from '../../../utils';

export type TotalEarlySettlementModalProps = {
    isThirdPartyRequester: boolean;
    shown: boolean;
    error: boolean;
    downloadDocument: () => void;
    onConfirm: () => void;
    onClose: () => void;
    contract: Contract;
};

export const testIds = {
    confirmModal: 'confirmModal',
    downloadLink: 'third-party-download',
};

export const TotalEarlySettlementModal: React.FC<TotalEarlySettlementModalProps> = ({
    shown,
    error,
    onConfirm,
    onClose,
    downloadDocument,
    contract,
    isThirdPartyRequester,
}) => {
    const { t } = useTranslation('total-early-settlement');

    const isClassicCredit = contract.productType === ProductType.CLASSIC_CREDIT;
    const isIndividualCredit = contract.productType === ProductType.INDIVIDUAL_CREDIT;
    const isDirectCreditGroup = isDirectCredit(contract);
    const isDirectCreditCarGroup = 'DirectCreditCar' === contract.productGroup;
    const isCcWithDirectCredit = isClassicCredit && isDirectCreditGroup;
    const isCcOrIcWithDirectCreditCar = (isClassicCredit || isIndividualCredit) && isDirectCreditCarGroup;

    const successMessage = () => {
        let message = t('modal.success.my-text');

        if (isThirdPartyRequester) {
            if (isCcWithDirectCredit) {
                message = t('modal.success.third-party-text.cc-with-direct-credit');
            } else if (isCcOrIcWithDirectCreditCar) {
                message = t('modal.success.third-party-text.cc-or-ic-with-direct-credit-car', {
                    link: `${postboxPagePathWithContractId(contract.contractId)}`,
                });
            } else {
                message = t('modal.success.third-party-text.default', {
                    link: `${requestDocumentWithDocumentTypePagePath(contract.contractId, 'CopyOfZBT2')}`,
                });
            }
        }

        return message;
    };

    const success = {
        title: t('modal.success.title'),
        text: (
            <>
                <div
                    dangerouslySetInnerHTML={{
                        __html: successMessage(),
                    }}
                />
                {isThirdPartyRequester && !isDirectCreditGroup && (
                    <>
                        <Button onClick={downloadDocument} testId={testIds.downloadLink} icon="download">
                            {t('modal.success.third-party-download-label')}
                        </Button>
                    </>
                )}
                {isThirdPartyRequester && (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t('modal.success.third-party-hint'),
                        }}
                    />
                )}
            </>
        ),
        button: t('modal.success.button'),
    };

    return (
        <Modal
            shown={shown}
            buttonConfirmText={error ? t('modal.error.button') : success.button}
            onConfirm={onConfirm}
            onClose={onClose}
            status={error ? 'error' : 'success'}
            testId={testIds.confirmModal}
            title={error ? t('modal.error.title') : success.title}
        >
            {error ? t('modal.error.text') : success.text}
        </Modal>
    );
};
