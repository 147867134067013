import { Contract, ContractType, marketApiDateFormat } from '@cp-de/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';

const getMostRecentContract = (contracts: Contract[]): Contract | null => {
    if (!contracts?.length) return null;
    return contracts.sort((a, b) => ((a.startDate || '') > (b.startDate || '') ? -1 : 1))[0];
};

export const filterContracts = (contracts: Contract[], type: ContractType): Contract[] => {
    return contracts.filter(contract => contract.contractType === type);
};

export const getMostRecentFinancingContract = (contracts: Contract[]): Contract | null => {
    const filtered = filterContracts(contracts, ContractType.FINANCING);
    return getMostRecentContract(filtered);
};

export const getMostRecentLeasingContract = (contracts: Contract[]): Contract | null => {
    const filtered = filterContracts(contracts, ContractType.LEASING);
    return getMostRecentContract(filtered);
};

export const isDateInPeriod = (
    value: number,
    unit: moment.unitOfTime.DurationConstructor,
    startDate?: string,
): boolean => {
    if (!startDate) return false;
    const dateObject = formatCpDate(startDate?.trim(), marketApiDateFormat).toMoment();
    const isValidDate = dateObject.isSameOrAfter(
        formatCpDate()
            .toMoment()
            .startOf(unit)
            .subtract(value, unit),
    );
    return isValidDate;
};
