import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerReservationIdentity } from '@cp-de/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export const IdentitySection: React.FC<{ identity?: CustomerReservationIdentity }> = ({ identity }) => {
    const { t, ready } = useTranslation('aod-contract-parties', { useSuspense: false });
    const { firstName = '', lastName = '' } = identity || {};

    const itemList: DefinitionListItem[] = [
        { label: t('identity.name'), value: firstName && lastName ? `${firstName} ${lastName}` : '-', testId: 'name' },
    ];

    return <>{ready && <DefinitionListHorizontal list={itemList} className={'u-mb u-mt-small'} />}</>;
};
