import { ServiceRequestCategory } from '@cp-de/common';

export const requestCategories: ServiceRequestCategory[] = [
    {
        category: 'CopyOfZBT2Leasing',
    },
    {
        category: 'CopyOfZBT2Finance',
    },
    {
        category: 'InterestAndAmortizationSchedule',
    },
    {
        category: 'InterestCertificate',
    },
    {
        category: 'LoanContract',
    },
    {
        category: 'SecuredSaleOfReturn',
    },
    {
        category: 'LeasingOrder',
    },
    {
        category: 'LeasingConfirmation',
    },
    {
        category: 'ContractProlongation',
    },
    {
        category: 'DiminishedValueAppraisal',
    },
    {
        category: 'ReturnProtocol',
    },
    {
        category: 'RequestForZBT2Finance',
    },
    {
        category: 'RequestForZBT2Leasing',
    },
    {
        category: 'StatementOfAccount',
    },
];

export const requestCategoriesLeasing: ServiceRequestCategory[] = [
    {
        category: 'LeasingOrder',
    },
    {
        category: 'LeasingConfirmation',
    },
];

export const requestCategoriesSM: ServiceRequestCategory[] = [
    {
        category: 'ServiceManagementOrder',
    },
    {
        category: 'ServiceManagementConfirmation',
    },
];
