import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Button, Fieldset, Form, Modal } from '@vwfs-bronson/bronson-react';
import {
    CleaveInput,
    InfoCheckbox,
    preventSubmit,
    Spinner,
    useAnalyticsFormTracker,
    useAuthUserInfo,
    ValidatedInput,
    ValidatedSelect,
    ValidatedSelectItem,
} from '@cp-shared-8/frontend-ui';

import { dashboardPagePath } from '../../../navigation/paths';
import { useRegistrationSubmission } from './useRegistrationSubmission';
import { RegistrationError } from './registration-error';
import { validationSchema } from './validation';
import { RegistrationFormFields } from './RegistrationFormFields';
import { TranslationFormat, useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';
import moment from 'moment-timezone';
import {
    ConsentStatus,
    formatAsDate,
    longDateFormat,
    marketApiDateFormat,
    AddContractProductType,
} from '@cp-de/common';
import { getAbsoluteUrlWithParams, getUrlSearchParams } from '../../../../utils/urlSearchParams';
import { targetNewTab } from '../../../../utils/string-utils';
import { TFunction } from 'i18next';

export const testIds = {
    product: 'product',
    contractNumber: 'contractNumber',
    zipCode: 'zipCode',
    lastName: 'lastName',
    birthDate: 'birthDate',
    showPrivacyPolicy: 'showPrivacyPolicy',
    showUsageAgreement: 'showUsageAgreement',
    showMarketingConsent: 'showMarketing',
    showDataStorageConsent: 'showDataStorage',
    modal: 'modal',
    privacyPolicy: 'privacyPolicy',
    usageAgreement: 'usageAgreement',
    marketingConsent: 'marketingConsent',
    dataStorageConsent: 'dataStorageConsent',
    submitButton: 'submitButton',
    confirmUsageAgreement: 'confirmUsageAgreement',
    confirmMarketingConsent: 'confirmMarketing',
    confirmDataStorageConsent: 'confirmDataStorage',
};

type TokenCustomerAddress = {
    city: string;
    countryCode: string;
    fullStreet: string;
    zipCode: string;
};

type TokenCustomerContactData = {
    email: string;
    fullPhoneNumber: string;
};

type TokenCustomerData = {
    addresses: TokenCustomerAddress;
    contactData: TokenCustomerContactData;
    dateOfBirth: string;
    firstName: string;
    lastName: string;
};

type TokenUserInfo = {
    customerData: TokenCustomerData;
};

export const RegistrationFormUi: React.FC<{
    consent?: ConsentStatus;
    privacyPolicy?: string;
    usageAgreement?: string;
}> = ({ consent, privacyPolicy, usageAgreement }) => {
    const onSubmissionSuccess = useMemo(
        () => (): void => {
            const redirectUrl =
                getUrlSearchParams().get('redirectUrl') || getAbsoluteUrlWithParams(dashboardPagePath());
            if (redirectUrl.includes('?')) {
                window.location.assign(redirectUrl + '&freshRegistration=true');
            } else {
                window.location.assign(redirectUrl + '?freshRegistration=true');
            }
        },
        [],
    );
    const { submit, isLoading, errorCode } = useRegistrationSubmission(onSubmissionSuccess);
    const { t, f } = useTranslationWithFormatting('registration');
    const { onTyping, onConfirmError, onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
        confirm: 'onConfirmConfirmIdentiy',
        formValidationError: 'onFormValidationErrorConfirmIdentity',
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [is4Sales, setIs4Sales] = useState<boolean>(false);
    const customerData = (useAuthUserInfo() as TokenUserInfo)?.customerData;

    if (!privacyPolicy || !usageAgreement) {
        return null;
    }

    const getAvailableProducts = (t: TFunction): ValidatedSelectItem[] =>
        [
            AddContractProductType.FOUR_SALES,
            AddContractProductType.LEASE,
            AddContractProductType.FINANCING,
            AddContractProductType.SERVICE_MANAGEMENT,
        ].map(type => {
            const translationType = type.toLowerCase().replace(/_/g, '-');
            return {
                label: t(`form.product.type.${translationType}`),
                value: type,
            };
        });

    const initialValues: RegistrationFormFields = {
        product: undefined,
        zipCode: customerData?.addresses?.zipCode || '',
        lastName: customerData?.lastName || '',
        birthDate: customerData?.dateOfBirth
            ? formatAsDate(moment(customerData?.dateOfBirth, marketApiDateFormat))
            : '',
        contractNumber: '',
        confirmUsageAgreement: false,
        confirmMarketing: false,
        confirmDataStorage: false,
    };

    const handleSubmit = (values: RegistrationFormFields): void => {
        const { product, zipCode, lastName, birthDate, contractNumber, confirmMarketing, confirmDataStorage } = values;
        const birthMoment = moment(birthDate, longDateFormat);
        if (values.product) {
            submit(
                {
                    zipCode,
                    lastName,
                    birthDate: f(birthMoment, TranslationFormat.DATE),
                    contractNumber,
                    marketingConsent: confirmMarketing,
                    dataStorageConsent: {
                        decision: !!confirmDataStorage,
                        versionId: consent?.dataStorage?.versionDetailsId || '',
                    },
                },
                product as string,
            );
            trackOnSubmit();
        }
    };

    return (
        <>
            <RegistrationError errorCode={errorCode} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, is4Sales)}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {formik => (
                    <Form onSubmit={e => preventSubmit(e)} onChange={(): void => onTyping()}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    name="product"
                                    testId={testIds.product}
                                    label={t('form.product.label')}
                                    emptyByDefault
                                    selectItems={getAvailableProducts(t)}
                                    onChange={(e: string): void => {
                                        if (e === AddContractProductType.FOUR_SALES) {
                                            setIs4Sales(true);
                                            const newValue = 'VM'.concat('', formik.values.contractNumber);
                                            formik.setFieldValue('contractNumber', newValue);
                                        } else if (formik.values.contractNumber.match(RegExp('^VM'))) {
                                            setIs4Sales(false);
                                            const newValue = formik.values.contractNumber.substring(2);
                                            formik.setFieldValue('contractNumber', newValue);
                                        } else {
                                            setIs4Sales(false);
                                        }
                                    }}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={t('form.contract-number.label')}
                                    hint={t('form.contract-number.link-label')}
                                    onLinkClick={(): void => setShowModal(true)}
                                    name="contractNumber"
                                    testId={testIds.contractNumber}
                                    withHtmlValidationWarning
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '',
                                        blocks: [5],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={t('form.zip-code')}
                                    name={'zipCode'}
                                    testId={testIds.zipCode}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput label={t('form.last-name')} name="lastName" testId={testIds.lastName} />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '.',
                                        blocks: [2, 2, 4],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={t('form.birth-date')}
                                    name={'birthDate'}
                                    testId={testIds.birthDate}
                                />
                            </Fieldset.Row>
                            <InfoCheckbox
                                preLinkLabel={t('form.usage-agreement.pre')}
                                linkLabel={t('form.usage-agreement.link')}
                                fieldName={testIds.confirmUsageAgreement}
                                testId={testIds.showUsageAgreement}
                                testIdInfoText={testIds.usageAgreement}
                            >
                                <div dangerouslySetInnerHTML={{ __html: usageAgreement }} />
                            </InfoCheckbox>
                            {consent?.marketing && (
                                <InfoCheckbox
                                    preLinkLabel={t('form.marketing.pre')}
                                    linkLabel={t('form.marketing.link')}
                                    afterLinkLabel={t('form.marketing.after')}
                                    fieldName={testIds.confirmMarketingConsent}
                                    testId={testIds.showMarketingConsent}
                                    testIdInfoText={testIds.marketingConsent}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: targetNewTab(consent.marketing.text) }} />
                                    <div
                                        dangerouslySetInnerHTML={{ __html: targetNewTab(consent.marketing.disclaimer) }}
                                    />
                                </InfoCheckbox>
                            )}
                            {consent?.dataStorage && (
                                <InfoCheckbox
                                    preLinkLabel={t('form.data-storage.pre')}
                                    linkLabel={t('form.data-storage.link')}
                                    afterLinkLabel={''}
                                    fieldName={testIds.confirmDataStorageConsent}
                                    testId={testIds.showDataStorageConsent}
                                    testIdInfoText={testIds.dataStorageConsent}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: targetNewTab(consent.dataStorage.text) }} />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: targetNewTab(consent.dataStorage.disclaimer),
                                        }}
                                    />
                                </InfoCheckbox>
                            )}
                            <Fieldset.Row>
                                <Button
                                    onClick={(e): void => {
                                        e.preventDefault();
                                        window.open('https://www.vwfs.de/metaseiten/datenschutz.html', '_blank');
                                    }}
                                    link={true}
                                    testId={testIds.showPrivacyPolicy}
                                >
                                    {t('form.privacy-policy')}
                                </Button>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Button
                                    full={true}
                                    onClick={() => {
                                        formik.submitForm();
                                        onConfirmError(formik.errors, formik.touched);
                                    }}
                                    testId={testIds.submitButton}
                                >
                                    {t('form.submit')}
                                </Button>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showModal}
                buttonConfirmText={t('modal.close')}
                onClose={(): void => setShowModal(false)}
                onConfirm={(): void => setShowModal(false)}
                testId={testIds.modal}
            >
                <h3>{t('modal.title-1')}</h3>
                <p>{t('modal.text-1')}</p>
                <h3>{t('modal.title-2')}</h3>
                <p>{t('modal.text-2')}</p>
                <h3>{t('modal.title-3')}</h3>
                <p>{t('modal.text-3')}</p>
                <h3>{t('modal.title-4')}</h3>
                <p>{t('modal.text-4')}</p>
            </Modal>
            {isLoading && <Spinner fullPage={true} />}
        </>
    );
};
