import { ReportDamageResponsibleInsurance } from '@cp-de/common';
import { YesNoButtons } from '../../../common';

export const showPolicyHolder = (responsibleInsurance: string): boolean => {
    return responsibleInsurance === ReportDamageResponsibleInsurance.OWN;
};

export const showTransferInsurance = (policyHolder: string): boolean => {
    return policyHolder === YesNoButtons.no;
};

export const showUploadInsuranceClaim = (transferInsurance: string): boolean => {
    return transferInsurance === YesNoButtons.yes;
};

export const showDownloadInsuranceClaim = (transferInsurance: string): boolean => {
    return transferInsurance === YesNoButtons.no;
};
