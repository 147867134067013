import React, { ReactElement } from 'react';
import { Button, Card } from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { useHistory } from 'react-router-dom';

type MarketingCardComponentProps = {
    title?: string;
    subtitle?: string;
    imageSrc?: string;
    buttonLabel?: string;
    buttonUrl?: string;
    testId?: string;
    children?: ReactElement | string;
    className?: string;
};

export const MarketingCardComponent: React.FC<MarketingCardComponentProps> = ({
    title,
    subtitle,
    imageSrc,
    buttonLabel,
    buttonUrl,
    testId,
    children,
    className,
}) => {
    const history = useHistory();
    const { onAction } = useAnalyticsActionTracker('onClickMarketingCard');

    const onButtonClick = (): void => {
        onAction(title);
        // Local Link
        if (buttonUrl?.startsWith('/')) {
            history.push(buttonUrl);
        } else {
            window.open(buttonUrl, '_blank');
        }
    };

    return (
        <Card
            element="article"
            title={title}
            subtitle={subtitle}
            imageSrc={imageSrc}
            footer
            testId={testId}
            className={className}
            buttons={
                buttonUrl && (
                    <Button
                        link
                        icon="semantic-forward"
                        iconReversed
                        small
                        testId={`${testId}-button`}
                        onClick={onButtonClick}
                    >
                        {buttonLabel}
                    </Button>
                )
            }
        >
            {children}
        </Card>
    );
};
