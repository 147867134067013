import React from 'react';
import { useParams } from 'react-router-dom';
import { ContractEndOptions } from '../../components/contracts';
import { ContentSection } from '@vwfs-bronson/bronson-react';

interface RouteParams {
    contractId: string;
}

export const ContractEndOptionsPage: React.FC = () => {
    const { contractId } = useParams<RouteParams>();

    return (
        <ContentSection pageWrapSize="medium">
            <ContractEndOptions contractId={contractId} />
        </ContentSection>
    );
};
