import React from 'react';
import { Contract } from '@cp-de/common';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
    contractEndOptionsPurchasePriceInquiryPagePath,
    contractEndOptionsSilentExtensionPagePath,
    contractEndOptionsVehicleReturnPagePath,
} from '../../../navigation/paths';
import { ContractEndOptionsEntryPoint } from '../entry-point';
import { ReturnOfCar } from '../return-of-car';
import { ContractSilentExtension } from '../contract-silent-extension';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';
import { PurchasePriceInquiry } from '../purchase-price-inquiry/PurchasePriceInquiry';

export const ContractEndOptionsUi: React.FC<{
    contract?: Contract;
    email?: string;
}> = ({ contract, email }) => {
    const { path } = useRouteMatch();

    if (!contract) {
        return <NoConnectionNotification />;
    }

    const { contractId } = contract;

    return (
        <Switch>
            <Route exact path={contractEndOptionsVehicleReturnPagePath(contractId)}>
                <ReturnOfCar contract={contract} />
            </Route>
            <Route exact path={contractEndOptionsSilentExtensionPagePath(contractId)}>
                <ContractSilentExtension contract={contract} email={email} />
            </Route>
            <Route exact path={contractEndOptionsPurchasePriceInquiryPagePath(contractId)}>
                <PurchasePriceInquiry contract={contract} email={email} />
            </Route>
            <Route path={path}>
                <ContractEndOptionsEntryPoint contract={contract} />
            </Route>
        </Switch>
    );
};
