import { IbanChange } from '@cp-de/common';
import { BankData, getIbanValidationEndpoint, SignedData } from '@cp-shared-8/apis';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-8/common-utilities';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import { CleaveInput, DataOverview, preventSubmit, UiBlockingSpinner } from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik, FormikProps } from 'formik';
import { useTranslationWithFormatting } from '../../../../../../localization/useTranslationWithFormatting';
import React, { useState } from 'react';
import { object, string, ValidationError } from 'yup';

import { IbanValidationError } from './IbanValidationError';
import { areDifferent, isValidCountryCode, isValidLength } from './ibanValidations';

export type IbanValidationViewProps = {
    previousIban?: string;
    onValidIban: (validateIban: IbanChange) => void;
    toPreviousView: () => void;
    isFirstView?: boolean;
};

type FormType = {
    iban: string;
};

const translationPrefix = 'financial-details.iban-section';
const translationInputPrefix = `${translationPrefix}.bank-account-validation.iban-change-input`;
const translationErrorPrefix = `${translationInputPrefix}.error`;
const cleaveOptionsIban = {
    uppercase: true,
    delimiter: ' ',
    blocks: [4, 4, 4, 4, 4, 4, 4, 4],
    numericOnly: false,
};

export const IbanValidationView: React.FC<IbanValidationViewProps> = ({
    previousIban,
    toPreviousView,
    onValidIban,
    isFirstView,
}) => {
    const { t } = useTranslationWithFormatting('contracts');
    const [isValidating, setIsValidating] = useState(false);
    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<{ iban?: string; error?: string }>({});

    const getError = (errorCode: string, iban: string): boolean | ValidationError => {
        switch (errorCode) {
            case 'BFF_API_ERROR':
            case 'SAME_BANK_ACCOUNT':
            case 'INCORRECT_IBAN':
                return false;
            default:
                return new ValidationError(t(`${translationErrorPrefix}.iban-validator-unavailable`), iban, 'iban');
        }
    };

    const initialValues: FormType = {
        iban: '',
    };

    const ibanValidation = string()
        .required(t(`${translationErrorPrefix}.required`))
        .test('notSameAsPrevious', t(`${translationErrorPrefix}.same-iban`), areDifferent(previousIban))
        .test('validCountryCode', t(`${translationErrorPrefix}.invalid-iban`), isValidCountryCode)
        .matches(RegExp('^[A-Z]{2}[0-9]{2}[a-zA-Z0-9_ ]*$'), t(`${translationErrorPrefix}.invalid-iban`))
        .test('ibanLength', t(`${translationErrorPrefix}.invalid-iban`), isValidLength);

    const validationSchema = object().shape({
        iban: ibanValidation.test(
            'asyncIban',
            t(`${translationErrorPrefix}.invalid-iban`),
            async (iban: string | undefined) => {
                if (!ibanValidation.isValidSync(iban)) {
                    setSignedBankData(undefined);
                    setSavedIban({});
                    return true;
                }
                if (savedIban.iban === iban) {
                    if (!savedIban.error) {
                        return true;
                    }
                    return getError(savedIban.error, savedIban.iban);
                }
                await setIsValidating(true);
                return await CpDataApi.post(getIbanValidationEndpoint(), { iban })
                    .then(({ data }: { data: SignedData<BankData> }) => {
                        const { isValid } = data.data;
                        setSignedBankData(isValid ? data : undefined);
                        setSavedIban({ iban, error: isValid ? undefined : 'INCORRECT_IBAN' });
                        return isValid;
                    })
                    .catch(error => {
                        const errorCode = parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationError>>(
                            error,
                        ).code;
                        setSavedIban({ iban, error: errorCode });
                        return getError(errorCode, iban);
                    })
                    .finally(() => {
                        setIsValidating(false);
                    });
            },
        ),
    });

    const handleSubmit = (): void => {
        if (signedBankData) {
            onValidIban({ signedBankData });
        }
    };

    const handleChange = (formik: FormikProps<FormType>) => async (e: React.ChangeEvent<{ rawValue: string }>) => {
        if (ibanValidation.isValidSync(e.target.rawValue)) {
            formik.setFieldTouched('iban');
        }
    };

    return (
        <DataOverview title={t(`${translationPrefix}.headline.edit-mode`)} withoutCardEffect={true}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnBlur={!signedBankData}
            >
                {(formik): JSX.Element => (
                    <Form onSubmit={e => preventSubmit(e)}>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/2" s="1/1">
                                        <UiBlockingSpinner isBlocking={isValidating}>
                                            <CleaveInput
                                                cleaveOptions={cleaveOptionsIban}
                                                label={t(`${translationInputPrefix}.text`)}
                                                name="iban"
                                                testId="iban"
                                                tooltip={t(`${translationInputPrefix}.tooltip`)}
                                                placeholder={t(`${translationInputPrefix}.placeholder`)}
                                                stateIcon
                                                handleChange={() => handleChange(formik)}
                                            />
                                            {signedBankData?.data.isValid && (
                                                <p className="u-text-success">
                                                    {signedBankData.data.bankDetails?.bankName}
                                                </p>
                                            )}
                                        </UiBlockingSpinner>
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button secondary onClick={toPreviousView} testId="cancelButton" type="reset">
                                        {t(`translation:editableSectionNav.${isFirstView ? 'cancel' : 'back'}`)}
                                    </Button>
                                    <Button onClick={() => formik.handleSubmit()} testId="submitButton" type="submit">
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};
