import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '../../../../file-upload/FileUpload';
import { maxFileSize, validFileFormats } from '../helpers';

export const AttachmentsUpload: React.FC<{ name: string; label?: string }> = ({ name, label }) => {
    const { t } = useTranslation('repair-approval');

    return (
        <>
            {label && <p>{label}</p>}
            <FileUpload
                name={name}
                multiple
                maxFileSize={maxFileSize}
                validFileFormats={validFileFormats}
                sizeError={t('file-upload.errors.size-error')}
                typeError={t('file-upload.errors.type-error')}
                sizeAndTypeError={
                    <div>
                        {t('file-upload.errors.size-error')}
                        <br />
                        {t('file-upload.errors.type-error')}
                    </div>
                }
            />
        </>
    );
};
