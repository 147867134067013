import React from 'react';
import { Fieldset, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { CleaveInput, ValidatedInput } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';

type CommonFormViewProps = { name: string; isAccipiens: boolean };

export const CommonInsuranceForm: React.FC<CommonFormViewProps> = ({ name, isAccipiens }) => {
    const { t } = useTranslation('repair-approval');
    const translationPrefix = 'common.insurance-form';
    return (
        <>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/1" s="1/1">
                        <Heading className="u-m-none" level={6}>
                            {t(`${translationPrefix}.title`)}
                        </Heading>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/2" s="1/1">
                        <ValidatedInput
                            label={t(`${translationPrefix}.name`)}
                            name={`${name}.name`}
                            testId={`${name}-name`}
                            type="text"
                        />
                    </Layout.Item>
                    <Layout.Item default="1/2" s="1/1">
                        <ValidatedInput
                            label={t(`${translationPrefix}.post-office-box.label`)}
                            tooltip={t(`${translationPrefix}.post-office-box.tooltip`)}
                            name={`${name}.address.postOfficeBox`}
                            testId={`${name}-postOfficeBox`}
                            type="text"
                        />
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="3/4" s="1/1">
                        <ValidatedInput
                            label={t(`${translationPrefix}.street-name`)}
                            name={`${name}.address.streetName`}
                            testId={`${name}-streetName`}
                            type="text"
                        />
                    </Layout.Item>
                    <Layout.Item default="1/4" s="1/1">
                        <ValidatedInput
                            label={t(`${translationPrefix}.house-number`)}
                            name={`${name}.address.houseNumber`}
                            testId={`${name}-houseNumber`}
                            type="text"
                        />
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/4" s="1/1">
                        <ValidatedInput
                            label={t(`${translationPrefix}.zip-code`)}
                            name={`${name}.address.zipCode`}
                            testId={`${name}-zipCode`}
                            type="text"
                        />
                    </Layout.Item>
                    <Layout.Item default="3/4" s="1/1">
                        <ValidatedInput
                            label={t(`${translationPrefix}.locality`)}
                            name={`${name}.address.locality`}
                            testId={`${name}-locality`}
                            type="text"
                        />
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            {!isAccipiens && (
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/4" s="1/1">
                            <ValidatedInput
                                label={t(`${translationPrefix}.fax-number`)}
                                name={`${name}.contact.faxNumber`}
                                testId={`${name}-faxNumber`}
                                type="text"
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            )}
            {isAccipiens && (
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/4" s="1/1">
                            <ValidatedInput
                                label={t(`${translationPrefix}.email`)}
                                name={`${name}.contact.email`}
                                testId={`${name}-email`}
                                type="text"
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            )}
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/2" s="1/1">
                        <ValidatedInput
                            label={t(`${translationPrefix}.claim-id`)}
                            name={`${name}.claimId`}
                            testId={`${name}-claimId`}
                            type="text"
                        />
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            {isAccipiens && (
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <CleaveInput
                                cleaveOptions={{
                                    numeral: true,
                                    delimiter: '.',
                                    numeralDecimalMark: ',',
                                }}
                                label={t(`${translationPrefix}.damage-amount`)}
                                name={`${name}.damageAmount`}
                                testId={`damage-amount`}
                                addonText={t('translation:currency.euro')}
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            )}
        </>
    );
};
