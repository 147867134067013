import React from 'react';
import { FileUpload as MarketFileUpload } from '../../file-upload/FileUpload';
import { useTranslation } from 'react-i18next';
import { formatAsFileSize } from '@cp-de/common';

export type StepSecondFileUploadProps = {
    maxFileSize: number;
    validFileFormats: string[];
    name: string;
};

export const FileUpload: React.FC<StepSecondFileUploadProps> = ({ maxFileSize, validFileFormats, name }) => {
    const { t } = useTranslation('early-termination');
    const translationPrefix = 'file-upload-validation';
    const sizeError = t(`${translationPrefix}.size`, {
        size: formatAsFileSize(maxFileSize, 2),
    });
    const typeError = t(`${translationPrefix}.type`, {
        formats: validFileFormats.join(', '),
    });
    const sizeAndTypeError = (
        <div>
            <span>{sizeError}</span>
            <br />
            <span>{typeError}</span>
        </div>
    );

    return (
        <MarketFileUpload
            name={name}
            multiple={false}
            maxFileSize={maxFileSize}
            validFileFormats={validFileFormats}
            sizeError={sizeError}
            typeError={typeError}
            sizeAndTypeError={sizeAndTypeError}
        />
    );
};
