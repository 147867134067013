import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { BundledProductsUi } from './ui';
import { FinancialDetails as ContractFinancialDetails } from '@cp-de/common';
import { useTranslation } from 'react-i18next';

const FinancialDetailsWithHandlers = withLoadingAndNoConnectionHandler(BundledProductsUi);

type BundledProductsProps = {
    isFinancialDetailsLoading: boolean;
    isFinancialDetailsLoadingError: boolean;
    financialDetails?: ContractFinancialDetails;
};

export const BundledProducts: React.FC<BundledProductsProps> = ({
    isFinancialDetailsLoading,
    isFinancialDetailsLoadingError,
    financialDetails,
}) => {
    const { t } = useTranslation('contracts');
    const bundledProducts = financialDetails?.additionalProducts;

    return (
        <div>
            <FinancialDetailsWithHandlers
                isLoading={isFinancialDetailsLoading}
                bundledProducts={bundledProducts}
                hasError={isFinancialDetailsLoadingError}
                errorText={t('bundled-products.error')}
            />
        </div>
    );
};
