import React, { useEffect } from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { CancelReservationUi } from './ui';
import { CancelReservationLoadingPlaceholder } from './CancelReservationLoadingPlaceholder';
import { useTranslation } from 'react-i18next';

import { useAoDContract } from '../contracts';
import { dashboardPagePath } from '../navigation/paths';
import { useHistory } from 'react-router-dom';
import { ReservationContractStatus } from '@cp-de/common';

const CancelReservationWithHandlers = withLoadingAndNoConnectionHandler(CancelReservationUi);

export const CancelAoDReservation: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { t } = useTranslation('aod-cancel-reservation');
    const history = useHistory();

    const { data: aodContract, isLoading, loadingError, hasReceivedResponse } = useAoDContract(contractId);

    const { contractStatus } = aodContract || {};

    useEffect(() => {
        if (contractStatus === ReservationContractStatus.RENT_CANCELLED) {
            history.push(dashboardPagePath());
        }
    }, [history, contractStatus]);

    return (
        <CancelReservationWithHandlers
            isLoading={isLoading}
            reservationContract={aodContract}
            hasError={!!loadingError || (hasReceivedResponse && !aodContract)}
            errorText={t('error.no-connection')}
            loadingPlaceholder={<CancelReservationLoadingPlaceholder />}
        />
    );
};
