import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export type FilterContractValidationMessages = {
    filterStartDate: {
        futureDate: string;
    };
    filterEndDate: {
        futureDate: string;
    };
};

export const filterContractValidationSchema = (): Yup.ObjectSchema<AnyObject> =>
    Yup.object().shape({
        filterStartDate: Yup.string(),
        filterEndDate: Yup.string(),
        filterContract: Yup.string(),
        filterBrands: Yup.array(),
    });
