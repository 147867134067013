import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { ChangePaymentDateUi } from './ui';
import { useContract } from '../contracts/useContract';
import { useMyProfile } from '../my-profile';

const ChangePaymentDateWithHandlers = withLoadingAndNoConnectionHandler(ChangePaymentDateUi);

type ChangePaymentDateProps = {
    contractId: string;
};

export const ChangePaymentDate: React.FC<ChangePaymentDateProps> = ({ contractId }) => {
    const { data: myProfileData, isLoading: isMyProfileLoading } = useMyProfile();
    const { data, isLoading, loadingError } = useContract(contractId);
    const { t } = useTranslation('change-payment-date');

    useAnalyticsPageViewTracker('changePaymentDate');

    return (
        <ChangePaymentDateWithHandlers
            isLoading={isLoading || isMyProfileLoading}
            errorText={t('error.no-connection')}
            contract={data}
            customerMailAddress={myProfileData?.contactDetails?.email}
            hasError={!!loadingError}
        />
    );
};
