import React, { useState } from 'react';
import { Transaction, TransactionItem, formatAsNumber, numberFormatRoundedTwo } from '@cp-de/common';
import { Layout, Modal, ButtonContainer, Button, InfoIcon, Table } from '@vwfs-bronson/bronson-react';
import {
    DynamicTableFormatOptions,
    DynamicTableSortOptions,
    TableHeaderEntry,
    Notification,
    NotificationStatus,
    Spinner,
    Cell,
    TableHeaderCell,
    DynamicTableProps,
    TableSummaryProps,
    createOrderedTableRows,
    GroupedTableRowsProps,
    DynamicTableGroupByOptions,
} from '@cp-shared-8/frontend-ui';
import { useHistory } from 'react-router-dom';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { formatCpDate, ISO_DATE_FORMAT } from '@cp-shared-8/common-utilities';
import { dashboardPagePath } from '../../navigation/paths';
import { CpDataApi } from 'cp-xhr';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';

export const testIds = {
    downloadFailureModal: 'downloadFailureModal',
    downloadLink: 'downloadLink',
    goBackButton: 'goBackButton',
};

interface SelectedSorting<TDataRow> {
    columnIndex: number;
    options?: DynamicTableSortOptions<TDataRow>;
    alternativeOrder: boolean;
}

const TableSummary: React.FC<TableSummaryProps> = ({ summaryRow }) => {
    return (
        <Table.Tr>
            {summaryRow.map((cellData, cellIndex) => {
                if (typeof cellData === 'string') {
                    return (
                        <Table.Th className="u-bg-brand u-text-white" key={cellIndex}>
                            {cellData}
                        </Table.Th>
                    );
                }
                return (
                    <Table.Th
                        key={cellIndex}
                        className={[
                            'u-bg-brand u-text-white',
                            !!cellData.hiddenForMobile ? 'u-hide@xs' : '',
                            !!cellData.textAlign ? `u-text-${cellData.textAlign}` : '',
                        ]
                            .filter(Boolean)
                            .join(' ')}
                    >
                        {cellData?.value}
                    </Table.Th>
                );
            })}
        </Table.Tr>
    );
};

type GroupedRows<TDataRow> = {
    [groupKey: string]: TDataRow[];
};

function groupRows<TDataRow>(rows: TDataRow[], groupByFn: (row: TDataRow) => string): GroupedRows<TDataRow> {
    const result: GroupedRows<TDataRow> = {};
    rows.forEach(row => {
        const groupLabel = groupByFn(row);
        result[groupLabel] ? result[groupLabel].push(row) : (result[groupLabel] = [row]);
    });
    return result;
}

function sortGroupLabels<TDataRow>(
    groupedRows: GroupedRows<TDataRow>,
    groupByOptions: DynamicTableGroupByOptions<TDataRow>,
): string[] {
    const groupLabels = Object.keys(groupedRows);
    const { reversed, compareGroupByLabel } = groupByOptions;
    const sortedGroupLabels = groupLabels.sort(compareGroupByLabel);
    return reversed ? sortedGroupLabels.reverse() : sortedGroupLabels;
}

function sortRows<TDataRow>(rows: TDataRow[], sortOptions?: DynamicTableSortOptions<TDataRow>): TDataRow[] {
    if (sortOptions) {
        const { compare, reversed = false } = sortOptions;
        const copyRows = [...rows];
        return reversed ? copyRows.sort(compare) : copyRows.sort(compare).reverse();
    }
    return rows;
}

function createGroupedTableRows<TDataRow extends object>(): React.FC<GroupedTableRowsProps<TDataRow>> {
    const GroupedTableRows: React.FC<GroupedTableRowsProps<TDataRow>> = ({
        rows,
        groupByOptions,
        sortOptions,
        toColumnValues,
    }) => {
        if (rows.length <= 0) {
            return null;
        }
        const { groupBy, formatGroupLabel } = groupByOptions;
        const sortedRows: TDataRow[] = sortRows(rows, sortOptions);
        const groupedRows: GroupedRows<TDataRow> = groupRows(sortedRows, groupBy);
        const sortedGroupLabels: string[] = sortGroupLabels(groupedRows, groupByOptions);
        const numberOfColumns: number = toColumnValues(rows[0]).length;

        return (
            <>
                {sortedGroupLabels.map(groupLabel => (
                    <React.Fragment key={groupLabel}>
                        <Table.Tr>
                            <Table.Th colSpan={numberOfColumns}>
                                {formatGroupLabel ? formatGroupLabel(groupLabel) : groupLabel}
                            </Table.Th>
                        </Table.Tr>
                        {groupedRows[groupLabel].map((row, rowIndex) => (
                            <Table.Tr key={groupLabel + rowIndex}>
                                {toColumnValues(row).map((cellData, cellIndex) => {
                                    if (typeof cellData === 'string') {
                                        return <Table.Td key={cellIndex}>{cellData}</Table.Td>;
                                    }
                                    return (
                                        <Table.Td
                                            key={cellIndex}
                                            className={[
                                                !!cellData.nowrap ? 'u-ws-nowrap' : '',
                                                !!cellData.hiddenForMobile ? 'u-hide@xs' : '',
                                                !!cellData.textAlign ? `u-text-${cellData.textAlign}` : '',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')}
                                        >
                                            {cellData?.value}
                                        </Table.Td>
                                    );
                                })}
                            </Table.Tr>
                        ))}
                    </React.Fragment>
                ))}
            </>
        );
    };
    GroupedTableRows.displayName = 'GroupedTableRows';
    return GroupedTableRows;
}

function createTable<TDataRow extends object>(): React.FC<DynamicTableProps<TDataRow>> {
    const DynamicTable: React.FC<DynamicTableProps<TDataRow>> = ({
        rows,
        columnHeadings,
        sortOptions: defaultSortOptions,
        columnSortOptions,
        toColumnValues,
        formatOptions,
        hasSummary = false,
        groupByOptions,
        summaryRow = [],
    }) => {
        const [selectedSortOptions, setSelectedSortOptions] = useState<SelectedSorting<TDataRow>>(() => ({
            columnIndex: 0,
            options: columnSortOptions && columnSortOptions.length > 0 ? columnSortOptions[0] : defaultSortOptions,
            alternativeOrder: false,
        }));

        if (!columnHeadings) {
            return null;
        }

        const sortCallback = (columnIndex: number) => () => {
            if (columnSortOptions && columnSortOptions.length > columnIndex) {
                let newValue: SelectedSorting<TDataRow> | undefined;
                if (selectedSortOptions && selectedSortOptions.columnIndex === columnIndex) {
                    const currentOrder = selectedSortOptions.alternativeOrder;
                    newValue = { ...selectedSortOptions, alternativeOrder: !currentOrder };
                } else {
                    newValue = {
                        columnIndex,
                        options: columnSortOptions[columnIndex],
                        alternativeOrder: false,
                    };
                }
                if (columnIndex === 0 && groupByOptions?.reversed !== undefined) {
                    groupByOptions.reversed = !groupByOptions.reversed;
                }

                setSelectedSortOptions(newValue);
            }
        };

        const OrderedTableRows = createOrderedTableRows<TDataRow>();
        const GroupedTableRows = createGroupedTableRows<TDataRow>();
        const initialSortOptions = selectedSortOptions.options;
        let modifiedSortOptions: DynamicTableSortOptions<TDataRow> | undefined = undefined;
        if (initialSortOptions) {
            modifiedSortOptions = {
                ...initialSortOptions,
                reversed: !!initialSortOptions?.reversed !== selectedSortOptions?.alternativeOrder,
            };
        }

        return (
            <Table {...formatOptions}>
                <Table.Thead>
                    {hasSummary && <TableSummary summaryRow={summaryRow} />}
                    <Table.Tr>
                        {columnHeadings.map((heading, index) => (
                            <TableHeaderCell
                                key={typeof heading === 'string' ? heading : heading.heading}
                                heading={heading}
                                sortable={columnSortOptions && columnSortOptions.length > index}
                                sortSelected={selectedSortOptions?.columnIndex === index}
                                sortOrder={modifiedSortOptions?.reversed}
                                sortCallback={sortCallback(index)}
                            />
                        ))}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {groupByOptions ? (
                        <GroupedTableRows
                            rows={rows}
                            toColumnValues={toColumnValues}
                            sortOptions={modifiedSortOptions}
                            groupByOptions={groupByOptions}
                        />
                    ) : (
                        <OrderedTableRows
                            rows={rows}
                            toColumnValues={toColumnValues}
                            sortOptions={modifiedSortOptions}
                        />
                    )}
                </Table.Tbody>
            </Table>
        );
    };
    DynamicTable.displayName = 'DynamicTable';
    return DynamicTable;
}

const DynamicTable = createTable();

export const RevenuesOpenPositionsUi: React.FC<{ transactions?: TransactionItem[]; customerNumber: string }> = ({
    transactions,
    customerNumber,
}) => {
    const { t, f } = useTranslationWithFormatting('revenues-open-positions');
    const history = useHistory();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [downloadError, setDownloadError] = useState<boolean>(false);

    const filteredTransactionsByCustomerNumber: TransactionItem | undefined = transactions?.find(
        transaction => transaction.customerNumber === customerNumber,
    );

    const download = (): void => {
        const link = filteredTransactionsByCustomerNumber?._links?.revenuesAndOpenPositionsDownload;
        if (!link) {
            return;
        }
        setIsDownloading(true);
        CpDataApi.get(link)
            .then(response => {
                const fileContent = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                const filename = `${t('download.filename')}_${
                    filteredTransactionsByCustomerNumber?.customerNumber
                }_${formatCpDate().format('YYYYMMDD')}.pdf`;
                downloadFileAs(pdfBlob, filename);
                setIsDownloading(false);
            })
            .catch(() => {
                setDownloadError(true);
                setIsDownloading(false);
            });
    };

    const goBackToDashboard = (): void => history.push(dashboardPagePath());

    const columnHeadings: TableHeaderEntry[] = [
        t('table.due-date'),
        {
            heading: t('table.amount-and-currency'),
            textAlign: 'right',
        },
        t('table.contract-number'),
        t('table.license-plate'),
        t('table.description'),
    ];

    const formatOptions: DynamicTableFormatOptions = {
        auto: false,
        noScroll: false,
        colored: false,
        bordered: false,
        highlight: false,
        wide: true,
        narrow: false,
    };

    const defaultSortOptions: DynamicTableSortOptions<Transaction> = {
        compare: (row1: Transaction, row2: Transaction) =>
            row1.dueDate !== undefined && row2.dueDate !== undefined
                ? formatCpDate(row1.dueDate, ISO_DATE_FORMAT).diff(
                      formatCpDate(row2.dueDate, ISO_DATE_FORMAT).toCpDate(),
                  )
                : 0,
        reversed: true,
    };

    const columnSortOptions: DynamicTableSortOptions<Transaction>[] = [
        {
            compare: (row1: Transaction, row2: Transaction) => formatCpDate(row1.dueDate).diff(row2.dueDate),
            reversed: false,
        },
        {
            compare: (row1: Transaction, row2: Transaction) => {
                return (row2.totalAmount?.amount || 0) - (row1.totalAmount?.amount || 0);
            },
            reversed: false,
        },
        {
            compare: (row1: Transaction, row2: Transaction) =>
                (row2.contractNumber || '').localeCompare(row1.contractNumber || ''),
            reversed: false,
        },
        {
            compare: (row1: Transaction, row2: Transaction) =>
                (row2.licensePlate || '').localeCompare(row1.licensePlate || ''),
            reversed: false,
        },
        {
            compare: (row1: Transaction, row2: Transaction) =>
                (row2.description || '').localeCompare(row1.description || ''),
            reversed: false,
        },
    ];

    const formatTotalAmount = (amount?: number, currency?: string): string => {
        return `${formatAsNumber(amount, numberFormatRoundedTwo).replace(/ /g, '.')} ${currency}`;
    };

    const toColumnValues = (row: Transaction): Cell[] => {
        const fallbackValue = '';
        return [
            {
                value: f(row.dueDate, TranslationFormat.DATE),
            },
            {
                value: formatTotalAmount(row.totalAmount?.amount, row.totalAmount?.currency),
                textAlign: 'right',
            },
            {
                value: row.contractNumber || fallbackValue,
            },
            {
                value: row.licensePlate || fallbackValue,
            },
            {
                value: row.description || fallbackValue,
            },
        ];
    };

    const groupByOptions: DynamicTableGroupByOptions<Transaction> = {
        groupBy: (row: Transaction) =>
            row
                ? formatCpDate(row.dueDate)
                      .toMoment()
                      .year()
                      .toString()
                : '',
        reversed: true,
    };

    return (
        <>
            <Layout>
                {!!filteredTransactionsByCustomerNumber?.transactions?.length ? (
                    <>
                        <Layout.Item default="1/1" className="u-mb-small u-mt-xlarge">
                            {t('description')}
                            <InfoIcon icon="semantic-info">
                                <div dangerouslySetInnerHTML={{ __html: t('description-info-tooltip') }} />
                            </InfoIcon>
                        </Layout.Item>
                        <Layout.Item default="1/1">
                            <DynamicTable
                                rows={filteredTransactionsByCustomerNumber?.transactions || []}
                                columnHeadings={columnHeadings}
                                toColumnValues={toColumnValues}
                                sortOptions={defaultSortOptions}
                                columnSortOptions={columnSortOptions}
                                formatOptions={formatOptions}
                                hasSummary={true}
                                groupByOptions={groupByOptions}
                                summaryRow={[
                                    t('table.summary'),
                                    {
                                        value: formatTotalAmount(
                                            filteredTransactionsByCustomerNumber?.totalAmountSum?.amount,
                                            filteredTransactionsByCustomerNumber?.totalAmountSum?.currency,
                                        ),
                                        textAlign: 'right',
                                    },
                                ]}
                            />
                        </Layout.Item>
                        <Layout.Item default="1/1" className="u-mt-large">
                            {isDownloading ? (
                                <Spinner center={true} />
                            ) : (
                                <Button onClick={download} testId={testIds.downloadLink} icon="download">
                                    {t('download.button')}
                                </Button>
                            )}
                        </Layout.Item>
                    </>
                ) : (
                    <Layout.Item default="1/1" className="u-m-large">
                        <Notification status={NotificationStatus.info} text={t('notification.no-transactions')} />
                    </Layout.Item>
                )}
                <Layout.Item>
                    <ButtonContainer center>
                        <Button secondary onClick={goBackToDashboard} testId={testIds.goBackButton}>
                            {t('goBackButton')}
                        </Button>
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
            <Modal
                shown={!!downloadError}
                buttonConfirmText={t('download.error-modal.close')}
                onConfirm={(): void => setDownloadError(false)}
                onClose={(): void => setDownloadError(false)}
                title={t('download.error-modal.title')}
                status="error"
                testId={testIds.downloadFailureModal}
            >
                {t('download.error-modal.text')}
            </Modal>
        </>
    );
};
