import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { LoginChoice } from '../../components/login-choice';

export const LoginChoicePage: React.FC = () => {
    return (
        <ContentSection pageWrapSize="xxsmall">
            <LoginChoice />
        </ContentSection>
    );
};
