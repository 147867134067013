import { FinancialDetails, formatAsIban } from '@cp-de/common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';
import React from 'react';
import { EditStatus } from '../../base-section/EditStatus';

export type ConsultViewProps = {
    startEditing: () => void;
    financialDetails: FinancialDetails;
    lastEditStatus: EditStatus;
    isInactive?: boolean;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    startEditing,
    financialDetails,
    lastEditStatus,
    isInactive,
}) => {
    const { t } = useTranslationWithFormatting('contracts');

    if (!financialDetails?.iban) {
        return null;
    }

    const isIbanChangePossible = financialDetails?.eligibleForIbanChange && financialDetails?._links?.changeIban;

    const bankAccountItems: DefinitionListItem[] = [
        {
            label: t('financial-details.iban-section.display-fields.iban'),
            value: formatAsIban(financialDetails.iban),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview
            title={t('financial-details.iban-section.headline.non-edit-mode')}
            buttonLabel={isIbanChangePossible && !isInactive ? ' ' : undefined}
            buttonProps={{ onClick: startEditing, testId: 'editButton' }}
            withoutCardEffect={true}
        >
            <DefinitionListHorizontal list={bankAccountItems} />
            {lastEditStatus === EditStatus.SUCCESS && (
                <Notification className={'u-mt'} testId={'success-notification'} status={NotificationStatus.success}>
                    {t('financial-details.iban-section.change-successful')}
                </Notification>
            )}
        </DataOverview>
    );
};
