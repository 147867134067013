import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { fetchPendingContracts } from './PendingContractsSlice';
import { PendingContractsDataSelector } from './PendingContractsDataSelector';
import { PendingContracts } from '@cp-de/common';

export function usePendingContracts(
    isFetchingAllowed = true,
): AbstractDataState<PendingContracts, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchPendingContracts,
        PendingContractsDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
