import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RevenuesOpenPositionsUi } from './ui';
import { useTransactions } from './useTransactions';

const RevenuesOpenPositionsWithHandlers = withLoadingAndNoConnectionHandler(RevenuesOpenPositionsUi);

type RevenuesOpenPositionsProps = {
    customerNumber: string;
};

export const RevenuesOpenPositions: React.FC<RevenuesOpenPositionsProps> = ({ customerNumber }) => {
    const { t } = useTranslation('revenues-open-positions');
    const {
        data: transactions,
        isLoading: isTransactionsLoading,
        loadingError: transactionsLoadingError,
    } = useTransactions();

    return (
        <RevenuesOpenPositionsWithHandlers
            isLoading={isTransactionsLoading}
            transactions={transactions}
            customerNumber={customerNumber}
            hasError={!!transactionsLoadingError}
            errorText={t('error.no-connection')}
        />
    );
};
