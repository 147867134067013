import { UsageAgreement, getUsageAgreementEndpoint } from '@cp-de/common';
import { createCmsContentSlice } from 'cms-integration';

// TODO: Add legal notice content (CO-8273)
const { reducer, fetchContent } = createCmsContentSlice<UsageAgreement>({
    contentName: 'usageAgreement',
    contentEndpoint: getUsageAgreementEndpoint,
});

export default reducer;
export const fetchUsageAgreement = fetchContent;
