export enum AddContractProductType {
    FOUR_SALES = 'FOUR_SALES',
    LEASE = 'LEASE',
    FINANCING = 'FINANCING',
    SERVICE_MANAGEMENT = 'SERVICE_MANAGEMENT',
    AUDI_ON_DEMAND = 'AUDI_ON_DEMAND',
    RENT_A_CAR = 'RENT_A_CAR',
    AUTO_ABO = 'AUTO_ABO',
    OTHER = 'OTHER',
}
