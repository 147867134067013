export const AutoAboVehicleDetailsWithAllValues = {
    registrationNumber: 'VW123456789012311',
    vin: 'WAUZZZ8T78A005288',
    carBrand: 'Audi',
    carModel: 'A3',
    bodyColorName: 'grey',
    trimValue: 'Attraction',
    engineSizeValue: '2.0',
    engineTechnology: 'TDI',
    enginePowerValue: '110',
    enginePowerUnit: 'kW',
};
export const AutoAboVehicleDetailsWithMissingValues = {
    registrationNumber: 'VW123456789012311',
    vin: 'WAUZZZ8T78A005288',
};
