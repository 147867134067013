import React, { Suspense } from 'react';
import { history } from './store/rootReducer';
import { AuthProviders } from './auth';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { useAxiosWithAuthentication, useAxiosWithCurrentLanguage } from './cp-xhr';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { createUserContractsProvider } from './UserContractsProviders';

const { UserContractsProvider, UserContractsContext, useUserContractsDataAndFlags } = createUserContractsProvider();

const AxiosConfiguration: React.FC = ({ children }) => {
    useAxiosWithCurrentLanguage();
    useAxiosWithAuthentication();
    return <>{children}</>;
};

const Providers: React.FC = ({ children }) => {
    return (
        <Suspense fallback={<Spinner center />}>
            <AuthProviders>
                <AxiosConfiguration>
                    <ConnectedRouter history={history}>
                        <LastLocationProvider>
                            <UserContractsProvider>{children}</UserContractsProvider>
                        </LastLocationProvider>
                    </ConnectedRouter>
                </AxiosConfiguration>
            </AuthProviders>
        </Suspense>
    );
};

export { Providers, UserContractsProvider, UserContractsContext, useUserContractsDataAndFlags };
