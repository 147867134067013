import React from 'react';
import { Fieldset, Form, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { preventSubmit, ValidatedRadioButtonGroup } from '@cp-shared-8/frontend-ui';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { getInitialValues } from './initialValues';
import { FurtherApprovalSelectionFormValues } from './types';
import { ReportDamageClaimType } from '@cp-de/common';
import { FormNavigationButtons } from '../../form-navigation-buttons';

const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        reportDamageClaimType: Yup.string()
            .trim()
            .required(t('step1.box2.validation-errors.types-of-approval-required')),
    });
};

type FurtherApprovalSelectionProps = {
    reportDamageClaimType?: ReportDamageClaimType;
    onReportDamageClaimTypeConfirm: (reportDamageClaimType: ReportDamageClaimType) => void;
    onReportDamageClaimTypeSelect: (reportDamageClaimType: ReportDamageClaimType) => void;
    handleNext: () => void;
    handlePrev: () => void;
};

export const FurtherApprovalSelection: React.FC<FurtherApprovalSelectionProps> = ({
    reportDamageClaimType,
    onReportDamageClaimTypeConfirm,
    onReportDamageClaimTypeSelect,
    handleNext,
    handlePrev,
}) => {
    const { t } = useTranslation('repair-approval');

    const handleSubmit = (values: FurtherApprovalSelectionFormValues): void => {
        const { reportDamageClaimType } = values;
        if (!reportDamageClaimType) return;
        onReportDamageClaimTypeConfirm(reportDamageClaimType as ReportDamageClaimType);
        handleNext();
    };

    const radioButtons: Array<{ label: string; value: ReportDamageClaimType }> = [
        { label: t('step1.box2.types-of-approval.expert-report'), value: ReportDamageClaimType.APPRAISAL },
        { label: t('step1.box2.types-of-approval.total-loss'), value: ReportDamageClaimType.TOTAL_LOSS },
        { label: t('step1.box2.types-of-approval.theft'), value: ReportDamageClaimType.THEFT },
    ];

    return (
        <Formik
            initialValues={getInitialValues(reportDamageClaimType)}
            validationSchema={validationSchema(t)}
            onSubmit={handleSubmit}
        >
            {(formik: FormikProps<FurtherApprovalSelectionFormValues>): React.ReactNode => (
                <Form onSubmit={e => preventSubmit(e)}>
                    <Layout center>
                        <Layout.Item default="2/3" m={'1/1'}>
                            <Heading className="u-mt-large" level={4}>
                                {t('step1.box2.hint.title')}
                            </Heading>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedRadioButtonGroup
                                        radioButtons={radioButtons}
                                        name={'reportDamageClaimType'}
                                        testId={'reportDamageClaimType'}
                                        isMandatory={true}
                                        handleChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                                            onReportDamageClaimTypeSelect(evt.target.value as ReportDamageClaimType);
                                        }}
                                    />
                                </Fieldset.Row>
                            </Fieldset>
                        </Layout.Item>
                        <Layout.Item>
                            <FormNavigationButtons
                                onPrimaryButtonClick={formik.submitForm}
                                onSecondaryButtonClick={handlePrev}
                            />
                        </Layout.Item>
                    </Layout>
                </Form>
            )}
        </Formik>
    );
};
