import React, { useState } from 'react';
import { AoDContract, AutoAboContract, Contract, ContractType, FourSalesContract, RaCContract } from '@cp-de/common';
import { useTranslation } from 'react-i18next';
import { LicensePlate } from '../../license-plate';
import { ProductInfo, ProductTable, DescriptionList, Button, Modal } from '@vwfs-bronson/bronson-react';
import { createVehicleData, GeneratedVehicleData } from './createVehicleData';
import { InfoIcon } from '@cp-shared-8/frontend-ui';

type VehicleDetailsAndImageProps = {
    contract?: Contract;
    reservationContract?: AutoAboContract | FourSalesContract | AoDContract | RaCContract;
};

export const VehicleDetailsAndImage: React.FC<VehicleDetailsAndImageProps> = ({ contract, reservationContract }) => {
    const { t, ready } = useTranslation('vehicle-details-and-image', { useSuspense: false });
    const [openModal, setOpenModal] = useState<boolean>(false);

    if (!contract && !reservationContract) {
        return null;
    }

    const vehicleData: GeneratedVehicleData = createVehicleData({ contract, reservationContract });

    const subTitle = '';
    let isVWFSAutoAbo = false;

    if (reservationContract?.contractType === ContractType.AUTO_ABO) {
        const product = (reservationContract as AutoAboContract).details.contractDetails?.product;
        if (product && ['001;i0008', '001;g0015'].includes(product.toLowerCase())) {
            isVWFSAutoAbo = true;
        }
    }

    const isAutoAboContrat = reservationContract?.contractType === ContractType.AUTO_ABO;
    const isRacContrat = reservationContract?.contractType === ContractType.RENT_A_CAR;
    const isAodOrRacContract = reservationContract?.contractType === ContractType.AUDI_ON_DEMAND || isRacContrat;
    const isFourSalesContract = reservationContract?.contractType === ContractType.FOUR_SALES;

    const getImageSrc = () => {
        const image = vehicleData?.imgSrc;
        const brand = vehicleData?.brand;
        const showDefault = !image;
        if (image) return image;
        if (showDefault) {
            switch (brand) {
                case 'Audi':
                    return '/contents/vwfs/audi_default.svg';
                case 'Seat':
                    return '/contents/vwfs/seat_default.svg';
                case 'SKODA':
                    return '/contents/vwfs/skoda_default.svg';
                case 'VW':
                    return '/contents/vwfs/vw_default.svg';
                default:
                    return '/contents/vwfs/vw_default.svg';
            }
        }
        return '';
    };

    return (
        <>
            {ready && (
                <ProductInfo
                    title={`${vehicleData?.brand} ${vehicleData?.modelName} ${vehicleData?.trim || ''}`}
                    titleSuffix={isAutoAboContrat ? <InfoIcon text={t('auto-abo.tooltip')} /> : null}
                    titleTagline={subTitle}
                    media={
                        <img
                            src={getImageSrc()}
                            alt={`${vehicleData?.brand} ${vehicleData?.modelName} ${vehicleData?.trim || ''}`}
                            style={
                                ['skoda', 'seat', 'cupra'].includes(vehicleData?.brand?.toLowerCase())
                                    ? {
                                          width: '400px',
                                          height: '400px',
                                          objectFit: 'cover',
                                      }
                                    : {}
                            }
                        />
                    }
                    className={'u-mb'}
                >
                    <div className={'u-mt-large u-hide@s'} />
                    <ProductTable>
                        <ProductTable.Section>
                            <DescriptionList split>
                                {!isAodOrRacContract && (
                                    <>
                                        <DescriptionList.Group termText={t('license-plate')}>
                                            <DescriptionList.Detail>
                                                <LicensePlate licensePlate={vehicleData?.licensePlate} />
                                            </DescriptionList.Detail>
                                        </DescriptionList.Group>
                                        <DescriptionList.Group termText={t('vin')}>
                                            <DescriptionList.Detail>{vehicleData?.vin}</DescriptionList.Detail>
                                        </DescriptionList.Group>
                                    </>
                                )}
                                <DescriptionList.Group termText={t('vehicle-details.model')}>
                                    <DescriptionList.Detail>{vehicleData?.modelName}</DescriptionList.Detail>
                                </DescriptionList.Group>
                                <DescriptionList.Group termText={t('vehicle-details.model-year')}>
                                    <DescriptionList.Detail>{vehicleData?.modelYear}</DescriptionList.Detail>
                                </DescriptionList.Group>
                                <DescriptionList.Group termText={t('vehicle-details.color')}>
                                    <DescriptionList.Detail>{vehicleData?.bodyColor}</DescriptionList.Detail>
                                </DescriptionList.Group>
                            </DescriptionList>
                        </ProductTable.Section>
                        <ProductTable.Section>
                            <DescriptionList split>
                                <DescriptionList.Group termText={t('vehicle-details.fuel-type')}>
                                    <DescriptionList.Detail>{vehicleData?.fuelType}</DescriptionList.Detail>
                                </DescriptionList.Group>
                                <DescriptionList.Group termText={t('vehicle-details.engine-power')}>
                                    <DescriptionList.Detail>{vehicleData?.enginePower}</DescriptionList.Detail>
                                </DescriptionList.Group>
                                <DescriptionList.Group termText={t('vehicle-details.transmission')}>
                                    <DescriptionList.Detail>{vehicleData?.transmission}</DescriptionList.Detail>
                                </DescriptionList.Group>
                            </DescriptionList>
                        </ProductTable.Section>
                        {!isAodOrRacContract && !isFourSalesContract && (
                            <ProductTable.Section>
                                <DescriptionList split>
                                    <DescriptionList.Group termText={t('vehicle-details.current-mileage.title')}>
                                        <DescriptionList.Detail
                                            action={
                                                <Button
                                                    icon="semantic-edit"
                                                    link
                                                    simple
                                                    onClick={() => setOpenModal(true)}
                                                />
                                            }
                                        >
                                            -- km
                                        </DescriptionList.Detail>
                                    </DescriptionList.Group>
                                </DescriptionList>
                            </ProductTable.Section>
                        )}
                    </ProductTable>
                </ProductInfo>
            )}
            <Modal shown={openModal} onClose={() => setOpenModal(false)} testId={'current-mileage-modal'}>
                {t('vehicle-details.current-mileage.text')}
            </Modal>
            {(isVWFSAutoAbo || isRacContrat) && <p>{isVWFSAutoAbo ? t('disclaimer') : t('rac.disclaimer')}</p>}
        </>
    );
};
