import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type ChangePaymentDateModalProps = {
    shown: boolean;
    errorStatus: string;
    isError: boolean;
    onConfirm: () => void;
    onClose: () => void;
};

export const testIds = {
    confirmModal: 'confirmModal',
};

export const ChangePaymentDateModal: React.FC<ChangePaymentDateModalProps> = ({
    shown,
    errorStatus,
    isError,
    onConfirm,
    onClose,
}) => {
    const { t } = useTranslation('change-payment-date');

    const modalText = () => {
        if (isError && errorStatus === '403') {
            return t('modal.error-403.text');
        } else if (isError) {
            return t('modal.error-other.text');
        } else {
            return t('modal.success.text');
        }
    };

    return (
        <Modal
            shown={shown}
            buttonConfirmText={t('modal.button')}
            onConfirm={onConfirm}
            onClose={onClose}
            status={isError ? 'error' : 'success'}
            testId={testIds.confirmModal}
            title={isError ? '' : t('modal.success.title')}
        >
            {modalText()}
        </Modal>
    );
};
