import {
    AutoAboContract,
    Contract,
    ContractType,
    ErrorMessages,
    formatAsFileSize,
    getOpenRequestEndpoint,
    maxFileSize,
    maxTotalFilesSize,
    OpenRequest,
    OpenRequestCategory,
    OpenRequestSubcategory,
    openRequestValidationSchema,
    Profile,
    UserType,
    validFileFormats,
} from '@cp-de/common';
import { Base64File } from '@cp-shared-8/apis';
import {
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    ValidatedInput,
    ValidatedSelect,
    ValidatedSelectItem,
    ValidatedTextarea,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Card, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from '../../navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getBase64 } from '../../../utils';
import { ContractSelect } from '../contract-select';
import { FileUpload } from '../file-upload';
import { MyRequestModal } from '../modal';
import { formFields } from './formFields';

export type MyRequestFormType = {
    email: string;
    contract: string;
    category: string;
    subCategory: string;
    message: string;
    files: File[];
};

type Category = {
    category: string;
    subCategories: string[];
};

type MyRequestUiProps = {
    myProfile?: Profile;
    contracts?: Contract[];
    aboContracts?: AutoAboContract[];
    isAod?: boolean;
    isRac?: boolean;
};

export const MyRequestUi: React.FC<MyRequestUiProps> = ({ myProfile, contracts, aboContracts, isAod, isRac }) => {
    const { t } = useTranslation('my-request');
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const errorMessages: ErrorMessages = {
        validRequiredEmail: t('validation.validRequiredEmail'),
        contractRequired: t('validation.contractRequired'),
        categoryRequired: t('validation.categoryRequired'),
        subCategoryRequired: t('validation.subCategoryRequired'),
        maxCharacters: t('validation.maxCharacters'),
        maxFiles: t('validation.file-upload.multiple'),
        fileType: '',
        maxFileSize: '',
        maxTotalSize: t('validation.file-upload.size', { size: formatAsFileSize(maxTotalFilesSize, 2) }),
    };

    if (!contracts && !aboContracts) {
        return null;
    }

    const contractNumber = new URLSearchParams(location.search).get('id') || '';
    const contractType = new URLSearchParams(location.search).get('type') || '';

    const initialValues: MyRequestFormType = {
        email: myProfile?.contactDetails?.email || '',
        contract:
            ['FINANCING', 'LEASING'].includes(contractType) &&
            contracts?.filter(contract => contract.contractNumber === contractNumber).length
                ? contractNumber
                : '',
        category:
            contractType === ContractType.FINANCING
                ? formFields.FINANCING[0].category
                : contractType === ContractType.LEASING
                ? formFields.LEASING[5].category
                : '',
        subCategory: contractType === ContractType.FINANCING ? formFields.FINANCING[0].subCategories[2] : '',
        message: '',
        files: [],
    };

    const getAvailableContracts = (): (Contract | AutoAboContract)[] => {
        return [...(aboContracts || []), ...(contracts || [])];
    };

    const goBackToDashboard = (): void => history.push(dashboardPagePath());

    const removeDoubleDot = (string: string): string => string.replace('..', '.');

    const camelToKebab = (string: string): string =>
        removeDoubleDot(string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase());

    const snakeToKebab = (string: string): string => string.replace(/_/g, '-').toLowerCase();

    const getContractType = (contractNumber: string): ContractType | 'NOT_FOUND' =>
        getAvailableContracts()?.find(item => item.contractNumber === contractNumber)?.contractType || 'NOT_FOUND';

    const getCategories = (contractNumber: string): ValidatedSelectItem[] =>
        (formFields[getContractType(contractNumber)] as Category[]).map((item: Category) => {
            return {
                label: t(
                    `form.fields.category.${snakeToKebab(getContractType(contractNumber))}.${camelToKebab(
                        item.category,
                    )}.label`,
                ),
                value: item.category,
            };
        }) || [];

    const getSubCategories = (contractNumber: string, category: string): ValidatedSelectItem[] =>
        (formFields[getContractType(contractNumber)] as Category[])
            .find(item => item.category === category)
            ?.subCategories.map(subCategory => {
                return {
                    label: t(
                        `form.fields.category.${snakeToKebab(getContractType(contractNumber))}.${camelToKebab(
                            `${category}.${subCategory}`,
                        )}.label`,
                    ),
                    value: subCategory,
                };
            }) || [];

    const mapFromValues = async (formValues: MyRequestFormType): Promise<OpenRequest> => {
        const { email, contract, category, subCategory, message, files } = formValues;
        const base64Files: Promise<Base64File>[] = files.map(file => getBase64(file));
        const fullNameArr = myProfile?.identity?.fullName.split(' ') || [];
        let nameWithoutTitle;

        if (fullNameArr.length === 3) {
            nameWithoutTitle = fullNameArr.slice(1);
        }

        const [name, surName] = nameWithoutTitle || fullNameArr;

        return Promise.all([...base64Files]).then(files => ({
            contactEmail: email,
            contractNumber: contract,
            contractType: getContractType(contract) as ContractType,
            category: category as OpenRequestCategory,
            subcategory: subCategory as OpenRequestSubcategory,
            message: message,
            files: files,
            clientName: name,
            clientSurname: surName,
        }));
    };

    const handleSubmit = async (formValues: MyRequestFormType): Promise<void> => {
        setLoading(true);
        setIsError(false);
        const body = await mapFromValues(formValues);
        const contractId = getAvailableContracts().find(x => x.contractNumber === body.contractNumber)?.contractId;
        if (!contractId) {
            setIsError(true);
            return;
        }

        const bodyContractTypeToUserType = (): UserType => {
            switch (body.contractType) {
                case 'AUTO_ABO':
                    return UserType.AUTO_ABO;
                default:
                    return UserType.STANDARD;
            }
        };

        const trackingCategoryAndSubcategory = `Category: ${formValues.category.toLowerCase()}  | Subcategory: ${formValues.subCategory.toLowerCase()}`;

        const openRequestEndpoint = getOpenRequestEndpoint(bodyContractTypeToUserType(), contractId);

        CpDataApi.post(openRequestEndpoint, body)
            .then(() => {
                setSelectedCategory(formValues.category);
                onSuccess(trackingCategoryAndSubcategory);
            })
            .catch(error => {
                setIsError(!!error);
                onError(trackingCategoryAndSubcategory);
            })
            .finally(() => {
                setLoading(false);
                setShowModal(true);
            });
    };

    const isMixedCustomer = isAod || isRac || (Array.isArray(aboContracts) && !!aboContracts.length);

    return (
        <>
            {isMixedCustomer && (
                <Notification
                    className="u-mb"
                    status={NotificationStatus.info}
                    text={t('mixed-customer-notification')}
                />
            )}
            {loading && <Spinner fullPage={true} />}
            <Card element={'article'}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={openRequestValidationSchema(errorMessages)}
                >
                    {({ values, setFieldValue, submitForm }): React.ReactNode => (
                        <Form onSubmit={e => preventSubmit(e)}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/1" s="1/1">
                                            <ValidatedInput
                                                name="email"
                                                className="u-text-left "
                                                label={t('form.fields.email.label')}
                                                inputMode={'email'}
                                                testId={'email'}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/1" s="1/1" className="u-text-left ">
                                            <ContractSelect
                                                labelText={t('form.fields.contract.label')}
                                                contracts={contracts}
                                                aboContracts={aboContracts}
                                                contractOnChange={(value): void => {
                                                    setFieldValue('category', '');
                                                    setFieldValue('subCategory', '');
                                                    setFieldValue('contract', value);
                                                }}
                                                contractNumber={values.contract}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                            {!!values.contract && (
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/1" s="1/1" className="u-text-left ">
                                                <ValidatedSelect
                                                    name="category"
                                                    testId="category"
                                                    label={t('form.fields.category.label')}
                                                    placeholder={t('form.fields.category.placeholder')}
                                                    selectItems={getCategories(values.contract)}
                                                    onChange={(value): void => {
                                                        setFieldValue('subCategory', '');
                                                        setFieldValue('category', value);
                                                    }}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                </Fieldset>
                            )}
                            {!!getSubCategories(values.contract, values.category).length && (
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/1" s="1/1" className="u-text-left ">
                                                <ValidatedSelect
                                                    name="subCategory"
                                                    label={t('form.fields.sub-category.label')}
                                                    placeholder={t('form.fields.sub-category.placeholder')}
                                                    selectItems={getSubCategories(values.contract, values.category)}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                </Fieldset>
                            )}
                            {!!values.category &&
                                (!!values.subCategory ||
                                    !getSubCategories(values.contract, values.category).length) && (
                                    <>
                                        <Fieldset>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item default="1/1" s="1/1" className="u-text-left ">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(
                                                                    `form.fields.category.${camelToKebab(
                                                                        `${snakeToKebab(
                                                                            getContractType(values.contract),
                                                                        )}.${values.category}.${
                                                                            values.subCategory
                                                                        }.description`,
                                                                    )}`,
                                                                ),
                                                            }}
                                                        />
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                        </Fieldset>
                                        <Fieldset>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item default="1/1" s="1/1" className="u-text-left ">
                                                        <ValidatedTextarea
                                                            enableMaxLength={true}
                                                            maxLength={3000}
                                                            name="message"
                                                            label={t('form.fields.message.label')}
                                                            placeholder={t('form.fields.message.placeholder')}
                                                        />
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                        </Fieldset>
                                        <Layout>
                                            <Layout.Item>
                                                <div>{t('file-upload-info')}</div>
                                            </Layout.Item>
                                            <Layout.Item>
                                                <FileUpload
                                                    name={'files'}
                                                    maxFileSizePerFile={maxFileSize}
                                                    maxFileSize={maxTotalFilesSize}
                                                    validFileFormats={validFileFormats}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </>
                                )}

                            <Fieldset>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            type="button"
                                            secondary
                                            onClick={goBackToDashboard}
                                            testId="cancelButton"
                                        >
                                            {t('form.go-back')}
                                        </Button>
                                        <Button onClick={submitForm} testId="submitButton" type="submit">
                                            {t('form.confirm')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Card>
            <MyRequestModal
                shown={showModal}
                error={isError}
                category={selectedCategory}
                onClose={(): void => setShowModal(false)}
                onConfirm={!isError ? goBackToDashboard : (): void => setShowModal(false)}
            />
        </>
    );
};
