import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';

import { HeycarMarketingCardUi } from './ui/HeycarMarketingCardUi';
import { useHeycarMarketingCard } from './useHeycarMarketingCard';

const HeycarMarketingCardWithHandlers = withLoadingAndNoConnectionHandler(HeycarMarketingCardUi);

export const HeycarMarketingCard: React.FC = () => {
    const { cmsContent: heyCarMarketingCard, isLoading, loadingError } = useHeycarMarketingCard();

    return (
        <HeycarMarketingCardWithHandlers
            isLoading={isLoading}
            heyCarMarketingCard={heyCarMarketingCard}
            hasError={!!loadingError}
        />
    );
};
