import React from 'react';
import { Button, Heading, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../../../../cp-xhr';
import {
    CustomerReservationDetails,
    DriverLicenseRequestFE,
    DriverLicenseResponse,
    getDriversLicenseCheckEndpoint,
} from '@cp-de/common';
import { AxiosResponse } from 'axios';

type DriverLicenseSectionProps = {
    customerDetails: CustomerReservationDetails;
    contractNumber?: string;
};

export const DriverLicenseSection: React.FC<DriverLicenseSectionProps> = ({ customerDetails, contractNumber }) => {
    const { t } = useTranslation('auto-abo-customer-details');

    if (!customerDetails && !contractNumber) {
        return null;
    }

    const handleCheckDriverLicense = () => {
        const { identity, addresses = [] } = customerDetails;

        if (!contractNumber) {
            return (window.location.href = '/dashboard?status=false');
        }

        const requestBody: DriverLicenseRequestFE = {
            data: {
                addresses: [
                    {
                        city: addresses[0].city,
                        countryCode: addresses[0].countryCode,
                        houseNumber: addresses[0].houseNumber,
                        street: addresses[0].street,
                        zipCode: addresses[0].zipCode,
                    },
                ],
                firstName: identity?.firstName,
                lastName: identity?.lastName,
                dateOfBirth: identity?.dateOfBirth,
            },
            contractNumber: contractNumber,
        };

        CpDataApi.post(getDriversLicenseCheckEndpoint(), requestBody)
            .then((res: AxiosResponse<DriverLicenseResponse>) => {
                const url = res.data.redirectUrl;
                window.location.href = url;
            })
            .catch(e => {
                if (e) {
                    window.location.href = '/dashboard?status=false';
                }
            });
    };

    return (
        <>
            <Layout>
                <Layout.Item default="1/2">
                    <Heading level="6" className="u-pt">
                        {t('driver-license-section.headline')}
                    </Heading>
                </Layout.Item>
                <Layout.Item default="1/2" className="u-text-right">
                    <Button
                        className="u-pt u-mb "
                        icon={'semantic-arrow-right'}
                        type="button"
                        link
                        secondary
                        small
                        onClick={handleCheckDriverLicense}
                    >
                        {t('driver-license-section.button')}
                    </Button>
                </Layout.Item>
                <Layout.Item default="1/1">
                    <Paragraph>{t('driver-license-section.text')}</Paragraph>
                </Layout.Item>
            </Layout>
        </>
    );
};
