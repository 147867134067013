import { AuthorityAddress } from '@cp-de/common';

export const LicensingAuthoritiesWithAllValues: AuthorityAddress[] = [
    {
        name: 'Mario Lüder Freier Handelsvertreter',
        street: 'Langhansstr.',
        houseNumber: '1',
        locality: 'Berlin',
        zipCode: '13086',
    },
    {
        name: 'Wolfgang Niegel Fahrschule',
        street: 'Kyllburger Weg',
        houseNumber: '36',
        locality: 'Berlin',
        zipCode: '13051',
    },
];
