import { AoDContract, AutoAboContract, Contract, ContractType, FourSalesContract, RaCContract } from '@cp-de/common';

type CombinedContractType = Contract | AutoAboContract | FourSalesContract | AoDContract | RaCContract;

export const convertBronsonCalendarDate = (date: string): Date => {
    const dateArr = date.split('.');
    const newDate = new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);
    return newDate;
};

export const contractFilter = (contracts: CombinedContractType[], type: ContractType): CombinedContractType[] => {
    if (Array.isArray(contracts) && contracts.length) {
        return contracts.filter(contract => contract.contractType === type);
    } else {
        return [];
    }
};

export const brandFilter = (contracts: CombinedContractType[], brands: string[]): CombinedContractType[] => {
    if (Array.isArray(contracts) && contracts.length) {
        return contracts.filter(contract => {
            if ('vehicle' in contract && contract.vehicle && contract.vehicle.carBrand) {
                return brands.includes(contract.vehicle.carBrand);
            } else if (
                'details' in contract &&
                contract.details &&
                contract.details.vehicleDetails &&
                contract.details.vehicleDetails.carBrand
            ) {
                return brands.includes(contract.details.vehicleDetails.carBrand);
            }
            return false;
        });
    }
    return contracts;
};

export const dateFilter = (
    contracts: CombinedContractType[],
    startDate: string,
    endDate: string,
): CombinedContractType[] => {
    return contracts.filter(contract => {
        if (startDate && !endDate) {
            const formattedStartDate = convertBronsonCalendarDate(startDate);
            const formattedContractStartDate = new Date(contract.startDate as string);
            return formattedStartDate <= formattedContractStartDate;
        } else if (!startDate && endDate && 'endDate' in contract) {
            const formattedEndDate = convertBronsonCalendarDate(endDate);
            const formattedContractEndDate = new Date(contract.endDate as string);
            return formattedContractEndDate <= formattedEndDate;
        } else if (startDate && endDate && 'endDate' in contract) {
            const formattedStartDate = convertBronsonCalendarDate(startDate);
            const formattedContractStartDate = new Date(contract.startDate as string);
            const formattedEndDate = convertBronsonCalendarDate(endDate);
            const formattedContractEndDate = new Date(contract.endDate as string);
            return formattedStartDate <= formattedContractStartDate && formattedContractEndDate <= formattedEndDate;
        }
        return false;
    });
};
