export function getPostboxDocumentsEndpoint(userType: string): string {
    return '/postbox-documents/' + userType;
}

export function getInboxDocumentsEndpoint(): string {
    return '/inbox-documents';
}

export function getSaveInboxConsentsEndpoint(): string {
    return '/inbox-consents';
}
