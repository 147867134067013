import { AddContractProductType } from '@cp-de/common';

export type AddContractValues = {
    lastName: string;
    birthDate: string;
    zipCode: string;
    contractNumber: string;
    product: AddContractProductType | undefined;
};

export const initialValues: AddContractValues = {
    lastName: '',
    birthDate: '',
    zipCode: '',
    contractNumber: '',
    product: undefined,
};
