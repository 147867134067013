import { LegalTerms, getLegalTermsEndpoint } from '@cp-de/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LegalTerms>({
    contentName: 'legalTerms',
    contentEndpoint: getLegalTermsEndpoint,
});

export default reducer;
export const fetchLegalTerms = fetchContent;
