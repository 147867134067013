import { License } from '@cp-de/common';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import React from 'react';
import frontendLicenses from './oss-licenses-fa.json';

import { ThirdPartyLicensesUi } from '../third-party-licenses/ui';
import { useThirdPartyLicenses } from '../third-party-licenses';

const FeatureAppLicensesWithHandlers = withLoadingAndNoConnectionHandler(ThirdPartyLicensesUi);

export const FeatureAppLicenses: React.FC = () => {
    const { cmsContent: thirdPartyLicenses, isLoading, loadingError } = useThirdPartyLicenses();
    const content: License[] = frontendLicenses as License[];

    return thirdPartyLicenses ? (
        <FeatureAppLicensesWithHandlers
            isLoading={isLoading}
            thirdPartyLicenses={{
                teaser: { ...thirdPartyLicenses.teaser, subTitle: thirdPartyLicenses.teaser.featureApp },
                content,
            }}
            hasError={!!loadingError}
        />
    ) : null;
};
