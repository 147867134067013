import { ConsentStatus } from '@cp-de/common';

export const ConsentWithAllValues: ConsentStatus = {
    usageAgreement: false,
    marketing: {
        title: 'Marketing consent title',
        text: 'Marketing consent text',
        disclaimer: 'Marketing consent disclaimer',
    },
    dataStorage: {
        title: 'Data storage consent title',
        text: 'Data storage consent text',
        disclaimer: 'Data storage consent disclaimer',
    },
    isFirstSeenMoreThenFourWeeksAgo: false,
};

export const ConsentWithTrue: ConsentStatus = {
    usageAgreement: true,
    marketing: {
        title: 'Marketing consent title',
        text: 'Marketing consent text',
        disclaimer: 'Marketing consent disclaimer',
    },
    dataStorage: {
        title: 'Data storage consent title',
        text: 'Data storage consent text',
        disclaimer: 'Data storage consent disclaimer',
    },
    isFirstSeenMoreThenFourWeeksAgo: false,
};
