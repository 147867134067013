/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from 'react';

import {
    AuthenticationProvider,
    AuthorizationFlowState,
    AutoLogout,
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
} from '@cp-shared-8/frontend-ui';

import {
    ConsentStatus,
    getConsentEndpoint,
    getRegistrationMappingEndpoint,
    getRegistrationOnlineFlagEndpoint,
    getUserRegistrationStatusEndpoint,
    RegistrationMappingResult,
    RegistrationStatus,
    UserRegistrationDSSResult,
    UserRegistrationsResult,
} from '@cp-de/common';
import {
    autoLogoutPath,
    connectionProblemPagePath,
    consentPagePath,
    loginChoiceOfferPagePath,
    loginChoicePagePath,
    postboxConsentPath,
    registrationPagePath,
} from '../components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { CenteredSpinner } from '../components/centered-spinner';
import { RedirectWithParams } from '../utils/redirectWithParams';
import { isDevMode } from 'utils/dev-utils';
import { isEqual } from 'lodash';
import { skipPostboxConsentsCheckStorageKey } from 'config';

const AuthenticationFactory = (loginRedirectionPath = loginChoicePagePath()) => {
    return createAuthenticationComponent({
        onMissingAuthentication: <RedirectWithParams to={loginRedirectionPath} />,
    });
};

const commonAuthorizationOptions = {
    onLoading: <CenteredSpinner />,
    onError: <RedirectWithParams to={connectionProblemPagePath()} />,
};

export interface AuthorizationResultData {
    isAuthorized: boolean;
    standard: UserRegistrationDSSResult;
    abo: RegistrationStatus;
    aod: RegistrationStatus;
    rac: RegistrationStatus;
    fourSales: RegistrationStatus;
    digitalOffer: RegistrationStatus;
    hookPageNeeded?: boolean;
}

const {
    Authorization: UserRegistrationAuthorization,
    useAuthorizationFlow: useRegistrationFlow,
    AuthorizationProvider: UserRegistrationProvider,
} = createAuthorization<AuthorizationResultData>({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: async () => {
        const [registrationResult, consentResult] = await Promise.all([
            CpDataApi.get<UserRegistrationsResult>(getUserRegistrationStatusEndpoint()),
            CpDataApi.get<ConsentStatus>(getConsentEndpoint()),
        ]);
        const { data: registrationData } = registrationResult;
        const {
            data: { usageAgreement },
        } = consentResult;

        const requests: Promise<unknown>[] = [];

        if (registrationData.standard.mappingNeeded && usageAgreement) {
            requests.push(CpDataApi.post<RegistrationMappingResult>(getRegistrationMappingEndpoint()));
        }

        if (registrationData.standard.onlineFlagNeeded && usageAgreement) {
            requests.push(CpDataApi.put<RegistrationMappingResult>(getRegistrationOnlineFlagEndpoint()));
        }

        await Promise.all(requests);

        const postboxHookNeeded =
            Object.values(registrationData.standard.postboxConsents || {}).some(consent => Boolean(consent)) &&
            window.localStorage.getItem(skipPostboxConsentsCheckStorageKey) !== 'true';

        return {
            isAuthorized:
                (registrationData.standard.isRegistered ||
                    registrationData.abo.isRegistered ||
                    registrationData.aod.isRegistered ||
                    registrationData.rac.isRegistered ||
                    registrationData.fourSales.isRegistered ||
                    registrationData.digitalOffer.isRegistered) &&
                !postboxHookNeeded,
            standard: registrationData.standard,
            abo: registrationData.abo,
            aod: registrationData.aod,
            rac: registrationData.rac,
            postboxConsents: registrationData.standard.postboxConsents,
            hookPageNeeded: postboxHookNeeded,
            fourSales: registrationData.fourSales,
            digitalOffer: registrationData.digitalOffer,
        };
    },
    // eslint-disable-next-line react/display-name
    onMissingAuthorization: (state: AuthorizationResultData) => {
        if (state.hookPageNeeded) {
            return <RedirectWithParams to={postboxConsentPath()} />;
        }
        return <RedirectWithParams to={registrationPagePath()} />;
    },
    ...commonAuthorizationOptions,
});

const {
    Authorization: UserConsentAuthorization,
    useAuthorizationFlow: useConsentFlow,
    AuthorizationProvider: UserConsentProvider,
} = createAuthorization({
    displayName: 'UserConsentAuthorization',
    authorizationDataProvider: async () => {
        const {
            data: { usageAgreement, isFirstSeenMoreThenFourWeeksAgo, isFirstLogin },
        } = await CpDataApi.get<ConsentStatus>(getConsentEndpoint());

        let isAuthorized: boolean;

        if (isFirstSeenMoreThenFourWeeksAgo || isFirstLogin) {
            isAuthorized = usageAgreement;
        } else {
            isAuthorized = true;
        }

        return Promise.resolve({ isAuthorized });
    },
    onMissingAuthorization: <RedirectWithParams to={consentPagePath()} />,
    ...commonAuthorizationOptions,
});

const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', AuthenticationFactory());
const LoginOfferAuthenticatedRoute = createDecoratedRoute(
    'LoginOfferAuthenticatedRoute',
    AuthenticationFactory(loginChoiceOfferPagePath()),
);
const AuthorizedRoute = createDecoratedRoute(
    'AuthorizedRoute',
    AuthenticationFactory(),
    UserRegistrationAuthorization,
    UserConsentAuthorization,
);

const AuthorizedithoutConsentRoute = createDecoratedRoute(
    'AuthorizedithoutConsentRoute',
    AuthenticationFactory(),
    UserRegistrationAuthorization,
);

const AuthProviders: FC = ({ children }) => (
    <AuthenticationProvider loadingComponent={<CenteredSpinner />}>
        <UserRegistrationProvider>
            <UserConsentProvider>
                <AutoLogout
                    idleTimeInMinutes={isDevMode() ? 99999 : 5}
                    redirectUri={window.location.origin + autoLogoutPath()}
                >
                    {children}
                </AutoLogout>
            </UserConsentProvider>
        </UserRegistrationProvider>
    </AuthenticationProvider>
);

const isOnlyDigitalOfferUser = (registrationState: AuthorizationFlowState<AuthorizationResultData>) => {
    const expectedStructure = {
        isAuthorized: true,
        standard: { isRegistered: false },
        abo: { isRegistered: false },
        aod: { isRegistered: false },
        rac: { isRegistered: false },
        fourSales: { isRegistered: false },
        digitalOffer: { isRegistered: true },
    };

    return isEqual(registrationState, expectedStructure);
};

export {
    AuthenticatedRoute,
    AuthorizedRoute,
    AuthorizedithoutConsentRoute,
    LoginOfferAuthenticatedRoute,
    useRegistrationFlow,
    useConsentFlow,
    AuthProviders,
    isOnlyDigitalOfferUser,
};
