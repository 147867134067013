export type InitialValues = {
    filterStartDate: string;
    filterEndDate: string;
    filterBrands: string[];
    filterContract: string;
};

export const initialValues = {
    filterStartDate: '',
    filterEndDate: '',
    filterBrands: [] as string[],
    filterContract: '',
};
