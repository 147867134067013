import React from 'react';
import { Hr } from '@vwfs-bronson/bronson-react';
import { CustomerReservationDetails } from '@cp-de/common';
import { IdentitySection } from './identity-section';
import { ContactSection } from './contact-section';
import { AddressesSection } from './addresses-section';

export const ReservationContractParties: React.FC<{ customerDetails?: CustomerReservationDetails }> = ({
    customerDetails,
}) => {
    const { identity, addresses, contactDetails } = customerDetails || {};

    return (
        <>
            <IdentitySection identity={identity} />
            <Hr className="u-mb-small" />
            <AddressesSection addresses={addresses} />
            <Hr className="u-mb-small" />
            <ContactSection contact={contactDetails} />
        </>
    );
};
