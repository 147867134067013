import { AutoAboContract, AutoAboContractDetails, ContractType, ReservationContractStatus } from '@cp-de/common';

export const AutoAboContractDetailsWithDeliveryAddress: AutoAboContractDetails = {
    status: ReservationContractStatus.SUBSCRIPTION_STARTED,
    startDate: '2020-05-11',
    endDate: '2022-07-11',
    contractCancellationNoticeDate: '2022-08-21',
    product: 'Product',
    vehicleClass: 'Vehicle Class',
    servicePackageLabels: ['Package 1', 'Package 2'],
    deliveryAddress: {
        city: 'Dresden',
        houseNumber: '5',
        street: 'Franklinstraße',
        zipCode: '01067',
    },
};

export const AutoAboContractDetailsWithInactiveStatus: AutoAboContractDetails = {
    ...AutoAboContractDetailsWithDeliveryAddress,
    status: ReservationContractStatus.SUBSCRIPTION_CANCELLED,
};

export const AutoAboContractDetailsWithPickupAddress: AutoAboContractDetails = {
    startDate: '2020-05-11',
    endDate: '2022-07-11',
    contractCancellationNoticeDate: '2022-08-21',
    product: 'Product',
    vehicleClass: 'Vehicle Class',
    servicePackageLabels: ['Package 1', 'Package 2'],
    pickupLocation: 'Pickup Location Name',
    pickupAddress: {
        city: 'Berlin',
        houseNumber: '5',
        street: 'Franklinstraße',
        zipCode: '10587',
    },
};

export const AutoAboContractDetailsWithEmptyAddress: AutoAboContractDetails = {
    ...AutoAboContractDetailsWithPickupAddress,
    pickupAddress: {},
};

export const AutoAboContractDetailsWithCity: AutoAboContractDetails = {
    ...AutoAboContractDetailsWithPickupAddress,
    pickupAddress: {
        city: 'Berlin',
    },
};

export const AutoAboContractDetailsWithHouseNumber: AutoAboContractDetails = {
    ...AutoAboContractDetailsWithPickupAddress,
    pickupLocation: undefined,
    deliveryAddress: {
        houseNumber: '7',
    },
};

export const AutoAboContractDetailsWithMissingValues: AutoAboContractDetails = {
    startDate: '2020-05-11',
};

export const AutoAboContractDetailsWithAllValues = AutoAboContractDetailsWithDeliveryAddress;

export const Contract: AutoAboContract = {
    contractId: 'WywGxNn63XBnL2f_BEgm8b8rWD7N-UnTcv7rXxtxJGI',
    contractNumber: '1234567890',
    licensePlate: 'GD908111',
    monthlyPayment: '535.16',
    monthlyMileage: 41,
    minimumContractPeriod: '3',
    startDate: '2022-08-30T22:00:00.000Z',
    contractType: ContractType.AUTO_ABO,
    details: {
        contractDetails: {
            product: '001;G0015',
            startDate: '2022-08-31',
            endDate: '2022-09-16',
            vehicleClass: 'VWFS AutoAbo ID.4',
            status: ReservationContractStatus.SUBSCRIPTION_RESERVATION,
        },
        vehicleDetails: {
            registrationNumber: 'GD908111',
            vin: 'VIN1233333',
            carBrand: 'Audi',
            carModel: 'A3',
            bodyColorName: 'grey',
            bodyColorFinish: 'shine',
            trimValue: 'Attraction',
            engineSizeValue: '2.0',
            engineTechnology: 'TDI',
            enginePowerValue: '110',
            enginePowerUnit: 'kW',
            fuelType: 'Diesel',
            modelYear: 2006,
            transmission: 'automatic',
            carImage: 'http://foto123.jpg',
        },
    },
    isInReservation: false,
    additionalProducts: [
        {
            code: 'CDW_NORMAL',
            codeFamily: 'CDW',
            isSelected: true,
            name: 'CDW_0',
            qualifiers: [
                {
                    id: 'insurance_text',
                    stringValue: '123',
                },
            ],
        },
    ],
};
