import {
    BundledProductsDetails,
    EligibleForMileageChangeType,
    FinancialDetails,
    getChangeIbanEndpoint,
    getChangeMileageEndpoint,
} from '@cp-de/common';

export const FollowUpGuaranty: BundledProductsDetails = {
    extendedProductId: '67',
    productId: 'FollowUpGuaranty',
    productName: 'Anschlussgarantie',
};

export const MellowInsurance: BundledProductsDetails = {
    extendedProductId: '1',
    productId: 'MellowInsurance',
    productName: 'Reifenversicherung',
};

export const FollowUpGuarantyWithoutIdAndName: BundledProductsDetails = {
    extendedProductId: '67',
};

export const FinancialDetailsWithAllValues: FinancialDetails = {
    additionalProducts: [FollowUpGuaranty, MellowInsurance],
    annualPercentageRate: 0.99,
    balloonInstalment: 123456.56,
    contractDuration: 48,
    contractExceedanceIndicator: false,
    contractExtensionEnd: '2020-04-01',
    contractExtensionDuration: 2,
    downPayment: 2351.03,
    endDate: '2030-02-01',
    iban: 'DE75512108001245126199',
    invoiceBegin: '2015-02-25',
    nominalAmount: 22490.8,
    pendingCapital: 400,
    startDate: '2015-11-10',
    yearlyMileage: 10,
    eligibleForIbanChange: true,
    eligibleForMileageChange: EligibleForMileageChangeType.YES,
    changeMileageOptions: [10, 20, 30],
    _links: {
        changeIban: getChangeIbanEndpoint('contractId'),
        changeMileage: getChangeMileageEndpoint('contractId'),
    },
};

export const FinancialDetailsWithAllValuesButZeroMileage: FinancialDetails = {
    additionalProducts: [FollowUpGuaranty, MellowInsurance],
    annualPercentageRate: 0.99,
    balloonInstalment: 123456.56,
    contractDuration: 48,
    contractExceedanceIndicator: false,
    contractExtensionEnd: '2020-04-01',
    contractExtensionDuration: 2,
    downPayment: 2351.03,
    endDate: '2030-02-01',
    iban: 'DE75512108001245126199',
    invoiceBegin: '2015-02-25',
    nominalAmount: 22490.8,
    pendingCapital: 400,
    startDate: '2015-11-10',
    yearlyMileage: 0,
    eligibleForIbanChange: true,
    eligibleForMileageChange: EligibleForMileageChangeType.YES,
    changeMileageOptions: [10, 20, 30],
    _links: {
        changeIban: getChangeIbanEndpoint('contractId'),
        changeMileage: getChangeMileageEndpoint('contractId'),
    },
};

export const FinancialDetailsWithoutKBS: FinancialDetails = {
    additionalProducts: [FollowUpGuarantyWithoutIdAndName],
    annualPercentageRate: 0.99,
    balloonInstalment: 123456.56,
    contractDuration: 48,
    contractExceedanceIndicator: false,
    contractExtensionEnd: '2020-04-01',
    contractExtensionDuration: 2,
    downPayment: 2351.03,
    endDate: '2030-02-01',
    iban: 'DE75512108001245126199',
    invoiceBegin: '2015-02-25',
    nominalAmount: 22490.8,
    pendingCapital: 400,
    startDate: '2015-11-10',
    yearlyMileage: 75000,
    eligibleForIbanChange: true,
    _links: {
        changeIban: getChangeIbanEndpoint('contractId'),
    },
};
