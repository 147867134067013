import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Tag } from '@vwfs-bronson/bronson-react';
import {
    AoDContract,
    formatAsCurrency,
    formatAsDate,
    formatAsDistance,
    RaCContract,
    ReservationContractStatus,
} from '@cp-de/common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-8/frontend-ui';
import { LicensePlate } from '../../../../license-plate';
import { ReservationDetails } from '../reservation-details/ReservationDetails';
import { confirmVehicleDetailsComposition } from '../../../utils';
import { VehicleDetailsAndImage } from '../../../vehicle-details-and-image/VehicleDetailsAndImage';

export const ReservationContractCard: React.FC<{
    contract: AoDContract | RaCContract;
    isVehicleDetailsAndImageHidden?: boolean;
}> = ({ contract, isVehicleDetailsAndImageHidden }) => {
    const { t } = useTranslation('reservation-contract');

    if (!contract) {
        return null;
    }

    const {
        details: { vehicleDetails },
        price,
        contractMileage,
        unlimitedMileage,
        startDate,
        startTime,
        contractNumber,
        contractStatus,
    } = contract;

    const { registrationNumber } = vehicleDetails || {};

    const licensePlateComponent = <LicensePlate licensePlate={registrationNumber} />;

    const getContractStatus = () => {
        const prefix = 'contract-status';
        switch (contractStatus) {
            case ReservationContractStatus.RENT_CANCELLED:
                return t(`${prefix}.rent-cancelled`);
            case ReservationContractStatus.RENT_CLOSED:
                return t(`${prefix}.rent-closed`);
            case ReservationContractStatus.RENT_STARTED:
                return t(`${prefix}.rent-started`);
            case ReservationContractStatus.RENT_RESERVATION:
                return t(`${prefix}.rent-reservation`);
            default:
                return '';
        }
    };

    const contractSummaryItems: Array<ContractSummaryItemProps> = [
        {
            label: t('contract-mileage'),
            value: unlimitedMileage ? 'unbegrenzt' : contractMileage ? formatAsDistance(contractMileage) : '',
            testClass: 'monthly-mileage',
        },
        {
            label: t('price'),
            value: price ? formatAsCurrency(Number(price)) : '',
            testClass: 'total-amount',
        },
        {
            label: t('start-date'),
            value: startDate ? formatAsDate(startDate) + `${startTime ? ', ' + startTime?.slice(0, -3) : ''}` : '',
            testClass: 'start-date',
        },
    ].filter(item => !!item.value);

    const contractSummary = <ContractSummary items={contractSummaryItems} />;
    const title = contract.contractType === 'AUDI_ON_DEMAND' ? t('title.aod') : t('title.rac');
    const isAodOrRacContract = ['AUDI_ON_DEMAND', 'RENT_A_CAR'].includes(contract.contractType);
    const cardContentShort = isAodOrRacContract ? (
        <Tag>{getContractStatus()}</Tag>
    ) : (
        registrationNumber && licensePlateComponent
    );

    return (
        <>
            {!isVehicleDetailsAndImageHidden && confirmVehicleDetailsComposition(contract?.details?.vehicleDetails) && (
                <VehicleDetailsAndImage reservationContract={contract} />
            )}
            <Card
                element="article"
                expandable
                className="u-mb"
                title={title}
                subtitle={contractNumber}
                contentShort={cardContentShort}
                contentLarge={<ReservationDetails contract={contract} />}
            >
                {contractSummary}
            </Card>
        </>
    );
};
