import React, { useState } from 'react';
import { ChangeNameForm } from '../../../../../../my-profile/identity-section/change-name-form';
import {
    IdentityChangeFormValues,
    initialValues,
} from '../../../../../../my-profile/identity-section/change-name-form/validation';

export type EditViewProps = {
    setShowEditSuccessMessage: (arg0: boolean) => void;
    onSuccess: () => void;
    backToNonEditMode: () => void;
};

export const EditView: React.FC<EditViewProps> = ({ setShowEditSuccessMessage, onSuccess, backToNonEditMode }) => {
    const [formValues, setFormValues] = useState<IdentityChangeFormValues>(initialValues);

    return (
        <ChangeNameForm
            isContractView={true}
            isAbo={true}
            initialValues={formValues}
            onSubmit={setFormValues}
            onSubmitSuccess={(): void => {
                setShowEditSuccessMessage(true);
                onSuccess();
                backToNonEditMode();
                setFormValues(initialValues);
            }}
            onCancel={(currentFormValues: IdentityChangeFormValues): void => {
                setFormValues(currentFormValues);
                backToNonEditMode();
            }}
        />
    );
};
