import { Base64File } from '@cp-shared-8/apis';
import { ContractType } from './contracts/common-contract';

export type OpenRequest = {
    contactEmail: string;
    contractNumber: string;
    contractType: ContractType;
    category: OpenRequestCategory;
    subcategory?: OpenRequestSubcategory;
    message: string;
    files: Base64File[];
    clientName: string;
    clientSurname: string;
};

export enum OpenRequestCategory {
    VEHICLE_REGISTRATION_DOCUMENT = 'vehicleRegistrationDocument',
    CREDIT_INSURANCE = 'creditInsurance',
    DAMAGE = 'damage',
    INCOMING_CONTRACT_PAYMENT = 'incomingContractPayment',
    END_RATE = 'endRate',
    PERSONAL_DATA_CHANGE = 'personalDataChange',
    MISCELLANEOUS = 'miscellaneous',
    CUSTOMER_PORTAL_INCIDENT = 'customerPortalIncident',
    CONTRACT_RENEWAL = 'contractRenewal',
    MILEAGE_CHANGE = 'mileageChange',
    EARLY_TERMINATION = 'earlyTermination',
    CAR_RETURN_REDUCE_VALUE = 'carReturnReduceValue',
    REQUEST_DOCUMENTS = 'requestDocuments',
}

export enum OpenRequestSubcategory {
    VEHICLE_REGISTRATION_DOCUMENT_MISSING = 'vehicleRegistrationDocumentMissing',
    FURTHER_OWNER_CHANGE_INQUIRY = 'furtherOwnerChangeInquiry',
    MISCELLANEOUS_QUESTION = 'miscellaneousQuestion',
    PROPERTY_DAMAGE_STATE_MESSAGE = 'propertyDamageStateMessage',
    FURTHER_INCOMING_PAYMENT_INQUIRY = 'furtherIncomingPaymentInquiry',
    END_RATE_PAID_BY_DEALER = 'endRatePaidByDealer',
    END_RATE_TO_BE_FINANCED = 'endRateToBeFinanced',
    PHONE_NUMBER_CHANGE = 'phoneNumberChange',
    EMAIL_CHANGE = 'emailChange',
}
