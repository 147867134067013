import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { useUsageAgreement } from '../usage-agreement';
import { ConsentUi } from './ui';
import { useConsent } from './useConsent';

const ConsentWithHandlers = withLoadingAndNoConnectionHandler(ConsentUi);

export const Consent: React.FC = () => {
    const { data: consent, isLoading: isLoadingConsent, loadingError: loadingErrorConsent } = useConsent();

    const {
        cmsContent: usageAgreement,
        isLoading: isLoadingUsageAgreement,
        loadingError: loadingErrorUsageAgreement,
    } = useUsageAgreement();

    const isLoading = isLoadingUsageAgreement || isLoadingConsent;
    const loadingError = !!loadingErrorUsageAgreement || !!loadingErrorConsent;

    return (
        <ConsentWithHandlers
            isLoading={isLoading}
            usageAgreement={usageAgreement?.content}
            consent={consent}
            hasError={!!loadingError}
        />
    );
};
