import { useTranslation } from 'react-i18next';
import { Modal } from '@vwfs-bronson/bronson-react';
import React, { useState, useEffect } from 'react';
import { testIds } from '../../../../../../arrear-payment/modal';
import { getUrlSearchParams } from '../../../../../../../utils/urlSearchParams';

export const DriverLicenseCheckSuccessModal: React.FC = () => {
    const { t } = useTranslation('auto-abo-customer-details');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        const statusUrl = getUrlSearchParams().get('status') || undefined;
        switch (statusUrl) {
            case 'true':
                setShowModal(true);
                setIsError(false);
                break;
            case 'false':
                setShowModal(true);
                setIsError(true);
                break;
            default:
                setShowModal(false);
        }
    }, []);

    return (
        <Modal
            shown={showModal}
            buttonConfirmText={t('modal.success.button')}
            onConfirm={() => setShowModal(!showModal)}
            onClose={() => setShowModal(!showModal)}
            status={isError ? 'error' : 'success'}
            testId={testIds.confirmModal}
            title={isError ? t('modal.error.title') : t('modal.success.title')}
        >
            {isError ? t('modal.error.text') : t('modal.success.text')}
        </Modal>
    );
};
