import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type MyRequestModalProps = {
    shown: boolean;
    error: boolean;
    category: string;
    onConfirm: () => void;
    onClose: () => void;
};

export const testIds = {
    confirmModal: 'confirmModal',
};

export const MyRequestModal: React.FC<MyRequestModalProps> = ({ shown, error, category, onConfirm, onClose }) => {
    const { t } = useTranslation('my-request');

    const getSuccessText = (category: string) => {
        return category !== 'requestDocuments' ? t('modal.success.text') : t('modal.success.requestDocumentsText');
    };

    return (
        <Modal
            shown={shown}
            buttonConfirmText={t('modal.button')}
            onConfirm={onConfirm}
            onClose={onClose}
            status={error ? 'error' : 'success'}
            testId={testIds.confirmModal}
            title={error ? t('modal.error.title') : t('modal.success.title')}
        >
            {error ? t('modal.error.text') : getSuccessText(category)}
        </Modal>
    );
};
