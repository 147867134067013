import React from 'react';
import { AutoAboContractDetails } from './contract-details';
import { AutoAboCustomerDetails } from './customer-details';
import { Accordion } from '@vwfs-bronson/bronson-react';
import { AutoAboContract } from '@cp-de/common';
import { useTranslation } from 'react-i18next';
import { ContractActionItemType, ContractActions } from '@cp-shared-8/frontend-ui';
// import { PaymentMethodUi } from './payment-method';
import { compact } from 'lodash';

type ContractDetailsUiProps = {
    contract: AutoAboContract;
    contractNumber?: string;
};

export const AutoAboDetails: React.FC<ContractDetailsUiProps> = ({ contract, contractNumber }) => {
    const { t, ready } = useTranslation(
        ['auto-abo-contracts', 'auto-abo-contract-details', 'auto-abo-customer-details'],
        { useSuspense: false },
    );

    const {
        details: { customerDetails },
        cancellationDate,
        cancellationNoticeDate,
    } = contract;
    const openCancelContract = (): void => {
        window.open(t('auto-abo-contracts:contract-actions.cancel-contract.link'));
    };
    const actionItems: ContractActionItemType[] = compact([
        !(cancellationDate || cancellationNoticeDate) && {
            iconName: 'semantic-crossed-out',
            onClick: openCancelContract,
            label: t('auto-abo-contracts:contract-actions.cancel-contract.label'),
        },
    ]);
    //Hardcoded for POC (CO-16351), when payment service is updated, we'll get the data from it
    // const paymentSerieId = 'PaymentSeries-906eb244-2dce-4311-b4e9-bf754b9eccc1';
    return (
        <>
            {ready && (
                <Accordion>
                    <ContractActions contractActionItems={actionItems} className="u-mt-none u-mb" />
                    <Accordion.Item title={t('auto-abo-contract-details:headline')} className={'u-mt'}>
                        <AutoAboContractDetails contract={contract} />
                    </Accordion.Item>
                    <Accordion.Item title={t('auto-abo-customer-details:headline')}>
                        <AutoAboCustomerDetails customerDetails={customerDetails} contractNumber={contractNumber} />
                    </Accordion.Item>
                    {/* {paymentSerieId && (
                        <Accordion.Item title={t('contracts:payment-method.headline')}>
                            <PaymentMethodUi paymentSerieId={paymentSerieId} />
                        </Accordion.Item>
                    )} */}
                </Accordion>
            )}
        </>
    );
};
