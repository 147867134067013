import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { DigitalOfferPage as DigitalOfferPageComponent } from '../../components/digital-offer-page/DigitalOfferPage';

export const DigitalOfferPage: React.FC = () => {
    return (
        <ContentSection pageWrapSize="medium">
            <DigitalOfferPageComponent />
        </ContentSection>
    );
};
