import { Contract } from '@cp-de/common';

export const getContractCategoryTranslationKey = (contract: Contract) => {
    if (!contract.productType) {
        return '';
    }
    const productTypeTranslationKey = contract.productType
        .toString()
        .toLowerCase()
        .replace(/_/g, '-');

    return `product-types.${productTypeTranslationKey}`;
};
