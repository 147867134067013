import React, { useCallback } from 'react';
import { AddressChange } from '@cp-de/common';
import { Combobox, TileSelect, TileSelectGroup } from '@vwfs-bronson/bronson-react';
import { ComboboxOption } from 'types/bronson-react';

export type AddressSelectComponentProps = {
    addresses: AddressChange[];
    onSelect: (selected: AddressChange) => void;
};

export const testIds = {
    customSelectItem: 'customSelectItem',
    tileSelectItem: 'tileSelectItem',
};

export const AddressSelectComponent: React.FC<AddressSelectComponentProps> = ({ addresses, onSelect }) => {
    const handleOnChange = useCallback(
        (selected: ComboboxOption[]): void => {
            if (Array.isArray(selected) && selected.length) {
                onSelect(addresses[parseInt(selected[0].key)]);
            }
        },
        [onSelect, addresses],
    );
    if (addresses.length > 5) {
        return (
            <Combobox onChange={handleOnChange}>
                {addresses.map((address, index) => (
                    <Combobox.Item
                        key={`address_${index}`}
                        testId={testIds.customSelectItem + index}
                        optionKey={index.toString()}
                        optionValue={`${address.street} ${address.houseNumber}, ${address.zipCode} ${address.city}`}
                    >
                        <div>{`${address.street} ${address.houseNumber}, ${address.zipCode} ${address.city}`}</div>
                    </Combobox.Item>
                ))}
            </Combobox>
        );
    }
    return (
        <TileSelectGroup layoutItemClassName={'u-1/3  u-1/3@l  u-1/1@s'}>
            {addresses.map((address, index) => (
                <TileSelect
                    inputType="radio"
                    radioGroupName="tile-select-group"
                    key={`address_${index}`}
                    defaultChecked={index === 0}
                    testId={testIds.tileSelectItem + index}
                    onClick={(): void => {
                        onSelect(addresses[index]);
                    }}
                >
                    <div className={'u-text-left'}>
                        <div>
                            {address.street} {address.houseNumber}
                        </div>
                        <div>
                            {address.zipCode} {address.city}
                        </div>
                    </div>
                </TileSelect>
            ))}
        </TileSelectGroup>
    );
};
