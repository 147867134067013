import { useMemo, useState } from 'react';
import {
    Registration,
    CustomerRegistrationError,
    TooManyRequestsError,
    getRegistrationEndpoint,
    MultipleRegistrationError,
} from '@cp-de/common';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-8/common-utilities';
import { CpDataApi } from 'cp-xhr';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';

type RegistrationSubmissionProps = {
    submit: (values: Registration, product: string) => void;
};

type RegistrationRequestProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<
        CustomerRegistrationError | TooManyRequestsError | MultipleRegistrationError
    >;
};

export const useRegistrationSubmission = (
    onSuccess: () => void,
): RegistrationSubmissionProps & RegistrationRequestProps => {
    const [registrationRequest, setRegistrationRequest] = useState<RegistrationRequestProps>({
        isLoading: false,
        errorCode: undefined,
    });
    const { onAction: trackOnError } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');
    const { onAction: trackOnSuccess } = useAnalyticsActionTracker('onConfirmIdentiyAuthSuccess');

    const submit = useMemo(
        () => (values: Registration, product: string): void => {
            setRegistrationRequest({ errorCode: undefined, isLoading: true });
            CpDataApi.post(getRegistrationEndpoint(product), values)
                .then(() => {
                    setRegistrationRequest({
                        errorCode: undefined,
                        isLoading: false,
                    });
                    onSuccess();
                    trackOnSuccess();
                })
                .catch(error => {
                    let errorCode = parseErrorResponse<
                        CustomerRegistrationError | TooManyRequestsError | MultipleRegistrationError
                    >(error).code;
                    if (error.response?.data.message === 'ThrottlerException: Too Many Requests') {
                        errorCode = 'TOO_MANY_REQUESTS';
                    }
                    setRegistrationRequest({
                        isLoading: false,
                        errorCode,
                    });
                    trackOnError();
                });
        },
        [onSuccess, trackOnSuccess, trackOnError],
    );
    return { submit, ...registrationRequest };
};
