import React from 'react';
import { TileSelect, Layout, Heading, ErrorMessage } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type ApprovalSelectOption = 'repairApproval' | 'furtherApprovals';

type ApprovalSelectionProps = {
    updateTile: (selection: ApprovalSelectOption) => void;
    selectedTile?: ApprovalSelectOption;
    isError?: boolean;
};

export const ApprovalSelection: React.FC<ApprovalSelectionProps> = ({ updateTile, selectedTile, isError }) => {
    const { t } = useTranslation('repair-approval');

    return (
        <Layout center>
            <Heading className="u-mt-xlarge" level={4}>
                {t('step1.headline')}
            </Heading>
            <Layout.Item default="1/3" m={'1/1'}>
                <TileSelect
                    title={t('step1.box1.title')}
                    inputType="radio"
                    value="repairApproval"
                    radioGroupName="radioGroup1"
                    checked={selectedTile === 'repairApproval'}
                    onClick={() => updateTile('repairApproval')}
                >
                    {t('step1.box1.content')}
                </TileSelect>
            </Layout.Item>
            <Layout.Item default="1/3" m={'1/1'}>
                <TileSelect
                    title={t('step1.box2.title')}
                    inputType="radio"
                    value="furtherApprovals"
                    radioGroupName="radioGroup1"
                    checked={selectedTile === 'furtherApprovals'}
                    onClick={() => updateTile('furtherApprovals')}
                >
                    {t('step1.box2.content')}
                </TileSelect>
            </Layout.Item>
            {isError && (
                <Layout.Item className={'u-mv-small u-text-center'} default="1/1" m={'1/1'}>
                    <ErrorMessage>{t('step1.error.message')}</ErrorMessage>
                </Layout.Item>
            )}
        </Layout>
    );
};
