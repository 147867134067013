import React from 'react';
import { Layout, Logo } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export const RegistrationHeader: React.FC = () => {
    const { t } = useTranslation('registration');
    return (
        <Layout className={'u-mt-large'}>
            <Layout.Item>
                <Logo className={'u-block-center'} />
            </Layout.Item>
            <Layout.Item className={'u-mb u-text-center'}>
                <h1>{t('header.title')}</h1>
                <p>{t('header.info-text-1')}</p>
                <p>{t('header.info-text-2')}</p>
                <p>{t('header.info-text-3')}</p>
            </Layout.Item>
        </Layout>
    );
};
