import React from 'react';
import { Modal, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type RepairApprovalModalProps = {
    shown: boolean;
    error: boolean;
    onConfirm: () => void;
    onClose: () => void;
    onDownload: () => void;
};

export const RepairApprovalModal: React.FC<RepairApprovalModalProps> = ({
    shown,
    onConfirm,
    onClose,
    onDownload,
    error,
}) => {
    const { t } = useTranslation('repair-approval');

    return (
        <Modal
            shown={shown}
            onClose={onClose}
            testId={'repairApproval.confirmModal'}
            status={error ? 'error' : 'success'}
            title={error ? t('modal.error.title') : t('modal.title')}
            buttonCancelText={t('modal.ok-button')}
            onCancel={onConfirm}
        >
            {error ? (
                t('modal.error.text')
            ) : (
                <>
                    <div dangerouslySetInnerHTML={{ __html: t('modal.text') }} />
                    <Button
                        link
                        secondary
                        simple
                        icon="download"
                        iconReversed
                        className="u-mt-small"
                        onClick={onDownload}
                    >
                        {t('modal.download-button')}
                    </Button>
                </>
            )}
        </Modal>
    );
};
