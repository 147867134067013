export interface CommonContract {
    /**
     * contractId - encrypted reservationId as displayed in urls in the FE
     */
    contractId: string;
    /**
     * contractNumber: reservationNumber for Reservation contract, contractId for Standard contract
     */
    contractNumber: string;
}

export enum ContractType {
    FINANCING = 'FINANCING',
    LEASING = 'LEASING',
    SERVICE_MANAGEMENT = 'SERVICE_MANAGEMENT',
    AUTO_ABO = 'AUTO_ABO',
    AUDI_ON_DEMAND = 'AUDI_ON_DEMAND',
    RENT_A_CAR = 'RENT_A_CAR',
    FOUR_SALES = 'FOUR_SALES',
}
