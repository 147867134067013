import React from 'react';
import { FieldProps } from 'formik';
import { ButtonGroup } from '@vwfs-bronson/bronson-react';

type BrandInputProps = {
    index: number;
    brand: string;
    selected: string[];
};

export const FilterBrand: React.FC<BrandInputProps & FieldProps> = ({ field, index, brand, selected }) => (
    <ButtonGroup.Item index={index.toString()} {...field} value={brand} checked={selected.includes(brand)}>
        {brand}
    </ButtonGroup.Item>
);
