export const getCategoryLabel = (category?: string): string => {
    let categoryLabel = '';
    switch (category) {
        case 'AccountStatementCopy':
            categoryLabel = 'account-statement-copy';
            break;
        case 'CopyOfZBT2Leasing':
            categoryLabel = 'copy-of-zbt2';
            break;
        case 'CopyOfZBT2Finance':
            categoryLabel = 'copy-of-zbt2';
            break;
        case 'InterestAndAmortizationSchedule':
            categoryLabel = 'interest-and-amortization-schedule';
            break;
        case 'InterestCertificate':
            categoryLabel = 'interest-certificate';
            break;
        case 'LoanContract':
            categoryLabel = 'loan-contract';
            break;
        case 'SecuredSaleOfReturn':
            categoryLabel = 'secured-sale-of-return';
            break;
        case 'LeasingOrder':
            categoryLabel = 'leasing-order';
            break;
        case 'ServiceManagementOrder':
            categoryLabel = 'service-management-order';
            break;
        case 'LeasingConfirmation':
            categoryLabel = 'leasing-confirmation';
            break;
        case 'ServiceManagementConfirmation':
            categoryLabel = 'service-management-confirmation';
            break;
        case 'ContractProlongation':
            categoryLabel = 'contract-prolongation';
            break;
        case 'DiminishedValueAppraisal':
            categoryLabel = 'diminished-value-appraisal';
            break;
        case 'ReturnProtocol':
            categoryLabel = 'return-protocol';
            break;
        case 'StatementOfAccount':
            categoryLabel = 'statement-of-account';
            break;
        case 'RequestForZBT2Finance':
        case 'RequestForZBT2Leasing':
        case 'RequestForZBT2FinanceAllContracts':
        case 'RequestForZBT2LeasingAllContracts':
            categoryLabel = 'zbt';
            break;
        default:
            break;
    }
    return categoryLabel;
};

export const getProductLabel = (product?: string): string => {
    let productLabel = '';
    switch (product) {
        case 'FINANCING':
            productLabel = 'financing';
            break;
        case 'LEASING':
            productLabel = 'leasing';
            break;
        case 'SERVICE_MANAGEMENT':
            productLabel = 'service-management';
            break;
        default:
            break;
    }

    return productLabel;
};
