import { Profile } from '@cp-de/common';
import { DefinitionListItem, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { changeNameOnlineBankingPath, changeNamePath, myProfilePagePath } from '../../navigation/paths';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { OnlineBankingRedirection } from '../online-banking-redirection';
import { ProfileSection, ProfileSectionEditButtonProps } from '../profile-section';
import { ChangeNameForm } from './change-name-form';
import { IdentityChangeFormValues, initialValues } from './change-name-form/validation';

export const testIds = {
    editNameButton: 'editName',
};

type IdentitySectionProps = { profile: Profile; isAbo?: boolean };

export const IdentitySection: React.FC<IdentitySectionProps> = ({ profile, isAbo }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { t } = useTranslation('my-profile');
    const [formValues, setFormValues] = useState<IdentityChangeFormValues>(initialValues);
    const [showEditSuccessMessage, setShowEditSuccessMessage] = useState<boolean>(false);

    const isEditAllowed = profile.nameChangePermission === 'ALLOWED' || isAbo;

    const identityItems: DefinitionListItem[] = [
        {
            label: t('identity.name'),
            value: profile.identity?.fullName,
        },
    ];

    const backToNonEditMode = (): void => {
        history.push(myProfilePagePath());
    };

    const { onAction } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onEditProfileContactSuccess');

    const startEdit = (editLink: string): void => {
        onAction();
        setShowEditSuccessMessage(false);
        history.push(editLink);
    };

    const buttonProps = (): ProfileSectionEditButtonProps | undefined => {
        if (profile.nameChangePermission === 'NOT_ALLOWED') return undefined;

        const editLink = isEditAllowed ? changeNamePath() : changeNameOnlineBankingPath();
        return {
            label: t('translation:editableSectionNav.start'),
            onClick: (): void => startEdit(editLink),
            testId: testIds.editNameButton,
        };
    };

    return (
        <Switch>
            {isEditAllowed && (
                <Route exact path={changeNamePath()}>
                    <ChangeNameForm
                        initialValues={formValues}
                        onSubmit={setFormValues}
                        onSubmitSuccess={(): void => {
                            setShowEditSuccessMessage(true);
                            onSuccess();
                            backToNonEditMode();
                            setFormValues(initialValues);
                        }}
                        onCancel={(currentFormValues: IdentityChangeFormValues): void => {
                            setFormValues(currentFormValues);
                            backToNonEditMode();
                        }}
                        isAbo={isAbo}
                    />
                </Route>
            )}
            {profile.nameChangePermission === 'DIRECT_BANK_CUSTOMER' && (
                <Route exact path={changeNameOnlineBankingPath()}>
                    <OnlineBankingRedirection />
                </Route>
            )}
            <Route path={path}>
                <ProfileSection
                    successMessage={showEditSuccessMessage ? t('change-name.success-modal.text') : undefined}
                    successMessageTitle={showEditSuccessMessage ? t('change-name.success-modal.title') : undefined}
                    headline={t('identity.headline')}
                    items={identityItems}
                    editButtonProps={buttonProps()}
                />
            </Route>
        </Switch>
    );
};
