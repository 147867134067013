// eslint-disable-next-line import/no-unresolved
import * as Yup from 'yup';
import { formatAsFileSize } from '../formatters';

export const validFileFormats = ['pdf', 'jpeg', 'jpg', 'png'];
export const maxTotalFilesSize = 4 * 1024 * 1024;
export const maxFileSize = 4 * 1024 * 1024;
export const maxCharsCount = 3000;

export type ErrorMessages = {
    validRequiredEmail: string;
    contractRequired: string;
    categoryRequired: string;
    subCategoryRequired: string;
    maxCharacters: string;
    maxFiles: string;
    fileType: string;
    maxFileSize: string;
    maxTotalSize: string;
};

const defaultErrorMessages: ErrorMessages = {
    validRequiredEmail: 'Please enter valid email address',
    contractRequired: 'Contract must be selected from dropdown list',
    categoryRequired: 'Category must be selected from dropdown list',
    subCategoryRequired: 'Sub-category must be selected from dropdown list',
    maxCharacters: `Text can not be longer than ${maxCharsCount} characters`,
    maxFiles: 'There can not be more than 4 files',
    fileType: 'only PDF, JPG and PNG types are allowed',
    maxFileSize: `At least one file is larger than ${formatAsFileSize(maxFileSize, 2)}`,
    maxTotalSize: `Total size of all files is larger than ${formatAsFileSize(maxTotalFilesSize, 2)}`,
};

const categoriesWithSubCategories = [
    'vehicleRegistrationDocument',
    'damage',
    'incomingContractPayment',
    'endRate',
    'personalDataChange',
];

const areFilesInCorrectType = (files: File[] | unknown[] | null | undefined): boolean =>
    !files?.length ||
    files.every((file: File) => {
        const ext = file.name.split('.');
        return validFileFormats.includes(ext[ext.length - 1].toLowerCase());
    });

const isTotalWeightInLimit = (files: File[] | unknown[] | null | undefined): boolean =>
    !files?.length ||
    maxTotalFilesSize > (files as File[]).map((file: File) => file.size).reduce((acc: number, v: number) => acc + v, 0);

const isSingleFileWeightInLimit = (files: File[] | unknown[] | null | undefined): boolean =>
    !files?.length || files.every((file: File) => maxFileSize > file.size);

export const openRequestValidationSchema = (
    errorMessages: ErrorMessages | undefined = defaultErrorMessages,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
): Yup.ObjectSchema =>
    Yup.object().shape({
        email: Yup.string()
            .required(errorMessages.validRequiredEmail)
            .email(errorMessages.validRequiredEmail),
        contract: Yup.string().required(errorMessages.contractRequired),
        category: Yup.string().required(errorMessages.categoryRequired),
        subCategory: Yup.string().when('category', {
            is: (val: string) => categoriesWithSubCategories.includes(val),
            then: Yup.string().required(errorMessages.subCategoryRequired),
        }),
        message: Yup.string().max(maxCharsCount, errorMessages.maxCharacters),
        files: Yup.array()
            .max(4, errorMessages.maxFiles)
            .test('max-file-size', errorMessages.maxFileSize, files => isSingleFileWeightInLimit(files))
            .test('max-total-files-size', errorMessages.maxTotalSize, files => isTotalWeightInLimit(files))
            .test('is-correct-file-type', errorMessages.fileType, files => areFilesInCorrectType(files)),
    });
