import { UserType } from '../types';

export function getProfileEndpoint(userType: string = UserType.STANDARD): string {
    return `/profile/${userType}`;
}

export function getNameAndMailEndpoint(userType: string = UserType.STANDARD): string {
    return `/name-mail/${userType}`;
}

export function getChangeAddressEndpoint(userType: string = UserType.STANDARD): string {
    return `${getProfileEndpoint(userType)}/change-address`;
}

export function getChangeNameEndpoint(userType: string = UserType.STANDARD): string {
    return `${getProfileEndpoint(userType)}/change-name`;
}

export function getChangeContactDetailsEndpoint(userType: string = UserType.AUTO_ABO): string {
    return `${getProfileEndpoint(userType)}/change-contact-details`;
}
