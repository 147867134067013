export function autoLogoutPath(): string {
    return '/auto-logout';
}

export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function connectionProblemPagePath(): string {
    return '/connection-problem';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}
export function dashboardPagePathParams(): string {
    return '/dashboard/';
}
export function faqPagePath(): string {
    return '/faq';
}

export function legalTermsPagePath(): string {
    return '/legal-terms';
}

export function usageAgreementPagePath(): string {
    return '/usage-agreement';
}

export function privacyPolicyPagePath(): string {
    return '/privacy-policy';
}

export function landingPagePath(): string {
    return '/';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function changeAddressPath(): string {
    return `${myProfilePagePath()}/change-address`;
}

export function changeAddressOnlineBankingPath(): string {
    return `${myProfilePagePath()}/change-address-online-banking`;
}

export function changeNamePath(): string {
    return `${myProfilePagePath()}/change-name`;
}

export function changeNameOnlineBankingPath(): string {
    return `${myProfilePagePath()}/change-name-online-banking`;
}

export function changeIbanPath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-iban`;
}

export function changeAutoAboIdentityPath(): string {
    return `${dashboardPagePath()}/change-identity`;
}

export function changeAutoAboAddressPath(): string {
    return `${dashboardPagePath()}/change-address`;
}

export function changeAutoAboContactDetailsPath(): string {
    return `${dashboardPagePath()}/change-contact-details`;
}

export function addContractPath(): string {
    return `${dashboardPagePath()}/add-contract`;
}

export function contractEndOptionsPagePath(contractId = ':contractId'): string {
    return `/contract-end-options/${contractId}`;
}

export function contractEndOptionsVehicleReturnPagePath(contractId = ':contractId'): string {
    return `${contractEndOptionsPagePath(contractId)}/vehicle-return`;
}

export function contractEndOptionsSilentExtensionPagePath(contractId = ':contractId'): string {
    return `${contractEndOptionsPagePath(contractId)}/contract-renewal`;
}

export function contractEndOptionsPurchasePriceInquiryPagePath(contractId = ':contractId'): string {
    return `${contractEndOptionsPagePath(contractId)}/purchase-price-inquiry`;
}

export function postboxPagePath(): string {
    return '/postbox';
}

export function postboxPagePathWithContractId(contractId = ':contractId'): string {
    return `/contracts/${contractId}/postbox`;
}

export function loginChoicePagePath(): string {
    return '/login-choice';
}

export function loginChoiceOfferPagePath(): string {
    return `/login-choice-offer`;
}

export function loginInProgressPagePath(): string {
    return `/login-in-progress`;
}

export function loginInProgressDigitalOfferPagePath(): string {
    return `/login-in-progress-offer`;
}

export function verimiActivationPagePath(): string {
    return '/verimi-activation';
}

export function reportDamagePagePath(contractId = ':contractId'): string {
    return `/report-damage/${contractId}`;
}

export function requestDocumentPagePath(contractId = ':contractId'): string {
    return `/request-document/${contractId}`;
}

export function requestDocumentWithDocumentTypePagePath(
    contractId = ':contractId',
    documentType = ':documentType',
): string {
    return `/${contractId}/request-document/${documentType}`;
}

export function requestDocumentWithDocumentTypeAndShippingReasonPagePath(
    contractId = ':contractId',
    documentType = ':documentType',
    shippingReason = ':shippingReason',
): string {
    return `${requestDocumentWithDocumentTypePagePath(contractId, documentType)}/${shippingReason}`;
}

export function consentPagePath(): string {
    return '/consent';
}

export function postboxConsentPath(): string {
    return '/postbox-consent';
}

export function thirdPartyLicensesPagePath(): string {
    return '/third-party-licenses';
}

export function featureAppLicensesPagePath(): string {
    return '/feature-app-licenses';
}

export function amortizationTablePagePath(contractId = ':contractId'): string {
    return `/amortization-table/${contractId}`;
}

export function totalEarlySettlementPagePath(contractId = ':contractId'): string {
    return `/total-early-settlement/${contractId}`;
}

export function changeMileagePath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-mileage`;
}

export function changePaymentDatePagePath(contractId = ':contractId'): string {
    return `/change-payment-date/${contractId}`;
}

export function earlyTerminationPagePath(contractId = ':contractId'): string {
    return `/early-termination/${contractId}`;
}

export function revenuesOpenPositionsPagePath(customerNumber = ':customerNumber'): string {
    return `/revenues-open-positions/${customerNumber}`;
}

export function myRequestPagePath(): string {
    return '/my-requests';
}

export function cancelContractPagePath(contractId = ':contractId'): string {
    return `/auto-abo/cancel-contract/${contractId}`;
}

export function whatsNewPagePath(): string {
    return '/whats-new';
}

export function arrearPaymentPagePath(contractId = ':contractId'): string {
    return `/arrear-payment/${contractId}`;
}

export function changeContactDetailsPath(): string {
    return `${myProfilePagePath()}/change-contact-details`;
}

export function cancelReservationPagePath(contractId = ':contractId', userType = ':userType'): string {
    return `/${userType}/cancel-reservation/${contractId}`;
}

export function errorPagePath(): string {
    return '/error';
}

export function registrationAnalyticsPath(): string {
    return 'register';
}

export function digitalOfferPagePath(): string {
    return `/offer`;
}
