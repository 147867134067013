import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { insuranceValidationSchema, InsuranceValidationError } from '@cp-de/common';
import { AnyObject } from 'yup/lib/types';

export const insuranceFormValidationSchema = (t: TFunction, isAccipiens: boolean): Yup.ObjectSchema<AnyObject> => {
    const translationPrefix = 'form.error';
    const errors: InsuranceValidationError = {
        name: { required: t(`${translationPrefix}.empty-name`) },
        address: {
            streetName: { required: t(`${translationPrefix}.empty-street-house-nummer`) },
            houseNumber: { required: t(`${translationPrefix}.empty-street-house-nummer`) },
            postOfficeBox: { required: t(`${translationPrefix}.empty-street-house-nummer`) },
            zipCode: { required: t(`${translationPrefix}.empty-ZIP`) },
            locality: { required: t(`${translationPrefix}.empty-city`) },
        },
        contact: {
            email: { required: t(`${translationPrefix}.empty-city`), email: t(`${translationPrefix}.empty-city`) },
        },
        claimId: { required: t(`${translationPrefix}.empty-claimId`) },
    };
    if (isAccipiens) {
        errors['contact']['email'] = {
            required: t(`${translationPrefix}.email.not-empty`),
            email: t(`${translationPrefix}.email.incorrect-format`),
        };
    }
    return insuranceValidationSchema(isAccipiens, errors);
};
