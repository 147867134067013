import {
    AuthorizationFlowState,
    AuthorizationResult,
    hasError,
    notInitiated,
    isLoading as isLoadingAuth,
} from '@cp-shared-8/frontend-ui';

export function isAuthorizationResult<T>(val: AuthorizationFlowState<T>): val is AuthorizationResult<T> {
    return !notInitiated(val) && !isLoadingAuth(val) && !hasError(val);
}
