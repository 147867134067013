export enum Step {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
}

export enum Requester {
    MY = 'MY',
    THIRD_PARTY = 'THIRD_PARTY',
}

export enum Tab {
    PERSON = 'PERSON',
    BUSINESS = 'BUSINESS',
}

export enum FormOfAddress {
    MR = 'Mr',
    MS = 'Ms',
    COMPANY = 'Company',
}
