import { CommonContract } from './common-contract';
import { CPDate } from '@cp-shared-8/common-utilities';
import { CustomerReservationDetails } from './customer-reservation-details';
import { DealerDetails } from './dealer-details';
import { VehicleDetails } from './vehicle-details';

export enum ReservationContractStatus {
    RENT_RESERVATION = 'RENT_RESERVATION',
    RENT_STARTED = 'RENT_STARTED',
    RENT_CLOSED = 'RENT_CLOSED',
    RENT_CANCELLED = 'RENT_CANCELLED',
    SUBSCRIPTION_RESERVATION = 'SUBSCRIPTION_RESERVATION',
    SUBSCRIPTION_STARTED = 'SUBSCRIPTION_STARTED',
    SUBSCRIPTION_CLOSED = 'SUBSCRIPTION_CLOSED',
    SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED',
}

export enum FourSalesContractSatus {
    OFFER = 'offer',
    PROPOSAL = 'proposal',
    CONTRACT = 'contract',
    CANCELLED = 'cancelled',
    EXPIRED = 'expired',
}

export interface CommonReservationContract extends CommonContract {
    contractMileage?: number;
    unlimitedMileage?: boolean;
    price?: number;
    startDate?: CPDate;
    startTime?: string;
    endDate?: CPDate;
    endTime?: string;
    contractStatus?: ReservationContractStatus;
    additionalProducts?: AdditionalProduct[];
    details: CommonReservationDetails;
    excessMileagePriceAmount?: number;
}

export type AdditionalProduct = {
    name?: string;
    code?: string;
    codeFamily?: string;
    qualifiers?: Qualifier[];
    isSelected?: boolean;
};

export type Qualifier = {
    id?: string;
    stringValue?: string;
};

export type CommonReservationDetails = {
    vehicleDetails?: VehicleDetails;
    dealerDetails?: DealerDetails;
    customerDetails?: CustomerReservationDetails;
};
