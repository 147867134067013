export const formFields = {
    FINANCING: [
        {
            category: 'vehicleRegistrationDocument',
            subCategories: ['vehicleRegistrationDocumentMissing', 'furtherOwnerChangeInquiry', 'miscellaneousQuestion'],
        },
        { category: 'creditInsurance', subCategories: [] },
        { category: 'damage', subCategories: ['propertyDamageStateMessage', 'miscellaneousQuestion'] },
        {
            category: 'incomingContractPayment',
            subCategories: ['furtherIncomingPaymentInquiry'], // deactivated: 'miscellaneousQuestion'
        },
        { category: 'endRate', subCategories: ['endRatePaidByDealer', 'endRateToBeFinanced', 'miscellaneousQuestion'] },
        {
            category: 'personalDataChange',
            subCategories: ['phoneNumberChange', 'emailChange', 'miscellaneousQuestion'],
        },
        // { category: 'miscellaneous', subCategories: [] }, // deactivated
        { category: 'customerPortalIncident', subCategories: [] },
    ],
    LEASING: [
        { category: 'contractRenewal', subCategories: [] },
        { category: 'mileageChange', subCategories: [] },
        { category: 'earlyTermination', subCategories: [] },
        { category: 'carReturnReduceValue', subCategories: [] },
        { category: 'requestDocuments', subCategories: [] },
        { category: 'miscellaneous', subCategories: [] },
        { category: 'customerPortalIncident', subCategories: [] },
    ],
    SERVICE_MANAGEMENT: [
        { category: 'mileageChange', subCategories: [] },
        { category: 'earlyTermination', subCategories: [] },
        { category: 'requestDocuments', subCategories: [] },
        { category: 'miscellaneous', subCategories: [] },
        { category: 'customerPortalIncident', subCategories: [] },
    ],
    AUTO_ABO: [{ category: 'miscellaneous', subCategories: [] }],
    AUDI_ON_DEMAND: {},
    RENT_A_CAR: {},
    FOUR_SALES: {},
    NOT_FOUND: [],
};
