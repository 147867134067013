import React from 'react';
import { CustomerReservationAddress } from '@cp-de/common';
import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { Heading, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type ConsultViewProps = {
    addresses: CustomerReservationAddress[];
    successMessage?: string;
    startEditing: () => void;
};

// export const ConsultView: React.FC<ConsultViewProps> = ({ addresses, successMessage, startEditing }) => {
export const ConsultView: React.FC<ConsultViewProps> = ({ addresses, successMessage }) => {
    const { t } = useTranslation();

    if (!addresses.length) {
        return null;
    }

    const getItemList = ({ street = '', houseNumber = '', zipCode = '', city = '' }): DefinitionListItem[] => [
        {
            label: t('auto-abo-customer-details:addresses.street'),
            value: street ? `${street} ${houseNumber}` : '-',
            testId: 'street',
        },
        {
            label: t('auto-abo-customer-details:addresses.city'),
            value: zipCode && city ? `${zipCode} ${city}` : '-',
            testId: 'city',
        },
    ];

    return (
        <>
            <Layout>
                <Layout.Item default="1/2">
                    <Heading level="6" className="u-pt">
                        {t('auto-abo-customer-details:addresses.headline')}
                    </Heading>
                </Layout.Item>
                {/* TODO return code below after BE is fixed */}
                {/* <Layout.Item default="1/2" className={'u-text-right'}>
                    <Button
                        icon={'semantic-edit'}
                        type="button"
                        link
                        simple
                        onClick={startEditing}
                        testId="editAddress"
                    />
                </Layout.Item> */}
            </Layout>
            {addresses?.map((address, index) => (
                <DefinitionListHorizontal
                    list={getItemList(address)}
                    key={`${address.street}${address.houseNumber}${index}`}
                    className={'u-mb u-mt-small'}
                />
            ))}
            {successMessage && (
                <Notification
                    className={'u-mt'}
                    testId={'success-notification'}
                    status={NotificationStatus.success}
                    text={successMessage}
                />
            )}
        </>
    );
};
