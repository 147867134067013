export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ContentEndpoint = (locale: string, brand: string) => string;

function getContentEndpoint(locale: string, brand: string, contentType: string): string {
    return `/${brand}/${locale}/${contentType}.json`;
}

export const getIconFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'icon-footer');
};

export const getLegalFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-footer');
};

export const getAutoLogoutSuccessEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'auto-logout-success');
};

export const getDashboardMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'dashboard-marketing-card');
};

export const getPrivacyPolicyEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'privacy-policy');
};

export const getUsageAgreementEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'usage-agreement');
};

export const getLegalTermsEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-terms');
};

export const getLoginChoiceEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'login-choice');
};

export const getLoginChoiceOfferEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'login-choice-offer');
};

export const getUnauthorizedErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'unauthorized-error');
};

export const getNotFoundErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'not-found-error');
};

export const getForbiddenErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'forbidden-error');
};

export const getFaqEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq');
};

export const getConnectionProblemErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'connection-problem-error');
};

export const getThirdPartyLicensesEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'third-party-licenses');
};

export const getHeyCarMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'heycar-marketing-card');
};

export const getWhatsNewNotificationEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'whats-new');
};

export const getGenericErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'generic-error');
};
