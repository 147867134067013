import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { MyRequest } from '../../components/my-request/MyRequest';

export const MyRequestPageComponent: React.FC<{}> = () => {
    const { t } = useTranslation('my-request');
    const title = t('headline');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <MyRequest />
        </ContentSection>
    );
};

export const MyRequestPage: React.FC = () => {
    return <MyRequestPageComponent />;
};
