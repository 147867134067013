import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { WhatsNewNotificationUi } from './ui/WhatsNewNotificationUi';
import { useWhatsNewNotification } from './useWhatsNewNotification';
import { StaticPageLoadingPlaceholder } from '@cp-shared-8/frontend-ui';

const WhatsNewNotificationWithHandlers = withLoadingAndNoConnectionHandler(WhatsNewNotificationUi);

export const WhatsNewNotification: React.FC = () => {
    const { cmsContent: whatsNewNotification, isLoading, loadingError } = useWhatsNewNotification();

    return (
        <WhatsNewNotificationWithHandlers
            isLoading={isLoading}
            whatsNewContent={whatsNewNotification}
            hasError={!!loadingError}
            loadingPlaceholder={<StaticPageLoadingPlaceholder />}
        />
    );
};
