import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { CpDataApi } from './axiosInstances';
import { currentBrand, apiGatewayConsumerName, integrationApiBaseUrlLocal } from 'config';
import { useAuthentication } from '@cp-shared-8/frontend-ui';

function extractSub(tokenParsed: object): string | undefined {
    const { idpSub } = tokenParsed as { idpSub: string };
    return idpSub;
}

export function useAxiosWithAuthentication(): void {
    const auth = useAuthentication();

    if (auth.isAuthenticated) {
        Object.assign(CpDataApi.defaults.headers, { 'x-cp-brand': currentBrand });
        if (process?.env?.NODE_ENV === 'production') {
            Object.assign(CpDataApi.defaults.headers, {
                'x-consumer-name': apiGatewayConsumerName,
                'x-authorization-token': `Bearer ${auth.token}`,
            });
        } else {
            Object.assign(CpDataApi.defaults.headers, {
                'x-consumer-id': '12351',
                'x-request-idpsub': extractSub(auth.tokenParsed),
                'x-access-token': auth.token,
            });
            CpDataApi.defaults.baseURL = integrationApiBaseUrlLocal;
        }
    }
}

export function useAxiosWithCurrentLanguage(): void {
    const { i18n } = useTranslation(undefined, { useSuspense: false });
    const { language } = i18n;
    useEffect(() => {
        if (language) {
            Object.assign(CpDataApi.defaults.headers, {
                'Accept-Language': language,
            });
        }
    }, [language]);
}
