import React from 'react';
import {
    Button,
    ButtonContainer,
    Fieldset,
    RadioButton,
    RadioButtonGroup,
    SectionHeading,
    ContextNotification,
    Notification,
} from '@vwfs-bronson/bronson-react';
import { CleaveInput, InfoIcon } from '@cp-shared-8/frontend-ui';
import { FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Requester, Step } from '../enums';
import { InitialValues } from '../initialValues';
import { Contract, ProductType, VehicleDocumentReceiver } from '@cp-de/common';

type FirstStepFormProps = {
    contract: Contract;
    onCancel: () => void;
    setStep: (step: Step) => void;
};

export const testIds = {
    contractRedemptionDate: 'contractRedemptionDate',
    cancel: 'cancel',
    submit: 'submit',
};

export const CustomerForm: React.FC<FirstStepFormProps> = ({ contract, onCancel, setStep }) => {
    const { t } = useTranslation('total-early-settlement');
    const {
        values,
        submitForm,
        setFieldValue,
        errors,
        resetForm,
        setFieldTouched,
    }: FormikProps<InitialValues> = useFormikContext();

    const requester: Requester[] = [Requester.MY, Requester.THIRD_PARTY];
    const vehicleDocumentReceiver = [VehicleDocumentReceiver.CUSTOMER, VehicleDocumentReceiver.DEALER];
    const isClassicCredit = contract.productType === ProductType.CLASSIC_CREDIT;
    const isIndividualCredit = contract.productType === ProductType.INDIVIDUAL_CREDIT;
    const isDirectCreditCarGroup = 'DirectCreditCar' === contract.productGroup;
    const isDirectCreditContract = ['DirectCredit', 'DirectCreditCar'].includes(contract.productGroup || '');

    const changeStep = (): void => {
        const currentValues = values;
        resetForm({ values: currentValues });
        setStep(Step.SECOND);
    };

    const handleClick = (): void | Promise<void> | null => {
        if (errors.contractRedemptionDate) {
            setFieldTouched('contractRedemptionDate', true);
            return null;
        }
        values.isThirdPartyRequester ? changeStep() : submitForm();
    };

    return (
        <>
            <div>
                <SectionHeading level="3" subtitle={t('info')} className={'u-mt'} />
            </div>
            <ContextNotification>
                <Notification showCloseButton={false} status="info" visible>
                    {t('notification')}
                </Notification>
            </ContextNotification>
            <Fieldset>
                <Fieldset.Row>
                    <CleaveInput
                        cleaveOptions={{
                            delimiter: '.',
                            blocks: [2, 2, 4],
                            numericOnly: true,
                        }}
                        inputMode={'numeric'}
                        label={t('contract-redemption-date.label')}
                        name={'contractRedemptionDate'}
                        testId={testIds.contractRedemptionDate}
                        placeholder="TT.MM.JJJJ"
                    />
                </Fieldset.Row>
                <Fieldset.Row>
                    <h4>{t('requester.headline')}</h4>
                    <RadioButtonGroup>
                        {requester.map((type, index) => {
                            const isThirdParty = type === Requester.THIRD_PARTY;
                            const suffix = type === Requester.THIRD_PARTY ? 'third-party' : 'my';
                            return (
                                <RadioButton
                                    key={index}
                                    testId={`requester-${suffix}`}
                                    value={`${isThirdParty}`}
                                    name="isThirdPartyRequester"
                                    defaultChecked={
                                        (!values.isThirdPartyRequester && type === Requester.MY) ||
                                        (values.isThirdPartyRequester && type === Requester.THIRD_PARTY)
                                    }
                                    onChange={() => setFieldValue('isThirdPartyRequester', isThirdParty)}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t(`requester.radio-button-${suffix}`),
                                        }}
                                    />
                                </RadioButton>
                            );
                        })}
                    </RadioButtonGroup>
                </Fieldset.Row>
                {!values.isThirdPartyRequester &&
                    Requester.MY &&
                    !(isClassicCredit && isDirectCreditContract) &&
                    !(isIndividualCredit && isDirectCreditCarGroup) && (
                        <Fieldset.Row>
                            <h4>{t('vehicle-document-receiver.headline')}</h4>
                            <RadioButtonGroup>
                                {vehicleDocumentReceiver.map((receiver, index) => {
                                    const translationSuffix =
                                        receiver === VehicleDocumentReceiver.CUSTOMER ? 'customer' : 'dealer';

                                    return (
                                        <React.Fragment key={`${index} - ${receiver}`}>
                                            <RadioButton
                                                testId={`customer-requester-${receiver}`}
                                                value={receiver}
                                                name="customerVehicleDocumentReceiver"
                                                defaultChecked={receiver === VehicleDocumentReceiver.CUSTOMER}
                                                onChange={() =>
                                                    setFieldValue('customerVehicleDocumentReceiver', receiver)
                                                }
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t(
                                                            `vehicle-document-receiver.radio-button-${translationSuffix}`,
                                                        ),
                                                    }}
                                                />
                                            </RadioButton>
                                            {receiver === VehicleDocumentReceiver.DEALER && (
                                                <InfoIcon text={t('vehicle-document-receiver.tooltip-dealer')} />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </RadioButtonGroup>
                        </Fieldset.Row>
                    )}
            </Fieldset>
            <ButtonContainer nav className="u-mt">
                <Button type="reset" secondary onClick={onCancel} testId={testIds.cancel}>
                    {t('translation:editableSectionNav.cancel')}
                </Button>
                <Button type="submit" onClick={() => handleClick()} testId={testIds.submit}>
                    {t('translation:editableSectionNav.send')}
                </Button>
            </ButtonContainer>
        </>
    );
};
