import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Form, Heading, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { preventSubmit, useAnalyticsActionTracker, CleaveInput } from '@cp-shared-8/frontend-ui';
import {
    areFilesValid,
    ClaimsTransferDownload,
    InsuranceClaimUpload,
    resetField,
    resetFileUpload,
    ResponsibleInsuranceRadioGroup,
    YesNoButtons,
    YesNoRadioGroup,
} from '../../../common';
import { ReportDamageResponsibleInsurance } from '@cp-de/common';
import { getStep1InitialValues } from '../initialValues';
import { TotalLossFormValues, TotalLossStep1FormValues } from '../../types';
import {
    isRedeemLoanWithYes,
    isTransferInsuranceWithNo,
    isTransferInsuranceWithYes,
    showPolicyHolder,
    showRedeemLoan,
    showTransferInsurance,
} from '../helpers';
import { useHistory } from 'react-router-dom';
import { totalEarlySettlementPagePath } from '../../../../../navigation/paths';
import { getStep1ValidationSchema } from '../validationSchema';
import { FormNavigationButtons } from '../../../../form-navigation-buttons';

type TotalLossFormViewProps = {
    handleNext: (totalLossValues: TotalLossStep1FormValues) => void;
    handlePrev: (totalLossValues: TotalLossStep1FormValues) => void;
    totalLossValues?: TotalLossFormValues;
    contractId: string;
    isAccipiens: boolean;
};

export const Step1FormView: React.FC<TotalLossFormViewProps> = ({
    totalLossValues,
    handleNext,
    handlePrev,
    contractId,
    isAccipiens,
}) => {
    const history = useHistory();
    const { t } = useTranslation('repair-approval');
    const [showModal, setShowModal] = useState(false);

    const { onAction } = useAnalyticsActionTracker('onTotalLossEarlySettlementRedirect');

    const onModalConfirm = (): void => {
        onAction();
        history.push(totalEarlySettlementPagePath(contractId));
    };

    const onModalClose = (): void => {
        setShowModal(false);
    };

    const handleSubmit = (values: TotalLossStep1FormValues): void => {
        const { redeemLoan, insuranceClaim } = values;
        if (isRedeemLoanWithYes(redeemLoan)) {
            setShowModal(true);
        } else {
            if (!areFilesValid(insuranceClaim)) return;
            handleNext(values);
        }
    };

    const onBackClick = (values: TotalLossStep1FormValues): void => {
        handlePrev(values);
    };

    const onResponsibleInsuranceChange = (
        formik: FormikProps<TotalLossStep1FormValues>,
        currentVal: ReportDamageResponsibleInsurance,
    ): void => {
        resetField(formik, 'policyHolder');
        resetField(formik, 'transferInsurance');
        resetFileUpload(formik, 'insuranceClaim');
        if (currentVal === ReportDamageResponsibleInsurance.OWN) {
            resetField(formik, 'redeemLoan');
        }
    };

    const onPolicyHolderChange = (formik: FormikProps<TotalLossStep1FormValues>, currentVal: YesNoButtons): void => {
        resetField(formik, 'transferInsurance');
        resetFileUpload(formik, 'insuranceClaim');
        if (currentVal === YesNoButtons.no) {
            resetField(formik, 'redeemLoan');
        }
    };

    const onTransferInsuranceChange = (formik: FormikProps<TotalLossStep1FormValues>): void => {
        resetField(formik, 'redeemLoan');
        resetFileUpload(formik, 'insuranceClaim');
    };

    return (
        <Formik
            initialValues={getStep1InitialValues(isAccipiens, totalLossValues)}
            validationSchema={getStep1ValidationSchema(t, isAccipiens)}
            onSubmit={handleSubmit}
        >
            {(formik: FormikProps<TotalLossStep1FormValues>): JSX.Element => (
                <Form onSubmit={e => preventSubmit(e)}>
                    <Layout center>
                        <Layout.Item default="2/3" m={'1/1'} className={'u-text-center'}>
                            <Heading className="u-mt-large" level={4}>
                                {t('total-loss.step1.title')}
                            </Heading>
                        </Layout.Item>
                        <Layout.Item default="2/3" m={'1/1'}>
                            {t('total-loss.step1.description')}
                        </Layout.Item>
                        {isAccipiens && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '.',
                                        blocks: [2, 2, 4],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={t('common.damage-date')}
                                    name={'damageDate'}
                                />
                            </Layout.Item>
                        )}
                        <Layout.Item default="2/3" m={'1/1'}>
                            <ResponsibleInsuranceRadioGroup
                                name={'responsibleInsurance'}
                                options={[
                                    ReportDamageResponsibleInsurance.OWN,
                                    ReportDamageResponsibleInsurance.OTHER_PARTY,
                                    ReportDamageResponsibleInsurance.NONE,
                                ]}
                                onChange={(val: ReportDamageResponsibleInsurance): void =>
                                    onResponsibleInsuranceChange(formik, val)
                                }
                            />
                        </Layout.Item>
                        {showPolicyHolder(formik.values.responsibleInsurance) && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <YesNoRadioGroup
                                    label={t('policy-holder.label')}
                                    name={'policyHolder'}
                                    onChange={(val: YesNoButtons): void => onPolicyHolderChange(formik, val)}
                                    isMandatory={false}
                                />
                            </Layout.Item>
                        )}
                        {showTransferInsurance(formik.values.policyHolder) && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <YesNoRadioGroup
                                    label={t('transfer-insurance.label')}
                                    name={'transferInsurance'}
                                    onChange={(): void => onTransferInsuranceChange(formik)}
                                    isMandatory={false}
                                />
                            </Layout.Item>
                        )}
                        {isTransferInsuranceWithNo(formik.values.transferInsurance) && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <ClaimsTransferDownload contractId={contractId} formVariant="TotalLoss" />
                            </Layout.Item>
                        )}
                        {isTransferInsuranceWithYes(formik.values.transferInsurance) && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <InsuranceClaimUpload name={'insuranceClaim'} />
                            </Layout.Item>
                        )}
                        {showRedeemLoan(formik.values) && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <YesNoRadioGroup
                                    label={t('total-loss.redeem-loan.label')}
                                    name={'redeemLoan'}
                                    isMandatory={false}
                                />
                            </Layout.Item>
                        )}
                        <Layout.Item>
                            <FormNavigationButtons
                                onPrimaryButtonClick={formik.submitForm}
                                onSecondaryButtonClick={(): void => onBackClick(formik.values)}
                                showDashboardButton={isTransferInsuranceWithNo(formik.values.transferInsurance)}
                            />
                        </Layout.Item>
                    </Layout>
                    <Modal
                        shown={showModal}
                        buttonConfirmText={t('translation:editableSectionNav.continue')}
                        buttonCancelText={t('translation:editableSectionNav.cancel')}
                        onConfirm={onModalConfirm}
                        onClose={onModalClose}
                        onCancel={onModalClose}
                        title={t('total-loss.early-settlement-modal.title')}
                        testId={'earlySettlementModal'}
                    >
                        {t('total-loss.early-settlement-modal.text')}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
