import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { LegalTermsUi } from './ui';
import { useLegalTerms } from './useLegalTerms';
import { LegalFooterLoadingPlaceholder } from '@cp-shared-8/frontend-ui';

export const LegalTerms: React.FC = () => {
    const { cmsContent: legalTerms, isLoading, loadingError } = useLegalTerms();

    const LegalTermsWithHandlers = withLoadingAndNoConnectionHandler(LegalTermsUi);

    return (
        <LegalTermsWithHandlers
            isLoading={isLoading}
            legalTerms={legalTerms}
            hasError={!!loadingError}
            loadingPlaceholder={<LegalFooterLoadingPlaceholder />}
        />
    );
};
