import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { StaticPageLoadingPlaceholder } from '@cp-shared-8/frontend-ui';

import { PrivacyPolicyUi } from './ui';
import { usePrivacyPolicy } from './usePrivacyPolicy';

export const PrivacyPolicy: React.FC = () => {
    const { cmsContent: privacyPolicy, isLoading, loadingError } = usePrivacyPolicy();

    const PrivacyPolicyWithHandlers = withLoadingAndNoConnectionHandler(PrivacyPolicyUi);

    return (
        <PrivacyPolicyWithHandlers
            isLoading={isLoading}
            privacyPolicy={privacyPolicy}
            hasError={!!loadingError}
            loadingPlaceholder={<StaticPageLoadingPlaceholder />}
        />
    );
};
