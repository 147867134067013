import React, { useState } from 'react';
import { CustomerReservationDetails } from '@cp-de/common';
import { changeAutoAboContactDetailsPath } from '../../../../../navigation/paths';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ChangeContactDetails } from '../../../../../my-profile/contact-details-section/change-contact-details/ChangeContactDetails';
import { ConsultView } from './consult-view/ConsultView';
import { EditStatus } from '../../../../../my-profile/contact-details-section/EditStatus';

type ContactDetailsProps = { details: CustomerReservationDetails };

export const ContactDetailsSection: React.FC<ContactDetailsProps> = ({ details }) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t, ready } = useTranslation('auto-abo-customer-details', { useSuspense: false });
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    if (!details) return null;

    const { contactDetails = {} } = details;

    const startEditing = (): void => {
        history.push(changeAutoAboContactDetailsPath());
    };

    const finishEditing = (newEditStatus: EditStatus, isError?: boolean): void => {
        setLastEditStatus(newEditStatus);
        if (!isError) history.push(path);
    };

    const cancelEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const successMessage =
        lastEditStatus === EditStatus.SUCCESS ? t('contact.change-contact.notifications.success') : undefined;

    return (
        <>
            {ready && (
                <Switch>
                    <Route exact path={changeAutoAboContactDetailsPath()}>
                        <ChangeContactDetails
                            finishEditing={finishEditing}
                            cancelEditing={cancelEditing}
                            contact={contactDetails}
                            isContractView={true}
                        />
                    </Route>
                    <Route path={path}>
                        <ConsultView
                            contactDetails={contactDetails}
                            successMessage={successMessage}
                            startEditing={startEditing}
                        />
                    </Route>
                </Switch>
            )}
        </>
    );
};
