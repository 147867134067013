import { FourSalesContract, formatAsCurrency, formatAsDate } from '@cp-de/common';
import { Card } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-8/frontend-ui';
import { FourSalesDetails } from './details';
import { compact } from 'lodash';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image/VehicleDetailsAndImage';
import { confirmVehicleDetailsComposition } from '../utils';

export const FourSalesContractCard: React.FC<{
    contract: FourSalesContract;
    isVehicleDetailsAndImageHidden?: boolean;
}> = ({ contract, isVehicleDetailsAndImageHidden }) => {
    const { t } = useTranslation('four-sales-contracts');

    if (!contract) {
        return null;
    }

    const {
        details: { customerDetails, financialProduct },
    } = contract;

    const formatAsPaymentFrequency = (value?: number): string => {
        switch (value) {
            case 0:
                return 'Einmalzahlung';
            case 1:
                return 'Monatlich';
            case 2:
                return 'Vierteljährlich';
            case 3:
                return 'Halbjährlich';
            case 4:
                return 'Jährlich Saison';
            case 5:
                return 'Monatlich Saison';
            case 6:
                return 'Jährlich';
            default:
                return '';
        }
    };

    const contractSummaryItems: Array<ContractSummaryItemProps> = compact([
        {
            label: t('premium.label'),
            value: financialProduct?.contract?.premium
                ? formatAsCurrency(Number(financialProduct.contract.premium))
                : '',
            testClass: 'premium',
        },
        {
            label: t('payment-period.label'),
            value: customerDetails?.paymentData?.paymentFrequency
                ? formatAsPaymentFrequency(customerDetails.paymentData.paymentFrequency)
                : '',
            testClass: 'payment-period',
        },
        {
            label: t('next-due-date.label'),
            value: customerDetails?.paymentData?.paymentNext
                ? formatAsDate(customerDetails.paymentData.paymentNext)
                : '',
            testClass: 'next-due-date',
        },
    ]).filter(item => !!item.value);

    const contractSummary = <ContractSummary items={contractSummaryItems} />;

    return (
        <>
            {!isVehicleDetailsAndImageHidden && confirmVehicleDetailsComposition(contract.details.vehicleDetails) && (
                <VehicleDetailsAndImage reservationContract={contract} />
            )}
            <Card
                element="article"
                expandable
                className="u-mb"
                title={t('title')}
                subtitle={contract?.contractNumber}
                defaultExpanded={true}
                contentLarge={<FourSalesDetails contract={contract} />}
            >
                {contractSummary}
            </Card>
        </>
    );
};
