import { ContractType, FourSalesContract, FourSalesContractSatus, FourSalesDetails } from '@cp-de/common';

export const FourSalesDetailsWithAllValues: FourSalesDetails = {
    contractDetails: {
        startDate: '2022-08-31',
        status: FourSalesContractSatus.PROPOSAL,
        coverageDetail: {
            thirdPartyLiability: '100 Mio. €',
            thirdPartyLiabilityBonusMalus: '12',
            fullyComprehensiveBonusMalus: '12',
            comprehensiveCover: '300 € SB/ 150 € SB',
            driverCircleKey: 'A',
            driverCircleKeyString: 'Beliebiger Fahrer',
            birthDateAdditionalDriver1: '02.05.1990',
            birthDateAdditionalDriver2: '02.05.1990',
            birthDateAdditionalDriver3: '02.05.1990',
            ageYoungestDriver: 18,
            contractRenewalDate: '05.10.',
            accompaniedDriving: true,
            occupationTariffGroup: 'Automobilwirtschaft',
            licensePlateSeason: '01.10 - 31.05.',
            additionalCoveragePackages: ['EigenschadenDeckung'],
        },
    },
    vehicleDetails: {
        registrationNumber: 'GD908111',
        vin: 'VIN1233333',
        carBrand: 'Audi',
        carModel: 'A3',
        bodyColorName: 'grey',
        bodyColorFinish: 'shine',
        trimValue: 'Attraction',
        engineSizeValue: '2.0',
        engineTechnology: 'TDI',
        enginePowerValue: '110',
        enginePowerUnit: 'kW',
        fuelType: 'Diesel',
        modelYear: 2006,
        transmission: 'automatic',
        carImage: undefined,
    },
    customerDetails: {
        firstName: 'Karl',
        lastName: 'Müller',
        addresses: [
            {
                street: 'BrelinStreet',
                houseNumber: '23',
                zipCode: '21-546',
                city: 'Berlin',
                countryCode: 'DE',
            },
        ],
        contactData: {
            email: 'de5211@euroleasing.eu',
            mobilePhoneNumber: '030 67068833',
        },
        paymentData: {
            paymentMethod: 'invoice',
            paymentFrequency: 0,
            paymentProvider: 'amazon',
            paymentNext: '2022-12-01',
        },
    },
    financialProduct: {
        contract: {
            contractId: 'sf9sd6fs7f6sfsdf6s78fsd6f7s6s78f6sd78fsfsf6sd78',
            premium: 33.17,
            premiumDefault: 33.17,
        },
        calculation: { yearlyMileage: 30000 },
    },
};

export const FourSalesDetailsWithContractStatus: FourSalesDetails = {
    contractDetails: {
        startDate: '2022-08-31',
        status: FourSalesContractSatus.CONTRACT,
        coverageDetail: {
            thirdPartyLiability: '100 Mio. €',
            thirdPartyLiabilityBonusMalus: '12',
            fullyComprehensiveBonusMalus: '12',
            comprehensiveCover: '300 € SB/ 150 € SB',
            driverCircleKey: 'B',
            driverCircleKeyString: 'Beliebiger Fahrer',
            birthDateAdditionalDriver1: '02.05.1990',
            birthDateAdditionalDriver2: '02.05.1990',
            birthDateAdditionalDriver3: '02.05.1990',
            ageYoungestDriver: 18,
            contractRenewalDate: '05.10.',
            accompaniedDriving: true,
            occupationTariffGroup: 'Automobilwirtschaft',
            licensePlateSeason: '01.10 - 31.05.',
            additionalCoveragePackages: ['EigenschadenDeckung'],
        },
    },
    vehicleDetails: {
        registrationNumber: 'GD908111',
        vin: 'VIN1233333',
        carBrand: 'Audi',
        carModel: 'A3',
        bodyColorName: 'grey',
        bodyColorFinish: 'shine',
        trimValue: 'Attraction',
        engineSizeValue: '2.0',
        engineTechnology: 'TDI',
        enginePowerValue: '110',
        enginePowerUnit: 'kW',
        fuelType: 'Diesel',
        modelYear: 2006,
        transmission: 'automatic',
        carImage: undefined,
    },
    customerDetails: {
        firstName: 'Karl',
        lastName: 'Müller',
        addresses: [
            {
                street: 'BrelinStreet',
                houseNumber: '23',
                zipCode: '21-546',
                city: 'Berlin',
                countryCode: 'DE',
            },
        ],
        contactData: {
            email: 'de5211@euroleasing.eu',
            mobilePhoneNumber: '030 67068833',
        },
        paymentData: {
            paymentMethod: 'invoice',
            paymentFrequency: 0,
            paymentProvider: 'amazon',
            paymentNext: '2022-12-01',
        },
    },
    financialProduct: {
        contract: {
            contractId: 'sf9sd6fs7f6sfsdf6s78fsd6f7s6s78f6sd78fsfsf6sd78',
            premium: 33.17,
            premiumDefault: 33.17,
        },
        calculation: { yearlyMileage: 30000 },
    },
};

export const FourSalesDetailsWithMissingValues: FourSalesDetails = {
    contractDetails: {
        startDate: '2022-08-31',
        status: FourSalesContractSatus.CONTRACT,
        coverageDetail: {
            thirdPartyLiability: undefined,
            thirdPartyLiabilityBonusMalus: '12',
            fullyComprehensiveBonusMalus: '12',
            comprehensiveCover: '300 € SB/ 150 € SB',
            driverCircleKey: 'B',
            driverCircleKeyString: 'Beliebiger Fahrer',
            ageYoungestDriver: 18,
            contractRenewalDate: '05.10.',
            accompaniedDriving: true,
            occupationTariffGroup: 'Automobilwirtschaft',
            licensePlateSeason: '01.10 - 31.05.',
            additionalCoveragePackages: ['EigenschadenDeckung'],
        },
    },
    vehicleDetails: {
        vin: 'VIN1233333',
        carImage: undefined,
    },
    customerDetails: {
        firstName: 'Karl',
        lastName: 'Müller',
        addresses: [
            {
                street: 'BrelinStreet',
                houseNumber: '23',
                zipCode: '21-546',
                city: 'Berlin',
                countryCode: 'DE',
            },
        ],
        contactData: {
            email: 'de5211@euroleasing.eu',
            mobilePhoneNumber: undefined,
        },
    },
    financialProduct: {
        contract: {
            contractId: 'sf9sd6fs7f6sfsdf6s78fsd6f7s6s78f6sd78fsfsf6sd78',
            premium: 33.17,
            premiumDefault: 33.17,
        },
        calculation: { yearlyMileage: 30000 },
    },
};

export const FourSalesContractWithAllValues: FourSalesContract = {
    contractId: 'WywGxNn63XBnL2f_BEgm8b8rWD7N-UnTcv7rXxtxJGI',
    contractNumber: 'CFX123456789',
    startDate: '2022-08-31',
    endDate: '2022-09-16',
    contractType: ContractType.FOUR_SALES,
    details: FourSalesDetailsWithAllValues,
};

export const FourSalesContractWithAllValuesContractStatus: FourSalesContract = {
    contractId: 'WywGxNn63XBnL2f_BEgm8b8rWD7N-UnTcv7rXxtxJGI',
    contractNumber: 'CFX123456789',
    startDate: '2022-08-31',
    endDate: '2022-09-16',
    contractType: ContractType.FOUR_SALES,
    details: FourSalesDetailsWithContractStatus,
};

export const FourSalesContractWithMissingValues: FourSalesContract = {
    ...FourSalesContractWithAllValues,
    details: FourSalesDetailsWithMissingValues,
};

export const FourSalesContractsWithDifferentValues = [FourSalesContractWithAllValues];
