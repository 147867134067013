import React from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';

export type NoConnectionNotificationProps = {
    testId?: string;
    errorTitle?: string;
    errorText?: string | React.ReactNode;
};

export const NoConnectionNotification: React.FC<NoConnectionNotificationProps> = ({
    testId,
    errorTitle,
    errorText,
}) => {
    const { t } = useTranslation();
    const headline = typeof errorTitle !== 'undefined' ? errorTitle : t('error.noConnection.title');
    return (
        <Notification
            status={NotificationStatus.error}
            headline={headline}
            testId={testId}
            className={'c-notification--context'}
        >
            <span
                dangerouslySetInnerHTML={{
                    __html: (errorText as string) || t('error.noConnection.text'),
                }}
            />
        </Notification>
    );
};
