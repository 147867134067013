import { AddressChangeResponse } from '@cp-de/common';

export const ResponseWithAddressChange: AddressChangeResponse = {
    addressChanged: true,
};

export const ResponseWithAddressSuggestions: AddressChangeResponse = {
    addressChanged: false,
    addressSuggestions: [
        {
            street: 'Sunrise Avenue',
            houseNumber: '13 A',
            zipCode: '10179',
            city: 'Berlin',
        },
        {
            street: 'Sunrise Avenue',
            houseNumber: '13 B',
            zipCode: '10179',
            city: 'Berlin',
        },
        {
            street: 'Sunrise Avenue',
            houseNumber: '13 C',
            zipCode: '10179',
            city: 'Berlin',
        },
        {
            street: 'Sunrise Avenue',
            houseNumber: '13 D',
            zipCode: '10179',
            city: 'Berlin',
        },
        {
            street: 'Sunrise Avenue',
            houseNumber: '13 E',
            zipCode: '10179',
            city: 'Berlin',
        },
    ],
};
