import { Contract, FinancialDetails as ContractFinancialDetails } from '@cp-de/common';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FinancialDetailsUi } from './ui';

const FinancialDetailsWithHandlers = withLoadingAndNoConnectionHandler(FinancialDetailsUi);

type FinancialDetailsProps = {
    contract: Contract;
    isFinancialDetailsLoading: boolean;
    isFinancialDetailsLoadingError: boolean;
    financialDetails?: ContractFinancialDetails;
    isInactive?: boolean;
};

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({
    contract,
    isFinancialDetailsLoading,
    isFinancialDetailsLoadingError,
    financialDetails,
    isInactive,
}) => {
    const { t } = useTranslation('contracts');

    return (
        <div>
            <FinancialDetailsWithHandlers
                isInactive={isInactive}
                isLoading={isFinancialDetailsLoading}
                financialDetails={financialDetails}
                contract={contract}
                hasError={isFinancialDetailsLoadingError}
                errorText={t('financial-details.error.no-connection')}
            />
        </div>
    );
};
