import { CombinedContractType, isStandardContract } from '../../utils';
import { VehicleDetails } from '@cp-de/common';

export const getCarBrandAndModel = (carBrand?: string, carModel?: string, language?: string): string =>
    (carBrand ? carBrand.toLocaleUpperCase(language) : '') +
    ' ' +
    (carModel ? carModel.toLocaleUpperCase(language) : '').trim();

export type GroupedContractsByVin = Array<CombinedContractType[]>;

const getVinFromCombinedContractType = (contract: CombinedContractType): string | undefined =>
    isStandardContract(contract)
        ? contract.vehicle?.vehicleIdentificationNumber
        : contract.details?.vehicleDetails?.vin;

export const groupContractsByVin = (contracts: CombinedContractType[]): GroupedContractsByVin => {
    return contracts.reduce((acc: GroupedContractsByVin, current) => {
        const groupIndex = acc.findIndex(group =>
            group.some(
                contract => getVinFromCombinedContractType(contract) === getVinFromCombinedContractType(current),
            ),
        );
        if (groupIndex > -1) {
            acc[groupIndex].push(current);
        } else {
            acc.push([current]);
        }
        return acc;
    }, []);
};

export const confirmVehicleDetailsComposition = (vehicleDetails: VehicleDetails | undefined): boolean => {
    return !!(vehicleDetails?.carBrand && vehicleDetails?.carModel);
};
