import React, { useEffect, useRef } from 'react';
import { FurtherApprovalSelection } from './further-approval-selection';
import { ExpertReport } from './expert-report';
import { TotalLoss } from './total-loss';
import { Theft } from './theft';
import { ReportDamageClaimType } from '@cp-de/common';

type FurtherApprovalProps = {
    step: number;
    handleNext: () => void;
    handlePrev: () => void;
    reportDamageClaimType?: ReportDamageClaimType;
    saveReportDamageClaimType: (reportDamageClaimType: ReportDamageClaimType) => void;
    contractId: string;
    isAccipiens: boolean;
};

export const FurtherApproval: React.FC<FurtherApprovalProps> = ({
    step,
    reportDamageClaimType,
    saveReportDamageClaimType,
    handleNext,
    handlePrev,
    contractId,
    isAccipiens,
}) => {
    const selectedClaimType = useRef<ReportDamageClaimType>();

    /* effect used to save currently selected type of claim when FurtherApprovalSelection is unmounted */
    useEffect(() => {
        return () => {
            if (selectedClaimType.current) {
                saveReportDamageClaimType(selectedClaimType.current as ReportDamageClaimType);
            }
        };
    }, [saveReportDamageClaimType]);

    const onReportDamageClaimTypeSelect = (reportDamageClaimType: ReportDamageClaimType): void => {
        selectedClaimType.current = reportDamageClaimType;
    };

    return (
        <>
            {step === 1 && (
                <FurtherApprovalSelection
                    reportDamageClaimType={reportDamageClaimType}
                    onReportDamageClaimTypeSelect={onReportDamageClaimTypeSelect}
                    onReportDamageClaimTypeConfirm={saveReportDamageClaimType}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                />
            )}
            {reportDamageClaimType === ReportDamageClaimType.APPRAISAL && (
                <ExpertReport
                    isAccipiens={isAccipiens}
                    step={step}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    contractId={contractId}
                />
            )}
            {reportDamageClaimType === ReportDamageClaimType.TOTAL_LOSS && (
                <TotalLoss
                    isAccipiens={isAccipiens}
                    step={step}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    contractId={contractId}
                />
            )}
            {reportDamageClaimType === ReportDamageClaimType.THEFT && (
                <Theft
                    isAccipiens={isAccipiens}
                    step={step}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    contractId={contractId}
                />
            )}
        </>
    );
};
