import { isEmpty } from 'lodash';
import { AutoAboVehicleDetails, AutoAboContractDetails } from '@cp-de/common';

import { TFunction } from 'i18next';

export const isEmptyOrUndefined = (object: AutoAboVehicleDetails | AutoAboContractDetails): boolean =>
    isEmpty(object) || Object.values(object).every(value => !value);

export const getYesNoValue = (t: TFunction, value?: boolean): string | undefined =>
    value === undefined ? value : value ? t('yes') : t('no');
