import { LoginChoiceOffer, getLoginChoiceOfferEndpoint } from '@cp-de/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LoginChoiceOffer>({
    contentName: 'loginChoiceOffer',
    contentEndpoint: getLoginChoiceOfferEndpoint,
});

export default reducer;
export const fetchLoginChoice = fetchContent;
