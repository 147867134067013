import { getHeyCarMarketingCardEndpoint, HeycarMarketingCard } from '@cp-de/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<HeycarMarketingCard>({
    contentName: 'heycarMarketingCard',
    contentEndpoint: getHeyCarMarketingCardEndpoint,
});

export default reducer;
export const fetchHeycarMarketingCard = fetchContent;
