import React from 'react';
import { Dashboard } from '../../components/dashboard';

export const DashboardPageComponent: React.FC = () => {
    return <Dashboard />;
};

export const DashboardPage: React.FC = () => {
    return <DashboardPageComponent />;
};
