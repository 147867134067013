import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import autoLogoutSuccessReducer from '../components/auto-logout-success/AutoLogoutSuccessSlice';
import consentReducer from '../components/consent/ConsentSlice';
import contractsReducer from '../components/contracts/ContractsSlice';
import financialDetailsReducer from '../components/contracts/financial-details/FinancialDetailsSlice';
import pendingContractsReducer from '../components/contracts/PendingContractsSlice';
import autoAboContractsReducer from '../components/contracts/auto-abo/AutoAboContractsSlice';
import fourSalesContractsReducer from '../components/contracts/four-sales/FourSalesContractsSlice';
import aodContractsReducer from '../components/contracts/reservations/aod/AoDContractsSlice';
import buttonUrlReducer from '../components/dashboard-hero-teasers/ButtonUrlSlice';
import dashboardMarketingCardReducer from '../components/dashboard-marketing-cards/DashboardMarketingCardSlice';
import connectionProblemErrorReducer from '../components/error-pages/connection-problem-error/ConnectionProblemErrorSlice';
import forbiddenErrorPageReducer from '../components/error-pages/forbidden/ForbiddenErrorPageSlice';
import notFoundErrorPageReducer from '../components/error-pages/not-found/NotFoundErrorPageSlice';
import unauthorizedErrorPageReducer from '../components/error-pages/unauthorized/UnauthorizedErrorPageSlice';
import genericErrorReducer from '../components/error-pages/generic/GenericErrorPageSlice';
import faqReducer from '../components/faq/FaqSlice';
import greetingReducer from '../components/greeting/GreetingSlice';
import iconFooterReducer from '../components/icon-footer/IconFooterSlice';
import legalFooterReducer from '../components/legal-footer/LegalFooterSlice';
import legalTermsReducer from '../components/legal-terms/LegalTermsSlice';
import loginChoiceReducer from '../components/login-choice/LoginChoiceSlice';
import loginChoiceOfferReducer from '../components/login-choice-offer/LoginChoiceOfferSlice';
import myProfileReducer from '../components/my-profile/overview/MyProfileSlice';
import customerHappinessIndexReducer from '../components/customer-happiness-index/CustomerHappinessIndexSlice';
import myProfileAutoAboReducer from '../components/my-profile/overview/MyProfileAutoAboSlice';
import postboxConsentsReducer from '../components/postbox-consent/PostboxConsentsSlice';
import postboxDocumentsReducer from '../components/postbox/PostboxSlice';
import postboxFourSalesDocumentsReducer from '../components/postbox/PostboxFourSalesSlice';
import inboxDocumentsReducer from '../components/inbox/InboxSlice';
import privacyPolicyReducer from '../components/privacy-policy/PrivacyPolicySlice';
import licensingAuthoritiesReducer from '../components/request-document/request-document-component/request-document-form/request-registration-certificate/licensing-authorities/LicensingAuthoritiesSlice';
import customLicensingAuthoritiesReducer from '../components/request-document/request-document-component/request-document-form/request-registration-certificate/licensing-authorities/custom-licensing-authorities/CustomLicensingAuthoritiesSlice';
import requestCategoriesReducer from '../components/request-document/RequestCategoriesSlice';
import transactionsReducer from '../components/revenues-open-positions/TransactionsSlice';
import thirdPartyLicensesReducer from '../components/third-party-licenses/ThirdPartyLicensesSlice';
import heyCarMarketingCardReducer from '../components/total-early-settlement/heycar-marketing-card/HeycarMarketingCardSlice';
import usageAgreementReducer from '../components/usage-agreement/UsageAgreementSlice';
import whatsNewNotification from '../components/whats-new-notification/WhatsNewNotificationSlice';
import racContractsReducer from '../components/contracts/reservations/rac/RaCContractsSlice';
import digitalOfferPageReducer from '../components/digital-offer-page/DigitalOfferPageSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    greeting: greetingReducer,
    contracts: contractsReducer,
    transactions: transactionsReducer,
    pendingContracts: pendingContractsReducer,
    autoAboContracts: autoAboContractsReducer,
    aodContracts: aodContractsReducer,
    racContracts: racContractsReducer,
    fourSalesContracts: fourSalesContractsReducer,
    myProfile: myProfileReducer,
    customerHappinessIndex: customerHappinessIndexReducer,
    myProfileAutoAbo: myProfileAutoAboReducer,
    financialDetails: financialDetailsReducer,
    requestCategories: requestCategoriesReducer,
    consent: consentReducer,
    postboxConsents: postboxConsentsReducer,
    postboxDocuments: postboxDocumentsReducer,
    inboxDocuments: inboxDocumentsReducer,
    postboxFourSalesDocuments: postboxFourSalesDocumentsReducer,
    licensingAuthorities: licensingAuthoritiesReducer,
    customLicensingAuthorities: customLicensingAuthoritiesReducer,
    buttonUrl: buttonUrlReducer,
    digitalOffer: digitalOfferPageReducer,
    content: combineReducers({
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        autoLogoutSuccess: autoLogoutSuccessReducer,
        privacyPolicy: privacyPolicyReducer,
        dashboardMarketingCard: dashboardMarketingCardReducer,
        loginChoice: loginChoiceReducer,
        loginChoiceOffer: loginChoiceOfferReducer,
        notFoundErrorPage: notFoundErrorPageReducer,
        forbiddenErrorPage: forbiddenErrorPageReducer,
        unauthorizedErrorPage: unauthorizedErrorPageReducer,
        genericError: genericErrorReducer,
        usageAgreement: usageAgreementReducer,
        faq: faqReducer,
        connectionProblemError: connectionProblemErrorReducer,
        legalTerms: legalTermsReducer,
        thirdPartyLicenses: thirdPartyLicensesReducer,
        heyCarMarketingCard: heyCarMarketingCardReducer,
        whatsNewNotification: whatsNewNotification,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction): RootState => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
