export enum LoginOptions {
    VERIMI = 'verimi',
    VW = 'volkswagen',
    AUDI = 'audi',
    SEAT = 'seat',
    CUPRA = 'cupra',
    SKODA = 'skoda',
}

export enum IdpHint {
    VW = 'VWIDIdentityProvider',
    VERIMI = 'VERIMIIdentityProvider',
    AUDI = 'identitykitcpdeaudi',
    SEAT = 'identitykitcpdeseat',
    SKODA = 'identitykitcpdeskoda',
    CUPRA = 'identitykitcpdecupra',
    FSID = 'identitykit',
}
