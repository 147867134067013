import { Profile } from '@cp-de/common';
import { useMyProfile } from './overview';
import { useMyProfileAutoAbo } from './overview/useMyProfileAutoAbo';
import { useUserContractsDataAndFlags } from '../../Providers';

export const useMyProfileData = (): {
    data?: Profile;
    isLoading: boolean;
    loadingError: boolean;
    isAbo?: boolean;
    isAod?: boolean;
    isRac?: boolean;
} => {
    const {
        isFetchingStandardAllowed,
        isFetchingAboAllowed,
        isFetchingAodAllowed,
        isFetchingRacAllowed,
    } = useUserContractsDataAndFlags();

    const isAbo = !isFetchingStandardAllowed && isFetchingAboAllowed;
    const isAod = isFetchingStandardAllowed && isFetchingAodAllowed;
    const isRac = isFetchingStandardAllowed && isFetchingRacAllowed;

    const { data: myProfile, isLoading: isLoadingMyProfile, loadingError: loadingMyProfileError } = useMyProfile(
        isFetchingStandardAllowed,
    );
    const { data: myProfileAutoAbo, isLoading: isLoadingAbo, loadingError: loadingAboError } = useMyProfileAutoAbo(
        isAbo,
    );

    return !isFetchingStandardAllowed && !isAbo
        ? {
              loadingError: true,
              isLoading: false,
          }
        : {
              data: myProfile || myProfileAutoAbo,
              isAbo,
              isAod,
              isRac,
              isLoading: isLoadingMyProfile || isLoadingAbo,
              loadingError: !!loadingMyProfileError || !!loadingAboError,
          };
};
