import {
    AoDContract,
    AutoAboContract,
    FourSalesContract,
    Contract,
    ContractStatus,
    RaCContract,
    ContractType,
} from '@cp-de/common';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { Layout, Button } from '@vwfs-bronson/bronson-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { addContractPath } from '../../navigation/paths';
import {
    getActiveReservationContracts,
    getClosedOrCancelledReservationContracts,
    getActiveAutoAboContracts,
    getClosedOrCancelledAutoAboContracts,
    getActiveFourSalesContracts,
    getClosedOrCancelledFourSalesContracts,
} from '../../../utils';
import { ContractFilter } from './ContractFilter';
import { getFilters } from './getFilters';
import { initialValues, InitialValues } from './initialValues';
import { brandFilter, contractFilter, dateFilter } from './filtersUtils';
import { AllContracts } from './AllContracts';

type ContractsOverviewProps = {
    isFetchingStandardAllowed: boolean;
    isFetchingAboAllowed: boolean;
    isFetchingFourSalesAllowed: boolean;
    isFetchingAodAllowed: boolean;
    isFetchingRacAllowed: boolean;
    contracts?: Contract[];
    autoAboContracts?: AutoAboContract[];
    fourSalesContracts?: FourSalesContract[];
    aodContracts?: AoDContract[];
    racContracts?: RaCContract[];
};

type TrackerProps = {
    trackFilter: React.Dispatch<React.SetStateAction<boolean>>;
};
const FilterTracker: React.FC<TrackerProps> = ({ trackFilter }) => {
    useAnalyticsPageViewTracker('filterPage', true);
    trackFilter(true);
    return null;
};

export const ContractsOverview: React.FC<ContractsOverviewProps> = ({
    contracts,
    autoAboContracts,
    fourSalesContracts,
    aodContracts,
    racContracts,
    isFetchingStandardAllowed,
    isFetchingAboAllowed,
    isFetchingFourSalesAllowed,
    isFetchingAodAllowed,
    isFetchingRacAllowed,
}) => {
    const { t } = useTranslation();
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
    const [filters, setFilters] = useState<InitialValues>(initialValues);
    const [fActiveContracts, setFActiveContracts] = useState<Contract[]>([]);
    const [fExpiredOrTerminatedContracts, setFExpiredOrTerminatedContracts] = useState<Contract[]>([]);
    const [fActiveAutoAboContracts, setFActiveAutoAboContracts] = useState<AutoAboContract[]>([]);
    const [fClosedOrCancelledAutoAboContracts, setFClosedOrCancelledAutoAboContracts] = useState<AutoAboContract[]>([]);
    const [fActiveFourSalesContracts, setFActiveFourSalesContracts] = useState<FourSalesContract[]>([]);
    const [fClosedOrCancelledFourSalesContracts, setFClosedOrCancelledFourSalesContracts] = useState<
        FourSalesContract[]
    >([]);
    const [fActiveRaCContracts, setFActiveRaCContracts] = useState<RaCContract[]>([]);
    const [fClosedOrCancelledRaCContracts, setFClosedOrCancelledRaCContracts] = useState<RaCContract[]>([]);
    const [fActiveAoDContracts, setFActiveAoDContracts] = useState<AoDContract[]>([]);
    const [fClosedOrCancelledAoDContracts, setFClosedOrCancelledAoDContracts] = useState<AoDContract[]>([]);
    const [filterTracked, onFilterTracked] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const activeContracts = (contracts || []).filter(contract => contract.contractStatus === ContractStatus.ACTIVE);
        const expiredOrTerminatedContracts = (contracts || []).filter(
            contract => contract.contractStatus !== ContractStatus.ACTIVE,
        );

        const activeAutoAboContracts = getActiveAutoAboContracts(autoAboContracts || []) as AutoAboContract[];
        const closedOrCancelledAutoAboContracts = getClosedOrCancelledAutoAboContracts(
            autoAboContracts || [],
        ) as AutoAboContract[];

        const activeAoDContracts = getActiveReservationContracts(aodContracts || []) as AoDContract[];
        const closedOrCancelledAoDContracts = getClosedOrCancelledReservationContracts(
            aodContracts || [],
        ) as AoDContract[];

        const activeRaCContracts = getActiveReservationContracts(racContracts || []) as RaCContract[];
        const closedOrCancelledRaCContracts = getClosedOrCancelledReservationContracts(
            racContracts || [],
        ) as RaCContract[];

        const activeFourSalesContracts = getActiveFourSalesContracts(fourSalesContracts || []) as FourSalesContract[];
        const closedOrCancelledFourSalesContracts = getClosedOrCancelledFourSalesContracts(
            fourSalesContracts || [],
        ) as FourSalesContract[];

        let filteredActiveContracts = [] as Contract[];
        let filteredExpiredOrTerminatedContracts = [] as Contract[];
        let filteredActiveAutoAboContracts = [] as AutoAboContract[];
        let filteredClosedOrCancelledAutoAboContracts = [] as AutoAboContract[];

        let filteredActiveFourSalesContracts = [] as FourSalesContract[];
        let filteredClosedOrCancelledFourSalesContracts = [] as FourSalesContract[];

        let filteredActiveAoDContracts = [] as AoDContract[];
        let filteredClosedOrCancelledAoDContracts = [] as AoDContract[];
        let filteredActiveRaCContracts = [] as RaCContract[];
        let filteredClosedOrCancelledRaCContracts = [] as RaCContract[];

        switch (filters.filterContract) {
            case 'FINANCING':
                filteredActiveContracts = contractFilter(activeContracts, ContractType.FINANCING) as Contract[];
                filteredExpiredOrTerminatedContracts = contractFilter(
                    expiredOrTerminatedContracts,
                    ContractType.FINANCING,
                ) as Contract[];
                break;
            case 'LEASING':
                filteredActiveContracts = contractFilter(activeContracts, ContractType.LEASING) as Contract[];
                filteredExpiredOrTerminatedContracts = contractFilter(
                    expiredOrTerminatedContracts,
                    ContractType.LEASING,
                ) as Contract[];
                break;
            case 'SERVICE_MANAGEMENT':
                filteredActiveContracts = contractFilter(
                    activeContracts,
                    ContractType.SERVICE_MANAGEMENT,
                ) as Contract[];
                filteredExpiredOrTerminatedContracts = contractFilter(
                    expiredOrTerminatedContracts,
                    ContractType.SERVICE_MANAGEMENT,
                ) as Contract[];
                break;
            case 'AUTO_ABO':
                filteredActiveAutoAboContracts = contractFilter(
                    activeAutoAboContracts,
                    ContractType.AUTO_ABO,
                ) as AutoAboContract[];
                filteredClosedOrCancelledAutoAboContracts = contractFilter(
                    closedOrCancelledAutoAboContracts,
                    ContractType.AUTO_ABO,
                ) as AutoAboContract[];
                break;
            case 'FOUR_SALES':
                filteredActiveFourSalesContracts = contractFilter(
                    activeFourSalesContracts,
                    ContractType.FOUR_SALES,
                ) as FourSalesContract[];
                filteredClosedOrCancelledFourSalesContracts = contractFilter(
                    closedOrCancelledFourSalesContracts,
                    ContractType.FOUR_SALES,
                ) as FourSalesContract[];
                break;
            case 'AUDI_ON_DEMAND':
                filteredActiveAoDContracts = contractFilter(
                    activeAoDContracts,
                    ContractType.AUDI_ON_DEMAND,
                ) as AoDContract[];
                filteredClosedOrCancelledAoDContracts = contractFilter(
                    closedOrCancelledAoDContracts,
                    ContractType.AUDI_ON_DEMAND,
                ) as AoDContract[];
                break;
            case 'RENT_A_CAR':
                filteredActiveRaCContracts = contractFilter(
                    activeRaCContracts,
                    ContractType.RENT_A_CAR,
                ) as RaCContract[];
                filteredClosedOrCancelledRaCContracts = contractFilter(
                    closedOrCancelledRaCContracts,
                    ContractType.RENT_A_CAR,
                ) as RaCContract[];
                break;
            default:
                filteredActiveContracts = [...activeContracts];
                filteredExpiredOrTerminatedContracts = [...expiredOrTerminatedContracts];
                filteredActiveAutoAboContracts = [...activeAutoAboContracts];
                filteredClosedOrCancelledAutoAboContracts = [...closedOrCancelledAutoAboContracts];
                filteredActiveFourSalesContracts = [...activeFourSalesContracts];
                filteredClosedOrCancelledFourSalesContracts = [...closedOrCancelledFourSalesContracts];
                filteredActiveAoDContracts = [...activeAoDContracts];
                filteredClosedOrCancelledAoDContracts = [...closedOrCancelledAoDContracts];
                filteredActiveRaCContracts = [...activeRaCContracts];
                filteredClosedOrCancelledRaCContracts = [...closedOrCancelledRaCContracts];
        }
        if (filters.filterBrands.length) {
            filteredActiveContracts = brandFilter(filteredActiveContracts, filters.filterBrands) as Contract[];
            filteredExpiredOrTerminatedContracts = brandFilter(
                filteredExpiredOrTerminatedContracts,
                filters.filterBrands,
            ) as Contract[];
            filteredActiveAutoAboContracts = brandFilter(
                filteredActiveAutoAboContracts,
                filters.filterBrands,
            ) as AutoAboContract[];
            filteredClosedOrCancelledAutoAboContracts = brandFilter(
                filteredClosedOrCancelledAutoAboContracts,
                filters.filterBrands,
            ) as AutoAboContract[];
            filteredActiveAoDContracts = brandFilter(filteredActiveAoDContracts, filters.filterBrands) as AoDContract[];
            filteredClosedOrCancelledAoDContracts = brandFilter(
                filteredClosedOrCancelledAoDContracts,
                filters.filterBrands,
            ) as AoDContract[];
            filteredActiveRaCContracts = brandFilter(filteredActiveRaCContracts, filters.filterBrands) as RaCContract[];
            filteredClosedOrCancelledRaCContracts = brandFilter(
                filteredClosedOrCancelledRaCContracts,
                filters.filterBrands,
            ) as RaCContract[];
            filteredActiveFourSalesContracts = brandFilter(
                filteredActiveFourSalesContracts,
                filters.filterBrands,
            ) as FourSalesContract[];
            filteredClosedOrCancelledFourSalesContracts = brandFilter(
                filteredClosedOrCancelledFourSalesContracts,
                filters.filterBrands,
            ) as FourSalesContract[];
        }
        if (filters.filterStartDate || filters.filterEndDate) {
            filteredActiveContracts = dateFilter(
                filteredActiveContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as Contract[];
            filteredExpiredOrTerminatedContracts = dateFilter(
                filteredExpiredOrTerminatedContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as Contract[];
            filteredActiveAutoAboContracts = dateFilter(
                filteredActiveAutoAboContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as AutoAboContract[];
            filteredClosedOrCancelledAutoAboContracts = dateFilter(
                filteredClosedOrCancelledAutoAboContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as AutoAboContract[];
            filteredActiveAoDContracts = dateFilter(
                filteredActiveAoDContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as AoDContract[];
            filteredClosedOrCancelledAoDContracts = dateFilter(
                filteredClosedOrCancelledAoDContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as AoDContract[];
            filteredActiveRaCContracts = dateFilter(
                filteredActiveRaCContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as RaCContract[];
            filteredClosedOrCancelledRaCContracts = dateFilter(
                filteredClosedOrCancelledRaCContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as RaCContract[];
            filteredActiveFourSalesContracts = dateFilter(
                filteredActiveFourSalesContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as FourSalesContract[];
            filteredClosedOrCancelledFourSalesContracts = dateFilter(
                filteredClosedOrCancelledFourSalesContracts,
                filters.filterStartDate,
                filters.filterEndDate,
            ) as FourSalesContract[];
        }
        setFActiveContracts(filteredActiveContracts);
        setFExpiredOrTerminatedContracts(filteredExpiredOrTerminatedContracts);
        setFActiveAutoAboContracts(filteredActiveAutoAboContracts);
        setFClosedOrCancelledAutoAboContracts(filteredClosedOrCancelledAutoAboContracts);
        setFActiveFourSalesContracts(filteredActiveFourSalesContracts);
        setFClosedOrCancelledFourSalesContracts(filteredClosedOrCancelledFourSalesContracts);
        setFActiveAoDContracts(filteredActiveAoDContracts);
        setFClosedOrCancelledAoDContracts(filteredClosedOrCancelledAoDContracts);
        setFActiveRaCContracts(filteredActiveRaCContracts);
        setFClosedOrCancelledRaCContracts(filteredClosedOrCancelledRaCContracts);
    }, [filters, contracts, autoAboContracts, fourSalesContracts, aodContracts, racContracts]);

    const rightPaddingClass = 'u-pr  u-pr-none@m';

    const handleFilters = (filterValues: InitialValues): void => {
        setFilters(filterValues);
    };

    const filtersValues = getFilters(t, contracts, autoAboContracts, aodContracts, racContracts, fourSalesContracts);

    return (
        <>
            <div className={rightPaddingClass}>
                <Layout className={'row'}>
                    <Layout.Item default="1/3" l="1/1">
                        <h3>{t('contracts:overview.my-contracts')}</h3>
                    </Layout.Item>
                    <Layout.Item className={'u-text-right@l-up u-mt-none@l u-mb@l'} default="2/3" l="1/1">
                        <Button
                            className={'u-hide@s u-mr'}
                            icon={'filter-02'}
                            type="button"
                            testId="filter-button"
                            onClick={(): void => setIsFilterVisible(!isFilterVisible)}
                            secondary
                        >
                            {t('contracts:overview.buttons.filter')}
                        </Button>
                        <Button
                            icon={'semantic-add'}
                            type="button"
                            testId="add-contract-button"
                            onClick={(): void => history.push(addContractPath())}
                            secondary
                        >
                            {t('contracts:overview.buttons.add-contract')}
                        </Button>
                    </Layout.Item>
                </Layout>
                <AllContracts
                    fActiveContracts={fActiveContracts}
                    fExpiredOrTerminatedContracts={fExpiredOrTerminatedContracts}
                    fActiveAutoAboContracts={fActiveAutoAboContracts}
                    fClosedOrCancelledAutoAboContracts={fClosedOrCancelledAutoAboContracts}
                    fActiveFourSalesContracts={fActiveFourSalesContracts}
                    fClosedOrCancelledFourSalesContracts={fClosedOrCancelledFourSalesContracts}
                    fActiveAoDContracts={fActiveAoDContracts}
                    fClosedOrCancelledAoDContracts={fClosedOrCancelledAoDContracts}
                    fActiveRaCContracts={fActiveRaCContracts}
                    fClosedOrCancelledRaCContracts={fClosedOrCancelledRaCContracts}
                    isFetchingStandardAllowed={isFetchingStandardAllowed}
                    isFetchingAboAllowed={isFetchingAboAllowed}
                    isFetchingFourSalesAllowed={isFetchingFourSalesAllowed}
                    isFetchingAodAllowed={isFetchingAodAllowed}
                    isFetchingRacAllowed={isFetchingRacAllowed}
                />
            </div>
            <ContractFilter
                isFilterVisible={isFilterVisible}
                setIsFilterVisible={setIsFilterVisible}
                handleFilters={handleFilters}
                brandList={filtersValues.brandList}
                contractList={filtersValues.contractTypes}
            />
            {(isFilterVisible || filterTracked) && <FilterTracker trackFilter={onFilterTracked} />}
        </>
    );
};
