import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { InboxUi } from './ui';
import { useInbox } from './useInbox';
import { useTranslation } from 'react-i18next';
import { MyDocumentsLoadingPlaceholder, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useUserContractsDataAndFlags } from '../../Providers';
import { CombinedContractType, isStandardContract } from '../../utils';

const InboxWithHandlers = withLoadingAndNoConnectionHandler(InboxUi);

type InboxProps = {
    contractId?: string;
};

export const Inbox: React.FC<InboxProps> = ({ contractId }) => {
    const { t } = useTranslation('postbox');

    const {
        contracts: { data: contracts, isLoading: isLoadingContracts },
        fourSalesContracts: { data: fourSalesContracts, isLoading: isFourSalesContractsLoading },
    } = useUserContractsDataAndFlags();

    const {
        data: inboxDocuments,
        isLoading: isDocumentsLoading,
        loadingError: loadingErrorInboxDocuments,
    } = useInbox();

    const isLoading = isDocumentsLoading || isLoadingContracts || isFourSalesContractsLoading;
    const isInboxDocumentsForbiddenError = loadingErrorInboxDocuments?.status === 403;

    const allContracts = [...(contracts || []), ...(fourSalesContracts || [])] as CombinedContractType[];

    let contractNumber;

    if (contractId) {
        const contract = allContracts.find(contract => contract.contractId === contractId);
        contractNumber = contract
            ? isStandardContract(contract)
                ? contract.contractNumber
                : contract.contractId
            : undefined;
    }
    return (
        <>
            {!!loadingErrorInboxDocuments && !isInboxDocumentsForbiddenError && (
                <Notification status={NotificationStatus.warning} className={'u-mb'}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('error-messages.connection-error-inbox'),
                        }}
                    />
                </Notification>
            )}

            <InboxWithHandlers
                isLoading={isLoading}
                contracts={allContracts}
                contractNumber={contractNumber}
                inboxDocumentsOriginal={inboxDocuments}
                hasError={!!loadingErrorInboxDocuments}
                errorText={t('error-messages.connection-error-inbox')}
                loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
            />
        </>
    );
};
