export const checkDateRange = (monthFrom: number, monthTo: number, dayFrom: number, dayTo: number): boolean => {
    const currDate = new Date();
    let isInRange = false;
    const dateFrom = new Date(currDate.getFullYear(), monthFrom, dayFrom);
    const dateTo = new Date(currDate.getFullYear(), monthTo, dayTo);
    if (dateFrom <= currDate && currDate <= dateTo) {
        isInRange = true;
    }
    return isInRange;
};
