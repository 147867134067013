import { TotalLossFormValues, TotalLossStep1FormValues, TotalLossStep2FormValues } from '../types';
import { getInitialValues as getCommonInsuranceFormInitialValues } from '../../common/common-insurance-form';

export const getStep1InitialValues = (
    isAccipiens: boolean,
    defaultValues?: TotalLossFormValues,
): TotalLossStep1FormValues => {
    const shape: TotalLossStep1FormValues = {
        responsibleInsurance: defaultValues?.responsibleInsurance || '',
        policyHolder: defaultValues?.policyHolder || '',
        transferInsurance: defaultValues?.transferInsurance || '',
        redeemLoan: defaultValues?.redeemLoan || '',
        insuranceClaim: defaultValues?.insuranceClaim || [],
    };
    if (isAccipiens) {
        shape['damageDate'] = defaultValues?.damageDate || '';
    }
    return shape;
};

export const getStep2InitialValues = (
    isAccipiens: boolean,
    defaultValues?: TotalLossFormValues,
    showInsuranceForm?: boolean,
): TotalLossStep2FormValues => {
    const shape: TotalLossStep2FormValues = {
        insurance: getCommonInsuranceFormInitialValues(
            isAccipiens,
            showInsuranceForm ? defaultValues?.insurance : undefined,
        ),
    };
    if (!showInsuranceForm || !isAccipiens) {
        shape['vehicleSold'] = defaultValues?.vehicleSold || '';
        shape['attachments'] = defaultValues?.attachments || [];
    }
    if (!isAccipiens) {
        shape['replacementValue'] = showInsuranceForm ? defaultValues?.replacementValue || '' : '';
    }
    return shape;
};

export const getInitialValues = (isAccipiens: boolean): TotalLossFormValues => {
    return {
        ...getStep1InitialValues(isAccipiens),
        ...getStep2InitialValues(isAccipiens),
    };
};
