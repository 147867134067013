import React from 'react';
import { Contract, EligibleForMileageChangeType, FinancialDetails } from '@cp-de/common';
import { DataOverview, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { testIds } from '../../Helper';

export type MileageInfoViewProps = {
    contract: Contract;
    financialDetails: FinancialDetails;
    cancelEditing: () => void;
};
export const MileageInfoView: React.FC<MileageInfoViewProps> = ({ contract, financialDetails, cancelEditing }) => {
    const { t } = useTranslation('contracts');
    const eligibleForMileageChange: EligibleForMileageChangeType | undefined =
        financialDetails.eligibleForMileageChange;
    const hasDealerData =
        contract.dealer &&
        contract.dealer.name &&
        contract.dealer.address &&
        (contract.dealer.contact?.email || contract.dealer.contact?.phone);
    const dealerAddress = {
        name: contract.dealer?.name,
        street: contract.dealer?.address?.street,
        houseNumber: contract.dealer?.address?.houseNumber,
        city: contract.dealer?.address?.city,
    };

    return (
        <DataOverview title={t('financial-details.yearly-mileage-section.edit-view.title')} withoutCardEffect={true}>
            {eligibleForMileageChange === EligibleForMileageChangeType.ERROR && (
                <Notification className={'u-mb'} status={NotificationStatus.error}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('financial-details.yearly-mileage-section.edit-view.info-view-messages.error'),
                        }}
                    />
                </Notification>
            )}
            {eligibleForMileageChange === EligibleForMileageChangeType.FORWARD_TO_DEALER && (
                <Notification className={'u-mb'} status={NotificationStatus.info}>
                    {t('financial-details.yearly-mileage-section.edit-view.info-view-messages.forwardToDealer')}
                    {hasDealerData && (
                        <>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'financial-details.yearly-mileage-section.edit-view.info-view-messages.dealerAddress',
                                        dealerAddress,
                                    ),
                                }}
                            />
                            {contract.dealer?.contact?.phone && (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'financial-details.yearly-mileage-section.edit-view.info-view-messages.dealerPhone',
                                            { phone: contract.dealer.contact.phone },
                                        ),
                                    }}
                                />
                            )}
                            {contract.dealer?.contact?.email && (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'financial-details.yearly-mileage-section.edit-view.info-view-messages.dealerEmail',
                                            { email: contract.dealer.contact.email },
                                        ),
                                    }}
                                />
                            )}
                        </>
                    )}
                </Notification>
            )}
            {eligibleForMileageChange === EligibleForMileageChangeType.NO && (
                <Notification className={'u-mb'} status={NotificationStatus.warning}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(
                                'financial-details.yearly-mileage-section.edit-view.info-view-messages.notAllowed',
                            ),
                        }}
                    />
                </Notification>
            )}
            <ButtonContainer className={'u-mt'} center>
                <Button secondary onClick={cancelEditing} testId={testIds.cancel}>
                    {t('translation:editableSectionNav.cancel')}
                </Button>
            </ButtonContainer>
        </DataOverview>
    );
};
