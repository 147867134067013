import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useParams } from 'react-router-dom';
import { CancelContract } from '../../components/cancel-contract';
import { useTranslation } from 'react-i18next';

export const CancelContractPage: React.FC = () => {
    const { contractId } = useParams<{ contractId: string }>();
    const { t } = useTranslation('auto-abo-cancel-contract');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <CancelContract contractId={contractId} />
        </ContentSection>
    );
};
