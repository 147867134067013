import { AutoLogoutSuccess, getAutoLogoutSuccessEndpoint } from '@cp-de/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<AutoLogoutSuccess>({
    contentName: 'autoLogoutSuccess',
    contentEndpoint: getAutoLogoutSuccessEndpoint,
});

export default reducer;
export const fetchAutoLogoutSuccess = fetchContent;
