import { TFunction } from 'i18next';
import {
    AdditionalProduct,
    AoDContract,
    CancelReservationEmailRequest,
    RaCContract,
    DealerOpeningHours,
    Qualifier,
} from '@cp-de/common';
import { getVehicleClassByAcrissCode } from '../../contracts/reservations/common/vehicle-details/helpers';
import { capitalize } from 'lodash';

const allowedProductCodes = [
    'PAI',
    'CDW_LOW',
    'CDW_NORMAL',
    'CDW_1',
    'CDW_2',
    'CDW_3',
    'CDW_4',
    '6',
    '7',
    '9',
    '10',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '22',
    '26',
    '27',
    '29',
    '45',
];

const getProductNameTranslation = (t: TFunction, product: AdditionalProduct): string => {
    if (!product.code) {
        return '';
    }
    switch (product.code) {
        case 'PAI':
            return `${t('product-codes.pai.name')} ${t('product-codes.pai.value')}`;
        case 'CDW_LOW':
        case 'CDW_NORMAL':
        case 'CDW_1':
        case 'CDW_2':
        case 'CDW_3':
        case 'CDW_4':
            const cdwValue = product.qualifiers?.find((el: Qualifier) => el.id === 'insurance_text');
            return `${t('product-codes.cdw.name')} ${t(
                'product-codes.cdw.value',
                cdwValue && { amount: cdwValue.stringValue },
            )}`;
        case '19':
        case '26':
            return t('product-codes.19-26');
        case '22':
        case '27':
            return t('product-codes.22-27');
        default:
            return t(`product-codes.${product.code.toLowerCase()}`);
    }
};

export const createCancelReservationEmailRequest = (
    t: TFunction,
    reservationContract: AoDContract | RaCContract,
): CancelReservationEmailRequest => {
    const { details, contractId } = reservationContract;
    const openingHours = (details.dealerDetails?.openingHours || [])
        .filter((openingHour: DealerOpeningHours) => openingHour.dayOfWeek && openingHour.opens && openingHour.closes)
        .map(
            (openingHour: DealerOpeningHours) =>
                `${t('opening-hours.' + openingHour.dayOfWeek)} ${openingHour.opens} - ${openingHour.closes}`,
        )
        .join(';');

    const additionalProducts: string =
        reservationContract.additionalProducts
            ?.filter((product: AdditionalProduct) => {
                return product?.code && allowedProductCodes.includes(product.code);
            })
            .map((product: AdditionalProduct) => getProductNameTranslation(t, product))
            .filter((product: string) => product)
            .join(';') || '';

    const excessMileagePriceAmount: number = reservationContract.excessMileagePriceAmount
        ? reservationContract.excessMileagePriceAmount
        : 0;

    return {
        status: 'Initialized',
        data: {
            excessMileagePriceAmount,
            miete6ContractId: contractId,
            customerData: {
                firstName: details.customerDetails?.identity?.firstName,
                lastName: details.customerDetails?.identity?.lastName,
                contactData: {
                    email: details.customerDetails?.contactDetails?.email,
                },
            },
            vehicleData: {
                model: {
                    name: details.vehicleDetails?.carModel,
                    vehicleImageUrl: details.vehicleDetails?.carImage || '',
                    year: details.vehicleDetails?.modelYear,
                    colorExterior: capitalize(details.vehicleDetails?.bodyColorName),
                    engine: {
                        fuelType: details.vehicleDetails?.fuelType,
                        power: details.vehicleDetails?.enginePowerValue,
                    },
                    transmissionType: capitalize(details.vehicleDetails?.transmission),
                },
                acrissCode: getVehicleClassByAcrissCode(t, details.vehicleDetails?.acrissCode),
            },
            dealerData: {
                companyName: details.dealerDetails?.companyName,
                contactData: {
                    email: details.dealerDetails?.contactData?.email,
                    telephoneCountryCode: details.dealerDetails?.contactData?.telephoneCountryCode,
                    telephoneNumber: details.dealerDetails?.contactData?.telephoneNumber,
                },
                address: {
                    street: details.dealerDetails?.address?.street,
                    houseNumber: details.dealerDetails?.address?.houseNumber,
                    zipCode: details.dealerDetails?.address?.zipCode,
                    city: details.dealerDetails?.address?.city,
                    state: details.dealerDetails?.address?.city,
                },
                openingHours,
            },
            financialProduct: {
                contract: {
                    startDate: reservationContract.startDate,
                    startTime: reservationContract.startTime,
                    endDate: reservationContract.endDate,
                    endTime: reservationContract.endTime,
                },
                calculation: {
                    contractMileage: reservationContract.unlimitedMileage
                        ? 'unbegrenzt'
                        : reservationContract.contractMileage + '',
                    excessMileagePriceAmount,
                },
                additionalProducts: additionalProducts,
                totalAmount: {
                    grossAmount: reservationContract.price,
                },
            },
        },
        eventDefinitionKey: 'CPDE_Multibrand_TerminationConfirmation',
        isPersonAccount: true,
        receiverEmail: details.customerDetails?.contactDetails?.email,
    };
};
