import * as Yup from 'yup';
import { LoginChoiceFormFields } from './LoginChoiceFormFields';
import { LoginChoice } from '@cp-de/common';
import { AnyObject } from 'yup/lib/types';

export const loginChoiceValidationSchema = (loginChoiceContent: LoginChoice): Yup.ObjectSchema<AnyObject> => {
    const validations: Record<keyof LoginChoiceFormFields, Yup.StringSchema | Yup.BooleanSchema> = {
        email: Yup.string()
            .trim()
            .required(loginChoiceContent.email.empty)
            .email(loginChoiceContent.email.invalid),
    };
    return Yup.object().shape(validations);
};
