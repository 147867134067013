import React from 'react';

import { useContract } from '../contracts/useContract';
import { useFinancialDetails } from '../contracts/financial-details';
import { useTranslation } from 'react-i18next';
import { EarlyTerminationUi } from './ui';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

const EarlyTerminationWithHandlers = withLoadingAndNoConnectionHandler(EarlyTerminationUi);

type EarlyTerminationProps = {
    contractId: string;
};

export const EarlyTermination: React.FC<EarlyTerminationProps> = ({ contractId }) => {
    const { t } = useTranslation('early-termination');
    const { data: contractState, isLoading: isContractLoading, loadingError: contractLoadingError } = useContract(
        contractId,
    );

    const {
        data: financialDetails,
        isLoading: isFinancialDetailsLoading,
        loadingError: financialDetailsLoadingError,
    } = useFinancialDetails(contractState?.contractId || '');

    const loadingError = contractLoadingError || financialDetailsLoadingError;
    const isLoading = isContractLoading || isFinancialDetailsLoading;

    return (
        <EarlyTerminationWithHandlers
            isLoading={isLoading}
            contract={contractState}
            financialDetails={financialDetails}
            hasError={!!loadingError}
            errorText={t('error.no-connection')}
        />
    );
};
