import { CombinedContractType } from 'utils';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { arrearPaymentPagePath } from '../../navigation/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '@vwfs-bronson/bronson-react';

export type UnpaidAlertNotificationProps = {
    contract: CombinedContractType;
    testId?: string;
};

export const UnpaidAlertNotification: React.FC<UnpaidAlertNotificationProps> = ({ contract, testId }) => {
    const { t } = useTranslation('contracts');
    const history = useHistory();

    let unpaidsNotification = null;
    if (contract.outstandingPayment) {
        const notificationContent = (
            <>
                <span>{t('unpaid-alert.text')}</span>
                <br /> <br />
                <span>
                    <strong>{t('unpaid-alert.bold-text')}</strong>
                </span>
                <br />
                <Button
                    secondary
                    className="u-bg-white u-mt"
                    onClick={(): void => history.push(arrearPaymentPagePath(contract.contractId))}
                    testId={'unpaid-alert-button'}
                >
                    {t('unpaid-alert.button-text')}
                </Button>
            </>
        );
        unpaidsNotification = (
            <Notification
                className={'u-mb c-notification--context'}
                status={NotificationStatus.warning}
                headline={t('unpaid-alert.headline')}
                testId={testId || 'unpaid-alert'}
            >
                {notificationContent}
            </Notification>
        );
    }
    return unpaidsNotification;
};
