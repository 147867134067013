import {
    CustomerReservationAddress,
    CustomerReservationContactDetails,
    CustomerReservationDetails,
    CustomerReservationIdentity,
    DayOfWeek,
    DealerDetails,
    VehicleDetails,
} from '@cp-de/common';

export const exampleDealerDetailsWithAllValues: DealerDetails = {
    companyName: 'company name',
    contactData: {
        mobilePhoneCountryCode: '+49',
        mobilePhoneNumber: '0176123823',
        email: 'info@blackwatermotors.ie',
        telephoneCountryCode: '+49',
        telephoneNumber: '030981233',
    },
    address: { street: 'Goethestraße', houseNumber: '29a', zipCode: '10162', city: 'Berlin' },
    openingHours: [
        {
            opens: '09:00',
            closes: '21:00',
            dayOfWeek: DayOfWeek.MONDAY,
        },
        {
            opens: '09:00',
            closes: '21:00',
            dayOfWeek: DayOfWeek.TUESDAY,
        },
        {
            opens: '09:00',
            closes: '21:00',
            dayOfWeek: DayOfWeek.WEDNESDAY,
        },
        {
            opens: '09:00',
            closes: '21:00',
            dayOfWeek: DayOfWeek.THURSDAY,
        },
    ],
};

export const exampleDealerDetailsWithMissingValues: DealerDetails = {
    companyName: 'company name',
    contactData: {},
    address: {},
};

export const exampleVehicleDetailsWithAllValues: VehicleDetails = {
    registrationNumber: 'MHR-U735',
    vin: 'WAUZZZ4L4FD029375',
    carBrand: 'Audi',
    carModel: 'A1',
    modelVariant: 'sportback',
    modelYear: 2020,
    descriptionLine: 'Audi A1 TDI sportback',
    bodyColorName: 'white',
    engineTechnology: 'TDI',
    enginePowerValue: '110',
    enginePowerUnit: 'kW',
    transmission: 'automatic',
    fuelType: 'Diesel',
    acrissCode: 'LSAR',
    carImage:
        'https://mediaservice.audi.com/media/fast/H4sIAAAAAAAAAFvzloG1tIiBOTrayfuvpGh6-m1zJgaGigIGBgZGoDhTtNOaz-I_2DhCHkCFmWQZmAvy0hmBClKYODNzE9NT9YF8fkbuQnPd1IoS3bzU3Hx2oKwgz2yuQ4uaC1KEQ196JhTFP9_0xmPnRgaeY4_2FBW8Ub3ZGJNUlDb1wkdBtk0rGHgmpIaobH-U43Rmbe2zlrqNNgX6K2UYeO7PuVxbn8gcsFq9-Zzpj-AIzooIWwaeS371jBuTfx2NTtzH5GI9JfDTZz8vBh4mluJlJ-Vuh9RfZFbVOrUx4WU_DxMDj1oa0_ryLpYja64zS8ycxud3Y3HOOwaeDdahOcycsw35fvxpPuLjc8alLwpo28ppVv8Zzjnn7tfgPc4dPena3K2TzBh4JK4cm9z0iU9ML1WgI-n0ccY59StvM_DMbPj-9E6KyYaI3BUfA8xuvWutyZRl4Dnxff6Z0M-vOp5Zbzqw1O5dwutLeyUYeLYVBJk9fbS69Dhj620fxm7PQwdnczPw5D39EPktZNbFi5mhk7cHsiVNrLK9yMATvm7Fj-I9T8qCuPUfrvyxf1VE-lZFBh7vQ_kfv0nPMw-KS4x6PT9fRsnynCQDT3b80o-7qq8xLp7yRkyAZeH5iQKzTzOwAmOGRQxEOAEJ7n1AguM2A5gExVkIiLAC8VksmBkY2BtADAYQ4BMuLcopSCxKzNUrSi0uyM8rzixLFdQwIBIIs_o4RroGAQDnScljUAIAAA',
};

export const exampleVehicleDetailsWithMissingValues: VehicleDetails = {
    registrationNumber: 'MHR-U735',
    vin: 'WAUZZZ4L4FD029375',
    enginePowerValue: '110',
};

export const exampleReservationIdentityWithAllValues: CustomerReservationIdentity = {
    title: 'PROF_DR',
    firstName: 'Max',
    lastName: 'Mustermann',
};

export const exampleReservationIdentityWithMissingValues: CustomerReservationIdentity = {
    title: 'PROF_DR',
    lastName: 'Mustermann',
};

export const exampleReservationAddressesWithAllValues: CustomerReservationAddress[] = [
    {
        addressType: 'PRIMARY',
        street: 'Hauptstr.',
        houseNumber: '12',
        zipCode: '12345',
        city: 'Berlin',
        countryCode: 'DE',
    },
    {
        addressType: 'PRIMARY',
        street: 'Hausstr.',
        houseNumber: '13',
        zipCode: '56789',
        city: 'Bonn',
        countryCode: 'DE',
    },
];

export const exampleReservationAddressesWithMissingValues: CustomerReservationAddress[] = [
    {
        addressType: 'PRIMARY',
        street: 'Hauptstr.',
        city: 'Berlin',
        countryCode: 'DE',
    },
];

export const exampleReservationContactDetailsWithAllValues: CustomerReservationContactDetails = {
    mobilePhoneCountryCode: '+49',
    mobilePhoneNumber: '123456789101',
    email: 'example@example.org',
};

export const exampleReservationContactDetailsWithMissingValues: CustomerReservationContactDetails = {
    email: 'example@example.org',
};

export const exampleReservationCustomerDetailsWithAllValues: CustomerReservationDetails = {
    identity: exampleReservationIdentityWithAllValues,
    addresses: exampleReservationAddressesWithAllValues,
    contactDetails: exampleReservationContactDetailsWithAllValues,
};

export const exampleReservationCustomerDetailsWithMissingValues: CustomerReservationDetails = {
    identity: exampleReservationIdentityWithMissingValues,
    addresses: exampleReservationAddressesWithMissingValues,
    contactDetails: exampleReservationContactDetailsWithMissingValues,
};
