import React from 'react';
import { Contract } from '@cp-de/common';
import { AddContractForm } from './ui';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

type AddContractProps = {
    contracts?: Contract[];
};

export const AddContract: React.FC<AddContractProps> = ({ contracts }) => {
    useAnalyticsPageViewTracker('addContract', true);
    return (
        <>
            <AddContractForm contracts={contracts} />
        </>
    );
};
