import React, { useState, useEffect } from 'react';
import { Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from 'cp-xhr';

import { InboxDocument, PostboxDocumentFile } from '@cp-de/common';
import {
    ContractIdentifierFilterItem,
    DocumentTypeFilterItem,
    Notification,
    NotificationStatus,
    Postbox,
    PostboxFilterProps,
    PostboxTableProps,
    TimeFrameFilterItem,
    PostboxDocument as InboxDocumentRow,
} from '@cp-shared-8/frontend-ui';
import { getAvailableTimeFrames } from 'components/postbox';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { CombinedContractType, isFourSalesContract } from 'utils';
import { CenteredSpinner } from 'components/centered-spinner';
import { ContractFilterItem } from 'components/postbox/ui';
import base64ToBlob from 'b64-to-blob';

export const testIds = {
    downloadFailureModal: 'downloadFailureModal',
};

export const InboxUi: React.FC<{
    inboxDocumentsOriginal?: InboxDocument[];
    contracts?: CombinedContractType[];
    contractNumber?: string;
}> = ({ inboxDocumentsOriginal, contracts, contractNumber }) => {
    const { t } = useTranslation('postbox');
    const [inboxDocuments, setInboxDocuments] = useState<InboxDocument[]>(
        inboxDocumentsOriginal ? inboxDocumentsOriginal : [],
    );
    const [loadingDocuments, setLoadingDocuments] = useState<{ [key: number]: boolean }>({});
    // Download States
    const [startDownload, setStartDownload] = useState<number | null>(null);
    const [finishDownload, setFinishDownload] = useState<number | null>(null);
    const [downloadError, setDownloadError] = useState<boolean>(false);
    // Deletion States
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedDocumentToDelete, setSelectedDocumentToDelete] = useState<null | InboxDocument>();
    const [deletionLoading, setDeletionLoading] = useState(false);
    const [deleteError, setDeleteError] = useState<boolean>(false);
    const [deletionSuccess, setDelitionSuccess] = useState<boolean>(false);

    // UseEffect has to be used to update the loadingDocuments state. Otherwise it is not ensured that the latest loadingDocumets is taken for setLoadingDocuments if several document downloads are started in paralell (due to the asynchronious state update).
    useEffect(() => {
        if (startDownload !== null && !loadingDocuments[startDownload]) {
            setLoadingDocuments({ ...loadingDocuments, [startDownload]: true });
            setStartDownload(null);
        }
        if (finishDownload !== null && loadingDocuments[finishDownload]) {
            setLoadingDocuments({ ...loadingDocuments, [finishDownload]: false });
            setFinishDownload(null);
        }
    }, [startDownload, finishDownload, loadingDocuments]);

    const downloadDocument = (index: number, inboxDocument: InboxDocument): void => {
        setStartDownload(index);
        const { _downloadLink, description, creationDate } = inboxDocument;

        CpDataApi.get(_downloadLink)
            .then(response => {
                const { content }: PostboxDocumentFile = response.data;
                const blob = base64ToBlob(content, response.data.mediaType);
                const extension = response.data.mediaType !== 'application/pdf' ? 'tif' : 'pdf';
                const filename = `${description}${contractNumber || ''}_${formatCpDate(creationDate).format(
                    'DDMMYYYY',
                )}.${extension}`;
                saveAs(blob, filename);
                setFinishDownload(index);
                if (inboxDocuments) {
                    const updatedInboxDocuments = [...inboxDocuments];
                    updatedInboxDocuments[index] = { ...updatedInboxDocuments[index], isRead: true };
                    setInboxDocuments(updatedInboxDocuments);
                }
            })
            .catch(() => {
                setFinishDownload(index);
                setDownloadError(true);
            });
    };

    const deleteDocument = (): void => {
        if (selectedDocumentToDelete) {
            setDeletionLoading(true);
            setDelitionSuccess(false);

            CpDataApi.delete(selectedDocumentToDelete?._downloadLink)
                .then(() => {
                    if (inboxDocuments) {
                        const updatedInboxDocuments = inboxDocuments.filter(
                            doc => doc.documentId !== selectedDocumentToDelete.documentId,
                        );
                        setInboxDocuments(updatedInboxDocuments);
                    }
                    setDeletionLoading(false);
                    setDelitionSuccess(true);
                })
                .catch(() => {
                    setDeletionLoading(false);
                    setDeleteError(true);
                });
        }
    };

    const handleDeletionConfrimButton = () => {
        if (deleteError) {
            setShowDeleteModal(false);
            setDeleteError(false);
        } else if (deletionSuccess) {
            setShowDeleteModal(false);
            setDelitionSuccess(false);
        } else {
            deleteDocument();
        }
    };

    const handleCloseModal = (): void => {
        if (!deletionLoading) {
            setShowDeleteModal(false);
            setDeleteError(false);
            setDelitionSuccess(false);
        }
    };

    if (!inboxDocuments) {
        return null;
    }

    const documentsAvailable = inboxDocuments.length > 0;

    const getDocumentDescription = (document: InboxDocument): string => {
        return document.description;
    };

    function getAllDocumentTypes(inboxDocuments: InboxDocument[]) {
        const allDocumentTypes = inboxDocuments.map(inboxDocument => getDocumentDescription(inboxDocument));
        return Array.from(new Set(allDocumentTypes));
    }

    const documentTypeFilters: DocumentTypeFilterItem[] = getAllDocumentTypes(inboxDocuments).map(inboxDocumentType => {
        const documentTypeFilterItem: DocumentTypeFilterItem = {
            documentType: inboxDocumentType,
            value: inboxDocumentType,
        };
        return documentTypeFilterItem;
    });

    const contractIdentifierFilter: ContractIdentifierFilterItem[] = contracts
        ? contracts.map(contract => {
              const contractIdentifierFilterItem: ContractIdentifierFilterItem = {
                  contractIdentifier: isFourSalesContract(contract) ? contract.contractId : contract.contractNumber,
                  reactNode: <ContractFilterItem contract={contract} />,
                  value: contract.contractNumber,
              };
              return contractIdentifierFilterItem;
          })
        : [];

    const timeFrameFilter: TimeFrameFilterItem[] = getAvailableTimeFrames(inboxDocuments).map(availableTimeFrame => {
        const timeFrame: TimeFrameFilterItem = {
            from: availableTimeFrame.from,
            key: availableTimeFrame.key,
            to: availableTimeFrame.to,
            value: t(availableTimeFrame.value),
        };
        return timeFrame;
    });

    const allLabel = t('all');

    const inboxFilters: PostboxFilterProps = {
        contractIdentifierFilter: {
            label: t('contract-filter'),
            filterItems: contractIdentifierFilter,
            allLabel,
        },
        documentTypeFilter: {
            label: t('document-type-filter'),
            filterItems: documentTypeFilters,
            allLabel,
        },
        timeFrameFilter: {
            label: t('timeframe-filter'),
            filterItems: timeFrameFilter,
            allLabel,
        },
    };

    const InboxDocumentsRows: InboxDocumentRow[] = inboxDocuments.map((inboxDocument, index) => {
        const getDocumentSubject = () => {
            const contract = contracts?.find(contract => contract.contractNumber === inboxDocument.contractNumber);
            const contractNumber = contract ? `(${contract.contractNumber})` : '';
            return `${getDocumentDescription(inboxDocument)} ${contractNumber}`;
        };
        const row: InboxDocumentRow = {
            contractIdentifier: inboxDocument.contractNumber,
            date: formatCpDate(inboxDocument.creationDate).toMoment(),
            documentId: index,
            loading: !!loadingDocuments[index],
            documentType: getDocumentDescription(inboxDocument),
            onClick: () => downloadDocument(index, inboxDocument),
            onDeleteClick: () => {
                setSelectedDocumentToDelete(inboxDocument);
                setShowDeleteModal(true);
            },
            showDelete: true,
            subject: getDocumentSubject(),
            read: inboxDocument.isRead,
            tooltipContent: t('table.unread-item'),
        };
        return row;
    });

    const inboxTableProps: PostboxTableProps = {
        documents: InboxDocumentsRows,
        locale: 'de',
        tableHeaderColumnLabels: { date: t('table.date'), subject: t('table.document'), delete: t('table.delete') },
        noDocumentsSelectedErrorText: t('error-messages.check-the-filter'),
        deleteShowLabel: false,
        sortingOrder: 'DESC',
    };

    const renderDeletionModalContent = () => {
        if (deletionLoading) {
            return <CenteredSpinner />;
        }
        if (deleteError) {
            return <Paragraph>{t('delete-modal.messages.error')}</Paragraph>;
        }
        if (deletionSuccess) {
            return t('delete-modal.messages.success');
        }
        if (selectedDocumentToDelete) {
            return <Paragraph>{t('delete-modal.text')}</Paragraph>;
        }
    };

    const renderDeletionModalOkButton = () => {
        if (deleteError || deletionSuccess) {
            return t('translation:editableSectionNav.ok');
        }

        if (deletionLoading) {
            // hide button
            return undefined;
        }

        return t('translation:editableSectionNav.delete');
    };

    const deletionLoadingErrorOrSuccess = deleteError || deletionLoading || deletionSuccess;

    if (!documentsAvailable) {
        return (
            <Notification
                className="u-mt c-notification--context"
                status={NotificationStatus.warning}
                text={t('error-messages.no-documents-available')}
                testId={'no-contracts-error'}
            />
        );
    }

    return (
        <>
            <Postbox
                filters={inboxFilters}
                defaultContractIdentifier={contractNumber}
                table={inboxTableProps}
                resetButtonText={t('clear-all-filters')}
            />

            {selectedDocumentToDelete && (
                <Modal
                    buttonCancelLabel={t('translation:editableSectionNav.cancel')}
                    //hides the cancel button on deletionLoading
                    buttonCancelText={
                        deletionLoadingErrorOrSuccess ? undefined : t('translation:editableSectionNav.cancel')
                    }
                    buttonConfirmLabel={renderDeletionModalOkButton()}
                    buttonConfirmText={renderDeletionModalOkButton()}
                    onCancel={handleCloseModal}
                    onClose={handleCloseModal}
                    onClickOutside={handleCloseModal}
                    onConfirm={(): void => handleDeletionConfrimButton()}
                    confirmationDisabled={deletionLoading}
                    hideCloseButton={deletionLoading}
                    shown={showDeleteModal}
                >
                    {renderDeletionModalContent()}
                </Modal>
            )}

            <Modal
                shown={!!downloadError}
                buttonConfirmText={t('translation:editableSectionNav.close')}
                onConfirm={(): void => setDownloadError(false)}
                onClose={(): void => setDownloadError(false)}
                title={t('error-modal.title')}
                status="error"
                testId={testIds.downloadFailureModal}
            >
                {t('error-modal.text')}
            </Modal>
        </>
    );
};
