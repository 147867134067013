import React from 'react';
import { useHistory } from 'react-router';
import { Carousel, HeroImage } from '@cp-shared-8/frontend-ui';
import { Contract } from '@cp-de/common';
import { ButtonUrlDataSelector } from './ButtonUrlSelector';
import { fetchButtonUrl } from './ButtonUrlSlice';
import { useTranslation } from 'react-i18next';
import { FlickityOptions } from 'react-flickity-component';
import { Layout } from '@vwfs-bronson/bronson-react';
import { getMostRecentFinancingContract } from './utils';
import { useGetSimpleApiData } from '@cp-shared-8/frontend-integration';

type HeroData = {
    title: string;
    text: string;
    imageUrl: string;
    button: {
        title: string;
        link: string;
        isLocal: boolean;
    };
};

interface DashboardHeroTeasersProps {
    contracts?: Contract[];
    userWithMotorInsurance: boolean;
    userWithAutoAbo: boolean;
}

export const DashboardHeroTeaserUi: React.FC<DashboardHeroTeasersProps> = ({
    contracts,
    userWithMotorInsurance,
    userWithAutoAbo,
}) => {
    const { t } = useTranslation('marketing-card');
    const history = useHistory();
    const options: FlickityOptions = {
        adaptiveHeight: false,
        autoPlay: 10000,
    };

    const financingContract = getMostRecentFinancingContract(contracts || []);
    const prefixTraciParam = t(`financial-contracts-only.button.traci-parameter.prefix`);
    const vwTraciParam = t(`financial-contracts-only.button.traci-parameter.brand.vw`);
    const audiTraciParam = t(`financial-contracts-only.button.traci-parameter.brand.audi`);
    const seatTraciParam = t(`financial-contracts-only.button.traci-parameter.brand.seat`);
    const skodaTraciParam = t(`financial-contracts-only.button.traci-parameter.brand.skoda`);
    const cupraTraciParam = t(`financial-contracts-only.button.traci-parameter.brand.cupra`);
    const suffixTraciParam = t(`financial-contracts-only.button.traci-parameter.sufix`);
    const carBrand = financingContract?.vehicle?.carBrand?.toLocaleLowerCase();

    const getBrandTraciParam = (carBrand?: string) => {
        let brandTraciParam = '';
        switch (carBrand) {
            case 'vw':
                brandTraciParam = vwTraciParam;
                break;
            case 'audi':
                brandTraciParam = audiTraciParam;
                break;
            case 'seat':
                brandTraciParam = seatTraciParam;
                break;
            case 'skoda':
            case 'škoda':
                brandTraciParam = skodaTraciParam;
                break;
            case 'cup' ||
                'cupra' /*Cupra cars are currently delivered by BE and  interpreted in CP as SEAT brand. If this shall be extended later to Cupra, BE systems have to deliver a new brand code for Cupra.*/:
                brandTraciParam = cupraTraciParam;
                break;
        }
        return brandTraciParam;
    };

    const { data: buttonUrl } = useGetSimpleApiData(
        fetchButtonUrl,
        ButtonUrlDataSelector,
        false,
        {
            data: {
                licensePlate: financingContract?.vehicle?.licensePlate,
                vehicleModelName: financingContract?.vehicle?.carModel,
                brand: financingContract?.vehicle?.carBrand,
                dealerNumber: financingContract?.dealer?.dealerNumber,
            },
        },
        undefined,
        !!financingContract,
    );

    const getFinancialContractsUrl = (): string => {
        return buttonUrl && 'url' in buttonUrl
            ? `${buttonUrl.url}${buttonUrl.url?.includes('?') ? '&' : '?'}${prefixTraciParam}${getBrandTraciParam(
                  carBrand,
              )}${suffixTraciParam}`
            : t('financial-contracts-only.button.link');
    };

    const heroTeaserData: HeroData[] = [
        {
            title: t('financial-contracts-only.title'),
            text: t('financial-contracts-only.text'),
            imageUrl: t('financial-contracts-only.imageUrl'),
            button: {
                title: t('financial-contracts-only.button.title'),
                link: getFinancialContractsUrl(),
                isLocal: false,
            },
        },
    ];

    if (!userWithMotorInsurance) {
        heroTeaserData.unshift({
            title: t('user-with-any-contract.title'),
            text: t('user-with-any-contract.text'),
            imageUrl: t('user-with-any-contract.imageUrl'),
            button: {
                title: t('user-with-any-contract.button.title'),
                link: t('user-with-any-contract.button.link'),
                isLocal: false,
            },
        });
    }

    if (!userWithAutoAbo) {
        heroTeaserData.unshift({
            title: t('user-with-auto-abo.hero-title'),
            text: t('user-with-auto-abo.hero-text'),
            imageUrl: t('user-with-auto-abo.imageUrl'),
            button: {
                title: t('user-with-auto-abo.button.title'),
                link: t('user-with-auto-abo.button.link'),
                isLocal: false,
            },
        });
    }

    return (
        <Layout>
            <Layout.Item className={'u-p-none u-overflow-hidden'}>
                <Carousel options={options}>
                    {heroTeaserData.map((element, elementIndex) => (
                        <HeroImage
                            key={elementIndex}
                            title={element.title}
                            subTitle={element.text}
                            imageUrl={element.imageUrl}
                            buttonText={element.button.title}
                            clickHandler={(): void => {
                                element.button.isLocal
                                    ? history.push(element.button.link)
                                    : window.open(element.button.link, '_blank');
                            }}
                        />
                    ))}
                </Carousel>
            </Layout.Item>
        </Layout>
    );
};
