import * as Yup from 'yup';

export type ContactDetailsChangeValidationMessages = {
    requiredEmailError: string;
    invalidEmailError: string;
    requiredMobilePhoneError: string;
    invalidMobilePhoneError: string;
};

export const contactDetailsValidationSchema = (errorMessages: ContactDetailsChangeValidationMessages) => {
    return Yup.object().shape({
        email: Yup.string()
            .trim()
            .required(errorMessages.requiredEmailError)
            .email(errorMessages.invalidEmailError),
        mobile: Yup.object().shape({
            number: Yup.string()
                .trim()
                .required(errorMessages.requiredMobilePhoneError)
                .min(9, errorMessages.invalidMobilePhoneError)
                .max(20, errorMessages.invalidMobilePhoneError)
                .matches(/^[\d ]*$/, errorMessages.invalidMobilePhoneError),
        }),
    });
};
