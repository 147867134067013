import React from 'react';
import { getChangeAddressEndpoint, UserType } from '@cp-de/common';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { ResponseWithAddressChange, ResponseWithAddressSuggestions } from './change-address/ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ResponseWithAddressChange,
    },
    'Success With Address Sugestions': {
        status: 200,
        responseBody: ResponseWithAddressSuggestions,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const AddressMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Change address',
    method: 'put',
    defaultMockOption: 'Success With Address Sugestions',
    mockOptions,
    url: getChangeAddressEndpoint(),
};

export const AutoAboAddressMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'AutoAbo change address',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: mockOptions,
    url: getChangeAddressEndpoint(UserType.AUTO_ABO),
};

export const AddressResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [AddressMock, AutoAboAddressMock]);

    return <div>{storyFn()}</div>;
};
