import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { AnyObject } from 'yup/lib/types';

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+")){2,}@[-a-zA-Z0-9]{2,}.[a-zA-Z]{2,}$/;

export const validationSchema = (t: TFunction): Yup.ObjectSchema<AnyObject> =>
    Yup.object().shape({
        dateOfNextInstallment: Yup.string().required(t('form-validation.date-of-next-installment')),
        customerConsent: Yup.bool().oneOf([true], t('form-validation.customer-consent')),
        customerMailAddress: Yup.string()
            .required(t('form-validation.customer-mail-address'))
            .matches(emailPattern, t('form-validation.customer-mail-address')),
    });
