import * as Yup from 'yup';

export type PurchasePriceInquiryValidationMessages = {
    dateRequired: string;
    mileageRequired: string;
    invalidMileage: string;
    maxMileageExceeded: string;
    emailRequired: string;
    invalidEmail: string;
};

const defaultValidationErrorMessages: PurchasePriceInquiryValidationMessages = {
    dateRequired: 'Date is required',
    mileageRequired: 'Mileage is required',
    invalidMileage: 'Invalid mileage',
    maxMileageExceeded: 'Max mileage is exceeded',
    emailRequired: 'Email is required',
    invalidEmail: 'Invalid email',
};

export const purchasePriceInquiryValidationSchema = (
    errorMessages: PurchasePriceInquiryValidationMessages | undefined = defaultValidationErrorMessages,
    maxMileage: number,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
): Yup.ObjectSchema =>
    Yup.object().shape({
        date: Yup.string().required(errorMessages.dateRequired),

        mileage: Yup.string()
            .required(errorMessages.mileageRequired)
            .test('mileageRequired', errorMessages.mileageRequired, value => +(value || 0) !== 0)
            .matches(/^([1-9]{1}[0-9]*)$/, errorMessages.invalidMileage)
            .test('maxMileageExceeded', errorMessages.maxMileageExceeded, value => +(value || 0) <= maxMileage),
        email: Yup.string()
            .required(errorMessages.emailRequired)
            .email(errorMessages.invalidEmail),
    });
