// Do not import with ".." only, as it leads to cyclic dependencies!
import React from 'react';

import { NoConnectionNotification } from '../notifications/no-connection/NoConnectionNotification';

import { getDisplayName } from '@cp-shared-8/frontend-ui';

export type withNoConnectionHandlerProps = {
    hasError: boolean;
    errorTitle?: string;
    errorText?: string | React.ReactNode;
};

export const withNoConnectionHandler = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<TProps & withNoConnectionHandlerProps> => {
    const Wrapper: React.FC<TProps & withNoConnectionHandlerProps> = ({
        hasError,
        errorTitle,
        errorText,
        ...props
    }: withNoConnectionHandlerProps) =>
        hasError ? (
            <NoConnectionNotification errorTitle={errorTitle ?? ''} errorText={errorText} />
        ) : (
            <WrappedComponent {...(props as TProps)} />
        );

    Wrapper.displayName = `withNoConnectionHandler(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
