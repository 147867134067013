export enum YesNoButtons {
    yes = 'yes',
    no = 'no',
}

export type RadioButton = {
    label: string;
    value: string | number | readonly string[] | undefined;
    tooltip?: string;
    disabled?: boolean;
};

export enum SubmittingStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_PERFORMED = 'NOT_PERFORMED',
}
