import { findLatestContract, getActiveReservationContracts } from './contracts-utils';
import { useEffect, useState } from 'react';
import { useUserContractsDataAndFlags } from '../Providers';
import { UserType } from '@cp-de/common';

type UseUserTypeFlagType = {
    userType?: UserType;
    isLoading: boolean;
};

export const useUserTypeFlag = (): UseUserTypeFlagType => {
    const {
        aboContracts: { data: aboContracts, hasReceivedResponse: hasReceivedAboContractsResponse },
        aodContracts: { data: aodContracts, hasReceivedResponse: hasReceivedAodContractsResponse },
        racContracts: { data: racContracts, hasReceivedResponse: hasReceivedRacContractsResponse },
        fourSalesContracts: { data: fourSalesContracts, hasReceivedResponse: hasReceivedFourSalesContractsResponse },
        isLoadingPermission,
        isFetchingStandardAllowed,
        isFetchingAboAllowed,
        isFetchingAodAllowed,
        isFetchingRacAllowed,
        isFetchingFourSalesAllowed,
    } = useUserContractsDataAndFlags();

    const [isLoadingFinished, setIsLoadingFinished] = useState(false);

    useEffect(() => {
        if (isFetchingStandardAllowed) {
            setIsLoadingFinished(true);
        } else if (
            !isLoadingPermission &&
            (isFetchingAboAllowed ? hasReceivedAboContractsResponse : true) &&
            (isFetchingAodAllowed ? hasReceivedAodContractsResponse : true) &&
            (isFetchingRacAllowed ? hasReceivedRacContractsResponse : true) &&
            (isFetchingFourSalesAllowed ? hasReceivedFourSalesContractsResponse : true)
        ) {
            setIsLoadingFinished(true);
        }
    }, [
        isLoadingPermission,
        isFetchingStandardAllowed,
        isFetchingAboAllowed,
        isFetchingAodAllowed,
        isFetchingRacAllowed,
        isFetchingFourSalesAllowed,
        hasReceivedAboContractsResponse,
        hasReceivedAodContractsResponse,
        hasReceivedRacContractsResponse,
        hasReceivedFourSalesContractsResponse,
    ]);

    if (isLoadingFinished) {
        if (isFetchingStandardAllowed) {
            return {
                userType: UserType.STANDARD,
                isLoading: false,
            };
        }

        const reservationContracts = [...(aodContracts || []), ...(racContracts || [])];
        const activeReservationContracts = getActiveReservationContracts(reservationContracts);

        const latestContract = !!aboContracts?.length
            ? findLatestContract([...(aboContracts || []), ...activeReservationContracts])
            : !!activeReservationContracts.length
            ? findLatestContract(activeReservationContracts)
            : !!reservationContracts.length
            ? findLatestContract(reservationContracts)
            : findLatestContract(fourSalesContracts);

        const getSecondaryUserType = (): UserType | undefined => {
            switch (latestContract?.contractType) {
                case 'AUTO_ABO':
                    return UserType.AUTO_ABO;
                case 'AUDI_ON_DEMAND':
                    return UserType.AOD;
                case 'RENT_A_CAR':
                    return UserType.RAC;
                case 'FOUR_SALES':
                    return UserType.FOUR_SALES;
                default:
                    return;
            }
        };

        return {
            userType: getSecondaryUserType(),
            isLoading: false,
        };
    }
    return {
        userType: undefined,
        isLoading: true,
    };
};
