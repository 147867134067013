import * as Yup from 'yup';
import { TFunction } from 'i18next';

import { AddressChange } from '@cp-de/common';
import { AnyObject } from 'yup/lib/types';

export const errorMessageIds = {
    street: {
        empty: 'change-address.street.error.empty',
        length: 'change-address.street.error.length',
    },
    houseNumber: {
        empty: 'change-address.house-number.error.empty',
        length: 'change-address.house-number.error.length',
    },
    zipCode: {
        empty: 'change-address.zip-code.error.empty',
        length: 'change-address.zip-code.error.length',
    },
    city: {
        empty: 'change-address.city.error.empty',
        length: 'change-address.city.error.length',
    },
};

export const validationSchema = (t: TFunction): Yup.ObjectSchema<AnyObject> => {
    const validations: Record<keyof AddressChange, Yup.StringSchema> = {
        street: Yup.string()
            .trim()
            .required(t(errorMessageIds.street.empty))
            .max(60, t(errorMessageIds.street.length)),
        houseNumber: Yup.string()
            .trim()
            .required(t(errorMessageIds.houseNumber.empty))
            .max(10, t(errorMessageIds.houseNumber.length)),
        zipCode: Yup.string()
            .trim()
            .required(t(errorMessageIds.zipCode.empty))
            .min(5, t(errorMessageIds.zipCode.length)),
        city: Yup.string()
            .trim()
            .required(t(errorMessageIds.city.empty))
            .max(40, t(errorMessageIds.city.length)),
    };
    return Yup.object().shape(validations);
};
