import React, { useState } from 'react';
import { CustomerReservationDetails } from '@cp-de/common';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { changeAutoAboAddressPath } from '../../../../../navigation/paths';
import { EditView } from './edit-view/EditView';
import { ConsultView } from './consult-view/ConsultView';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

type AddressSectionProps = { details: CustomerReservationDetails };

export const AddressSection: React.FC<AddressSectionProps> = ({ details }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { t, ready } = useTranslation('auto-abo-customer-details', { useSuspense: false });
    const [showEditSuccessMessage, setShowEditSuccessMessage] = useState<boolean>(false);

    const { addresses = [] } = details;

    const backToNonEditMode = (): void => {
        setShowEditSuccessMessage(() => false);
        history.push(path);
    };

    const { onAction } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onEditProfileAddressSuccess');

    const startEditing = (): void => {
        onAction();
        setShowEditSuccessMessage(false);
        history.push(changeAutoAboAddressPath());
    };

    return (
        <>
            {ready && (
                <Switch>
                    <Route exact path={changeAutoAboAddressPath()}>
                        <EditView
                            setShowEditSuccessMessage={setShowEditSuccessMessage}
                            backToNonEditMode={backToNonEditMode}
                            onSuccess={onSuccess}
                        />
                    </Route>
                    <Route path={path}>
                        <ConsultView
                            addresses={addresses}
                            successMessage={showEditSuccessMessage ? t('addresses.change-address.success') : undefined}
                            startEditing={startEditing}
                        />
                    </Route>
                </Switch>
            )}
        </>
    );
};
